import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { IBookingManagerFinanceSubdomain, EFinanceTableTypes } from './types';

export const initialState: IBookingManagerFinanceSubdomain = {
  financeDocument: {
    rows: [],
  },
  financeDocumentBalance: {
    totalCreditCents: 0,
    totalDebitCents: 0,
    totalOutstandingCents: 0,
  },
  isAddModalOpen: false,
  isDeleteModalOpen: false,
  isEditModalOpen: false,
  versionList: [],
  activeVersion: null,
  financeTableTypeToRender: EFinanceTableTypes.SALES,
  deleteRowIndex: null,
  editRowIndex: null,
  paymentMethods: {
    list: null,
    defaultPaymentMethodPerCurrency: []
  },
  errorMessages: {
    addRowAndSaveError: null,
    editRowAndSaveError: null,
  },
  networkRequests: {
    financeDocumentLoad: ENetworkRequestStatus.IDLE,
    financeDocumentBalanceLoad: ENetworkRequestStatus.IDLE,
    saveAndUpdatefinanceDocumentLoad: ENetworkRequestStatus.IDLE,
    addRowAndSaveLoad: ENetworkRequestStatus.IDLE,
    deleteRowAndSaveLoad: ENetworkRequestStatus.IDLE,
    editRowAndSaveLoad: ENetworkRequestStatus.IDLE,
    financeDocumentVersionListLoad: ENetworkRequestStatus.IDLE,
    paymentMethodsLoad: ENetworkRequestStatus.IDLE,
  },
};
