import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BookingBuilderResponse, ENetworkRequestStatus } from "services/BackendApi";
import { IStaticRate } from "../MealPlanDropdown/types";
import { convertMealPlanToStaticRate } from "../helpers";
import { bamBookingBuildRequestAction, setBAMSelectedMealPlanAction, setBAMSelectedOccasionsAction, setBAMSelectedRepeatGuestAction } from "store/modules/bookingManager/subdomains/breakdown/actions";
import { RHPSubtitle } from "./RHPSubtitle";
import { MealPlanDropdown } from "../MealPlanDropdown/MealPlanDropdown";
import Checkbox from 'ui/Checkbox';
import { getCurrencyBySymbol } from "utils";
import { Multiselect } from "ui/Multiselect";
import { BAMSelectedMealPlansSelector, BAMSelectedOccasionsSelector, BAMSelectedRepeatGuestSelector } from "store/modules/bookingManager/subdomains/breakdown/selectors";
import { LoadingBar } from "ui/NetworkStatusBar";

const occasionsMultiselectOptions = [
  { value: 'honeymoon', label: 'Honeymoon' },
  { value: 'birthday', label: 'Birthday' },
  { value: 'anniversary', label: 'Anniversary' },
  { value: 'wedding', label: 'Wedding' },
];

export interface IRHPPageAccommodationsProps {
  selectedBuild: BookingBuilderResponse;
  currencySymbol: string;
  bookingBuildRequest: ENetworkRequestStatus;
  bookingUuid: string;
}

export const RHPPageAccommodations: React.FC<IRHPPageAccommodationsProps> = ({ selectedBuild, bookingBuildRequest, bookingUuid, currencySymbol }) => {
  const dispatch = useDispatch();
  const selectedAccommodation = selectedBuild.availableProductSets.Accommodation[0];
  const mealPlanOptions: IStaticRate[] = selectedAccommodation?.availableSubProductSets['Meal Plan'].map(
    item => convertMealPlanToStaticRate(item)
  ) as unknown as IStaticRate[];
  const selectedMealPlan = useSelector(BAMSelectedMealPlansSelector);
  const selectedOccasions = useSelector(BAMSelectedOccasionsSelector);
  const isRepeatGuest = useSelector(BAMSelectedRepeatGuestSelector);
  
  useLayoutEffect(() => {
    const initiallySelectedMealPlan = selectedAccommodation?.availableSubProductSets['Meal Plan'].find(
      item => item.selected
    );
    if (initiallySelectedMealPlan) {
      dispatch(setBAMSelectedMealPlanAction(convertMealPlanToStaticRate(initiallySelectedMealPlan)));
    }
  }, [dispatch, selectedAccommodation]);

  const handleMealPlanSelect = useCallback((mealPlan: IStaticRate) => {
    dispatch(setBAMSelectedMealPlanAction(mealPlan));
    dispatch(bamBookingBuildRequestAction(bookingUuid));
  }, [dispatch, bookingUuid]);
  
  const handleOccasionsSelect = useCallback(selectedValues => {
    dispatch(
      setBAMSelectedOccasionsAction(selectedValues)
    );
    dispatch(bamBookingBuildRequestAction(bookingUuid));
  }, [dispatch, bookingUuid, selectedMealPlan]);

  const handleRepeatGuestSelect = useCallback(e => {
    dispatch(setBAMSelectedRepeatGuestAction(e.target.checked));
    dispatch(bamBookingBuildRequestAction(bookingUuid));
  }, [dispatch, bookingUuid, selectedMealPlan]);

  return (
    <>
      <div className="flex flex-col gap-[5px] mt-[20px]">
        <RHPSubtitle>Meal Plan</RHPSubtitle>
        {bookingBuildRequest === ENetworkRequestStatus.PENDING || !selectedMealPlan ? (
          <div className="min-h-[35px] mt-[5px]">Loading...</div>
        ) : (
          <MealPlanDropdown
            value={selectedMealPlan}
            options={mealPlanOptions}
            currency={getCurrencyBySymbol(currencySymbol).code}
            onChoose={handleMealPlanSelect}
            maxVisibleItems={5}
            className="mt-[5px]"
          />
        )}
      </div>

      <div className="flex flex-col gap-[5px] mt-[20px]">
        <RHPSubtitle>Occasion</RHPSubtitle>
        <Multiselect
          className=" mt-5px bg-ivory w-full"
          onUpdate={handleOccasionsSelect}
          itemsClassname="bg-ivory"
          itemCtaClassName="hover:bg-gray-10"
          options={occasionsMultiselectOptions}
          selectedValues={selectedOccasions}
          placeholderText="None"
          placeholderClasses="text-black"
        />
      </div>

      <div className="checkbox mt-[20px]">
        <label className="flex items-center justify-start text-black cursor-pointer">
          <Checkbox
            checked={isRepeatGuest}
            onChange={handleRepeatGuestSelect}
          />
          &nbsp;&nbsp;Repeat Guest
        </label>
      </div>
    </>
  )
}
