import { IFinanceRow, IPaymentMethod, IPaymentMethodPerCurrency } from 'services/BookingManagerApi';
import {
  EFinanceTableTypes,
  IFinanceDocument,
  IFinanceDocumentBalance,
  IFinanceDocumentVersionListVersion,
} from './types';

// get the versions
export const GET_FINANCE_DOCUMENT_VERSION_LIST_REQUEST =
  'bookingManager_finance/GET_FINANCE_DOCUMENT_VERSION_LIST_REQUEST';
export const GET_FINANCE_DOCUMENT_VERSION_LIST_SUCCESS =
  'bookingManager_finance/GET_FINANCE_DOCUMENT_VERSION_LIST_SUCCESS';
export const GET_FINANCE_DOCUMENT_VERSION_LIST_FAILURE =
  'bookingManager_finance/GET_FINANCE_DOCUMENT_VERSION_LIST_FAILURE';

export const SET_FINANCE_DOCUMENT_ACTIVE_VERSION = 'bookingManager_finance/SET_FINANCE_DOCUMENT_ACTIVE_VERSION';

// the microdocument
export const GET_FINANCE_DOCUMENT_REQUEST = 'bookingManager_finance/GET_FINANCE_DOCUMENT_REQUEST';
export const GET_FINANCE_DOCUMENT_SUCCESS = 'bookingManager_finance/GET_FINANCE_DOCUMENT_SUCCESS';
export const GET_FINANCE_DOCUMENT_FAILURE = 'bookingManager_finance/GET_FINANCE_DOCUMENT_FAILURE';

// the view for the totals
export const GET_FINANCE_DOCUMENT_BALANCE_REQUEST = 'bookingManager_finance/GET_FINANCE_DOCUMENT_BALANCE_REQUEST';
export const GET_FINANCE_DOCUMENT_BALANCE_SUCCESS = 'bookingManager_finance/GET_FINANCE_DOCUMENT_BALANCE_SUCCESS';
export const GET_FINANCE_DOCUMENT_BALANCE_FAILURE = 'bookingManager_finance/GET_FINANCE_DOCUMENT_BALANCE_FAILURE';

export const ADD_ROW_AND_SAVE_REQUEST = 'bookingManager_finance/ADD_ROW_AND_SAVE_REQUEST';
export const ADD_ROW_AND_SAVE_SUCCESS = 'bookingManager_finance/ADD_ROW_AND_SAVE_SUCCESS';
export const ADD_ROW_AND_SAVE_FAILURE = 'bookingManager_finance/ADD_ROW_AND_SAVE_FAILURE';

export const DELETE_ROW_AND_SAVE_REQUEST = 'bookingManager_finance/DELETE_ROW_AND_SAVE_REQUEST';
export const DELETE_ROW_AND_SAVE_SUCCESS = 'bookingManager_finance/DELETE_ROW_AND_SAVE_SUCCESS';
export const DELETE_ROW_AND_SAVE_FAILURE = 'bookingManager_finance/DELETE_ROW_AND_SAVE_FAILURE';

export const EDIT_ROW_AND_SAVE_REQUEST = 'bookingManager_finance/EDIT_ROW_AND_SAVE_REQUEST';
export const EDIT_ROW_AND_SAVE_SUCCESS = 'bookingManager_finance/EDIT_ROW_AND_SAVE_SUCCESS';
export const EDIT_ROW_AND_SAVE_FAILURE = 'bookingManager_finance/EDIT_ROW_AND_SAVE_FAILURE';

export const SET_IS_ADD_MODAL_OPEN = 'bookingManager_finance/SET_IS_ADD_MODAL_OPEN';
export const SET_IS_EDIT_MODAL_OPEN = 'bookingManager_finance/SET_IS_EDIT_MODAL_OPEN';
export const SET_IS_DELETE_MODAL_OPEN = 'bookingManager_finance/SET_IS_DELETE_MODAL_OPEN';
export const SET_DELETE_ROW_INDEX = 'bookingManager_finance/SET_DELETE_ROW_INDEX';
export const SET_EDIT_ROW_INDEX = 'bookingManager_finance/SET_EDIT_ROW_INDEX';
export const SET_FINANCE_TABLE_TYPE_TO_RENDER = 'bookingManager/SET_FINANCE_TABLE_TYPE_TO_RENDER';

export const GET_PAYMENT_METHODS_REQUEST = 'bookingManager_finance/GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'bookingManager_finance/GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = 'bookingManager_finance/GET_PAYMENT_METHODS_FAILURE';

export type GetFinanceDocumentVersionListRequestAction = ReturnType<typeof getFinanceDocumentVersionListRequestAction>;
export const getFinanceDocumentVersionListRequestAction = () => ({
  type: GET_FINANCE_DOCUMENT_VERSION_LIST_REQUEST as typeof GET_FINANCE_DOCUMENT_VERSION_LIST_REQUEST,
});
export type GetFinanceDocumentVersionListSuccessAction = ReturnType<typeof getFinanceDocumentVersionListSuccessAction>;
export const getFinanceDocumentVersionListSuccessAction = (versionList: IFinanceDocumentVersionListVersion[]) => ({
  type: GET_FINANCE_DOCUMENT_VERSION_LIST_SUCCESS as typeof GET_FINANCE_DOCUMENT_VERSION_LIST_SUCCESS,
  versionList,
});
export type GetFinanceDocumentVersionListFailureAction = ReturnType<typeof getFinanceDocumentVersionListFailureAction>;
export const getFinanceDocumentVersionListFailureAction = (error: string) => ({
  type: GET_FINANCE_DOCUMENT_VERSION_LIST_FAILURE as typeof GET_FINANCE_DOCUMENT_VERSION_LIST_FAILURE,
  error,
});
export type SetFinanceDocumentActiveVersionRequestAction = ReturnType<
  typeof setFinanceDocumentActiveVersionRequestAction
>;
export const setFinanceDocumentActiveVersionRequestAction = (versionNum: number) => ({
  type: SET_FINANCE_DOCUMENT_ACTIVE_VERSION as typeof SET_FINANCE_DOCUMENT_ACTIVE_VERSION,
  versionNum,
});

export type GetFinanceDocumentRequestAction = ReturnType<typeof getFinanceDocumentRequestAction>;
export const getFinanceDocumentRequestAction = () => ({
  type: GET_FINANCE_DOCUMENT_REQUEST as typeof GET_FINANCE_DOCUMENT_REQUEST,
});

export type GetFinanceDocumentSuccessAction = ReturnType<typeof getFinanceDocumentSuccessAction>;
export const getFinanceDocumentSuccessAction = (financeDocument: IFinanceDocument) => ({
  type: GET_FINANCE_DOCUMENT_SUCCESS as typeof GET_FINANCE_DOCUMENT_SUCCESS,
  financeDocument,
});

export type GetFinanceDocumentFailureAction = ReturnType<typeof getFinanceDocumentFailureAction>;
export const getFinanceDocumentFailureAction = (error: string) => ({
  type: GET_FINANCE_DOCUMENT_FAILURE as typeof GET_FINANCE_DOCUMENT_FAILURE,
  error,
});

export type AddRowAndSaveRequestAction = ReturnType<typeof addRowAndSaveRequestAction>;
export const addRowAndSaveRequestAction = (financeRowWithoutUpload: IFinanceRow, files: File[]) => ({
  type: ADD_ROW_AND_SAVE_REQUEST as typeof ADD_ROW_AND_SAVE_REQUEST,
  financeRowWithoutUpload,
  files,
});

export type AddRowAndSaveSuccessAction = ReturnType<typeof addRowAndSaveSuccessAction>;
export const addRowAndSaveSuccessAction = (rows: IFinanceRow[]) => ({
  type: ADD_ROW_AND_SAVE_SUCCESS as typeof ADD_ROW_AND_SAVE_SUCCESS,
  rows,
});

export type AddRowAndSaveFailureAction = ReturnType<typeof addRowAndSaveFailureAction>;
export const addRowAndSaveFailureAction = (error: string) => ({
  type: ADD_ROW_AND_SAVE_FAILURE as typeof ADD_ROW_AND_SAVE_FAILURE,
  error,
});

export type SetIsAddModalOpenAction = ReturnType<typeof setIsAddModalOpenAction>;
export const setIsAddModalOpenAction = (value: boolean) => ({
  type: SET_IS_ADD_MODAL_OPEN as typeof SET_IS_ADD_MODAL_OPEN,
  value,
});

export type SetIsEditModalOpenAction = ReturnType<typeof setIsEditModalOpenAction>;
export const setIsEditModalOpenAction = (value: boolean) => ({
  type: SET_IS_EDIT_MODAL_OPEN as typeof SET_IS_EDIT_MODAL_OPEN,
  value,
});

// delete
export type DeleteRowAndSaveRequestAction = ReturnType<typeof deleteRowAndSaveRequestAction>;
export const deleteRowAndSaveRequestAction = () => ({
  type: DELETE_ROW_AND_SAVE_REQUEST as typeof DELETE_ROW_AND_SAVE_REQUEST,
});

export type DeleteRowAndSaveSuccessAction = ReturnType<typeof deleteRowAndSaveSuccessAction>;
export const deleteRowAndSaveSuccessAction = (rows: IFinanceRow[]) => ({
  type: DELETE_ROW_AND_SAVE_SUCCESS as typeof DELETE_ROW_AND_SAVE_SUCCESS,
  rows,
});

export type DeleteRowAndSaveFailureAction = ReturnType<typeof deleteRowAndSaveFailureAction>;
export const deleteRowAndSaveFailureAction = (error: string) => ({
  type: DELETE_ROW_AND_SAVE_FAILURE as typeof DELETE_ROW_AND_SAVE_FAILURE,
  error,
});

export type SetIsDeleteModalOpenAction = ReturnType<typeof setIsDeleteModalOpenAction>;
export const setIsDeleteModalOpenAction = (value: boolean) => ({
  type: SET_IS_DELETE_MODAL_OPEN as typeof SET_IS_DELETE_MODAL_OPEN,
  value,
});

export type SetDeleteRowIndexAction = ReturnType<typeof setDeleteRowIndexAction>;
export const setDeleteRowIndexAction = (value: number) => ({
  type: SET_DELETE_ROW_INDEX as typeof SET_DELETE_ROW_INDEX,
  value,
});

export type SetEditRowIndexAction = ReturnType<typeof setEditRowIndexAction>;
export const setEditRowIndexAction = (value: number) => ({
  type: SET_EDIT_ROW_INDEX as typeof SET_EDIT_ROW_INDEX,
  value,
});

// edit
export type EditRowAndSaveRequestAction = ReturnType<typeof editRowAndSaveRequestAction>;
export const editRowAndSaveRequestAction = (amendedFinanceRow: IFinanceRow, files: File[]) => ({
  type: EDIT_ROW_AND_SAVE_REQUEST as typeof EDIT_ROW_AND_SAVE_REQUEST,
  amendedFinanceRow,
  files,
});

export type EditRowAndSaveSuccessAction = ReturnType<typeof editRowAndSaveSuccessAction>;
export const editRowAndSaveSuccessAction = (rows: IFinanceRow[]) => ({
  type: EDIT_ROW_AND_SAVE_SUCCESS as typeof EDIT_ROW_AND_SAVE_SUCCESS,
  rows,
});

export type EditRowAndSaveFailureAction = ReturnType<typeof editRowAndSaveFailureAction>;
export const editRowAndSaveFailureAction = (error: string) => ({
  type: EDIT_ROW_AND_SAVE_FAILURE as typeof EDIT_ROW_AND_SAVE_FAILURE,
  error,
});

export type GetFinanceDocumentBalanceRequestAction = ReturnType<typeof getFinanceDocumentBalanceRequestAction>;
export const getFinanceDocumentBalanceRequestAction = () => ({
  type: GET_FINANCE_DOCUMENT_BALANCE_REQUEST as typeof GET_FINANCE_DOCUMENT_BALANCE_REQUEST,
});

export type GetFinanceDocumentBalanceSuccessAction = ReturnType<typeof getFinanceDocumentBalanceSuccessAction>;
export const getFinanceDocumentBalanceSuccessAction = (financeDocumentBalance: IFinanceDocumentBalance) => ({
  type: GET_FINANCE_DOCUMENT_BALANCE_SUCCESS as typeof GET_FINANCE_DOCUMENT_BALANCE_SUCCESS,
  financeDocumentBalance,
});

export type GetFinanceDocumentBalanceFailureAction = ReturnType<typeof getFinanceDocumentBalanceFailureAction>;
export const getFinanceDocumentBalanceFailureAction = (error: string) => ({
  type: GET_FINANCE_DOCUMENT_BALANCE_FAILURE as typeof GET_FINANCE_DOCUMENT_BALANCE_FAILURE,
  error,
});

export type SetFinanceTableTypeToRenderAction = ReturnType<typeof setFinanceTableTypeToRenderAction>;
export const setFinanceTableTypeToRenderAction = (value: EFinanceTableTypes) => ({
  type: SET_FINANCE_TABLE_TYPE_TO_RENDER as typeof SET_FINANCE_TABLE_TYPE_TO_RENDER,
  value,
});

export type GetPaymentMethodsRequestAction = ReturnType<typeof getPaymentMethodsRequestAction>;
export const getPaymentMethodsRequestAction = () => ({
  type: GET_PAYMENT_METHODS_REQUEST as typeof GET_PAYMENT_METHODS_REQUEST,
});

export type GetPaymentMethodsSuccessAction = ReturnType<typeof getPaymentMethodsSuccessAction>;
export const getPaymentMethodsSuccessAction = (paymentMethods: IPaymentMethod[], defaultPaymentMethodPerCurrency: IPaymentMethodPerCurrency[]) => ({
  type: GET_PAYMENT_METHODS_SUCCESS as typeof GET_PAYMENT_METHODS_SUCCESS,
  paymentMethods,
  defaultPaymentMethodPerCurrency
});

export type GetPaymentMethodsFailureAction = ReturnType<typeof getPaymentMethodsFailureAction>;
export const getPaymentMethodsFailureAction = () => ({
  type: GET_PAYMENT_METHODS_FAILURE as typeof GET_PAYMENT_METHODS_FAILURE,
});

export type FinanceAction =
  | GetFinanceDocumentVersionListRequestAction
  | GetFinanceDocumentVersionListSuccessAction
  | GetFinanceDocumentVersionListFailureAction
  | SetFinanceDocumentActiveVersionRequestAction
  | GetFinanceDocumentRequestAction
  | GetFinanceDocumentSuccessAction
  | GetFinanceDocumentFailureAction
  | AddRowAndSaveRequestAction
  | AddRowAndSaveSuccessAction
  | AddRowAndSaveFailureAction
  | SetIsAddModalOpenAction
  | SetIsEditModalOpenAction
  | DeleteRowAndSaveRequestAction
  | DeleteRowAndSaveSuccessAction
  | DeleteRowAndSaveFailureAction
  | SetIsDeleteModalOpenAction
  | SetDeleteRowIndexAction
  | SetEditRowIndexAction
  | EditRowAndSaveRequestAction
  | EditRowAndSaveSuccessAction
  | EditRowAndSaveFailureAction
  | GetFinanceDocumentBalanceRequestAction
  | GetFinanceDocumentBalanceSuccessAction
  | GetFinanceDocumentBalanceFailureAction
  | SetFinanceTableTypeToRenderAction
  | GetPaymentMethodsRequestAction
  | GetPaymentMethodsSuccessAction
  | GetPaymentMethodsFailureAction;
