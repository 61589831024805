import { usePagination } from 'hooks/usePagination';
import { Pagination } from 'pureUi/Pagination';
import React, { useEffect, useState } from 'react';
import { ENetworkRequestStatus, IHotel, makeBackendApi } from 'services/BackendApi';
import { ITableColumn } from 'ui/Table/types';
import { Table } from 'ui/Table';
import { ESortOrder } from 'store/common/types';
import { isNil } from 'lodash-es';
import { Link } from 'ui/Link';
import * as Inputs from './components/Inputs';
import { TCountryCode } from 'interfaces';
import { useSelector } from 'react-redux';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import FluidButton from 'ui/FluidButton';
import { MenuButton } from 'ui/MenuButton/stateful';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';

const tableColumns: ITableColumn[] = [
  {
    id: 'name',
    label: 'Name',
    width: '210px',
    fieldToSortBy: 'name',
  },
  {
    id: 'countryCode',
    label: 'Country Code',
    width: '80px',
    fieldToSortBy: 'countryCode',
  },
  {
    id: 'region',
    label: 'Region',
    width: '100px',
    fieldToSortBy: 'region',
  },
  {
    id: 'starRating',
    label: 'Star Rating',
    width: '50px',
  },
  {
    id: 'preferred',
    label: 'Preferred',
    width: '50px',
  },
  {
    id: 'live-rates',
    label: 'Live Rates',
    width: '50px',
  },
  {
    id: 'cta',
    label: '',
    width: '160px',
  },
];

export const List = () => {
  const backendApi = makeBackendApi();

  const countries = useSelector(BootstrapSelectors.getBootstrapCountriesSelector);
  const paginationData = usePagination({ initialPage: 1, initialPerPage: 10, initialTotal: 100 });
  const [hotels, setHotels] = useState<IHotel[]>([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc' as ESortOrder);

  const [filterName, setFilterName] = useState('');
  const [debouncedFilterName, setDebouncedFilterName] = useState('');
  const [filterCountryCode, setFilterCountryCode] = useState<TCountryCode | null>(null);
  const [getHotelsRequestStatus, setGetHotelsRequestStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );

  const dispatch = useDispatch();
  useEffect(() => {
    setGetHotelsRequestStatus(ENetworkRequestStatus.PENDING);
    backendApi
      .hotelAdminGetList({
        page: paginationData.page,
        perPage: paginationData.perPage,
        sortBy,
        sortOrder,
        filterName: debouncedFilterName,
        countryCode: filterCountryCode,
      })
      .then(res => {
        setGetHotelsRequestStatus(ENetworkRequestStatus.SUCCESS);
        setHotels(res.data.data);
        paginationData.setTotal(res.data.meta.total);
      })
      .catch(error => {
        setGetHotelsRequestStatus(ENetworkRequestStatus.ERROR);
        dispatch(enqueueNotification({ message: 'Error fetching hotels', options: { variant: 'error' } }));
      });
  }, [paginationData.page, paginationData.perPage, sortBy, sortOrder, debouncedFilterName, filterCountryCode]);

  // https://admin-api.development.pure-escapes.com/hotels?page%5Boffset%5D=0&page%5Blimit%5D=1000&sort%5B%5D=hotel.name

  const escapeCSV = value => {
    if (value === null || value === undefined) {
      return `""`; // Replace null or undefined with an empty string
    }
    if (Array.isArray(value)) {
      // Concatenate array elements with semicolons
      value = value.join(';');
    }
    if (typeof value === 'string') {
      // Escape double quotes and wrap in quotes
      return `"${value.replace(/"/g, '""')}"`;
    }
    return `"${value}"`; // Wrap non-strings in quotes
  };

  const handleExport = async () => {
    try {
      const res = await backendApi.hotelAdminGetList({
        page: 1,
        perPage: 1000,
        sortBy: 'name',
        sortOrder: ESortOrder.ASC,
        filterName: '',
        countryCode: null,
      });

      const dataRows = res.data.data;

      const headers = Object.keys(dataRows[0]);

      // Extract rows and escape values
      const rows = dataRows.map(obj => headers.map(header => escapeCSV(obj[header])));

      // Combine headers and rows into a single CSV string with BOM
      const csvContent = [
        headers.map(escapeCSV).join(','), // Header row
        ...rows.map(row => row.join(',')), // Data rows
      ].join('\n');

      const bom = '\uFEFF'; // Byte Order Mark for UTF-8
      const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });

      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'hotels.csv';

      // Append the anchor to the body, click it, and remove it
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      dispatch(
        enqueueNotification({
          message: `Failed to export hotels. Please check data and try again`,
          options: { variant: 'error' },
        })
      );
    }
  };

  const deleteHotel = async (hotel: IHotel) => {
    try {
      await backendApi.hotelAdminDelete(hotel.uuid);
      setHotels(hotels.filter(h => h.uuid !== hotel.uuid));
      dispatch(
        enqueueNotification({
          message: `Hotel deleted successfully`,
          options: { variant: 'success' },
        })
      );
    } catch (error) {
      dispatch(
        enqueueNotification({
          message: `Failed to delete hotel`,
          options: { variant: 'error' },
        })
      );
    }
  };

  const tableRows = hotels.map(hotel => {
    return {
      id: hotel.uuid,
      cells: [
        { id: 'name', value: hotel.name },
        { id: 'countryCode', value: hotel.countryCode },
        { id: 'region', value: hotel.region },
        { id: 'starRating', value: hotel.starRating },
        { id: 'preferred', value: hotel.preferred ? 'Yes' : 'No' },
        { id: 'live-rates', value: !isNil(hotel.externalSystem) ? 'Yes' : 'No' },
        {
          id: 'cta',
          value: (
            <span className="flex space-x-4 pr-4 items-center justify-end">
              <Link className="underline hover:decoration-brown-prime" to={`/hotel-admin/${hotel.uuid}/edit`}>
                Edit Hotel
              </Link>
              <Link className="underline hover:decoration-brown-prime" to={`/hotel-admin/${hotel.uuid}/edit-children`}>
                Edit Children
              </Link>

              <button
                className="rounded-full w-6 h-6 bg-brown-60 hover:bg-brown-80 text-white text-xs cursor-pointer"
                onClick={() => {
                  if (confirm(`Are you sure you want to delete hotel "${hotel.name}"odel? This cannot be undone!`)) {
                    deleteHotel(hotel);
                  }
                }}
              >
                &#x2715;
              </button>
            </span>
          ),
        },
      ],
    };
  });

  return (
    <div className="container w-1280px mx-auto flex flex-col space-y-25px">
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Products - <span className="text-[26px]">Hotels</span>
      </h1>

      <div className="flex space-x-4 items-center justify-between font-pt-sans">
        <fieldset className="flex flex-row space-x-2 border border-gray-20 border-solid p-2 pb-4">
          <legend className="text-sm">List Filters</legend>

          <Inputs.Input
            label="Name"
            value={filterName}
            className="min-w-[200px]"
            onDebounceChange={val => {
              setDebouncedFilterName(val);
              paginationData.setPage(1);
            }}
            inputClassName="!min-w-[300px]"
            onChange={val => {
              setFilterName(val);
            }}
          />

          <Inputs.Dropdown
            label="Country"
            options={countries.map(c => ({ value: c.code, label: c.name }))}
            values={filterCountryCode ? [filterCountryCode] : []}
            className="min-w-[250px]"
            onChange={values => {
              const firstValue = values[0];
              if (isNil(firstValue)) {
                setFilterCountryCode(null);
                paginationData.setPage(1);
              } else {
                setFilterCountryCode(firstValue as TCountryCode);
                paginationData.setPage(1);
              }
            }}
            multiselectProps={{
              isSingleSelectMode: true,
              isCloseOnSelect: true,
              hideCheckboxes: true,
              isIncludeClearButton: true,
              isEnableFuzzySearch: true,
              hideNonMatchingOptions: true,
            }}
          />
        </fieldset>

        <fieldset className="border border-gray-20 border-solid p-2 pt-[28px] pb-[21px]">
          <legend className="text-sm">Actions</legend>

          <div className="flex flex-row items-center justify-center space-x-4">
            <a href="/hotel-admin/create" className="w-[100px] text-center underline font-pt-sans">
              New Hotel
            </a>

            <FluidButton
              type="primary"
              className="w-[150px]"
              onClick={async () => {
                await handleExport();
              }}
            >
              Export
            </FluidButton>

            <MenuButton
              className="w-[150px] z-[9999] mr-4"
              innerClassName="bg-red-70"
              fixedWidth="150px"
              options={[
                {
                  key: '1',
                  label: 'Import Rates',
                  handler: () => {
                    window.location.href = `/rates/import`;
                  },
                },
                {
                  key: '2',
                  label: 'Import Products',
                  handler: () => {
                    window.location.href = `/products/import`;
                  },
                },
                {
                  key: '3',
                  label: 'Import Static Rates Availability',
                  handler: () => {
                    window.location.href = `/static-rates-availability/import`;
                  },
                },
              ]}
              onSelect={a => {
                a.handler();
              }}
            >
              Import
            </MenuButton>
          </div>
        </fieldset>
      </div>

      {getHotelsRequestStatus === ENetworkRequestStatus.ERROR && (
        <div className="flex flex-col items-center justify-center">
          <ErrorBar />
        </div>
      )}

      {getHotelsRequestStatus === ENetworkRequestStatus.PENDING && tableRows.length <= 0 && (
        <div className="flex flex-col items-center justify-center">
          <LoadingBar />
        </div>
      )}

      {tableRows.length >= 1 && (
        <>
          <Table
            columns={tableColumns}
            rows={tableRows}
            messageWhenEmpty="No results"
            loading={getHotelsRequestStatus === ENetworkRequestStatus.PENDING}
            onSort={(newSortBy, newSortOrder) => {
              setSortBy(newSortBy);
              setSortOrder(newSortOrder as ESortOrder);
            }}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
          <Pagination
            currentPage={paginationData.page}
            itemsPerPage={paginationData.perPage}
            onItemsPerPageChange={paginationData.setPerPage}
            onPageSelect={paginationData.setPage}
            pageCount={paginationData.pageCount}
          />
        </>
      )}

      {getHotelsRequestStatus === ENetworkRequestStatus.SUCCESS && tableRows.length <= 0 && (
        <div className="font-pt-sans flex flex-col items-center justify-center">No results</div>
      )}
    </div>
  );
};
