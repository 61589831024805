import { call, takeLatest, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  BAM_BOOKING_BUILD_REQUEST,
  bamBookingBuildFailureAction,
  BAMBookingBuildRequestAction,
  bamBookingBuildSuccessAction,
  setBAMLastRequestedBuildAction,
} from '../actions';
import { subDays, format } from 'date-fns';

import { BookingBuilderRequest, BookingBuilderResponse, IHotel, IHotelAccommodationsSearchRequest, IRequestedBuild, makeBackendApi } from 'services/BackendApi';
import { IBookingCountryResponse, IOwnershipResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import { BAMEndDateFilterSelector, BAMGuestAgesFilterSelector, BAMHotelFilterSelector, BAMSelectedBuildSelector, BAMSelectedFinesSelector, BAMSelectedGroundServicesSelector, BAMSelectedMealPlansSelector, BAMSelectedOccasionsSelector, BAMSelectedOtherItemsSelector, BAMSelectedRepeatGuestSelector, BAMSelectedTransfersSelector, BAMStartDateFilterSelector } from '../selectors';
import { IStaticRate, IStaticRateMealPlan } from 'ui/AddAccommodationModal/MealPlanDropdown/types';

export function* bookingBuildSaga(action: BAMBookingBuildRequestAction) {
  try {
    const selectedBuild = (yield select(BAMSelectedBuildSelector)) as unknown as BookingBuilderResponse;
    const selectedAccommodation = selectedBuild.availableProductSets.Accommodation[0];
    const bookingManagerApi = makeBookingManagerApi();
    const ownershipData: AxiosResponse<IOwnershipResponse> = yield call(bookingManagerApi.getOwnership, action.bookingUuid);
    const travelAgentUuid = ownershipData.data.ownershipData.taInfo.uuid;
    const bookingCountryData: AxiosResponse<IBookingCountryResponse> = yield call(bookingManagerApi.getBookingCountryCode, action.bookingUuid);
    const clientCountryCode = bookingCountryData.data.countryCode;

    const backendApi = makeBackendApi(travelAgentUuid);
    const startDate = yield select(BAMStartDateFilterSelector);
    const endDate = yield select(BAMEndDateFilterSelector);
    const hotelUuid = yield select(BAMHotelFilterSelector);
    const selectedOccasions = yield select(BAMSelectedOccasionsSelector);
    const selectedMealPlan = (yield select(BAMSelectedMealPlansSelector)) as IStaticRate;
    const repeatCustomer = yield select(BAMSelectedRepeatGuestSelector);
    const guestAges = yield select(BAMGuestAgesFilterSelector);

    const transfers = yield select(BAMSelectedTransfersSelector);
    const groundServiceUuids = yield select(BAMSelectedGroundServicesSelector);
    const finesUuids = yield select(BAMSelectedFinesSelector);
    const otherItemsUuids = yield select(BAMSelectedOtherItemsSelector);

    const bookingBuilderRequest: BookingBuilderRequest = {
      startDate,
      endDate: format(subDays(new Date(endDate), 1), 'yyyy-MM-dd'),
      guestAges,
      hotelUuid,
      Accommodation: [{
        uuid: selectedAccommodation.products[0].uuid,
        guestAges,
        repeatCustomer,
        startDate,
        endDate: format(subDays(new Date(endDate), 1), 'yyyy-MM-dd'),
        subProducts: {
          'Meal Plan': selectedMealPlan.mealPlan.uuids.map(item => ({ uuid: item })),
          Supplement: [],
        },
        honeymoon: selectedOccasions.includes('honeymoon'),
        anniversary: selectedOccasions.includes('anniversary'),
        wedding: selectedOccasions.includes('wedding'),
        birthday: selectedOccasions.includes('birthday'),
      }],
      Transfer: transfers.map(item => {
        if (item.direction === 'return') {
          return { uuid: item.uuid }
        } else {
          return item;
        }
      }),
      customItems: [],
      'Ground Service': groundServiceUuids.map((item: string) => ({ uuid: item })),
      Supplement: otherItemsUuids.map((item: string) => ({ uuid: item })),
      Fine: finesUuids.map((item: string) => ({ uuid: item })),
    };
    const response: AxiosResponse<{ data: BookingBuilderResponse }> = yield call(
      backendApi.postBookingBuilderRequest,
      bookingBuilderRequest,
      clientCountryCode,
      travelAgentUuid
    );
    yield put(setBAMLastRequestedBuildAction(bookingBuilderRequest));

    yield put(bamBookingBuildSuccessAction(response.data.data));
  } catch (e) {
    yield put(bamBookingBuildFailureAction(e));
    console.log('Error', e)
  }
}

export function* watchBookingBuildSaga() {
  yield takeLatest([BAM_BOOKING_BUILD_REQUEST], bookingBuildSaga);
}
