import { call, takeLatest, select, put, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { IUser, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';
import { GET_FILTER_INFO_REQUEST, GET_USERS_REQUEST, getFilterInfoFailureAction, getFilterInfoSuccessAction, getUsersFailureAction, GetUsersRequestAction, getUsersSuccessAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';

export function* getFilterInfoSaga() {
  yield delay(1000);

  try {
    const taskManagementApi = makeTaskManagementApi();
    const taskCategories: AxiosResponse<any> = yield call(taskManagementApi.getTaskCategoriesInfo);
    const taskTeams: AxiosResponse<any> = yield call(taskManagementApi.getTaskTeamsInfo);

    yield put(getFilterInfoSuccessAction(taskCategories.data, taskTeams.data));
  } catch (e) {
    yield put(getFilterInfoFailureAction(e));
    yield put(enqueueNotification({ message: 'Can not get filter information', options: { variant: 'error' } }));
  }
}

export function* searchUsersSimpleSaga(action: GetUsersRequestAction) {
  yield delay(700);
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const taskUsers: AxiosResponse<{ data: Pick<IUser, 'uuid' | 'firstName' | 'lastName' | 'email'>[] }> = yield call(backendApi.searchUsersSimple, action.filterTerm, 'firstName');

    yield put(getUsersSuccessAction(taskUsers.data.data, action.typeOfUser));
  } catch (e) {
    yield put(getUsersFailureAction(e, action.typeOfUser));
    yield put(enqueueNotification({ message: 'Can not get users', options: { variant: 'error' } }));
  }
}

export function* watchGetFilterInfoSaga() {
  yield takeLatest(
    GET_USERS_REQUEST,
    searchUsersSimpleSaga
  );
  yield takeLatest(
    GET_FILTER_INFO_REQUEST,
    getFilterInfoSaga
  );
}
