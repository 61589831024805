import { createSelector } from 'reselect';
import { IBookingManagerFinanceSubdomain, IFinanceDocument } from './types';
import { financeSubdomainSelector } from '../../selectors';
import { EFinanceRowTypes } from 'services/BookingManagerApi';

export const financeDocumentSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.financeDocument
);

export const networkRequestsSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.networkRequests
);

export const isAddModalOpenSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.isAddModalOpen
);

export const isDeleteModalOpenSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.isDeleteModalOpen
);

export const deleteRowIndexSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.deleteRowIndex
);

export const editRowIndexSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.editRowIndex
);

export const isEditModalOpenSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.isEditModalOpen
);

export const errorMessagesSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.errorMessages
);

export const financeDocumentBalanceSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.financeDocumentBalance
);

export const financeTableTypeToRenderSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.financeTableTypeToRender
);

export const automaticFinalInvoiceToTaSelector = createSelector(financeDocumentSelector, (doc: IFinanceDocument) =>
  doc.rows.find(item => item.rowType === EFinanceRowTypes.Automatic_Invoice_to_Travel_Agent)
);

export const automaticProformaInvoiceToTaSelector = createSelector(financeDocumentSelector, (doc: IFinanceDocument) =>
  doc.rows.find(item => item.rowType === EFinanceRowTypes.Automatic_Proforma_to_Travel_Agent)
);

export const financeDocumentVersionListSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.versionList
);

export const financeDocumentActiveVersionSelector = createSelector(
  financeSubdomainSelector,
  (subdomain: IBookingManagerFinanceSubdomain) => subdomain.activeVersion
);

export const paymentMethodsSelector = createSelector(
  financeSubdomainSelector,
  domain => domain.paymentMethods
);

export const defaultPaymentMethodPerCurrencySelector = createSelector(
  paymentMethodsSelector,
  ({ list, defaultPaymentMethodPerCurrency }) => 
    defaultPaymentMethodPerCurrency.reduce((acc, cur) => ({
      ...acc,
      [cur.currency]: list?.find(x => x.code === cur.paymentMethodCode)
    }), {})
);
