import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  ENetworkRequestStatus,
  IAccommodationProduct,
  IAccomodationProductOptions,
  IFineProduct,
  IGroundServiceProduct,
  IGroundServiceProductOptions,
  IHotel,
  IMealPlanProduct,
  IMealPlanProductOptions,
  IProduct,
  ISeason,
  ISeasonalProductAddonRate,
  ISeasonalProductRate,
  ISupplementProduct,
  ITransferProduct,
  ITransferProductOptions,
  IUploadFileInfo,
  makeBackendApi,
} from 'services/BackendApi';
import { Link } from 'ui/Link';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { SimpleTabs } from 'ui/SimpleTabs';
import * as _ from 'lodash-es';
import { produce } from 'immer';
import { formatDateDisplayFourDigitYear, getCurrencySymbol, formatDateDisplay, isBlank } from 'utils';
import { ChildrenList } from './components/ChildrenList';
import { Season } from './components/child-product-forms/Season';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';
import { MealPlan } from './components/child-product-forms/MealPlan';
import { Room } from './components/child-product-forms/Room';
import { Transfer } from './components/child-product-forms/Transfer';
import { Supplement } from './components/child-product-forms/Supplement';
import { GroundService } from './components/child-product-forms/GroundService';
import { Fine } from './components/child-product-forms/Fine';
import { AddNewRate } from './components/AddNewRate';
import { Uploads } from './components/Uploads';
import FluidButton from 'ui/FluidButton';
import { useModal } from 'hooks/useModal';
import { UploadModal } from './components/UploadModal';
import { RoomRateDetails } from './components/RoomRateDetails';
import { RoomRateAddons } from './components/RoomRateAddons';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import { TransferRateDetails } from './components/TransferRateDetails';
import { SupplementRateDetails } from './components/SupplementRateDetails';
import { GroundServiceRateDetails } from './components/GroundServiceRateDetails';
import { FineRateDetails } from './components/FineRateDetails';
import { CreateSeasonModal } from './components/create-modals/CreateSeasonModal';
import { CreateRoomModal } from './components/create-modals/CreateRoomModal';
import { CreateMealPlanModal } from './components/create-modals/CreateMealPlanModal';
import { CreateTransferModal } from './components/create-modals/CreateTransferModal';
import { CreateSupplementModal } from './components/create-modals/CreateSupplementModal';
import { CreateGroundServiceModal } from './components/create-modals/CreateGroundServiceModal';
import { CreateFineModal } from './components/create-modals/CreateFineModal';
import { CreateRateModal } from './components/create-modals/CreateRateModal';
import { CreateAddonRateModal } from './components/create-modals/CreateAddonRateModal';
import { categoryToLabelMap } from './utils';
import {
  validateFine,
  validateGroundService,
  validateMealPlan,
  validateRoom,
  validateSeason,
  validateSeasonalProductAddonRate,
  validateSeasonalProductRate,
  validateSupplement,
  validateTransfer,
} from './validation';
import classNames from 'classnames';

export const EditChildren = () => {
  const match = useRouteMatch<{ hotelUuid: string }>();
  const hotelUuid = match.params.hotelUuid;
  const dispatch = useDispatch();
  const backendApi = makeBackendApi();

  const [hotel, setHotel] = useState<IHotel | null>(null);
  const [draftHotel, setDraftHotel] = useState<IHotel | null>(null);

  const [updatingRequest, setUpdatingRequest] = useState(ENetworkRequestStatus.IDLE);

  const uploadModalData = useModal();

  const createNewChildItemModals = {
    season: useModal(),
    room: useModal(),
    mealPlan: useModal(),
    transfer: useModal(),
    supplement: useModal(),
    groundService: useModal(),
    fine: useModal(),
  };

  const createAddonRateModalData = useModal();

  const createRateModalData = useModal();

  const [creatingRateFor, setCreatingRateFor] = React.useState<IProduct<any> | null>(null);

  const bootstrapCountries = useSelector(BootstrapSelectors.getBootstrapCountriesSelector);

  const epsProduct = useSelector(BootstrapSelectors.getBootstrapExtraPersonSupplementProductSelector);

  const [editedUnsavedDetailsItemUuids, setEditedUnsavedDetailsItemUuids] = useState<string[]>([]);
  const [editedUnsavedRatesItemUuids, setEditedUnsavedRatesItemUuids] = useState<string[]>([]);

  const [
    seasonalProductRateForCreatingAddonRate,
    setSeasonalProductRateForCreatingAddonRate,
  ] = useState<ISeasonalProductRate | null>(null);

  const retrieveHotel = async () => {
    try {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);
      const res = await backendApi.hotelAdminGetHotel(hotelUuid, [
        'accommodationProducts',
        'accommodationProducts.seasonalProductRates',
        'accommodationProducts.seasonalProductAddonRates',
        'accommodationProducts.uploads',
        'seasons',
        'seasons.seasonDates',

        'fineProducts',
        'fineProducts.seasonalProductRates',
        'fineProducts.uploads',

        'groundServiceProducts',
        'groundServiceProducts.uploads',
        'groundServiceProducts.seasonalProductRates',

        'mealPlanProducts',
        'mealPlanProducts.uploads',
        'mealPlanProducts.seasonalProductRates',

        'transferProducts',
        'transferProducts.uploads',
        'transferProducts.seasonalProductRates',

        'supplementProducts',
        'supplementProducts.uploads',
        'supplementProducts.seasonalProductRates',
      ]);
      setHotel({
        ...res.data.data,
      });
      setDraftHotel({
        ...res.data.data,
      });
      setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
    } catch (error) {
      setUpdatingRequest(ENetworkRequestStatus.ERROR);
    }
  };

  useEffect(() => {
    retrieveHotel();
  }, [hotelUuid]);

  const postSeasonalProductRate = useCallback(
    async (forProduct: IProduct<any>) => {
      setCreatingRateFor(forProduct);
      const data = await createRateModalData.openModal();
      if (data) {
        setUpdatingRequest(ENetworkRequestStatus.PENDING);

        try {
          await backendApi.hotelAdminPostSeasonalProductRate(data, forProduct);
          setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
          dispatch(
            enqueueNotification({
              message: `Seasonal product rate created successfully`,
              options: { variant: 'success' },
            })
          );
        } catch (error) {
          console.error('error', error);
          setUpdatingRequest(ENetworkRequestStatus.ERROR);
          dispatch(
            enqueueNotification({
              message: `Failed to create seasonal product rate`,
              options: { variant: 'error' },
            })
          );
        }
      }
    },
    [backendApi, createRateModalData]
  );

  const handleUpdateSeasonalProductRateAttribute = useCallback(
    (key: string, childItemUuid: string, seasonalProductRateUuid: string, field: any, val: any) => {
      if (!draftHotel || !draftHotel[key]) {
        return;
      }

      const childItemIndex = draftHotel[key].findIndex(item => item.uuid === childItemUuid);

      const childItem = draftHotel[key][childItemIndex];

      const seasonalProductRateIndex = childItem.seasonalProductRates.findIndex(
        spr => spr.uuid === seasonalProductRateUuid
      );

      const updatedHotel = produce(draftHotel, draftHotel => {
        _.set(
          draftHotel,
          `[${key}][${childItemIndex}].seasonalProductRates[${seasonalProductRateIndex}].${field}`,
          val
        );
      });

      setEditedUnsavedRatesItemUuids(_.uniq([...editedUnsavedRatesItemUuids, childItemUuid]));

      setHotel({
        ...updatedHotel,
      });

      setDraftHotel({
        ...updatedHotel,
      });
    },
    [draftHotel, editedUnsavedRatesItemUuids, setEditedUnsavedRatesItemUuids]
  );

  const handleUpdateChildItemAttribute = useCallback(
    (key: string, childItemUuid: string, field: any, val: any) => {
      if (!draftHotel || !draftHotel[key]) {
        return;
      }

      setEditedUnsavedDetailsItemUuids(_.uniq([...editedUnsavedDetailsItemUuids, childItemUuid]));

      const itemIndex = draftHotel[key].findIndex(c => c.uuid === childItemUuid);

      const updatedHotel = produce(draftHotel, draftHotel => {
        _.set(draftHotel[key][itemIndex], field, val);
      });

      setHotel({
        ...updatedHotel,
      });

      setDraftHotel({
        ...updatedHotel,
      });
    },
    [draftHotel, editedUnsavedDetailsItemUuids, setEditedUnsavedDetailsItemUuids]
  );

  const retrieveProductAndInjectIntoState = useCallback(
    async (childItem: any) => {
      let childListKey = '';
      if (!_.isNil(childItem.seasonDates)) {
        childListKey = 'seasons';
      } else if (childItem.type === 'Accommodation') {
        childListKey = 'accommodationProducts';
      } else if (childItem.type === 'Meal Plan') {
        childListKey = 'mealPlanProducts';
      } else if (childItem.type === 'Transfer') {
        childListKey = 'transferProducts';
      } else if (childItem.type === 'Supplement') {
        childListKey = 'supplementProducts';
      } else if (childItem.type === 'Ground Service') {
        childListKey = 'groundServiceProducts';
      } else if (childItem.type === 'Fine') {
        childListKey = 'fineProducts';
      }

      if (!hotel || !childItem) {
        return;
      }

      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        const childProductResponse =
          childListKey === 'seasons'
            ? await backendApi.hotelAdminGetSeason(childItem.uuid, ['seasonDates'])
            : await backendApi.hotelAdminGetProduct(childItem.uuid, [
                'seasonalProductRates',
                'seasonalProductRates.season',
                'seasonalProductRates.product',
                'seasonalProductRates.countries',
                'seasonalProductRates.seasonalProductAddonRates',
                'seasonalProductRates.uploads',
                'uploads',
              ]);

        const childIndex = hotel[childListKey].findIndex(c => c.uuid === childItem.uuid);
        let updatedHotel = {
          ...hotel,
        };

        const { uploads, seasonalProductRates } = childProductResponse.data.data;

        // if we're dealing with a season, we just replace the whole thing and quit out
        if (childListKey === 'seasons') {
          updatedHotel = produce(updatedHotel, draftHotel => {
            draftHotel[childListKey][childIndex] = childProductResponse.data.data;
          });
          setHotel({
            ...updatedHotel,
          });
          setDraftHotel({
            ...updatedHotel,
          });
          setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
          return;
        }

        // if we have uploads, put them into the child object in the updatedHotel object
        if (uploads) {
          if (uploads.length >= 0) {
            updatedHotel = produce(updatedHotel, draftHotel => {
              draftHotel[childListKey][childIndex].uploads = uploads;
            });
          } else {
            updatedHotel[childListKey][childIndex].uploads = [];
          }
        }

        // if this child doesn't have any seasonal product rates, update the hotel in state and exit out
        if (_.isNil(seasonalProductRates) || seasonalProductRates.length <= 0) {
          setHotel({
            ...updatedHotel,
          });
          setDraftHotel({
            ...updatedHotel,
          });
          setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
          return;
        }

        const seasonalProductRateRequests: Promise<AxiosResponse>[] = seasonalProductRates
          .map(spr => spr.uuid)
          .map(sprUuid =>
            backendApi.hotelAdminGetSeasonalProductRate(sprUuid, [
              'seasonalProductAddonRates',
              'seasonalProductAddonRates.seasonalProductRate',
              'seasonalProductAddonRates.product',
              'seasonalProductAddonRates.uploads',
              'countries',
              'uploads',
            ])
          );

        const seasonalProductRateResponses = await Promise.all(seasonalProductRateRequests);
        const parentItem = hotel[childListKey].find(c => c.uuid === childItem.uuid);
        const parentItemIndex = hotel[childListKey].findIndex(c => c.uuid === childItem.uuid);

        // update our in flight variable
        updatedHotel = produce(updatedHotel, draftHotel => {
          seasonalProductRateResponses.forEach((res, index) => {
            // @ts-ignore
            const seasonalProductRateIndex = parentItem!.seasonalProductRates.findIndex(
              spr => spr.uuid === res.data.data.uuid
            );

            if (seasonalProductRateIndex === -1) {
              hotel[childListKey][parentItemIndex].seasonalProductRates.push(res.data.data);
            } else {
              // @ts-ignore
              hotel[childListKey][parentItemIndex].seasonalProductRates[seasonalProductRateIndex] = res.data.data;
            }
          });
        });

        // ...and then update the hotel in state
        setHotel({
          ...updatedHotel,
        });
        setDraftHotel({
          ...updatedHotel,
        });
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
      } catch (error) {
        console.error(error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
      }
    },
    [backendApi, editedUnsavedDetailsItemUuids, dispatch, draftHotel, setUpdatingRequest]
  );

  /**
   * function to handle patching a child item, while validating it first, and also re-retrieving the item after patching
   * and putting the latest into state
   *
   * @param patchFunction the function that does the patching for this item type, e.g `patchSeason` or `patchRoom`, etc.
   * @param validateFunction the function that does the validation for this item type e.g `validateSeason` or `validateRoom`, etc.
   * @param item the item to patch, e.g a room or a season
   * @returns
   */
  const handlePatchChildItemFlow = async (patchFunction, validateFunction, item) => {
    const validation = validateFunction(item);
    if (validation.isValid === false) {
      dispatch(
        enqueueNotification({
          message: `Item is not valid: ${validation.message}`,
          options: { variant: 'warning' },
        })
      );
      return;
    }

    try {
      await patchFunction(item.uuid, item);
    } finally {
      await retrieveProductAndInjectIntoState(item);
    }
  };

  const patchSeason = useCallback(
    async (seasonUuid, updatedData) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        // patch the season
        await backendApi.hotelAdminPatchSeason(seasonUuid, {
          ...updatedData,
          seasonDates: undefined,
        });

        // then post/patch all the season dates
        const seasonDateRequests: Promise<AxiosResponse>[] = [];
        updatedData.seasonDates.forEach(sd => {
          if (sd.isNew === true && sd.deleted === true) {
            // do nothing - they made it then deleted it without saving, it only ever existed in FE state
          } else if (sd.uuid && sd.deleted && sd.isNew !== true) {
            // only delete if its NOT new
            seasonDateRequests.push(backendApi.hotelAdminDeleteSeasonDate(sd.uuid));
          } else if (sd.isNew) {
            seasonDateRequests.push(backendApi.hotelAdminPostSeasonDate(sd));
          } else {
            seasonDateRequests.push(backendApi.hotelAdminPutSeasonDate(sd.uuid, sd));
          }
        });
        await Promise.all(seasonDateRequests);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        setEditedUnsavedDetailsItemUuids(_.without(editedUnsavedDetailsItemUuids, seasonUuid));
        dispatch(
          enqueueNotification({
            message: `Season "${updatedData.name}" updated successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating season "${updatedData.name}"`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, editedUnsavedDetailsItemUuids, dispatch, hotel]
  );

  const patchMealPlan = useCallback(
    async (mealPlanUuid, mealPlanData: Partial<IProduct<IMealPlanProductOptions>>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminPatchProduct(mealPlanUuid, mealPlanData);
        setEditedUnsavedDetailsItemUuids(_.without(editedUnsavedDetailsItemUuids, mealPlanUuid));
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Meal Plan "${mealPlanData.name}" updated successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating meal plan "${mealPlanData.name}"`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, editedUnsavedDetailsItemUuids, dispatch]
  );

  const patchRoom = useCallback(
    async (roomUuid: string, room: IProduct<IAccomodationProductOptions>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminPatchProduct(roomUuid, room);
        setEditedUnsavedDetailsItemUuids(_.without(editedUnsavedDetailsItemUuids, roomUuid));
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Room updated successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating room`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, editedUnsavedDetailsItemUuids, dispatch]
  );

  const patchTransfer = useCallback(
    async (transferUuid: string, transfer: ITransferProduct) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminPatchProduct(transferUuid, transfer);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        setEditedUnsavedDetailsItemUuids(_.without(editedUnsavedDetailsItemUuids, transferUuid));
        dispatch(
          enqueueNotification({
            message: `Transfer updated successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating transfer`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, editedUnsavedDetailsItemUuids, dispatch]
  );

  const patchSupplement = useCallback(
    async (supplementUuid: string, supplement: ISupplementProduct) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminPatchProduct(supplementUuid, supplement);
        setEditedUnsavedDetailsItemUuids(_.without(editedUnsavedDetailsItemUuids, supplementUuid));
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Successfully updated supplement`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating supplement`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, editedUnsavedDetailsItemUuids, dispatch]
  );

  const patchGroundService = useCallback(
    async (groundServiceUuid: string, groundService: IGroundServiceProduct) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);
      try {
        await backendApi.hotelAdminPatchProduct(groundServiceUuid, groundService);
        setEditedUnsavedDetailsItemUuids(_.without(editedUnsavedDetailsItemUuids, groundServiceUuid));
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Successfully updated ground service`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating ground service`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, editedUnsavedDetailsItemUuids, dispatch]
  );

  const patchFineProduct = useCallback(
    async (fineUuid: string, fine: IFineProduct) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);
      try {
        await backendApi.hotelAdminPatchProduct(fineUuid, fine);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        setEditedUnsavedDetailsItemUuids(_.without(editedUnsavedDetailsItemUuids, fineUuid));
        dispatch(
          enqueueNotification({
            message: `Successfully updated fine`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating fine`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, editedUnsavedDetailsItemUuids, dispatch]
  );

  const patchSeasonalProductRate = useCallback(
    async (childItem: IProduct<any>, seasonalProductRateUuid: string) => {
      let key = '';
      if (childItem.type === 'Season') {
        key = 'seasons';
      } else if (childItem.type === 'Accommodation') {
        key = 'accommodationProducts';
      } else if (childItem.type === 'Meal Plan') {
        key = 'mealPlanProducts';
      } else if (childItem.type === 'Transfer') {
        key = 'transferProducts';
      } else if (childItem.type === 'Supplement') {
        key = 'supplementProducts';
      } else if (childItem.type === 'Ground Service') {
        key = 'groundServiceProducts';
      } else if (childItem.type === 'Fine') {
        key = 'fineProducts';
      }

      if (!draftHotel || !draftHotel[key]) {
        return;
      }
      const itemIndex = draftHotel[key].findIndex(c => c.uuid === childItem.uuid);
      const item = draftHotel[key][itemIndex];
      const seasonalProductRateIndex = item.seasonalProductRates.findIndex(c => c.uuid === seasonalProductRateUuid);
      const seasonalProductRate = item.seasonalProductRates[seasonalProductRateIndex];

      setUpdatingRequest(ENetworkRequestStatus.PENDING);
      try {
        // PUT the SPR
        await backendApi.hotelAdminPutSeasonalProductRate(
          key,
          seasonalProductRateUuid,
          seasonalProductRate,
          epsProduct
        );

        // if its an accommodation, we also need to patch the EPS SPAR
        if (key === 'accommodationProducts') {
          const epsRate = (seasonalProductRate.seasonalProductAddonRates || []).find(
            rate => rate.product.uuid === epsProduct.uuid
          );
          await backendApi.hotelAdminPatchSeasonalProductAddonRate(epsRate?.uuid, epsRate);
        }
        setEditedUnsavedRatesItemUuids(_.without(editedUnsavedRatesItemUuids, childItem.uuid));
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Rate updated successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to update rate`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, draftHotel, editedUnsavedRatesItemUuids, dispatch, setUpdatingRequest]
  );

  const patchRoomAddonMealPlans = async (roomProductUuid: string, seasonalProductRateUuid: string) => {
    const roomIndex = draftHotel!.accommodationProducts!.findIndex(c => c.uuid === roomProductUuid);
    const room = draftHotel!.accommodationProducts![roomIndex];
    const seasonalProductRateIndex = room.seasonalProductRates.findIndex(c => c.uuid === seasonalProductRateUuid);
    const seasonalProductRate = room.seasonalProductRates[seasonalProductRateIndex];

    // now get the meal plans
    const mealPlansAddonRates = (seasonalProductRate.seasonalProductAddonRates || []).filter(
      rate => rate.product.type === 'Meal Plan'
    );

    // now do a patch for each meal plan
    const mealPlanRequests: Promise<AxiosResponse>[] = mealPlansAddonRates.map(mealPlan => {
      return backendApi.hotelAdminPatchSeasonalProductAddonRate(mealPlan.uuid, mealPlan);
    });

    setUpdatingRequest(ENetworkRequestStatus.PENDING);

    try {
      await Promise.all(mealPlanRequests);
      setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
      setEditedUnsavedRatesItemUuids(_.without(editedUnsavedRatesItemUuids, roomProductUuid));
      dispatch(
        enqueueNotification({
          message: `Updated meal plans successfully`,
          options: { variant: 'success' },
        })
      );
    } catch (error) {
      setUpdatingRequest(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Error updating meal plans`,
          options: { variant: 'error' },
        })
      );
    }
  };

  const deleteUpload = async (upload: IUploadFileInfo) => {
    backendApi
      .deleteUpload(upload.uuid)
      .then(res => {
        dispatch(
          enqueueNotification({
            message: `Upload deleted successfully`,
            options: { variant: 'success' },
          })
        );
      })
      .catch(error => {
        console.error('error', error);
      });
  };

  const postNewSeason = useCallback(
    async (seasonData: Partial<ISeason>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        // post the season
        const seasonRes = await backendApi.hotelAdminPostSeason({
          ...seasonData,
          seasonDates: undefined,
        });

        // post the season dates
        const seasonDateRequests: Promise<AxiosResponse>[] = [];
        (seasonData.seasonDates || []).forEach(sd => {
          seasonDateRequests.push(
            backendApi.hotelAdminPostSeasonDate({
              ...sd,
              seasonUuid: seasonRes.data.data.uuid,
            })
          );
        });

        await Promise.all(seasonDateRequests);

        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Season created successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create season`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const postNewRoom = useCallback(
    async (hotel: IHotel, roomData: Partial<IAccommodationProduct>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);

        await backendApi.hotelAdminPostProduct(roomData, 'Accommodation', hotel.uuid);
        dispatch(
          enqueueNotification({
            message: `Room created successfully`,
            options: { variant: 'success' },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create room`,
            options: { variant: 'error' },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const postNewMealPlan = useCallback(
    async (hotel: IHotel, mealPlanData: Partial<IMealPlanProduct>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);
      try {
        await backendApi.hotelAdminPostProduct(mealPlanData, 'Meal Plan', hotel.uuid);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Meal Plan created successfully`,
            options: { variant: 'success' },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create meal plan`,
            options: { variant: 'error' },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const postNewTransfer = useCallback(
    async (hotel: IHotel, transferData: Partial<ITransferProduct>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);
      try {
        await backendApi.hotelAdminPostProduct(transferData, 'Transfer', hotel.uuid);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Transfer created successfully`,
            options: { variant: 'success' },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create transfer`,
            options: { variant: 'error' },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const postNewSupplement = useCallback(
    async (hotel: IHotel, supplementData: Partial<ISupplementProduct>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminPostProduct(supplementData, 'Supplement', hotel.uuid);
        setUpdatingRequest(ENetworkRequestStatus.PENDING);

        dispatch(
          enqueueNotification({
            message: `Supplement created successfully`,
            options: { variant: 'success' },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create supplement`,
            options: { variant: 'error' },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const postNewGroundService = useCallback(
    async (hotel: IHotel, groundServiceData: Partial<IGroundServiceProduct>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminPostProduct(groundServiceData, 'Ground Service', hotel.uuid);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);

        dispatch(
          enqueueNotification({
            message: `Ground Service created successfully`,
            options: { variant: 'success' },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create ground service`,
            options: { variant: 'error' },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const postNewFine = useCallback(
    async (hotel: IHotel, fineData: Partial<IFineProduct>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminPostProduct(fineData, 'Fine', hotel.uuid);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);

        dispatch(
          enqueueNotification({
            message: `Fine created successfully`,
            options: { variant: 'success' },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create fine`,
            options: { variant: 'error' },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const postSeasonalProductAddonRate = useCallback(
    async (seasonalProductAddonRate: Partial<ISeasonalProductAddonRate>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);
      try {
        await backendApi.hotelAdminPostSeasonalProductAddonRate(seasonalProductAddonRate);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Seasonal Product Addon Rate created successfully`,
            options: { variant: 'success' },
          })
        );
      } catch (error) {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create seasonal product addon rate`,
            options: { variant: 'error' },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const deleteSeason = useCallback(
    async (seasonData: Partial<ISeason>) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminDeleteSeason(seasonData.uuid!);

        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Season created successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Failed to create season`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const deleteProduct = useCallback(
    async (productData: Partial<IProduct<{}>>, productNoun: string) => {
      setUpdatingRequest(ENetworkRequestStatus.PENDING);

      try {
        await backendApi.hotelAdminDeleteProduct(productData.uuid!);

        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `${productNoun} deleted successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      } catch (error) {
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `${productNoun} failed to delete, please try again`,
            options: {
              variant: 'error',
            },
          })
        );
      }
    },
    [backendApi, dispatch]
  );

  const seasonsRenderContent = useCallback(() => {
    return (
      <ChildrenList
        hotel={hotel}
        singularNoun="Season"
        childList={_.orderBy(draftHotel!.seasons, ['name'], ['asc']) || []}
        editedUnsavedDetailsItemUuids={editedUnsavedDetailsItemUuids}
        onOpen={childItem => {
          // do nothing - seasons dont need to load their own data
        }}
        titleRenderer={(season: ISeason) => {
          return (
            <span className="inline-flex items-center space-x-3">
              <span className="font-bold">{season.name}</span>
              <span className="text-sm">
                {season.seasonDates.length} periods (
                {season.seasonDates.length > 0 &&
                  season.seasonDates
                    .map(sd =>
                      !isBlank(sd.validFrom) && !isBlank(sd.validTo)
                        ? `${formatDateDisplay(sd.validFrom)} - ${formatDateDisplay(sd.validTo)}`
                        : ''
                    )
                    .join(', ')}
                )
              </span>
            </span>
          );
        }}
        contentRenderer={(season: ISeason) => {
          return (
            <Season
              hotel={draftHotel!}
              season={season}
              onUpdate={(field, value) => {
                handleUpdateChildItemAttribute('seasons', season.uuid, field, value);
              }}
              onCta={async () => await handlePatchChildItemFlow(patchSeason, validateSeason, season)}
              ctaLabel="Update Season"
            />
          );
        }}
        renderChildSubProducts={[]}
        onCreateTrigger={async () => {
          const data = await createNewChildItemModals.season.openModal();
          if (data) {
            try {
              await postNewSeason(data);
            } finally {
              await retrieveHotel();
            }
          }
        }}
        onDeleteTrigger={async (season: ISeason) => {
          if (confirm(`Are you sure you want to delete season "${season.name}"? This cannot be undone!`)) {
            try {
              alert('deleting season');
              await deleteSeason(season);
            } finally {
              await retrieveHotel();
            }
          }
        }}
      />
    );
  }, [draftHotel?.seasons || [], editedUnsavedDetailsItemUuids, editedUnsavedRatesItemUuids]);

  const roomsRenderContent = useCallback(() => {
    return (
      <ChildrenList
        hotel={hotel}
        singularNoun="Room"
        childList={_.orderBy(hotel!.accommodationProducts, ['name'], ['asc']) || []}
        onOpen={async childItem => {
          await retrieveProductAndInjectIntoState(childItem);
        }}
        editedUnsavedDetailsItemUuids={editedUnsavedDetailsItemUuids}
        titleRenderer={(room: IAccommodationProduct) => {
          return (
            <span className="inline-flex items-center space-x-3">
              <span className="font-bold">{room.name}</span>
              <span className="text-sm">Type: {room.meta.categoryType}</span>
              <span className="text-sm">Size: {room.meta.size}</span>
              <span className="text-sm">SO: {room.options?.occupancy?.standardOccupancy}</span>
              <span className="text-sm">Max People: {room.options?.occupancy?.maximumPeople}</span>
              <span className="text-sm">
                Live Rates: {!_.isNil(room.externalProductId) && room.externalProductId !== '' ? 'Yes' : 'No'}
              </span>
            </span>
          );
        }}
        contentRenderer={(room: IProduct<IAccomodationProductOptions>) => (
          <Room
            room={room as IAccommodationProduct}
            onUpdate={(field, value) => {
              handleUpdateChildItemAttribute('accommodationProducts', room.uuid, field, value);
            }}
            onCta={async () => await handlePatchChildItemFlow(patchRoom, validateRoom, room)}
          />
        )}
        renderChildSubProducts={[
          {
            title: 'Documents',
            name: 'documents',
            accessKey: 'uploads',
            tabContentRenderer: (roomProduct, uploadList) => {
              return (
                <div className="flex flex-col space-y-2 w-full">
                  <Uploads
                    uploads={uploadList}
                    deleteUpload={async upload => {
                      try {
                        await deleteUpload(upload);
                        await retrieveProductAndInjectIntoState(roomProduct);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  <FluidButton
                    type="secondary"
                    className="w-[200px] !mt-4 self-start"
                    onClick={async () => {
                      const modalResult = await uploadModalData.openModal();
                      // then upload the file
                      if (modalResult) {
                        const formData = new FormData();
                        formData.append('file', modalResult.file);
                        formData.append('tag', modalResult.tag);
                        formData.append('displayName', modalResult.name);

                        formData.append('ownerUuid', roomProduct.uuid);
                        formData.append('ownerType', 'Product');

                        backendApi.uploadFile(formData).then(async res => {
                          await retrieveProductAndInjectIntoState(roomProduct);
                          dispatch(
                            enqueueNotification({
                              message: `Upload successful`,
                              options: { variant: 'success' },
                            })
                          );
                        });
                      }
                    }}
                  >
                    Add Document
                  </FluidButton>
                </div>
              );
            },
          },
          {
            title: 'Rates',
            name: 'rates',
            accessKey: 'seasonalProductRates',
            hasUnsavedChanges: item => editedUnsavedRatesItemUuids.includes(item.uuid),
            titleRenderer: (roomProduct, seasonalProductRate) => {
              const matchingSeason = hotel!.seasons!.find(season => season.uuid === seasonalProductRate.seasonUuid);
              return (
                <span>
                  <span className="">{matchingSeason!.name}</span>
                  {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                    <span className="ml-2 font-bold">
                      {getCurrencySymbol(hotel?.defaultCurrency!)}
                      {seasonalProductRate.rate}
                    </span>
                  )}
                </span>
              );
            },
            contentRenderer: (roomProduct: IAccommodationProduct, seasonalProductRate: ISeasonalProductRate) => {
              return (
                <SimpleTabs
                  className="o:w-full o:pt-0 o:pb-0"
                  tabConfig={[
                    {
                      title: 'Rate Details',
                      name: 'rate-details',
                      styles: 'min-w-150px py-2',
                      renderContent: () => (
                        <RoomRateDetails
                          hotel={draftHotel!}
                          room={roomProduct}
                          seasonalProductRate={seasonalProductRate}
                          bootstrapCountries={bootstrapCountries}
                          epsProduct={epsProduct}
                          onUpdateSeasonalProductRate={(field, value) => {
                            handleUpdateSeasonalProductRateAttribute(
                              'accommodationProducts',
                              roomProduct.uuid,
                              seasonalProductRate.uuid,
                              field,
                              value
                            );
                          }}
                          onCta={async () => {
                            const validation = validateSeasonalProductRate(
                              seasonalProductRate,
                              roomProduct,
                              epsProduct
                            );
                            if (validation.isValid === false) {
                              dispatch(
                                enqueueNotification({
                                  message: `Room rate is not valid: ${validation.message}`,
                                  options: { variant: 'warning' },
                                })
                              );
                              return;
                            }
                            try {
                              await patchSeasonalProductRate(roomProduct, seasonalProductRate.uuid);
                            } finally {
                              await retrieveProductAndInjectIntoState(roomProduct);
                            }
                          }}
                          ctaLabel="Update Rate & EPS Addon Rate"
                        />
                      ),
                    },
                    {
                      title: 'Meal Plan Rates',
                      name: 'rate-addons',
                      styles: 'min-w-150px py-2',
                      renderContent: () => {
                        return (
                          <RoomRateAddons
                            hotel={draftHotel}
                            seasonalProductRate={seasonalProductRate}
                            onUpdateRoomRate={(field, value) => {
                              handleUpdateSeasonalProductRateAttribute(
                                'accommodationProducts',
                                roomProduct.uuid,
                                seasonalProductRate.uuid,
                                field,
                                value
                              );
                            }}
                            onPatchMealPlans={async () => {
                              if (
                                _.isNil(seasonalProductRate.seasonalProductAddonRates) ||
                                seasonalProductRate.seasonalProductAddonRates.length === 0
                              ) {
                                return;
                              }

                              const addonsThatArentEps = seasonalProductRate.seasonalProductAddonRates.filter(
                                spar => spar.product.uuid !== epsProduct.uuid
                              );

                              for (const mealPlan of addonsThatArentEps) {
                                const validation = validateSeasonalProductAddonRate(
                                  mealPlan,
                                  // @ts-ignore
                                  mealPlan.product.options.ages,
                                  seasonalProductRate
                                );
                                if (validation.isValid === false) {
                                  dispatch(
                                    enqueueNotification({
                                      message: `Meal plan rate is not valid: ${validation.message}`,
                                      options: { variant: 'warning' },
                                    })
                                  );
                                  return;
                                }
                              }
                              try {
                                await patchRoomAddonMealPlans(roomProduct.uuid, seasonalProductRate.uuid);
                              } finally {
                                await retrieveProductAndInjectIntoState(roomProduct);
                              }
                            }}
                            onNewCta={async () => {
                              setSeasonalProductRateForCreatingAddonRate(seasonalProductRate);
                              const sparData = await createAddonRateModalData.openModal();
                              if (sparData) {
                                try {
                                  await postSeasonalProductAddonRate({
                                    ...sparData,
                                    seasonalProductRateUuid: seasonalProductRate.uuid,
                                  });
                                } finally {
                                  await retrieveProductAndInjectIntoState(roomProduct);
                                }
                              }
                            }}
                          />
                        );
                      },
                    },
                  ]}
                />
              );
            },
            bottomContent: roomProduct => {
              return (
                <AddNewRate
                  onCta={async () => {
                    try {
                      await postSeasonalProductRate(roomProduct);
                    } finally {
                      await retrieveProductAndInjectIntoState(roomProduct);
                    }
                  }}
                />
              );
            },
          },
        ]}
        onCreateTrigger={async () => {
          const data = await createNewChildItemModals.room.openModal();
          if (data) {
            try {
              await postNewRoom(hotel!, data);
            } finally {
              await retrieveHotel();
            }
          }
        }}
        onDeleteTrigger={async (room: IAccommodationProduct) => {
          if (confirm(`Are you sure you want to delete room "${room.name}"? This cannot be undone!`)) {
            try {
              await deleteProduct(room, 'Room');
            } finally {
              await retrieveHotel();
            }
          }
        }}
      />
    );
  }, [draftHotel?.accommodationProducts || [], editedUnsavedDetailsItemUuids, editedUnsavedRatesItemUuids]);

  const mealPlansRenderContent = useCallback(() => {
    return (
      <ChildrenList
        hotel={draftHotel}
        singularNoun="Meal Plan"
        childList={_.orderBy(draftHotel!.mealPlanProducts, ['name'], ['asc']) || []}
        editedUnsavedDetailsItemUuids={editedUnsavedDetailsItemUuids}
        onOpen={childItem => {
          retrieveProductAndInjectIntoState(childItem);
        }}
        titleRenderer={(mealPlan: IProduct<IMealPlanProductOptions>) => {
          return (
            <span className="inline-flex items-center space-x-3">
              <span className="font-bold">{mealPlan.name}</span>
              <span className="text-sm">Category: {mealPlan.meta.categoryType}</span>
            </span>
          );
        }}
        contentRenderer={(mealPlan: IProduct<IMealPlanProductOptions>) => {
          return (
            <MealPlan
              hotel={draftHotel!}
              mealPlan={mealPlan}
              onUpdate={(field, value) => {
                handleUpdateChildItemAttribute('mealPlanProducts', mealPlan.uuid, field, value);
              }}
              onCta={async () => await handlePatchChildItemFlow(patchMealPlan, validateMealPlan, mealPlan)}
              ctaLabel="Update Meal Plan"
              mode="edit"
            />
          );
        }}
        renderChildSubProducts={[
          {
            title: 'Documents',
            name: 'documents',
            accessKey: 'uploads',
            listFilter: uploads => {
              return true;
            },
            tabContentRenderer: (mealPlanProduct, uploadList) => {
              return (
                <div className="flex flex-col space-y-2 w-full">
                  <Uploads
                    uploads={uploadList}
                    deleteUpload={async upload => {
                      try {
                        await deleteUpload(upload);
                        await retrieveProductAndInjectIntoState(mealPlanProduct);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  <FluidButton
                    type="secondary"
                    onClick={async () => {
                      const modalResult = await uploadModalData.openModal();
                      // then upload the file
                      if (modalResult) {
                        const formData = new FormData();
                        formData.append('file', modalResult.file);
                        formData.append('tag', modalResult.tag);
                        formData.append('displayName', modalResult.name);

                        formData.append('ownerUuid', mealPlanProduct.uuid);
                        formData.append('ownerType', 'Product');

                        backendApi.uploadFile(formData).then(async res => {
                          await retrieveProductAndInjectIntoState(mealPlanProduct);
                          dispatch(
                            enqueueNotification({
                              message: `Upload successful`,
                              options: { variant: 'success' },
                            })
                          );
                        });
                      }
                    }}
                  >
                    Add Document
                  </FluidButton>
                </div>
              );
            },
          },
        ]}
        onCreateTrigger={async () => {
          const data = await createNewChildItemModals.mealPlan.openModal();
          if (data) {
            try {
              await postNewMealPlan(hotel!, data);
            } finally {
              await retrieveHotel();
            }
          }
        }}
        onDeleteTrigger={async (mealPlan: IProduct<IMealPlanProductOptions>) => {
          if (confirm(`Are you sure you want to delete meal plan "${mealPlan.name}"? This cannot be undone!`)) {
            try {
              await deleteProduct(mealPlan, 'Meal plan');
            } finally {
              await retrieveHotel();
            }
          }
        }}
      />
    );
  }, [draftHotel?.mealPlanProducts || [], editedUnsavedDetailsItemUuids, editedUnsavedRatesItemUuids]);

  const transfersRenderContent = useCallback(() => {
    return (
      <ChildrenList
        hotel={hotel}
        singularNoun="Transfer"
        childList={_.orderBy(hotel!.transferProducts, ['name'], ['asc']) || []}
        editedUnsavedDetailsItemUuids={editedUnsavedDetailsItemUuids}
        onOpen={transfer => {
          retrieveProductAndInjectIntoState(transfer);
        }}
        titleRenderer={(transfer: IProduct<ITransferProductOptions>) => {
          return (
            <span className="inline-flex items-center space-x-3">
              <span className="font-bold">{transfer.name}</span>
              <span className="text-sm">Category: {categoryToLabelMap[transfer.category]}</span>
              <span className="text-sm">Capacity: {transfer.options.capacity}</span>
              <span className="text-sm">One Way: {transfer.options.isOneWay ? 'Yes' : 'No'}</span>
            </span>
          );
        }}
        contentRenderer={(transfer: IProduct<ITransferProductOptions>) => {
          return (
            <Transfer
              transfer={transfer}
              onUpdate={(field, value) => {
                handleUpdateChildItemAttribute('transferProducts', transfer.uuid, field, value);
              }}
              onCta={async () => await handlePatchChildItemFlow(patchTransfer, validateTransfer, transfer)}
              ctaLabel="Update Transfer"
            />
          );
        }}
        renderChildSubProducts={[
          {
            title: 'Documents',
            name: 'documents',
            accessKey: 'uploads',
            tabContentRenderer: (transferProduct, uploadList) => {
              return (
                <div className="flex flex-col space-y-2 w-full">
                  <Uploads
                    uploads={uploadList}
                    deleteUpload={async upload => {
                      try {
                        await deleteUpload(upload);
                        await retrieveProductAndInjectIntoState(transferProduct);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  <FluidButton
                    type="secondary"
                    onClick={async () => {
                      const modalResult = await uploadModalData.openModal();
                      // then upload the file
                      if (modalResult) {
                        const formData = new FormData();
                        formData.append('file', modalResult.file);
                        formData.append('tag', modalResult.tag);
                        formData.append('displayName', modalResult.name);

                        formData.append('ownerUuid', transferProduct.uuid);
                        formData.append('ownerType', 'Product');

                        backendApi.uploadFile(formData).then(async res => {
                          await retrieveProductAndInjectIntoState(transferProduct);
                          dispatch(
                            enqueueNotification({
                              message: `Upload successful`,
                              options: { variant: 'success' },
                            })
                          );
                        });
                      }
                    }}
                  >
                    Add Document
                  </FluidButton>
                </div>
              );
            },
          },
          {
            title: 'Rates',
            name: 'rates',
            accessKey: 'seasonalProductRates',
            hasUnsavedChanges: item => editedUnsavedRatesItemUuids.includes(item.uuid),
            titleRenderer: (transferProduct, seasonalProductRate) => {
              const matchingSeason = hotel!.seasons!.find(season => season.uuid === seasonalProductRate.seasonUuid);
              return (
                <span>
                  <span className="">{matchingSeason!.name}</span>
                  {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                    <span className="ml-2 font-bold">
                      {getCurrencySymbol(hotel?.defaultCurrency!)}
                      {seasonalProductRate.rate}
                    </span>
                  )}
                </span>
              );
            },
            contentRenderer: (transferProduct, seasonalProductRate) => {
              return (
                <SimpleTabs
                  className="o:w-full o:pt-0 o:pb-0"
                  tabConfig={[
                    {
                      title: 'Rate Details',
                      name: 'rate-details',
                      styles: 'min-w-150px py-2',
                      renderContent: () => {
                        return (
                          <TransferRateDetails
                            hotel={draftHotel!}
                            transfer={transferProduct}
                            seasonalProductRate={seasonalProductRate}
                            bootstrapCountries={bootstrapCountries}
                            onUpdateSeasonalProductRate={(field, value) => {
                              handleUpdateSeasonalProductRateAttribute(
                                'transferProducts',
                                transferProduct.uuid,
                                seasonalProductRate.uuid,
                                field,
                                value
                              );
                            }}
                            onCta={async () => {
                              const validation = validateSeasonalProductRate(seasonalProductRate, transferProduct);
                              if (validation.isValid === false) {
                                dispatch(
                                  enqueueNotification({
                                    message: `Transfer rate is not valid: ${validation.message}`,
                                    options: { variant: 'warning' },
                                  })
                                );
                                return;
                              }
                              try {
                                await patchSeasonalProductRate(transferProduct, seasonalProductRate.uuid);
                              } finally {
                                await retrieveProductAndInjectIntoState(transferProduct);
                              }
                            }}
                            ctaLabel="Update Transfer Rate"
                          />
                        );
                      },
                    },
                  ]}
                />
              );
            },
            bottomContent: transferProduct => {
              return (
                <AddNewRate
                  onCta={async () => {
                    try {
                      await postSeasonalProductRate(transferProduct);
                    } finally {
                      await retrieveProductAndInjectIntoState(transferProduct);
                    }
                  }}
                />
              );
            },
          },
        ]}
        onCreateTrigger={async () => {
          const data = await createNewChildItemModals.transfer.openModal();
          if (data) {
            try {
              await postNewTransfer(hotel!, data);
            } finally {
              await retrieveHotel();
            }
          }
        }}
        onDeleteTrigger={async (transfer: IProduct<ITransferProductOptions>) => {
          if (confirm(`Are you sure you want to delete transfer "${transfer.name}"? This cannot be undone!`)) {
            try {
              await deleteProduct(transfer, 'Transfer');
            } finally {
              await retrieveHotel();
            }
          }
        }}
      />
    );
  }, [draftHotel?.transferProducts || [], editedUnsavedDetailsItemUuids, editedUnsavedRatesItemUuids]);

  const supplementsRenderContent = useCallback(() => {
    return (
      <ChildrenList
        hotel={hotel}
        singularNoun="Supplement"
        childList={_.orderBy(hotel!.supplementProducts, ['name'], ['asc']) || []}
        editedUnsavedDetailsItemUuids={editedUnsavedDetailsItemUuids}
        onOpen={childItem => {
          retrieveProductAndInjectIntoState(childItem);
        }}
        titleRenderer={(supplement: IProduct<any>) => {
          return (
            <span className="inline-flex items-center space-x-3">
              <span className="font-bold">{supplement.name}</span>
              <span className="text-sm">Category: {categoryToLabelMap[supplement.category]}</span>
            </span>
          );
        }}
        contentRenderer={(supplementProduct: IProduct<any>) => (
          <Supplement
            supplement={supplementProduct}
            onUpdate={(field, value) => {
              handleUpdateChildItemAttribute('supplementProducts', supplementProduct.uuid, field, value);
            }}
            onCta={async () => await handlePatchChildItemFlow(patchSupplement, validateSupplement, supplementProduct)}
            ctaLabel="Update Supplement"
          />
        )}
        renderChildSubProducts={[
          {
            title: 'Documents',
            name: 'documents',
            accessKey: 'uploads',
            tabContentRenderer: (supplementProduct, uploadList) => {
              return (
                <div className="flex flex-col space-y-2 w-full">
                  <Uploads
                    uploads={uploadList}
                    deleteUpload={async upload => {
                      try {
                        await deleteUpload(upload);
                        await retrieveProductAndInjectIntoState(supplementProduct);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  <FluidButton
                    type="secondary"
                    onClick={async () => {
                      const modalResult = await uploadModalData.openModal();
                      // then upload the file
                      if (modalResult) {
                        const formData = new FormData();
                        formData.append('file', modalResult.file);
                        formData.append('tag', modalResult.tag);
                        formData.append('displayName', modalResult.name);

                        formData.append('ownerUuid', supplementProduct.uuid);
                        formData.append('ownerType', 'Product');

                        backendApi.uploadFile(formData).then(async res => {
                          await retrieveProductAndInjectIntoState(supplementProduct);
                          dispatch(
                            enqueueNotification({
                              message: `Upload successful`,
                              options: { variant: 'success' },
                            })
                          );
                        });
                      }
                    }}
                  >
                    Add Document
                  </FluidButton>
                </div>
              );
            },
          },
          {
            title: 'Rates',
            name: 'rates',
            accessKey: 'seasonalProductRates',
            hasUnsavedChanges: item => editedUnsavedRatesItemUuids.includes(item.uuid),
            titleRenderer: (supplementProduct, seasonalProductRate) => {
              const matchingSeason = hotel!.seasons!.find(season => season.uuid === seasonalProductRate.seasonUuid);
              return (
                <span>
                  <span className="">{matchingSeason!.name}</span>
                  {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                    <span className="ml-2 font-bold">
                      {getCurrencySymbol(hotel?.defaultCurrency!)}
                      {seasonalProductRate.rate}
                    </span>
                  )}
                </span>
              );
            },
            contentRenderer: (supplementProduct, seasonalProductRate) => {
              return (
                <SimpleTabs
                  className="o:w-full o:pt-0 o:pb-0"
                  tabConfig={[
                    {
                      title: 'Rate Details',
                      name: 'rate-details',
                      styles: 'min-w-150px py-2',
                      renderContent: () => {
                        return (
                          <SupplementRateDetails
                            hotel={draftHotel!}
                            supplement={supplementProduct}
                            seasonalProductRate={seasonalProductRate}
                            bootstrapCountries={bootstrapCountries}
                            onUpdateSeasonalProductRate={(field, value) => {
                              handleUpdateSeasonalProductRateAttribute(
                                'supplementProducts',
                                supplementProduct.uuid,
                                seasonalProductRate.uuid,
                                field,
                                value
                              );
                            }}
                            ctaLabel="Update Supplement Rate"
                            onCta={async () => {
                              const validation = validateSeasonalProductRate(seasonalProductRate, supplementProduct);
                              if (validation.isValid === false) {
                                dispatch(
                                  enqueueNotification({
                                    message: `Supplement rate is not valid: ${validation.message}`,
                                    options: { variant: 'warning' },
                                  })
                                );
                                return;
                              }
                              try {
                                await patchSeasonalProductRate(supplementProduct, seasonalProductRate.uuid);
                              } finally {
                                await retrieveProductAndInjectIntoState(supplementProduct);
                              }
                            }}
                          />
                        );
                      },
                    },
                  ]}
                />
              );
            },
            bottomContent: supplementProduct => {
              return (
                <AddNewRate
                  onCta={async () => {
                    try {
                      await postSeasonalProductRate(supplementProduct);
                    } finally {
                      await retrieveProductAndInjectIntoState(supplementProduct);
                    }
                  }}
                />
              );
            },
          },
        ]}
        onCreateTrigger={async () => {
          const data = await createNewChildItemModals.supplement.openModal();
          if (data) {
            try {
              await postNewSupplement(hotel!, data);
            } finally {
              await retrieveHotel();
            }
          }
        }}
        onDeleteTrigger={async (supplement: IProduct<any>) => {
          if (confirm(`Are you sure you want to delete supplement "${supplement.name}"? This cannot be undone!`)) {
            try {
              await deleteProduct(supplement, 'Supplement');
            } finally {
              await retrieveHotel();
            }
          }
        }}
      />
    );
  }, [draftHotel?.supplementProducts || [], editedUnsavedDetailsItemUuids, editedUnsavedRatesItemUuids]);

  const groundServiceRenderContent = useCallback(() => {
    return (
      <ChildrenList
        hotel={hotel}
        singularNoun="Ground Service"
        childList={_.orderBy(hotel!.groundServiceProducts, ['name'], ['asc']) || []}
        editedUnsavedDetailsItemUuids={editedUnsavedDetailsItemUuids}
        onOpen={childItem => {
          retrieveProductAndInjectIntoState(childItem);
        }}
        contentRenderer={(groundServiceProduct: IProduct<any>) => (
          <GroundService
            groundService={groundServiceProduct}
            onUpdate={(field, value) => {
              handleUpdateChildItemAttribute('groundServiceProducts', groundServiceProduct.uuid, field, value);
            }}
            onCta={async () =>
              await handlePatchChildItemFlow(patchGroundService, validateGroundService, groundServiceProduct)
            }
            ctaLabel="Update Ground Service"
          />
        )}
        renderChildSubProducts={[
          {
            title: 'Documents',
            name: 'documents',
            accessKey: 'uploads',
            tabContentRenderer: (groundService, uploadList) => {
              return (
                <div className="flex flex-col space-y-2 w-full">
                  <Uploads
                    uploads={uploadList}
                    deleteUpload={async upload => {
                      try {
                        await deleteUpload(upload);
                        await retrieveProductAndInjectIntoState(groundService);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  <FluidButton
                    type="secondary"
                    onClick={async () => {
                      const modalResult = await uploadModalData.openModal();
                      // then upload the file
                      if (modalResult) {
                        const formData = new FormData();
                        formData.append('file', modalResult.file);
                        formData.append('tag', modalResult.tag);
                        formData.append('displayName', modalResult.name);

                        formData.append('ownerUuid', groundService.uuid);
                        formData.append('ownerType', 'Product');

                        backendApi.uploadFile(formData).then(async res => {
                          await retrieveProductAndInjectIntoState(groundService);
                          dispatch(
                            enqueueNotification({
                              message: `Upload successful`,
                              options: { variant: 'success' },
                            })
                          );
                        });
                      }
                    }}
                  >
                    Add Document
                  </FluidButton>
                </div>
              );
            },
          },
          {
            title: 'Rates',
            name: 'rates',
            accessKey: 'seasonalProductRates',
            hasUnsavedChanges: item => editedUnsavedRatesItemUuids.includes(item.uuid),
            titleRenderer: (groundService, seasonalProductRate) => {
              const matchingSeason = hotel!.seasons!.find(season => season.uuid === seasonalProductRate.seasonUuid);
              return (
                <span>
                  <span className="">{matchingSeason!.name}</span>
                  {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                    <span className="ml-2 font-bold">
                      {getCurrencySymbol(hotel?.defaultCurrency!)}
                      {seasonalProductRate.rate}
                    </span>
                  )}
                </span>
              );
            },
            contentRenderer: (groundServiceProduct, seasonalProductRate) => {
              return (
                <SimpleTabs
                  className="o:w-full o:pt-0 o:pb-0"
                  tabConfig={[
                    {
                      title: 'Rate Details',
                      name: 'rate-details',
                      styles: 'min-w-150px py-2',
                      renderContent: () => {
                        return (
                          <GroundServiceRateDetails
                            hotel={draftHotel!}
                            groundService={groundServiceProduct}
                            seasonalProductRate={seasonalProductRate}
                            bootstrapCountries={bootstrapCountries}
                            onUpdateSeasonalProductRate={(field, value) => {
                              handleUpdateSeasonalProductRateAttribute(
                                'groundServiceProducts',
                                groundServiceProduct.uuid,
                                seasonalProductRate.uuid,
                                field,
                                value
                              );
                            }}
                            onCta={async () => {
                              const validation = validateSeasonalProductRate(seasonalProductRate, groundServiceProduct);
                              if (validation.isValid === false) {
                                dispatch(
                                  enqueueNotification({
                                    message: `Ground service rate is not valid: ${validation.message}`,
                                    options: { variant: 'warning' },
                                  })
                                );
                                return;
                              }
                              try {
                                await patchSeasonalProductRate(groundServiceProduct, seasonalProductRate.uuid);
                              } finally {
                                await retrieveProductAndInjectIntoState(groundServiceProduct);
                              }
                            }}
                            ctaLabel="Update Ground Service Rate"
                          />
                        );
                      },
                    },
                  ]}
                />
              );
            },
            bottomContent: groundService => {
              return (
                <AddNewRate
                  onCta={async () => {
                    try {
                      await postSeasonalProductRate(groundService);
                    } finally {
                      await retrieveProductAndInjectIntoState(groundService);
                    }
                  }}
                />
              );
            },
          },
        ]}
        onCreateTrigger={async () => {
          const data = await createNewChildItemModals.groundService.openModal();
          if (data) {
            try {
              await postNewGroundService(hotel!, data);
            } finally {
              await retrieveHotel();
            }
          }
        }}
        titleRenderer={(groundServiceProduct: IProduct<any>) => {
          return (
            <span className="inline-flex items-center space-x-3">
              <span className="font-bold">{groundServiceProduct.name}</span>
              <span className="text-sm">Category: {categoryToLabelMap[groundServiceProduct.category]}</span>
            </span>
          );
        }}
        onDeleteTrigger={async (groundService: IProduct<any>) => {
          if (
            confirm(`Are you sure you want to delete ground service "${groundService.name}"? This cannot be undone!`)
          ) {
            try {
              await deleteProduct(groundService, 'Ground service');
            } finally {
              await retrieveHotel();
            }
          }
        }}
      />
    );
  }, [draftHotel?.groundServiceProducts || [], editedUnsavedDetailsItemUuids, editedUnsavedRatesItemUuids]);

  const fineRenderContent = useCallback(() => {
    return (
      <ChildrenList
        hotel={hotel}
        singularNoun="Fine"
        childList={_.orderBy(hotel!.fineProducts, ['name'], ['asc']) || []}
        editedUnsavedDetailsItemUuids={editedUnsavedDetailsItemUuids}
        onOpen={childItem => {
          retrieveProductAndInjectIntoState(childItem);
        }}
        contentRenderer={(fineProduct: IProduct<any>) => (
          <Fine
            fine={fineProduct}
            onUpdate={(field, value) => {
              handleUpdateChildItemAttribute('fineProducts', fineProduct.uuid, field, value);
            }}
            onCta={async () => await handlePatchChildItemFlow(patchFineProduct, validateFine, fineProduct)}
            ctaLabel="Update Fine"
          />
        )}
        renderChildSubProducts={[
          {
            title: 'Documents',
            name: 'documents',
            accessKey: 'uploads',
            tabContentRenderer: (fineProduct, uploadList) => {
              return (
                <div className="flex flex-col space-y-2 w-full">
                  <Uploads
                    uploads={uploadList}
                    deleteUpload={async upload => {
                      try {
                        await deleteUpload(upload);
                        await retrieveProductAndInjectIntoState(fineProduct);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  <FluidButton
                    type="secondary"
                    onClick={async () => {
                      const modalResult = await uploadModalData.openModal();
                      // then upload the file
                      if (modalResult) {
                        const formData = new FormData();
                        formData.append('file', modalResult.file);
                        formData.append('tag', modalResult.tag);
                        formData.append('displayName', modalResult.name);

                        formData.append('ownerUuid', fineProduct.uuid);
                        formData.append('ownerType', 'Product');

                        backendApi.uploadFile(formData).then(async res => {
                          await retrieveProductAndInjectIntoState(fineProduct);
                          dispatch(
                            enqueueNotification({
                              message: `Upload successful`,
                              options: { variant: 'success' },
                            })
                          );
                        });
                      }
                    }}
                  >
                    Add Document
                  </FluidButton>
                </div>
              );
            },
          },
          {
            title: 'Rates',
            name: 'rates',
            accessKey: 'seasonalProductRates',
            hasUnsavedChanges: item => editedUnsavedRatesItemUuids.includes(item.uuid),
            titleRenderer: (fineProduct, seasonalProductRate) => {
              const matchingSeason = hotel!.seasons!.find(season => season.uuid === seasonalProductRate.seasonUuid);
              return (
                <span>
                  <span className="">{matchingSeason!.name}</span>
                  {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                    <span className="ml-2 font-bold">
                      {getCurrencySymbol(hotel?.defaultCurrency!)}
                      {seasonalProductRate.rate}
                    </span>
                  )}
                </span>
              );
            },
            contentRenderer: (fineProduct, seasonalProductRate) => {
              return (
                <div>
                  <SimpleTabs
                    className="o:w-full o:pt-0 o:pb-0"
                    tabConfig={[
                      {
                        title: 'Rate Details',
                        name: 'rate-details',
                        styles: 'min-w-150px py-2',
                        renderContent: () => {
                          return (
                            <FineRateDetails
                              hotel={draftHotel!}
                              fineProduct={fineProduct}
                              seasonalProductRate={seasonalProductRate}
                              bootstrapCountries={bootstrapCountries}
                              onUpdateSeasonalProductRate={(field, value) => {
                                handleUpdateSeasonalProductRateAttribute(
                                  'fineProducts',
                                  fineProduct.uuid,
                                  seasonalProductRate.uuid,
                                  field,
                                  value
                                );
                              }}
                              onCta={async () => {
                                const validation = validateSeasonalProductRate(seasonalProductRate, fineProduct);
                                if (validation.isValid === false) {
                                  dispatch(
                                    enqueueNotification({
                                      message: `Fine rate is not valid: ${validation.message}`,
                                      options: { variant: 'warning' },
                                    })
                                  );
                                  return;
                                }
                                try {
                                  await patchSeasonalProductRate(fineProduct, seasonalProductRate.uuid);
                                } finally {
                                  await retrieveProductAndInjectIntoState(fineProduct);
                                }
                              }}
                              ctaLabel="Update Fine Rate"
                            />
                          );
                        },
                      },
                    ]}
                  />
                </div>
              );
            },
            bottomContent: fineProduct => {
              return (
                <AddNewRate
                  onCta={async () => {
                    try {
                      await postSeasonalProductRate(fineProduct);
                    } finally {
                      await retrieveProductAndInjectIntoState(fineProduct);
                    }
                  }}
                />
              );
            },
          },
        ]}
        onCreateTrigger={async () => {
          const data = await createNewChildItemModals.fine.openModal();
          if (data) {
            try {
              await postNewFine(hotel!, data);
            } finally {
              await retrieveHotel();
            }
          }
        }}
        titleRenderer={(fineProduct: IProduct<any>) => {
          return (
            <span className="inline-flex items-center space-x-3">
              <span className="font-bold">{fineProduct.name}</span>
              <span className="text-sm">Category: {categoryToLabelMap[fineProduct.category]}</span>
            </span>
          );
        }}
        onDeleteTrigger={async (fine: IProduct<any>) => {
          if (confirm(`Are you sure you want to delete fine "${fine.name}"? This cannot be undone!`)) {
            try {
              await deleteProduct(fine, 'Fine');
            } finally {
              await retrieveHotel();
            }
          }
        }}
      />
    );
  }, [draftHotel?.fineProducts || [], editedUnsavedDetailsItemUuids, editedUnsavedRatesItemUuids]);

  if (!hotel || !draftHotel) {
    return (
      <div className="container w-1280px mx-auto">
        <LoadingBar />
      </div>
    );
  }

  return (
    <div className="container w-1280px mx-auto">
      <Link className="font-pt-sans underline hover:decoration-brown-prime" to="/hotel-admin">
        Back to Hotels
      </Link>
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Products - <span className="text-[26px]">Hotels - Editing "{hotel.name}" Children Products</span>
      </h1>
      <Link className="font-pt-sans underline hover:decoration-brown-prime" to={`/hotel-admin/${hotel.uuid}/edit`}>
        Edit Hotel
      </Link>

      <span
        className={classNames(
          'fixed inline-block top-[50%] left-[50%] bg-white p-3 rounded-full pointer-events-none z-[999999]',
          {
            'opacity-0': updatingRequest !== ENetworkRequestStatus.PENDING,
          }
        )}
      >
        <i className="fas fa-circle-notch fa-spin text-brown-140 fa-2x"></i>
      </span>

      <SimpleTabs
        className={classNames('', {
          'opacity-60 pointer-events-none': updatingRequest === ENetworkRequestStatus.PENDING,
        })}
        tabConfig={[
          {
            title: 'Seasons',
            name: 'seasons',
            styles: 'min-w-150px',
            renderContent: seasonsRenderContent,
          },
          {
            title: 'Rooms',
            name: 'rooms',
            styles: 'min-w-150px',
            renderContent: roomsRenderContent,
          },
          {
            title: 'Meal Plans',
            name: 'meal-plans',
            styles: 'min-w-150px',
            renderContent: mealPlansRenderContent,
          },
          {
            title: 'Transfers',
            name: 'transfers',
            styles: 'min-w-150px',
            renderContent: transfersRenderContent,
          },
          {
            title: 'Supplements',
            name: 'supplements',
            styles: 'min-w-150px',
            renderContent: supplementsRenderContent,
          },
          {
            title: 'Ground Services',
            name: 'ground-services',
            styles: 'min-w-150px',
            renderContent: groundServiceRenderContent,
          },
          {
            title: 'Fines',
            name: 'fines',
            styles: 'min-w-150px',
            renderContent: fineRenderContent,
          },
        ]}
      />

      {uploadModalData.isOpen && (
        <UploadModal onConfirm={uploadModalData.handleConfirm} onClose={uploadModalData.handleCancel} />
      )}

      {createNewChildItemModals.season.isOpen && (
        <CreateSeasonModal
          hotel={hotel}
          onConfirm={createNewChildItemModals.season.handleConfirm}
          onClose={createNewChildItemModals.season.handleCancel}
        />
      )}

      {createNewChildItemModals.room.isOpen && (
        <CreateRoomModal
          hotel={hotel}
          onConfirm={createNewChildItemModals.room.handleConfirm}
          onClose={createNewChildItemModals.room.handleCancel}
        />
      )}

      {createNewChildItemModals.mealPlan.isOpen && (
        <CreateMealPlanModal
          hotel={hotel}
          onConfirm={createNewChildItemModals.mealPlan.handleConfirm}
          onClose={createNewChildItemModals.mealPlan.handleCancel}
        />
      )}

      {createNewChildItemModals.transfer.isOpen && (
        <CreateTransferModal
          hotel={hotel}
          onConfirm={createNewChildItemModals.transfer.handleConfirm}
          onClose={createNewChildItemModals.transfer.handleCancel}
        />
      )}

      {createNewChildItemModals.supplement.isOpen && (
        <CreateSupplementModal
          hotel={hotel}
          onConfirm={createNewChildItemModals.supplement.handleConfirm}
          onClose={createNewChildItemModals.supplement.handleCancel}
        />
      )}

      {createNewChildItemModals.groundService.isOpen && (
        <CreateGroundServiceModal
          hotel={hotel}
          onConfirm={createNewChildItemModals.groundService.handleConfirm}
          onClose={createNewChildItemModals.groundService.handleCancel}
        />
      )}

      {createNewChildItemModals.fine.isOpen && (
        <CreateFineModal
          hotel={hotel}
          onConfirm={createNewChildItemModals.fine.handleConfirm}
          onClose={createNewChildItemModals.fine.handleCancel}
        />
      )}

      {createRateModalData.isOpen && creatingRateFor && (
        <CreateRateModal
          hotel={hotel}
          onConfirm={createRateModalData.handleConfirm}
          onClose={createRateModalData.handleCancel}
          product={creatingRateFor}
          bootstrapCountries={bootstrapCountries}
          epsProduct={epsProduct}
        />
      )}

      {createAddonRateModalData.isOpen && seasonalProductRateForCreatingAddonRate && (
        <CreateAddonRateModal
          hotel={hotel}
          onConfirm={createAddonRateModalData.handleConfirm}
          onClose={createAddonRateModalData.handleCancel}
          mealPlans={hotel.mealPlanProducts || []}
          seasonalProductRateForCreatingAddonRate={seasonalProductRateForCreatingAddonRate}
        />
      )}
    </div>
  );
};
