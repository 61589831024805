import React from 'react';
import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const MealPlanIcon: React.FC<IIconProps> = React.memo(({ className = '', icon = 'black' }) => (
  <svg width="16" height="17" className={className} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 6.49992H7.33333V1.83325H8.66667V6.49992C8.66667 7.97325 7.47333 9.16659 6 9.16659V15.1666H4.66667V9.16659C3.19333 9.16659 2 7.97325 2 6.49992V1.83325H3.33333V6.49992H4.66667V1.83325H6V6.49992ZM10.6667 9.83325V4.49992C10.6667 3.32659 12.16 1.83325 14 1.83325V15.1666H12.6667V9.83325H10.6667Z"
      fill={theme.colors[icon]}
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

export default MealPlanIcon;
