import { call, take, takeLatest, select, put, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import {
  makeBookingManagerApi,
  IBookingLedgerRequestData,
  IDepositAccountRequestData,
  ILedgerTotalsResponse,
  IDepositAccountResponse,
  IPostExportResponse,
  IGetExportResponse,
  IStatementPdf,
  IStatementPdfDownloadHistoryResponse,
  IPaymentMethodsGetInContextResponse,
} from 'services/BookingManagerApi';
import { enqueueNotification } from 'store/modules/ui';

import {
  IBookingLedger,
  IDepositAccountRowForSave,
  IManualBookingLedgerRowForSave,
  IPagination,
  ISorting,
} from './model';
import * as Actions from './actions';
import * as Selectors from './selectors';
import { TCurrencyCode } from 'interfaces';
import { selectedTaSelector } from '../agents';
import { EUploadTag, IUploadResponse, makeBackendApi } from 'services/BackendApi';
import { CheckDepositInitialBCFRequestAction } from './actions';
import { isDepositAccountRowInitial, isInitialType } from './utils';

const currencyOrder: TCurrencyCode[] = ['USD', 'EUR'];
const sortByCurrencyOrder = (a, b) => {
  if (currencyOrder.indexOf(b.currency) === -1) {
    return -1;
  }
  if (currencyOrder.indexOf(a.currency) > currencyOrder.indexOf(b.currency)) {
    return 1;
  }
  if (currencyOrder.indexOf(a.currency) < currencyOrder.indexOf(b.currency)) {
    return -1;
  }
  return 0;
};

export function* uploadFile(file: File, uploadTag: EUploadTag, companyUuid: string) {
  const selectedTa = yield select(selectedTaSelector);
  const backendApi = makeBackendApi(selectedTa?.uuid);

  const formData = new FormData();
  formData.append('file', file);
  formData.append('tag', uploadTag);
  formData.append('displayName', file.name);
  formData.append('ownerType', 'Company');
  formData.append('ownerUuid', companyUuid);

  return yield call(backendApi.uploadFile, formData);
}

// eslint-disable-next-line no-unused-vars
export function* getBookingLedgerByCurrencySaga(_action: Actions.BookingLedgerRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();

    const currency = yield select(Selectors.ledgerBookingLedgerCurrencySelector);
    const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
    const bookingLedgerSort = yield select(Selectors.ledgerBookingLedgerSortSelector);
    const currentPage = yield select(Selectors.ledgerBookingLedgerPageSelector);
    const itemsPerPage = yield select(Selectors.ledgerBookingLedgerItemsPerPageSelector);
    const filter = yield select(Selectors.ledgerBookingLedgerFilterSelector);

    const requestData: IBookingLedgerRequestData = {
      filter,
      pagination: { limit: itemsPerPage, offset: currentPage * itemsPerPage },
    };

    if (bookingLedgerSort.field) {
      requestData.sort = bookingLedgerSort;
    }

    const result: AxiosResponse<IBookingLedger> = yield call(
      bookingManagerApi.bookingLedgerByCurrency,
      companyUuid,
      currency,
      requestData
    );

    yield put(Actions.bookingLedgerSuccessAction(result.data));
  } catch (e) {
    yield put(Actions.bookingLedgerFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to load booking ledger.',
        options: { variant: 'error' },
      })
    );
  }
}

// eslint-disable-next-line no-unused-vars
export function* getBookingStatementTotalsSaga(action: Actions.BookingStatementTotalsRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const result: AxiosResponse<ILedgerTotalsResponse> = yield call(
      bookingManagerApi.bookingStatementTotals,
      action.company
    );
    yield put(Actions.bookingStatementTotalsSuccessAction(result.data.sort(sortByCurrencyOrder)));
  } catch (e) {
    yield put(Actions.bookingStatementTotalsFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to load booking ledger totals.',
        options: { variant: 'error' },
      })
    );
  }
}

// eslint-disable-next-line no-unused-vars
export function* getDepositStatementTotalsSaga(action: Actions.DepositStatementTotalsRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const result: AxiosResponse<ILedgerTotalsResponse> = yield call(
      bookingManagerApi.depositStatementTotals,
      action.company
    );
    yield put(Actions.depositStatementTotalsSuccessAction(result.data.sort(sortByCurrencyOrder)));
  } catch (e) {
    yield put(Actions.depositStatementTotalsFailureAction(e));
  }
}

export function* getDepositAccountSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();

    const sorting: ISorting = yield select(Selectors.depositAccountSortingSelector);
    const pagination: IPagination = yield select(Selectors.depositAccountPaginationSelector);
    const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
    const currency = yield select(Selectors.depositAccountCurrencySelector);
    const filter = yield select(Selectors.depositAccountFilterSelector);

    const requestData: IDepositAccountRequestData = {
      pagination: {
        limit: pagination.itemsPerPage,
        offset: pagination.currentPage * pagination.itemsPerPage,
      },
      sort: {
        field: sorting.sortBy,
        order: sorting.sortOrder,
      },
      filter,
    };

    const result: AxiosResponse<IDepositAccountResponse> = yield call(
      bookingManagerApi.getDepositAccountData,
      companyUuid,
      currency,
      requestData
    );

    yield put(Actions.depositAccountSuccessAction(result.data));
  } catch (e) {
    yield put(Actions.depositAccountFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to load deposit account.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* postBookingLedgerExportSaga() {
  const bookingManagerApi = makeBookingManagerApi();

  const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
  const currency = yield select(Selectors.ledgerBookingLedgerCurrencySelector);
  const filter = yield select(Selectors.ledgerBookingLedgerFilterSelector);

  try {
    const res: AxiosResponse<IPostExportResponse> = yield call(
      bookingManagerApi.postBookingLedgerExport,
      companyUuid,
      currency,
      filter
    );

    yield put(Actions.bookingLedgerPostExportSuccessAction(res.data.exportUuid));
  } catch (e) {
    yield put(Actions.bookingLedgerPostExportFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to export booking statement.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getBookingLedgerExportSaga() {
  const bookingManagerApi = makeBookingManagerApi();

  try {
    const res: AxiosResponse<IGetExportResponse> = yield call(
      bookingManagerApi.getBookingLedgerExport,
      yield select(Selectors.ledgerBookingLedgerCsvExportUuidSelector)
    );

    yield put(Actions.bookingLedgerGetExportSuccessAction(res.data.data.signedUrl));
  } catch (e) {
    yield put(Actions.bookingLedgerGetExportFailureAction(e.message));
  }
}

export function* pollBookingLedgerExportSaga() {
  while (yield select(Selectors.ledgerBookingLedgerCsvExportUuidSelector)) {
    yield put(Actions.bookingLedgerGetExportRequestAction());

    const res = yield take([Actions.BOOKING_LEDGER_GET_EXPORT_SUCCESS, Actions.BOOKING_LEDGER_GET_EXPORT_FAILURE]);

    if (res.type === Actions.BOOKING_LEDGER_GET_EXPORT_SUCCESS) {
      if (res.signedUrl) saveAs(res.signedUrl);
    } else {
      yield put(
        enqueueNotification({
          message: 'Failed to export booking statement.',
          options: { variant: 'error' },
        })
      );
    }

    yield delay(1000);
  }
}

export function* postBookingLedgerAggregateExportSaga() {
  const bookingManagerApi = makeBookingManagerApi();
  const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);

  try {
    const res: AxiosResponse<IPostExportResponse> = yield call(
      bookingManagerApi.postBookingLedgerAggregateExport,
      companyUuid
    );

    yield put(Actions.bookingLedgerPostAggregateExportSuccessAction(res.data.exportUuid));
  } catch (e) {
    yield put(Actions.bookingLedgerPostAggregateExportFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to export booking statement.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getBookingLedgerAggregateExportSaga() {
  const bookingManagerApi = makeBookingManagerApi();

  try {
    const res: AxiosResponse<IGetExportResponse> = yield call(
      bookingManagerApi.getBookingLedgerExport,
      yield select(Selectors.ledgerBookingLedgerCsvAggregateExportUuidSelector)
    );

    yield put(Actions.bookingLedgerGetAggregateExportSuccessAction(res.data.data.signedUrl));
  } catch (e) {
    yield put(Actions.bookingLedgerGetAggregateExportFailureAction(e.message));
  }
}

export function* pollBookingLedgerAggregateExportSaga() {
  while (yield select(Selectors.ledgerBookingLedgerCsvAggregateExportUuidSelector)) {
    yield put(Actions.bookingLedgerGetAggregateExportRequestAction());

    const res = yield take([
      Actions.BOOKING_LEDGER_GET_AGGREGATE_EXPORT_SUCCESS,
      Actions.BOOKING_LEDGER_GET_AGGREGATE_EXPORT_FAILURE,
    ]);

    if (res.type === Actions.BOOKING_LEDGER_GET_AGGREGATE_EXPORT_SUCCESS) {
      if (res.signedUrl) saveAs(res.signedUrl);
    } else {
      yield put(
        enqueueNotification({
          message: 'Failed to export booking statement.',
          options: { variant: 'error' },
        })
      );
    }

    yield delay(1000);
  }
}

export function* postDepositAccountExportSaga() {
  const bookingManagerApi = makeBookingManagerApi();

  const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
  const currency = yield select(Selectors.depositAccountCurrencySelector);
  const sorting: ISorting = yield select(Selectors.depositAccountSortingSelector);
  const filter = yield select(Selectors.depositAccountFilterSelector);

  const requestData: IDepositAccountRequestData = {
    sort: {
      field: sorting.sortBy,
      order: sorting.sortOrder,
    },
    filter,
  };
  try {
    const res: AxiosResponse<IPostExportResponse> = yield call(
      bookingManagerApi.postDepositAccountExport,
      companyUuid,
      currency,
      requestData
    );

    yield put(Actions.depositAccountPostExportSuccessAction(res.data.exportUuid));
  } catch (e) {
    yield put(Actions.depositAccountPostExportFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to export deposit account.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getDepositAccountExportSaga() {
  const bookingManagerApi = makeBookingManagerApi();

  try {
    const res: AxiosResponse<IGetExportResponse> = yield call(
      bookingManagerApi.getDepositAccountExport,
      yield select(Selectors.depositAccountCsvExportUuidSelector)
    );

    yield put(Actions.depositAccountGetExportSuccessAction(res.data.data.signedUrl));
  } catch (e) {
    yield put(Actions.depositAccountGetExportFailureAction(e.message));
  }
}

export function* pollDepositAccountExportSaga() {
  while (yield select(Selectors.depositAccountCsvExportUuidSelector)) {
    yield put(Actions.depositAccountGetExportRequestAction());

    const res = yield take([Actions.DEPOSIT_ACCOUNT_GET_EXPORT_SUCCESS, Actions.DEPOSIT_ACCOUNT_GET_EXPORT_FAILURE]);

    if (res.type === Actions.DEPOSIT_ACCOUNT_GET_EXPORT_SUCCESS) {
      if (res.signedUrl) saveAs(res.signedUrl);
    } else {
      yield put(
        enqueueNotification({
          message: 'Failed to export deposit account.',
          options: { variant: 'error' },
        })
      );
    }

    yield delay(1000);
  }
}

export function* postDepositAccountAggregateExportSaga() {
  const bookingManagerApi = makeBookingManagerApi();
  const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);

  try {
    const res: AxiosResponse<IPostExportResponse> = yield call(
      bookingManagerApi.postDepositAccountAggregateExport,
      companyUuid
    );

    yield put(Actions.depositAccountPostAggregateExportSuccessAction(res.data.exportUuid));
  } catch (e) {
    yield put(Actions.depositAccountPostAggregateExportFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to export deposit account.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getDepositAccountAggregateExportSaga() {
  const bookingManagerApi = makeBookingManagerApi();

  try {
    const res: AxiosResponse<IGetExportResponse> = yield call(
      bookingManagerApi.getDepositAccountExport,
      yield select(Selectors.depositAccountCsvAggregateExportUuidSelector)
    );

    yield put(Actions.depositAccountGetAggregateExportSuccessAction(res.data.data.signedUrl));
  } catch (e) {
    yield put(Actions.depositAccountGetAggregateExportFailureAction(e.message));
  }
}

export function* pollDepositAccountAggregateExportSaga() {
  while (yield select(Selectors.depositAccountCsvAggregateExportUuidSelector)) {
    yield put(Actions.depositAccountGetAggregateExportRequestAction());

    const res = yield take([
      Actions.DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_SUCCESS,
      Actions.DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_FAILURE,
    ]);

    if (res.type === Actions.DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_SUCCESS) {
      if (res.signedUrl) saveAs(res.signedUrl);
    } else {
      yield put(
        enqueueNotification({
          message: 'Failed to export deposit account.',
          options: { variant: 'error' },
        })
      );
    }

    yield delay(1000);
  }
}

export function* checkInitialBCFSaga(action: Actions.CheckInitialBCFRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const currency = yield select(Selectors.ledgerBookingLedgerCurrencySelector);
    const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);

    const requestData: IBookingLedgerRequestData = {
      pagination: { limit: 2, offset: 0 },
    };

    const result: AxiosResponse<IBookingLedger> = yield call(
      bookingManagerApi.bookingLedgerByCurrency,
      companyUuid,
      currency,
      requestData
    );
    const firstRow = result.data?.rows[0];
    const isFirstRowInitial = firstRow && isInitialType(firstRow.row.type);
    const isEditedRowInitial = action.rowEdited && isInitialType(action.rowEdited.type);
    const initialBCFRowExists = isFirstRowInitial && !isEditedRowInitial;
    const firstRowThatCounts = isEditedRowInitial ? result.data?.rows[1] : firstRow;

    yield put(Actions.checkInitialBCFSuccessAction(initialBCFRowExists, firstRowThatCounts));
    yield put(Actions.openBalanceCarriedForwardModalAction());
  } catch (e) {
    yield put(Actions.checkInitialBCFFailureAction(e.message));
  }
}

export function* saveBCFRowSaga(action: Actions.SaveBCFRowRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const bookingManagerApi = makeBookingManagerApi();
    const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
    const currency = yield select(Selectors.ledgerBookingLedgerCurrencySelector);

    const manualBookingLedgerRowForSave: IManualBookingLedgerRowForSave = {
      date: action.bcfRowWithoutUpload.date,
      type: action.bcfRowWithoutUpload.type,
      amountCents: action.bcfRowWithoutUpload.amountCents,
      uploadUuid: action.bcfRowWithoutUpload.uploadUuid,
      uploadName: action.bcfRowWithoutUpload.uploadName,
      uploadUrl: action.bcfRowWithoutUpload.uploadUrl,
    };

    if (action.bcfRowWithoutUpload.uuid) {
      manualBookingLedgerRowForSave.uuid = action.bcfRowWithoutUpload.uuid;
    }

    if (action.files.length >= 1) {
      // if we have a file, upload it
      const file = action.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('tag', EUploadTag.BALANCE_CARRIED_FORWARD_DOCUMENT);
      formData.append('displayName', file.name);
      formData.append('ownerType', 'Company');
      formData.append('ownerUuid', companyUuid);

      // if the upload works, attach the upload uuid and url to the row
      const uploadResult: AxiosResponse<IUploadResponse> = yield call(backendApi.uploadFile, formData);
      manualBookingLedgerRowForSave.uploadUuid = uploadResult.data.data.uuid;
      manualBookingLedgerRowForSave.uploadUrl = uploadResult.data.data.url;
      manualBookingLedgerRowForSave.uploadName = uploadResult.data.data.filename;
    }

    let manualBookingLedgerRowResponse;
    if (manualBookingLedgerRowForSave.uuid) {
      manualBookingLedgerRowResponse = yield call(
        bookingManagerApi.updateManualBookingLedgerRow,
        companyUuid,
        currency,
        manualBookingLedgerRowForSave
      );
    } else {
      manualBookingLedgerRowResponse = yield call(
        bookingManagerApi.createManualBookingLedgerRow,
        companyUuid,
        currency,
        manualBookingLedgerRowForSave
      );
    }

    yield put(Actions.saveBCFRowSuccessAction(manualBookingLedgerRowResponse.data.rows));
    yield put(Actions.bookingLedgerRequestAction(currency));
    yield put(Actions.closeBalanceCarriedForwardModalAction());
    yield put(
      enqueueNotification({
        message: 'Balance Carried Forward row saved successfully',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(Actions.saveBCFRowFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to save Balance Carried Forward row. Please try again.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* generateBookingLedgerPdfSaga() {
  const bookingManagerApi = makeBookingManagerApi();

  const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
  const currency = yield select(Selectors.ledgerBookingLedgerCurrencySelector);
  const bookingLedgerSort = yield select(Selectors.ledgerBookingLedgerSortSelector);
  const filter = yield select(Selectors.ledgerBookingLedgerFilterSelector);

  const requestData: IBookingLedgerRequestData = {
    filter,
  };

  if (bookingLedgerSort.field) {
    requestData.sort = bookingLedgerSort;
  }

  try {
    const res: AxiosResponse<IStatementPdf> = yield call(
      bookingManagerApi.generateBookingLedgerPdf,
      companyUuid,
      currency,
      requestData
    );

    saveAs(res.data.url, res.data.filename);
    yield put(Actions.bookingLedgerGeneratePdfSuccessAction(res.data));
    yield put(Actions.bookingLedgerDownloadPdfHistoryRequestAction(companyUuid, currency));
  } catch (e) {
    yield put(Actions.bookingLedgerGeneratePdfFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to generate booking statement PDF.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* downloadPdfHistoryForBookingLedgerSaga(action: Actions.BookingLedgerDownloadPdfHistoryRequestAction) {
  const bookingManagerApi = makeBookingManagerApi();

  try {
    const res: AxiosResponse<IStatementPdfDownloadHistoryResponse> = yield call(
      bookingManagerApi.downloadHistoryForBookingLedger,
      action.companyUuid,
      action.currency
    );

    yield put(Actions.bookingLedgerDownloadPdfHistorySuccessAction(res.data.rows));
  } catch (e) {
    yield put(Actions.bookingLedgerDownloadPdfHistoryFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to download PDF history',
        options: { variant: 'error' },
      })
    );
  }
}

export function* checkInitialDepositRowSaga(action: CheckDepositInitialBCFRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const currency = yield select(Selectors.depositAccountCurrencySelector);
    const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);

    const requestData: IDepositAccountRequestData = {
      pagination: {
        limit: 2,
        offset: 0,
      },
      sort: {
        field: 'date',
        order: 'asc',
      },
    };

    const result: AxiosResponse<IDepositAccountResponse> = yield call(
      bookingManagerApi.getDepositAccountData,
      companyUuid,
      currency,
      requestData
    );
    const firstRow = result.data?.rows[0];
    const secondRow = result.data?.rows[1];
    const isFirstRowInitial = firstRow && isDepositAccountRowInitial(firstRow.row.type);
    const isEditedRowInitial = action.rowEdited && isDepositAccountRowInitial(action.rowEdited.type);
    const initialBCFRowExists = isFirstRowInitial && !isEditedRowInitial;
    const firstRowThatCounts = isEditedRowInitial ? secondRow : firstRow;

    yield put(Actions.checkDepositInitialBCFSuccessAction(initialBCFRowExists, firstRowThatCounts));
    yield put(Actions.openDepositAccountModalAction());
  } catch (e) {
    yield put(Actions.checkDepositInitialBCFFailureAction(e.message));
  }
}

export function* saveDepositRowSaga(action: Actions.SaveDepositRowRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
    const currency = yield select(Selectors.depositAccountCurrencySelector);

    const depositAccountRowForSave: IDepositAccountRowForSave = {
      date: action.depositAccountRowWithoutUpload.date,
      type: action.depositAccountRowWithoutUpload.type,
      amountCents: Math.abs(action.depositAccountRowWithoutUpload.amountCents),
      uploadUuid: action.depositAccountRowWithoutUpload.uploadUuid,
      uploadName: action.depositAccountRowWithoutUpload.uploadName,
      uploadUrl: action.depositAccountRowWithoutUpload.uploadUrl,
      notes: action.depositAccountRowWithoutUpload.notes,
      bankAccount: action.depositAccountRowWithoutUpload.bankAccount,
      paymentReceiverCompanyUuid: action.depositAccountRowWithoutUpload.paymentReceiverCompanyUuid,
    };

    if (action.depositAccountRowWithoutUpload.uuid) {
      depositAccountRowForSave.uuid = action.depositAccountRowWithoutUpload.uuid;
    }

    // if we have a file, upload it and attach the upload uuid and url to the row
    if (action.files.length > 0) {
      const uploadResponse: AxiosResponse<IUploadResponse> = yield call(
        uploadFile,
        action.files[0],
        EUploadTag.DEPOSIT_ACCOUNT_DOCUMENT,
        companyUuid
      );
      const uploadedFile = uploadResponse.data.data;
      depositAccountRowForSave.uploadUuid = uploadedFile.uuid;
      depositAccountRowForSave.uploadUrl = uploadedFile.url;
      depositAccountRowForSave.uploadName = uploadedFile.filename;
    }

    const apiMethod = depositAccountRowForSave.uuid
      ? bookingManagerApi.updateDepositAccountRow
      : bookingManagerApi.createDepositAccountRow;
    const saveDepositAccountRowResponse = yield call(apiMethod, companyUuid, currency, depositAccountRowForSave);

    yield put(Actions.saveDepositRowSuccessAction(saveDepositAccountRowResponse.data.rows));
    yield put(Actions.depositAccountRequestAction(currency));
    yield put(Actions.closeDepositAccountModalAction());
    yield put(
      enqueueNotification({
        message: 'Deposit Account row saved successfully',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(Actions.saveDepositRowFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to save Deposit Account row. Please try again.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* deleteDepositRowSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
    const currency = yield select(Selectors.depositAccountCurrencySelector);
    const depositAccountRowUuidToDelete = yield select(Selectors.depositAccountRowUuidToDeleteSelector);

    yield call(bookingManagerApi.deleteDepositAccountRow, companyUuid, currency, depositAccountRowUuidToDelete);

    yield put(Actions.deleteDepositRowSuccessAction());
    yield put(Actions.setDepositAccountRowToDeleteAction(null));
    yield put(Actions.depositAccountRequestAction(currency));
    yield put(Actions.setDARDeleteModalOpenAction(false));
    yield put(
      enqueueNotification({
        message: 'Deposit Account row deleted successfully',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(Actions.deleteDepositRowFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to delete Deposit Account row. Please try again.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* generateDepositAccountPdfSaga() {
  const bookingManagerApi = makeBookingManagerApi();

  const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);
  const currency = yield select(Selectors.depositAccountCurrencySelector);
  const sorting: ISorting = yield select(Selectors.depositAccountSortingSelector);
  const filter = yield select(Selectors.depositAccountFilterSelector);

  const requestData: IDepositAccountRequestData = {
    sort: {
      field: sorting.sortBy,
      order: sorting.sortOrder,
    },
    filter,
  };

  try {
    const res: AxiosResponse<IStatementPdf> = yield call(
      bookingManagerApi.generateDepositAccountPdf,
      companyUuid,
      currency,
      requestData
    );

    saveAs(res.data.url, res.data.filename);
    yield put(Actions.depositAccountGeneratePdfSuccessAction(res.data));
    yield put(Actions.depositAccountDownloadPdfHistoryRequestAction(companyUuid, currency));
  } catch (e) {
    yield put(Actions.depositAccountGeneratePdfFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to generate deposit account statement PDF.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* downloadPdfHistoryForDepositAccountSaga(action: Actions.BookingLedgerDownloadPdfHistoryRequestAction) {
  const bookingManagerApi = makeBookingManagerApi();

  try {
    const res: AxiosResponse<IStatementPdfDownloadHistoryResponse> = yield call(
      bookingManagerApi.downloadHistoryForDepositAccount,
      action.companyUuid,
      action.currency
    );

    yield put(Actions.depositAccountDownloadPdfHistorySuccessAction(res.data.rows));
  } catch (e) {
    yield put(Actions.depositAccountDownloadPdfHistoryFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to download PDF history',
        options: { variant: 'error' },
      })
    );
  }
}

export function* generateSummaryPdfSaga() {
  const bookingManagerApi = makeBookingManagerApi();
  const companyUuid = yield select(Selectors.ledgerSelectedCompanySelector);

  try {
    const res: AxiosResponse<IStatementPdf> = yield call(bookingManagerApi.generateFinanceSummaryPdf, companyUuid);

    saveAs(res.data.url, res.data.filename);
    yield put(Actions.summaryGeneratePdfSuccessAction(res.data));
    yield put(Actions.summaryDownloadPdfHistoryRequestAction(companyUuid));
  } catch (e) {
    yield put(Actions.summaryGeneratePdfFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to generate summary statement PDF.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* downloadPdfHistoryForSummarySaga(action: Actions.SummaryDownloadPdfHistoryRequestAction) {
  const bookingManagerApi = makeBookingManagerApi();

  try {
    const res: AxiosResponse<IStatementPdfDownloadHistoryResponse> = yield call(
      bookingManagerApi.downloadHistoryForFinanceSummary,
      action.companyUuid
    );

    yield put(Actions.summaryDownloadPdfHistorySuccessAction(res.data.rows));
  } catch (e) {
    yield put(Actions.summaryDownloadPdfHistoryFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to download PDF history',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getDepositPaymentMethodsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();

    const result: AxiosResponse<IPaymentMethodsGetInContextResponse> = yield call(
      bookingManagerApi.getPaymentMethods,
    );

    yield put(Actions.getDepositPaymentMethodsSuccessAction(
      result.data.paymentMethods,
      result.data.defaultPaymentMethodPerCurrency
    ));
  } catch (e) {
    yield put(Actions.getDepositPaymentMethodsFailureAction());
    yield put(
      enqueueNotification({
        message: 'Failed to fetch deposit payment methods',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchBookingLedgerSaga() {
  yield takeLatest([Actions.BOOKING_STATEMENT_TOTALS_REQUEST], getBookingStatementTotalsSaga);
  yield takeLatest([Actions.DEPOSIT_STATEMENT_TOTALS_REQUEST], getDepositStatementTotalsSaga);
  yield takeLatest(
    [
      Actions.BOOKING_LEDGER_REQUEST,
      Actions.BOOKING_LEDGER_SET_SORT,
      Actions.BOOKING_LEDGER_SET_PAGE_NUMBER,
      Actions.STATEMENT_FILTER_UPDATE_RESULTS,
    ],
    getBookingLedgerByCurrencySaga
  );
  yield takeLatest(
    [
      Actions.DEPOSIT_ACCOUNT_REQUEST,
      Actions.DEPOSIT_ACCOUNT_SET_SORT,
      Actions.DEPOSIT_ACCOUNT_SET_PAGE_NUMBER,
      Actions.DEPOSIT_FILTER_UPDATE_RESULTS,
    ],
    getDepositAccountSaga
  );

  yield takeLatest(Actions.BOOKING_LEDGER_POST_EXPORT_REQUEST, postBookingLedgerExportSaga);
  yield takeLatest(Actions.BOOKING_LEDGER_GET_EXPORT_REQUEST, getBookingLedgerExportSaga);
  yield takeLatest(Actions.BOOKING_LEDGER_POST_EXPORT_SUCCESS, pollBookingLedgerExportSaga);

  yield takeLatest(Actions.BOOKING_LEDGER_POST_AGGREGATE_EXPORT_REQUEST, postBookingLedgerAggregateExportSaga);
  yield takeLatest(Actions.BOOKING_LEDGER_GET_AGGREGATE_EXPORT_REQUEST, getBookingLedgerAggregateExportSaga);
  yield takeLatest(Actions.BOOKING_LEDGER_POST_AGGREGATE_EXPORT_SUCCESS, pollBookingLedgerAggregateExportSaga);

  yield takeLatest(Actions.DEPOSIT_ACCOUNT_POST_EXPORT_REQUEST, postDepositAccountExportSaga);
  yield takeLatest(Actions.DEPOSIT_ACCOUNT_GET_EXPORT_REQUEST, getDepositAccountExportSaga);
  yield takeLatest(Actions.DEPOSIT_ACCOUNT_POST_EXPORT_SUCCESS, pollDepositAccountExportSaga);

  yield takeLatest(Actions.DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_REQUEST, postDepositAccountAggregateExportSaga);
  yield takeLatest(Actions.DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_REQUEST, getDepositAccountAggregateExportSaga);
  yield takeLatest(Actions.DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_SUCCESS, pollDepositAccountAggregateExportSaga);

  yield takeLatest(Actions.CHECK_INITIAL_BCF_REQUEST, checkInitialBCFSaga);
  yield takeLatest(Actions.SAVE_BCF_ROW_REQUEST, saveBCFRowSaga);
  yield takeLatest(Actions.DEPOSIT_CHECK_INITIAL_BCF_REQUEST, checkInitialDepositRowSaga);
  yield takeLatest(Actions.DEPOSIT_SAVE_ROW_REQUEST, saveDepositRowSaga);
  yield takeLatest(Actions.DEPOSIT_DELETE_ROW_REQUEST, deleteDepositRowSaga);
  yield takeLatest(Actions.GET_DEPOSIT_PAYMENT_METHODS_REQUEST, getDepositPaymentMethodsSaga);

  yield takeLatest(Actions.BOOKING_LEDGER_GENERATE_PDF_REQUEST, generateBookingLedgerPdfSaga);
  yield takeLatest(Actions.BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_REQUEST, downloadPdfHistoryForBookingLedgerSaga);

  yield takeLatest(Actions.DEPOSIT_ACCOUNT_GENERATE_PDF_REQUEST, generateDepositAccountPdfSaga);
  yield takeLatest(Actions.DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_REQUEST, downloadPdfHistoryForDepositAccountSaga);

  yield takeLatest(Actions.SUMMARY_GENERATE_PDF_REQUEST, generateSummaryPdfSaga);
  yield takeLatest(Actions.SUMMARY_DOWNLOAD_PDF_HISTORY_REQUEST, downloadPdfHistoryForSummarySaga);
}
