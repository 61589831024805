import React, { useCallback } from "react";
import classnames from 'classnames';
import { BookingBuilderResponse, ENetworkRequestStatus } from "services/BackendApi";
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { AddAccommodationModalFilters } from "../AddAccommodationModalFilters";
import { useDispatch, useSelector } from "react-redux";
import { RHPSubtitle } from "../RightHandPanel/RHPSubtitle";
import { AddAccommodationModalTable } from "../AddAccommodationModalTable";
import { convertMealPlanToStaticRate } from "../helpers";
import { LHPProducts } from "./LHPProducts";
import { LoadingBar } from "ui/NetworkStatusBar";
import { LHPTransfers } from "./LHPTransfers";

export interface ILHPPageAddonsProps {
  currencySymbol: string;
  hotelUuid: string;
  isVisible: boolean;
  bookingUuid: string;
}

export const LHPPageAddons: React.FC<ILHPPageAddonsProps> = ({ hotelUuid, bookingUuid, currencySymbol, isVisible }) => {
  const dispatch = useDispatch();
  const searchAccommodationsRequest = useSelector(BreakdownSelectors.BAMSearchAccommodationsRequestSelector);
  const selectedBuild = useSelector(BreakdownSelectors.BAMSelectedBuildSelector);

  if (!isVisible) {
    return null;
  }

  if (!selectedBuild) {
    return <LoadingBar />
  }

  return (
    <div className={classnames({ 'hidden': !isVisible })}>
      <div className="font-pt-sans flex gap-[5px] mb-[10px]">
        <RHPSubtitle>Select Add-Ons</RHPSubtitle>
      </div>

      <div className="flex flex-col gap-[10px] mt-[10px]">
        {selectedBuild.availableProductSets['Transfer'].length > 0 && (
          <LHPTransfers
            selectedBuild={selectedBuild}
            currencySymbol={currencySymbol}
            bookingUuid={bookingUuid}
            products={selectedBuild.availableProductSets['Transfer']}
            title="Transfers"
          />
        )}
        {selectedBuild.availableProductSets['Ground Service'].length > 0 && (
          <LHPProducts
            selectedBuild={selectedBuild}
            currencySymbol={currencySymbol}
            bookingUuid={bookingUuid}
            products={selectedBuild.availableProductSets['Ground Service']}
            title="Ground Services"
          />
        )}
        {selectedBuild.availableProductSets['Supplement'].length > 0 && (
          <LHPProducts
            selectedBuild={selectedBuild}
            currencySymbol={currencySymbol}
            bookingUuid={bookingUuid}
            products={selectedBuild.availableProductSets['Supplement']}
            title="Other Items"
          />
        )}
        {selectedBuild.availableProductSets['Fine'].length > 0 && (
          <LHPProducts
            selectedBuild={selectedBuild}
            currencySymbol={currencySymbol}
            bookingUuid={bookingUuid}
            products={selectedBuild.availableProductSets['Fine']}
            title="Fines"
          />
        )}
      </div>
    </div>
  )
}
