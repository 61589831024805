import React from 'react';
import { Loader } from '@pure-escapes/webapp-ui-components';

import i18n from 'config/i18n';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export const AdminRedirect = () => {
  window.location.pathname === '/' && window && window.location.replace('/hotel-admin');
  return <Loader text={i18n.t('messages.redirecting')} />;
};

export default AdminRedirect;
