import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import classnames from 'classnames';
import * as UiActions from 'store/modules/ui/actions';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import { useCurrentWidth } from 'effects';
import { useDispatch, useSelector } from 'react-redux';
import SaveIcon from 'ui/Icons/components/Save.component';
import DownloadIcon from 'ui/Icons/components/Download.component';
import AddToPhotosIcon from 'ui/Icons/components/AddToPhotos.component';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { StandardModal } from 'pureUi/Modal';
import { RatesWarningModalContent } from 'containers/HotelContainer/RatesWarningModal';
import { ModalModes } from 'containers/SummaryForm/const';
import DownloadQuoteMarginModal from 'containers/SummaryForm/DownloadQuoteMarginModal';
import { BookingBuilder, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from 'store/modules/agents';
import { makeBookingManagerApi } from 'services/BookingManagerApi';
import { ProposalModal } from 'containers/HotelContainer/ProposalModal';
import { useDownloadQuote } from './hooks/useDownloadQuote';
import { useSaveBooking } from './hooks/useSaveBooking';
import { useAddBookingToProposal } from './hooks/useAddBookingToProposal';
import { LodgingSummary } from 'interfaces';
import { ButtonWithIcon } from './components/ButtonWithIcon';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';

interface ISaveQuoteProposalButtonsProps {
  bookingBuild: BookingBuilder | null;
  lodgingSummaries: LodgingSummary[];
  className?: string;
  canBook: boolean;
  isRequestInProgress: boolean;
  setRequestInProgress: Dispatch<SetStateAction<boolean>>;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  taMarginAmountOverride?: string;
  basketBuildUuid?: string;
  onPostRemove: () => void;
  clientCountryCode: string | null;
}

export const BasketSaveQuoteProposalButtons: React.FC<ISaveQuoteProposalButtonsProps> = React.memo(
  ({
    bookingBuild,
    canBook,
    isRequestInProgress,
    setRequestInProgress,
    setRedirectLocation,
    setShouldShowLeaveAlert,
    isLoading,
    taMarginAmountOverride,
    basketBuildUuid,
    onPostRemove,
    clientCountryCode,
  }) => {
    const { currentWidth } = useCurrentWidth();
    const dispatch = useDispatch();

    const actingOnBehalfOfUser = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUserSelector);
    const selectedTa = useSelector(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const bookingManagerApi = makeBookingManagerApi();
    const selectedCompanyMembership = useSelector(AgentsSelectors.selectedCompanyMembershipSelector);
    const enqueueNotification = (notification: any) => dispatch(UiActions.enqueueNotification(notification));

    const [modalRatesWarningMode, setModalRatesWarningMode] = useState(null);
    const openModalRatesWarning = useCallback(mode => setModalRatesWarningMode(mode), []);
    const closeModalRatesWarning = useCallback(() => setModalRatesWarningMode(null), []);
    const containsLiveRates =
      bookingBuild?.response.potentialBooking.Accommodation.some(requestedAccommodation => {
        return requestedAccommodation.isLiveRate;
      }) || false;

    console.log('actingOnBehalfOfUser', actingOnBehalfOfUser);
    console.log('selectedTa', selectedTa);
    const { handleSaveBookingButtonTrigger, handleSaveBookingButton } = useSaveBooking({
      bookingBuild,
      backendApi,
      containsLiveRates,
      selectedCompanyMembership,
      clientCountryCode,
      closeModalRatesWarning,
      setShouldShowLeaveAlert,
      setRedirectLocation,
      openModalRatesWarning,
      setRequestInProgress,
      taMarginAmountOverride,
      actingOnBehalfOfUser,
      selectedTa,
      basketBuildUuid,
    });

    const {
      handleDownloadQuoteModalButton,
      handleDownloadQuoteModalClose,
      handleDownloadQuoteButtonTrigger,
      handleDownloadQuoteButton,
      isQuoteMarginSelectorVisible,
      isQuoteDownloading,
    } = useDownloadQuote({
      bookingBuild,
      setRequestInProgress,
      backendApi,
      bookingManagerApi,
      enqueueNotification,
      selectedCompanyMembership,
      containsLiveRates,
      closeModalRatesWarning,
      setShouldShowLeaveAlert,
      openModalRatesWarning,
      taMarginAmountOverride,
      actingOnBehalfOfUser,
      selectedTa,
      basketBuildUuid,
      onPostRemove,
      clientCountryCode,
    });

    const {
      handleCreateNewProposal,
      handleAddToProposal,
      handleAddToProposalButton,
      handleAddToProposalTrigger,
      proposals,
      proposalStatus,
      isProposalModalOpen,
      handleOnProposalModalClose,
    } = useAddBookingToProposal({
      bookingBuild,
      backendApi,
      containsLiveRates,
      openModalRatesWarning,
      closeModalRatesWarning,
      setShouldShowLeaveAlert,
      setRequestInProgress,
      taMarginAmountOverride,
      selectedTa,
      basketBuildUuid,
      onPostRemove,
      clientCountryCode,
    });

    const guestInfo = useSelector(BookingBuilderSelectors.guestInfoSelector);
    const guestInfoFilledIn = guestInfo.guestFirstName?.trim() && guestInfo.guestLastName?.trim();
    const buttonsDisabled = !guestInfoFilledIn || !canBook || isLoading;

    const ModalActions = Object.freeze({
      [ModalModes.SAVE]: handleSaveBookingButton,
      [ModalModes.DOWNLOAD]: handleDownloadQuoteButton,
      [ModalModes.PROPOSAL]: handleAddToProposalButton,
    });

    return (
      <div className="save-quote-proposal-buttons-container">
        <div className="save-quote-proposal-buttons flex gap-[8px]">
          <ButtonWithIcon
            caption="Save"
            className="booking-button-save"
            Icon={SaveIcon}
            onClick={handleSaveBookingButtonTrigger}
            disabled={buttonsDisabled}
          />
          <ButtonWithIcon
            caption="Quote"
            Icon={DownloadIcon}
            className="booking-button-quote"
            onClick={handleDownloadQuoteButtonTrigger}
            disabled={buttonsDisabled}
          />
          <ButtonWithIcon
            caption="Proposal"
            Icon={AddToPhotosIcon}
            className={classnames('booking-button-proposal', {
              'min-w-[132px]': currentWidth >= RIGHT_COLUMN_BREAKPOINT,
            })}
            onClick={handleAddToProposalTrigger}
            disabled={buttonsDisabled}
          />
        </div>

        {isQuoteMarginSelectorVisible && (
          <DownloadQuoteMarginModal
            isQuoteDownloading={isQuoteDownloading}
            onClose={handleDownloadQuoteModalClose}
            onDownload={handleDownloadQuoteModalButton}
          />
        )}
        {modalRatesWarningMode !== null && (
          <StandardModal removePadding={true} showCloseButton={false}>
            <RatesWarningModalContent
              onConfirm={modalRatesWarningMode ? ModalActions[modalRatesWarningMode] : () => {}}
              onClose={closeModalRatesWarning}
              isConfirmDisabled={isRequestInProgress}
            />
          </StandardModal>
        )}
        {bookingBuild && isProposalModalOpen && (
          <ProposalModal
            proposals={proposals}
            hotelUuid={bookingBuild.request.hotelUuid}
            createNewProposal={handleCreateNewProposal}
            addToProposal={handleAddToProposal}
            isLoading={isLoading}
            proposalStatus={proposalStatus}
            onClose={handleOnProposalModalClose}
          />
        )}
      </div>
    );
  }
);
