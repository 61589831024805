import React from 'react';
import { ITableRow } from './types';
import classnames from 'classnames';

interface ITableSubRowProps {
  uniqueId: string;
  subRow: ITableRow;
  isEven: boolean;
  evenColor: string;
}

export const TableSubRow: React.FC<ITableSubRowProps> = React.memo(({ uniqueId, subRow, isEven, evenColor }) => {

  return (
    <tr
      className={classnames(`${uniqueId} h-10 text-15px`, subRow.className, {
        [evenColor]: isEven,
      })}
    >
      {subRow.cells.map(cell => {
        return (
          <td key={cell.id} className={classnames('subrow px-2 h-full', cell.className)} title={cell.title}>
            <div className={cell.contentClassName}>{cell.value}</div>
          </td>
        );
      })}
    </tr>
  );
});
