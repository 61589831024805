import React, { useCallback, useEffect, useRef, useState } from 'react';
import { theme } from '../../../../tailwind.config';
import classnames from 'classnames';
import AnonymousAvatarIcon from 'ui/Icons/components/AnonymousAvatar.component';

export interface ISingleSelectOption {
  id: string;
  name: string;
  email: string;
  role: string;
}

export enum ESingleSelectDirection {
  UP = 'up',
  DOWN = 'down',
}

export interface IEditorMentionsProps {
  options: ISingleSelectOption[];
  onChange?: (option?: ISingleSelectOption) => void;
  maxVisibleItems?: number;
  direction?: ESingleSelectDirection;
  hasLegacyLook?: boolean;
  style?: React.CSSProperties;
  nameFilter: string;
  className: string;
}

const EditorMentions: React.FC<IEditorMentionsProps> = React.memo(
  ({
    options,
    onChange,
    maxVisibleItems,
    direction = ESingleSelectDirection.DOWN,
    hasLegacyLook = false,
    style,
    nameFilter = '',
    className = '',
  }) => {
    const [internalOptions, setInternalOptions] = useState<ISingleSelectOption[]>(options);
    const optionsHeight = maxVisibleItems ? { style: { maxHeight: `${maxVisibleItems * 35}px` } } : {};

    useEffect(() => {
      const filteredOptions = options.filter(io => io.name?.toLowerCase().startsWith(nameFilter.toLowerCase()));
      setInternalOptions(filteredOptions);
    }, [nameFilter]);

    const select = useCallback(
      (option: ISingleSelectOption) => {
        onChange && onChange(option);
      },
      [onChange]
    );

    return (
      <div
        className={classnames(
          'font-pt-sans absolute -left-1px z-30 border border-solid border-gray-20 max-h-[210px] overflow-auto',
          className,
          {
            'bg-ivory': !hasLegacyLook,
            'bg-white': hasLegacyLook,
            'top-[35px] border-t-0': direction === ESingleSelectDirection.DOWN,
            'bottom-[34px] border-b-0': direction === ESingleSelectDirection.UP,
          }
        )}
        style={{ ...style }}
      >
        <div className="options-container flex flex-col overflow-y-auto select-none" {...optionsHeight}>
          {internalOptions.length ? (
            internalOptions.map(option => (
              <span key={option.id} className="flex items-center px-[3px] py-[5px] hover:bg-teal-40">
                <AnonymousAvatarIcon icon={theme.colors['gray-100']} fill={theme.colors['gray-40']} />
                <span
                  key={option.email}
                  className={classnames(`value-${option.name} flex flex-col px-10px`)}
                  onClick={() => select(option)}
                >
                  <span className="block font-pt-sans pt-2 text-15px leading-19px">
                    {option.name} ({option.role})
                  </span>
                  <span className="block font-pt-sans pb-2 text-15px leading-19px">{option.email}</span>
                </span>
              </span>
            ))
          ) : (
            <span className={classnames(`flex flex-col py-[5px] px-10px h-[60px] justify-center`)}>
              <span className="block font-pt-sans text-15px leading-19px">No Result Found ..</span>
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default EditorMentions;
