import React from 'react';
import { IHotel } from 'services/BackendApi';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import { validateContactDetails } from 'containers/HotelAdmin/validation';
import { enqueueNotification } from 'store/modules/ui';
import { useDispatch } from 'react-redux';

export const ContactDetailsTab = ({
  hotel,
  onUpdate,
  onPatchHotel,
}: {
  hotel: IHotel;
  onUpdate: (field: keyof IHotel, value: any) => void;
  onPatchHotel: (updatedData: Partial<IHotel>) => void;
}) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input label={'Email'} value={hotel.email || ''} onChange={val => onUpdate('email', val)} />

      <Inputs.Input
        label={'Reservation Emails'}
        value={hotel.reservationEmails || ''}
        onChange={val => onUpdate('reservationEmails', val)}
      />
      <span className="text-gray-80 italic text-sm !mt-0">Add emails separating addresses with a "," sign</span>

      <Inputs.Input
        label={'Phone number'}
        value={hotel.phoneNumber || ''}
        onChange={val => onUpdate('phoneNumber', val)}
      />

      <Inputs.TextArea label={'Address'} value={hotel.address || ''} onChange={val => onUpdate('address', val)} />

      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        onClick={() => {
          const validation = validateContactDetails(hotel as IHotel);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Contact Details are not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onPatchHotel({
            email: hotel.email,
            reservationEmails: hotel.reservationEmails,
            phoneNumber: hotel.phoneNumber,
            address: hotel.address,
          });
        }}
      >
        Update Contact Details
      </FluidButton>
    </div>
  );
};
