import ReactDOM from 'react-dom';
import { ReactPortal } from 'react';

export enum PortalType {
  Tooltip = 'portal-tooltip',
  Modal = 'portal-modal',
  Overlay = 'portal-overlay',
  ToastNotification = 'portal-toast-notification',
}

export const renderPortal = (jsx: JSX.Element, portalType: PortalType): ReactPortal => {
  const el = document.getElementById(portalType);
  if (el) {
    return ReactDOM.createPortal(jsx, el);
  } else {
    throw new Error(`No portal container of type ${portalType}`);
  }
};
