import { usePagination } from 'hooks/usePagination';
import { isNil } from 'lodash-es';
import { Pagination } from 'pureUi/Pagination';
import React, { useEffect, useState } from 'react';
import { ENetworkRequestStatus, IContentEntity, makeBackendApi } from 'services/BackendApi';
import { ESortOrder } from 'store/common/types';
import FluidButton from 'ui/FluidButton';
import { Link } from 'ui/Link';
import { MenuButton } from 'ui/MenuButton';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { Table } from 'ui/Table';
import { ITableColumn } from 'ui/Table/types';
import { formatDateDisplay } from 'utils';
import * as Inputs from '../HotelAdmin/components/Inputs';

const tableColumns: ITableColumn[] = [
  {
    id: 'enabled',
    label: 'Enabled',
    width: '50px',
    fieldToSortBy: 'enabled',
  },
  {
    id: 'title',
    label: 'Title',
    width: '200px',
    fieldToSortBy: 'title',
  },
  {
    id: 'subtitle',
    label: 'subtitle',
    width: '200px',
  },
  {
    id: 'type',
    label: 'Type',
    width: '100px',
    fieldToSortBy: 'type',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '100px',
    fieldToSortBy: 'createdAt',
  },
  {
    id: 'uodatedAt',
    label: 'Updated At',
    width: '100px',
    fieldToSortBy: 'updatedAt',
  },
  {
    id: 'cta',
    label: '',
    width: '70px',
  },
];

export const List = () => {
  const backendApi = makeBackendApi();

  const paginationData = usePagination({ initialPage: 1, initialPerPage: 10, initialTotal: 100 });

  const [contentEntities, setContentEntities] = useState<IContentEntity[]>([]);
  const [sortBy, setSortBy] = useState('title');
  const [sortOrder, setSortOrder] = useState('asc' as ESortOrder);
  const [getContentEntitiesRequestStatus, setGetContentEntitiesRequestStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [titleFilter, setTitleFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState<'latest-offer' | 'page' | null>(null);

  useEffect(() => {
    setGetContentEntitiesRequestStatus(ENetworkRequestStatus.PENDING);
    backendApi
      .contentAdminGetList({
        page: paginationData.page,
        perPage: paginationData.perPage,
        sortBy,
        sortOrder,
        titleFilter,
        typeFilter,
      })
      .then(res => {
        setGetContentEntitiesRequestStatus(ENetworkRequestStatus.SUCCESS);
        setContentEntities(res.data.data);
        paginationData.setTotal(res.data.meta.total);
      })
      .catch(error => {
        setGetContentEntitiesRequestStatus(ENetworkRequestStatus.ERROR);
      });
  }, [paginationData.page, paginationData.perPage, sortBy, sortOrder, titleFilter, typeFilter]);

  const tableRows = contentEntities.map(contentEntity => {
    return {
      id: contentEntity.uuid,
      cells: [
        { id: 'enabled', value: contentEntity.enabled ? 'Yes' : 'No' },
        { id: 'title', value: contentEntity.title },
        { id: 'subtitle', value: contentEntity.subtitle },
        { id: 'type', value: contentEntity.type === 'latest-offer' ? 'Latest Offer' : 'Page' },
        { id: 'createdAt', value: formatDateDisplay(new Date(contentEntity.createdAt)) },
        { id: 'updatedAt', value: formatDateDisplay(new Date(contentEntity.updatedAt)) },
        {
          id: 'cta',
          value: (
            <span className="flex space-x-2">
              <Link className="underline hover:decoration-brown-prime" to={`/content-admin/${contentEntity.uuid}/edit`}>
                Edit Content
              </Link>
            </span>
          ),
        },
      ],
    };
  });

  return (
    <div className="container w-1280px mx-auto flex flex-col space-y-25px">
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Content</h1>

      <div className="flex space-x-4 items-center justify-between font-pt-sans">
        <fieldset className="flex flex-row space-x-2 border border-gray-20 border-solid p-2 pb-4">
          <legend className="text-sm">List Filters</legend>

          <Inputs.Input
            label="Name"
            value={titleFilter}
            className="min-w-[200px]"
            onDebounceChange={val => {
              setTitleFilter(val);
              paginationData.setPage(1);
            }}
            inputClassName="!min-w-[200px]"
          />

          <Inputs.Dropdown
            label={'Type'}
            className="min-w-[200px]"
            values={typeFilter ? [typeFilter] : []}
            options={[
              {
                value: 'latest-offer',
                label: 'Latest Offer',
              },
              {
                value: 'page',
                label: 'Page',
              },
            ]}
            onChange={sv => {
              if (sv.length <= 0) {
                setTypeFilter(null);
              } else {
                setTypeFilter(sv[0] as 'latest-offer' | 'page');
              }
            }}
            multiselectProps={{
              isSingleSelectMode: true,
              isCloseOnSelect: true,
              hideCheckboxes: true,
              isIncludeClearButton: true,
            }}
          />
        </fieldset>

        <fieldset className="border border-gray-20 border-solid p-2 pt-[28px] pb-[21px]">
          <legend className="text-sm">Actions</legend>

          <div className="flex flex-row items-center justify-center space-x-4">
            <Link className="w-[100px] text-center underline font-pt-sans" to="/content-admin/create">
              New Content
            </Link>
          </div>
        </fieldset>
      </div>

      {getContentEntitiesRequestStatus === ENetworkRequestStatus.ERROR && (
        <div className="flex flex-col items-center justify-center">
          <ErrorBar />
        </div>
      )}

      {getContentEntitiesRequestStatus === ENetworkRequestStatus.PENDING && tableRows.length <= 0 && (
        <div className="flex flex-col items-center justify-center">
          <LoadingBar />
        </div>
      )}

      {tableRows.length >= 1 && (
        <>
          <Table
            columns={tableColumns}
            rows={tableRows}
            messageWhenEmpty="No results"
            loading={getContentEntitiesRequestStatus === ENetworkRequestStatus.PENDING}
            onSort={(newSortBy, newSortOrder) => {
              setSortBy(newSortBy);
              setSortOrder(newSortOrder as ESortOrder);
            }}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
          <Pagination
            currentPage={paginationData.page}
            itemsPerPage={paginationData.perPage}
            onItemsPerPageChange={paginationData.setPerPage}
            onPageSelect={paginationData.setPage}
            pageCount={paginationData.pageCount}
          />
        </>
      )}

      {getContentEntitiesRequestStatus === ENetworkRequestStatus.SUCCESS && tableRows.length <= 0 && (
        <div className="font-pt-sans flex flex-col items-center justify-center">No results</div>
      )}
    </div>
  );
};
