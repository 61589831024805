import { IVoucherFormView, EVoucherLang } from 'services/BackendApi/types/Voucher';
import { EPdfLogo } from '../breakdown/model';
import { IVoucherSubdomainFormData } from './types';

export const GET_VOUCHER_FORM_VIEW_REQUEST = 'bookingManager/GET_VOUCHER_FORM_VIEW_REQUEST';
export const GET_VOUCHER_FORM_VIEW_SUCCESS = 'bookingManager/GET_VOUCHER_FORM_VIEW_SUCCESS';
export const GET_VOUCHER_FORM_VIEW_FAILURE = 'bookingManager/GET_VOUCHER_FORM_VIEW_FAILURE';

export const SET_VOUCHER_FORM_DATA = 'bookingManager/SET_VOUCHER_FORM_DATA';

export const DOWNLOAD_VOUCHER_REQUEST = 'bookingManager/DOWNLOAD_VOUCHER_REQUEST';
export const DOWNLOAD_VOUCHER_SUCCESS = 'bookingManager/DOWNLOAD_VOUCHER_SUCCESS';
export const DOWNLOAD_VOUCHER_FAILURE = 'bookingManager/DOWNLOAD_VOUCHER_FAILURE';

export const SET_VOUCHER_LOGO = 'bookingManager/SET_VOUCHER_LOGO';
export const SET_VOUCHER_LANG = 'bookingManager/SET_VOUCHER_LANG';
export const SET_VOUCHER_FORM_MEAL_PLAN_DESCRIPTION = 'bookingManager/SET_VOUCHER_FORM_MEAL_PLAN_DESCRIPTION';

export type GetVoucherFormViewRequestAction = ReturnType<typeof getVoucherFormViewRequestAction>;
export const getVoucherFormViewRequestAction = () => ({
  type: GET_VOUCHER_FORM_VIEW_REQUEST as typeof GET_VOUCHER_FORM_VIEW_REQUEST,
});

export type GetVoucherFormViewSuccessAction = ReturnType<typeof getVoucherFormViewSuccessAction>;
export const getVoucherFormViewSuccessAction = (voucherFormView: IVoucherFormView) => ({
  type: GET_VOUCHER_FORM_VIEW_SUCCESS as typeof GET_VOUCHER_FORM_VIEW_SUCCESS,
  voucherFormView,
});

export type GetVoucherFormViewFailureAction = ReturnType<typeof getVoucherFormViewFailureAction>;
export const getVoucherFormViewFailureAction = (error: string) => ({
  type: GET_VOUCHER_FORM_VIEW_FAILURE as typeof GET_VOUCHER_FORM_VIEW_FAILURE,
  error,
});

export type SetVoucherFormDataAction = ReturnType<typeof setVoucherFormDataAction>;
export const setVoucherFormDataAction = (path: keyof IVoucherSubdomainFormData, value: any) => ({
  type: SET_VOUCHER_FORM_DATA as typeof SET_VOUCHER_FORM_DATA,
  path,
  value,
});

export type DownloadVoucherRequestAction = ReturnType<typeof downloadVoucherRequestAction>;
export const downloadVoucherRequestAction = (logoType: EPdfLogo) => ({
  type: DOWNLOAD_VOUCHER_REQUEST as typeof DOWNLOAD_VOUCHER_REQUEST,
  logoType,
});

export type DownloadVoucherSuccessAction = ReturnType<typeof downloadVoucherSuccessAction>;
export const downloadVoucherSuccessAction = () => ({
  type: DOWNLOAD_VOUCHER_SUCCESS as typeof DOWNLOAD_VOUCHER_SUCCESS,
});

export type DownloadVoucherFailureAction = ReturnType<typeof downloadVoucherFailureAction>;
export const downloadVoucherFailureAction = (error: string) => ({
  type: DOWNLOAD_VOUCHER_FAILURE as typeof DOWNLOAD_VOUCHER_FAILURE,
  error,
});

export type SetVoucherLogoAction = ReturnType<typeof setVoucherLogoAction>;
export const setVoucherLogoAction = (voucherLogo: EPdfLogo) => ({
  type: SET_VOUCHER_LOGO as typeof SET_VOUCHER_LOGO,
  voucherLogo,
});

export type SetVoucherLangAction = ReturnType<typeof setVoucherLangAction>;
export const setVoucherLangAction = (lang: EVoucherLang) => ({
  type: SET_VOUCHER_LANG as typeof SET_VOUCHER_LANG,
  lang,
});

export type SetVoucherMealPlanDescriptionAction = ReturnType<typeof setVoucherMealPlanDescriptionAction>;
export const setVoucherMealPlanDescriptionAction = (details: string) => ({
  type: SET_VOUCHER_FORM_MEAL_PLAN_DESCRIPTION as typeof SET_VOUCHER_FORM_MEAL_PLAN_DESCRIPTION,
  details,
});

export type VoucherAction =
  | GetVoucherFormViewRequestAction
  | GetVoucherFormViewSuccessAction
  | GetVoucherFormViewFailureAction
  | SetVoucherFormDataAction
  | DownloadVoucherRequestAction
  | DownloadVoucherSuccessAction
  | DownloadVoucherFailureAction
  | SetVoucherLogoAction
  | SetVoucherLangAction
  | SetVoucherMealPlanDescriptionAction;
