import React, { useCallback, useState } from 'react';
import { getYear, setYear, addMonths } from 'date-fns';
import { range } from 'ramda';

import DatePicker from 'pureUi/DatePicker';
import DatePickerWrapper from 'pureUi/DatePickerWrapper';
import { PureSelect } from 'pureUi/forms/PureSelect';
import { formatDateDisplay, dateToMidnightDate } from 'utils';

import { SingleDateInputStyles } from './SingleDateInputStyles';

interface ISingleDateInputProps {
  value: Date | null;
  onChange: (date: Date) => void;
  className?: string;
  internalClassName?: string;
  enablePastDates?: boolean;
  showYearDropdown?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disableDates?: string[];
  placeholder?: string;
  calendarCount?: number;
  isDisabled?: boolean;
  shouldKeepVisibleIfClickOutside?: boolean;
}

export const SingleDateInput: React.FC<ISingleDateInputProps> = props => {
  const { shouldKeepVisibleIfClickOutside = false } = props;

  const [currentDate, setCurrentDate] = useState<Date>(props.value ?? new Date());
  const [isDatePickerVisible, setDatePickerVisible] = useState<boolean>(false);

  const { calendarCount = 2, isDisabled } = props;

  const getYears = () => {
    const from = props.minDate ? getYear(props.minDate) : getYear(new Date()) - 150;
    const to = getYear(new Date());
    return range(from, to + 1);
  };

  const handleDayClick = useCallback(
    async (date: string) => {
      props.onChange(new Date(date));
      setDatePickerVisible(false);
    },
    [props, setDatePickerVisible]
  );

  const handleNextClick = useCallback(async () => {
    setCurrentDate(dateToMidnightDate(addMonths(currentDate, 1)));
  }, [currentDate]);

  const handlePrevClick = useCallback(async () => {
    setCurrentDate(dateToMidnightDate(addMonths(currentDate, -1)));
  }, [currentDate]);

  const handleInputClick = e => {
    if (!isDisabled) {
      e.stopPropagation();
      setDatePickerVisible(!isDatePickerVisible);
    }
  };

  const handleYearChange = useCallback(
    e => {
      const year = parseInt(e.target.value);
      setCurrentDate(dateToMidnightDate(setYear(currentDate, year)));
    },
    [currentDate, setCurrentDate]
  );

  const selectedDateAsArray = useCallback((date: Date | null) => {
    return date ? [date.toISOString()] : [];
  }, []);

  const getDisplayDate = (date: Date | null) => {
    // render the date, or the placeholder, or "Select a Date"
    return date ? formatDateDisplay(date) : props.placeholder !== undefined ? props.placeholder : 'Select a date';
  };

  const renderDateSelectionInput = () => (
    <button type="button" className="pseudoSelect" onClick={handleInputClick}>
      <span className="displayString">{getDisplayDate(props.value)}</span>
    </button>
  );

  const renderDatePicker = () => (
    <div>
      {props.showYearDropdown && (
        <PureSelect className="!relative !z-10" value={getYear(new Date(currentDate))} onChange={handleYearChange}>
          {getYears().map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </PureSelect>
      )}
      <DatePicker
        data-role={'date-picker'}
        calendarCount={calendarCount}
        currentDate={currentDate.toISOString()}
        selectedDates={selectedDateAsArray(props.value)}
        onDayClick={handleDayClick}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
        enablePastDates={props.enablePastDates || false}
        minDate={props.minDate && props.minDate.toISOString()}
        maxDate={props.maxDate && props.maxDate.toISOString()}
        disableDates={props.disableDates || []}
      />
    </div>
  );

  return (
    <div className={props.className}>
      <SingleDateInputStyles>
        <DatePickerWrapper
          data-role={'date-picker-wrapper'}
          className={props.internalClassName}
          renderDateSelectionInput={renderDateSelectionInput}
          renderDatePicker={renderDatePicker}
          isDatePickerVisible={isDatePickerVisible}
          onSetDatePickerVisible={setDatePickerVisible}
          shouldKeepVisibleIfClickOutside={shouldKeepVisibleIfClickOutside}
        />
      </SingleDateInputStyles>
    </div>
  );
};

export default SingleDateInput;
