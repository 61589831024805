import { call, takeLatest, take, select, put, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import {
  makeBookingManagerApi,
  IHeadlineLineItemBreakdownResponse,
  IHeadlineBreakdownVersionListVersion,
  IPoliciesAndTermsResponse,
  IHeadlineLineItemBreakdown,
  IHeadlineLineItemBreakdownAccommodationLineItem,
  IBarCommissionResponse,
  IFinanceRow,
  IExternalIds,
  IExternalIdsResponse,
  ICompanyMembership,
  ICompanyMembershipMicrodocResponse,
} from 'services/BookingManagerApi';
import * as BreakdownActions from '../actions';
import * as BreakdownSelectors from '../selectors';

import { isInternalUser as isSrSelector, isAdmin as isAdminSelector, isTA as isTASelector } from 'store/modules/auth';
import { bookingCurrencySelector, bookingUuidSelector } from '../../../selectors';
import { headlineLineItemBreakdownSelector, isHeadlineBreakdownEditedWithoutSavingSelector } from '../selectors';
import { EUploadTag, Upload } from 'services/BackendApi/types';
import { getUploadsRequestAction } from '../../uploads/actions';
import { enqueueNotification } from 'store/modules/ui';
import { updatePoliciesAndTermsSuccessAction, updatePoliciesAndTermsFailureAction } from '../actions';
import { getPurchaseCostReviewRequestAction } from '../../purchaseCostReview/actions';
import { getBookingStatusOptionsRequestAction, SET_BOOKING_STATUS_SUCCESS } from '../../bookingStatusOptions/actions';
import { EInvoiceType, EInvoiceMutationMode } from '../model';

import { getFinanceDocumentRequestAction, getFinanceDocumentBalanceRequestAction } from '../../finance/actions';
import { automaticFinalInvoiceToTaSelector, automaticProformaInvoiceToTaSelector } from '../../finance/selectors';
import { POST_CANCELLATION_INVOICE_SUCCESS } from '../../bookingStatusOptions/actions';
import { isTA } from '../../../../auth';
import { getTopNavigationDataInlineRequestAction } from '../../dashboard/actions';
import * as BookingManagerSelectors from 'store/modules/bookingManager/selectors';

import * as ExternalIdsSelectors from 'store/modules/bookingManager/subdomains/externalIds/selectors';
import * as ExternalIdsActions from 'store/modules/bookingManager/subdomains/externalIds/actions';
import * as ExternalIdsModel from 'store/modules/bookingManager/subdomains/externalIds/model';
import { patchHotelDetailsSaga } from '../../hotelDetails/saga';
import * as HotelDetailsSelectors from 'store/modules/bookingManager/subdomains/hotelDetails/selectors';
import { EAncillaryProductType } from 'services/BookingManagerApi/types/AncillaryService';
import { TCurrencyCode } from 'interfaces';

export function* updatePoliciesAndTerms(
  cancellationPolicies: string,
  paymentTerms: string,
  policiesAndRestrictions: string,
  offerTerms: string
) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    yield put(BreakdownActions.updatePoliciesAndTermsRequestAction());

    const policiesAndTermsResponse: AxiosResponse<IPoliciesAndTermsResponse> = yield call(
      bookingManagerApi.updatePoliciesAndTerms,
      bookingUuid,
      cancellationPolicies,
      paymentTerms,
      policiesAndRestrictions,
      offerTerms
    );

    yield put(updatePoliciesAndTermsSuccessAction(policiesAndTermsResponse.data));
    yield put(
      enqueueNotification({
        message: 'Policies data saved successfully',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(updatePoliciesAndTermsFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was an error saving policies data',
        options: { variant: 'error' },
      })
    );
  }
}

export function* updateMembership(membership: ICompanyMembership) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    yield put(BreakdownActions.updateMembershipRequestAction());

    const res: AxiosResponse<ICompanyMembershipMicrodocResponse> = yield call(
      bookingManagerApi.updateCompanyMembership,
      bookingUuid,
      { benefitsDescription: membership.benefitsDescription }
    );

    yield put(BreakdownActions.updateMembershipSuccessAction(res.data.membershipData));
    yield put(
      enqueueNotification({
        message: 'Membership benefits saved successfully',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(BreakdownActions.updateMembershipFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was an error saving membership benefits',
        options: { variant: 'error' },
      })
    );
  }
}

export function* saveExternalIds() {
  const stored: IExternalIds | null = yield select(ExternalIdsSelectors.storedSelector);
  const editing: ExternalIdsModel.IEditing = yield select(ExternalIdsSelectors.editingSelector);

  if (stored && (stored.travelPartnerRef || '') === editing.travelPartnerRef) {
    return;
  }

  yield put(ExternalIdsActions.updateRequestAction());
}

export function* saveAndUpdateHeadlineBreakdown() {
  const bookingManagerApi = makeBookingManagerApi();
  const bookingUuid = yield select(bookingUuidSelector);
  const headlineLineItemBreakdown: IHeadlineLineItemBreakdown = yield select(headlineLineItemBreakdownSelector);
  const commissionPercentage: number | null = yield select(BookingManagerSelectors.barCommissionPercentageSelector);
  const commissionAmount: number | null = yield select(BookingManagerSelectors.barCommissionAmountSelector);
  const cancellationPolicies = yield select(BreakdownSelectors.cancellationPoliciesSelector);
  const paymentTerms = yield select(BreakdownSelectors.paymentTermsSelector);
  const offerTerms = yield select(BreakdownSelectors.offerTermsSelector);
  const membership = yield select(BreakdownSelectors.membershipSelector);
  const isSr = yield select(isSrSelector);
  const isAdmin = yield select(isAdminSelector);
  const isTA = yield select(isTASelector);

  const policiesAndRestrictions = yield select(BreakdownSelectors.policiesAndRestrictionsSelector);
  if (isSr || isAdmin) {
    yield call(updatePoliciesAndTerms, cancellationPolicies, paymentTerms, policiesAndRestrictions, offerTerms);
    if (membership) {
      yield call(updateMembership, membership);
    }
    // if we're an SR or admin, save any updated hotel details
    yield call(patchHotelDetailsSaga);
  }
  const result: AxiosResponse<IHeadlineLineItemBreakdownResponse> = yield call(
    bookingManagerApi.saveAndUpdateHeadlineBreakdown,
    bookingUuid,
    headlineLineItemBreakdown
  );

  yield saveExternalIds();

  if (!isTA) {
    yield call(bookingManagerApi.saveAndUpdateBarCommission, bookingUuid, commissionPercentage, commissionAmount);

    // After updating bar commission, booking refresh status options for message refresh
    yield put(getBookingStatusOptionsRequestAction());
  }

  yield put(BreakdownActions.getHeadlineLineItemBreakdownSuccessAction(result.data.headlineLineItemBreakdown, null));

  // if we've just saved, we need to retrieve the latest version list,
  // this will also set the selected version to the latest from the list
  // - see getHeadlineBreakdownVersionListSaga() in this file
  yield put(BreakdownActions.getHeadlineBreakdownVersionListRequestAction());

  return result;
}

export function* getBarCommissionSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IBarCommissionResponse> = yield call(bookingManagerApi.getBarCommission, bookingUuid);

    yield put(
      BreakdownActions.getBookingBarCommissionSuccessAction(
        result.data.commission?.percentage,
        result.data.commission?.amount
      )
    );
  } catch (e) {
    yield put(BreakdownActions.getBookingBarCommissionFailureAction(e));
  }
}

export function* getHeadlineLineItemBreakdownSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IHeadlineLineItemBreakdownResponse> = yield call(
      bookingManagerApi.getHeadlineLineItemBreakdown,
      bookingUuid
    );

    yield put(
      BreakdownActions.getHeadlineLineItemBreakdownSuccessAction(
        result.data.headlineLineItemBreakdown,
        result.data.updatedAt
      )
    );
  } catch (e) {
    yield put(BreakdownActions.getHeadlineLineItemBreakdownFailureAction(e));
  }
}

export function* saveAndUpdateHeadlineBreakdownSaga() {
  try {
    const result: AxiosResponse<IHeadlineLineItemBreakdownResponse> = yield call(saveAndUpdateHeadlineBreakdown);
    yield put(BreakdownActions.saveAndUpdateHeadlineBreakdownSuccessAction(result.data.headlineLineItemBreakdown));

    yield put(
      enqueueNotification({
        message: 'The breakdown was updated and saved',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(BreakdownActions.saveAndUpdateHeadlineBreakdownFailureAction(e));

    yield put(
      enqueueNotification({
        message: 'There was a problem saving the breakdown - please try again',
        options: { variant: 'error' },
      })
    );
  }

  // also save the booking arrival departure
  try {
    yield call(saveAndUpdateBookingArrivalDeparture);

    yield put(
      enqueueNotification({
        message: 'The booking arrival and departure was updated and saved',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(
      enqueueNotification({
        message: 'There was a problem saving the booking arrival & departure - please try again',
        options: { variant: 'error' },
      })
    );
  }

  yield put(getTopNavigationDataInlineRequestAction());
  yield put(BreakdownActions.getPaymentMethodsRequestAction());
}

export function* downloadBreakdownForTASaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const isHeadlineBreakdownEditedWithoutSaving = yield select(isHeadlineBreakdownEditedWithoutSavingSelector);

    // 1. Save the breakdown
    if (isHeadlineBreakdownEditedWithoutSaving) {
      yield saveAndUpdateHeadlineBreakdownSaga();
    }

    // 2. Generate the PDF for TA
    const result: AxiosResponse<Upload> = yield call(bookingManagerApi.generateBreakdownPdfForTa, bookingUuid);

    // 3. Download pdf that was generated
    const upload = result.data;
    saveAs(upload.url, upload.filename);

    // 4. Reload the list of breakdown pdfs
    yield put(getUploadsRequestAction(EUploadTag.BREAKDOWN));

    yield put(BreakdownActions.downloadBreakdownForTASuccessAction());
    yield put(
      enqueueNotification({
        message: 'PDF was generated',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(BreakdownActions.downloadBreakdownForTAFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was a problem generating PDF',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getHeadlineBreakdownVersionListSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IHeadlineBreakdownVersionListVersion[]> = yield call(
      bookingManagerApi.getHeadlineBreakdownVersionList,
      bookingUuid
    );
    const latestVersion = result.data[0];

    yield put(BreakdownActions.getHeadlineBreakdownVersionListSuccessAction(result.data));
    yield put(BreakdownActions.setHeadlineBreakdownSelectedVersionAction(latestVersion));
    yield put(BreakdownActions.setHeadlineBreakdownRealLatestVersionAction(latestVersion));
  } catch (e) {
    yield put(BreakdownActions.getHeadlineBreakdownVersionListFailureAction(e));
  }
}

export function* retrieveSelectedHeadlineBreakdownVersion() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const selectedVersion = yield select(BreakdownSelectors.headlineBreakdownSelectedVersionSelector);

    const result: AxiosResponse<IHeadlineLineItemBreakdownResponse> = yield call(
      bookingManagerApi.getHeadlineLineItemBreakdown,
      bookingUuid,
      selectedVersion
    );

    yield put(
      BreakdownActions.getHeadlineLineItemBreakdownSuccessAction(
        result.data.headlineLineItemBreakdown,
        result.data.updatedAt
      )
    );
  } catch (e) {
    yield put(BreakdownActions.getHeadlineLineItemBreakdownFailureAction(e));
  }
}

export function* downloadBreakdownForClientSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const isHeadlineBreakdownEditedWithoutSaving = yield select(isHeadlineBreakdownEditedWithoutSavingSelector);

    const breakdownLogoChoice = yield select(BreakdownSelectors.getBreakdownLogoSelector);

    // 1. Save the breakdown
    if (isHeadlineBreakdownEditedWithoutSaving) {
      yield saveAndUpdateHeadlineBreakdownSaga();
    }

    // 2. Generate the PDF for client
    const result: AxiosResponse<Upload> = yield call(
      bookingManagerApi.generateBreakdownPdfForClient,
      bookingUuid,
      breakdownLogoChoice
    );

    // 3. Download pdf that was generated
    const upload = result.data;
    saveAs(upload.url, upload.filename);

    // 4. Reload the list of breakdown pdfs
    yield put(getUploadsRequestAction(EUploadTag.BREAKDOWN));

    yield put(BreakdownActions.downloadBreakdownForClientSuccessAction());
    yield put(
      enqueueNotification({
        message: 'PDF was generated',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(BreakdownActions.downloadBreakdownForClientFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was a problem generating PDF',
        options: { variant: 'error' },
      })
    );
  }
}

export function* generateInvoiceSaga(action: BreakdownActions.GenerateInvoiceRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const isHeadlineBreakdownEditedWithoutSaving = yield select(isHeadlineBreakdownEditedWithoutSavingSelector);
    const createInvoice = yield select(BreakdownSelectors.breakdownCreateInvoiceSelector);

    const invoicePrompt = action.invoiceType === EInvoiceType.PROFORMA ? 'Proforma' : 'Invoice';
    const actionPrompt = createInvoice.mode === EInvoiceMutationMode.CREATE ? 'generated' : 'updated';

    const message = `${invoicePrompt} has been ${actionPrompt}.`;

    // 1. Save the breakdown
    if (isHeadlineBreakdownEditedWithoutSaving) {
      yield saveAndUpdateHeadlineBreakdownSaga();
    }

    // 2. Generate Invoice
    let result: AxiosResponse<Upload>;
    switch (action.invoiceType) {
      case EInvoiceType.PROFORMA:
        result = yield call(
          bookingManagerApi.generateProformaInvoice,
          bookingUuid,
          action.bankAccount,
          action.invoiceDueDates?.filter(dd => dd.isConfirmed) || [],
          createInvoice.invoiceAddresseeType,
          createInvoice.lang
        );
        break;
      case EInvoiceType.FINAL:
        result = yield call(
          bookingManagerApi.generateFinalInvoice,
          bookingUuid,
          action.bankAccount,
          createInvoice.invoiceAddresseeType,
          createInvoice.lang
        );
        break;
      case EInvoiceType.CANCELLATION:
        result = yield call(
          bookingManagerApi.generateCancellationInvoice,
          bookingUuid,
          action.bankAccount,
          action.invoiceDueDates || [],
          createInvoice.invoiceAddresseeType,
          createInvoice.lang
        );
        break;
    }

    // 3. Download Invoice that was generated
    const upload = result.data;
    saveAs(upload.url, upload.filename);

    yield put(BreakdownActions.generateInvoiceSuccessAction());
    yield put(BreakdownActions.closeCreateInvoiceModalAction());

    yield put(
      enqueueNotification({
        message,
        options: { variant: 'success' },
      })
    );

    // reload the booking status options
    // as per 3167
    yield put(getBookingStatusOptionsRequestAction());
  } catch (e) {
    yield put(BreakdownActions.generateInvoiceFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was a problem generating invoice',
        options: { variant: 'error' },
      })
    );
  }
}

export function* generateManualInvoiceSaga(action: BreakdownActions.GenerateManualInvoiceRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const isHeadlineBreakdownEditedWithoutSaving = yield select(isHeadlineBreakdownEditedWithoutSavingSelector);
    const createManualInvoice = yield select(BreakdownSelectors.breakdownCreateManualInvoiceSelector);

    const actionPrompt = createManualInvoice.mode === EInvoiceMutationMode.CREATE ? 'generated' : 'updated';

    const message = `'Manual Invoice' has been ${actionPrompt}.`;

    // 1. Save the breakdown
    if (isHeadlineBreakdownEditedWithoutSaving) {
      yield saveAndUpdateHeadlineBreakdownSaga();
    }

    // 2. Generate Invoice
    let result = yield call(
      bookingManagerApi.generateManualProformaInvoice,
      bookingUuid,
      action.bankAccount,
      action.invoiceDueDate,
      createManualInvoice.invoiceAddresseeType,
      createManualInvoice.lang,
      action.cancellationPolicyText,
      action.description
    );

    // 3. Download Invoice that was generated
    const upload = result.data;
    saveAs(upload.url, upload.filename);

    yield put(BreakdownActions.generateManualInvoiceSuccessAction());
    yield put(BreakdownActions.closeCreateManualInvoiceModalAction());

    yield put(
      enqueueNotification({
        message,
        options: { variant: 'success' },
      })
    );

    // reload the booking status options
    // as per 3167
    yield put(getBookingStatusOptionsRequestAction());
  } catch (e) {
    yield put(BreakdownActions.generateManualInvoiceFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was a problem generating manual invoice',
        options: { variant: 'error' },
      })
    );
  }
}

export function* syncPurchaseCostReview() {
  const isTARole = yield select(isTA);
  if (!isTARole) {
    yield put(getPurchaseCostReviewRequestAction());
  }
}

export function* syncFinanceDocument() {
  yield put(getFinanceDocumentRequestAction());
  yield put(getFinanceDocumentBalanceRequestAction());
}

export function* saveAndUpdateHeadlineIntentSaga(action: BreakdownActions.SaveAndUpdateHeadlineBreakdownIntentAction) {
  const isBreakdownSalesCostModified = yield select(BreakdownSelectors.isBreakdownSalesCostModifiedSelector);
  const isBreakdownTextModified = yield select(BreakdownSelectors.isBreakdownTextModifiedSelector);
  const isBreakdownCurrencyExchangeModified = yield select(BreakdownSelectors.isBreakdownCurrencyExchangeModifiedSelector);

  const finalInvoice: IFinanceRow | undefined = yield select(automaticFinalInvoiceToTaSelector);
  const proformaInvoice: IFinanceRow | undefined = yield select(automaticProformaInvoiceToTaSelector);
  const bookingCurrency = yield select(bookingCurrencySelector);
  const booking = yield select(BookingManagerSelectors.bookingSelector);
  const hotelDetails = yield select(HotelDetailsSelectors.hotelDetailsSelector);
  const invoice = finalInvoice || proformaInvoice;

  // if they've set a bar commission percentage, but NOT an amount, throw an error
  let barCommissionPercentage = yield select(BookingManagerSelectors.barCommissionPercentageSelector);
  let barCommissionAmount = yield select(BookingManagerSelectors.barCommissionAmountSelector);

  if (barCommissionAmount === 0 && barCommissionPercentage !== 0) {
    yield put(BreakdownActions.setBookingBarCommissionAmountAction(null));
    barCommissionAmount = null;
  }
  if (barCommissionPercentage === 0 && barCommissionAmount !== 0) {
    yield put(BreakdownActions.setBookingBarCommissionPercentageAction(null));
    barCommissionPercentage = null;
  }
  // if one is set, but not the other, throw an error
  if (barCommissionPercentage !== null && barCommissionAmount === null) {
    yield put(
      enqueueNotification({
        message: 'Please set a Bar Commission Amount',
        options: { variant: 'error' },
      })
    );
    yield put(
      BreakdownActions.setBookingBarCommissionErrorsAction([
        { field: 'barCommissionAmount', message: 'Field is required' },
      ])
    );
    return; // if bar error, don't do anything else
  } else if (barCommissionAmount !== null && barCommissionPercentage === null) {
    yield put(
      enqueueNotification({
        message: 'Please set a Bar Commission Percentage',
        options: { variant: 'error' },
      })
    );
    yield put(
      BreakdownActions.setBookingBarCommissionErrorsAction([
        { field: 'barCommissionPercentage', message: 'Field is required' },
      ])
    );
    return; // if bar error, don't do anything else
  } else {
    yield put(BreakdownActions.setBookingBarCommissionErrorsAction([]));
  }

  const updateInvoiceAutomatically =
    (finalInvoice && (isBreakdownSalesCostModified || isBreakdownTextModified)) ||
    (proformaInvoice && isBreakdownTextModified && !isBreakdownSalesCostModified && !isBreakdownCurrencyExchangeModified);

  const updateInvoiceWithAck = proformaInvoice && (isBreakdownSalesCostModified || isBreakdownCurrencyExchangeModified);
  
  if (updateInvoiceAutomatically) {
    yield put(BreakdownActions.saveAndUpdateHeadlineBreakdownRequestAction());

    const saveResult = yield take([
      BreakdownActions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_SUCCESS,
      BreakdownActions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_FAILURE,
    ]);

    if (saveResult.type === BreakdownActions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_FAILURE) {
      return;
    }

    const invoiceType = proformaInvoice ? EInvoiceType.PROFORMA : EInvoiceType.FINAL;

    yield put(
      BreakdownActions.generateInvoiceRequestAction(invoiceType, invoice!.bankAccount!, invoice!.invoiceDueDates)
    );
    return;
  }

  if (updateInvoiceWithAck) {
    yield put(
      BreakdownActions.openCreateInvoiceModalAction(
        EInvoiceMutationMode.UPDATE,
        bookingCurrency,
        booking.travelAgentCountryCode,
        hotelDetails.countryCode
      )
    );
    yield put(BreakdownActions.getInvoiceAddresseeRequestAction());
    return;
  }

  yield put(BreakdownActions.saveAndUpdateHeadlineBreakdownRequestAction());
}

export const getEarliestArrivalAndLatestDeparture = (
  items: IHeadlineLineItemBreakdownAccommodationLineItem[]
): { earliestArrival?: string; latestDeparture?: string } => {
  let earliestArrival: string | undefined = '';
  let latestDeparture: string | undefined = '';

  items.forEach(i => {
    if ((i.arrival && earliestArrival && i.arrival < earliestArrival) || (i.arrival && earliestArrival === '')) {
      earliestArrival = i.arrival;
    }
    if ((i.departure && latestDeparture && i.departure > latestDeparture) || (i.departure && latestDeparture === '')) {
      latestDeparture = i.departure;
    }
  });

  if (earliestArrival === '') {
    earliestArrival = undefined;
  }
  if (latestDeparture === '') {
    latestDeparture = undefined;
  }

  return {
    earliestArrival,
    latestDeparture,
  };
};

export function* saveAndUpdateBookingArrivalDeparture() {
  const isTARole = yield select(isTA);
  if (isTARole) {
    return;
  }
  const headlineLineItemBreakdown: IHeadlineLineItemBreakdown = yield select(headlineLineItemBreakdownSelector);
  const bookingManagerApi = makeBookingManagerApi();
  const bookingUuid = yield select(bookingUuidSelector);

  const { earliestArrival, latestDeparture } = getEarliestArrivalAndLatestDeparture(
    headlineLineItemBreakdown.Accommodation.items
  );

  if (!earliestArrival || !latestDeparture) {
    return;
  }

  // calculate earliest and latest accommodation and patch the BookingArrivalDeparture with those dates
  return yield call(bookingManagerApi.updateBookingArrivalDeparture, bookingUuid, earliestArrival, latestDeparture);
}

export function* getSuppliersSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingCurrency: TCurrencyCode = yield select(bookingCurrencySelector);
    const productTypes = [
      EAncillaryProductType.TRANSFER,
      EAncillaryProductType.GROUND_SERVICE,
      EAncillaryProductType.ACTIVITY,
    ];

    const responses = yield all(
      productTypes.map(productType =>
        call(bookingManagerApi.ancillarySuppliersSimpleSearch, productType, bookingCurrency)
      )
    );

    yield put(BreakdownActions.getSuppliersSuccessAction(responses[0].data, responses[1].data, responses[2].data));
  } catch (e) {
    yield put(BreakdownActions.getSuppliersFailureAction());
    yield put(
      enqueueNotification({
        message: 'Failed to fetch suppliers',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchBookingManagerBreakdown() {
  yield takeLatest([BreakdownActions.GET_BAR_COMMISSION_REQUEST], getBarCommissionSaga);
  yield takeLatest([BreakdownActions.GET_HEADLINE_LINE_ITEM_BREAKDOWN_REQUEST], getHeadlineLineItemBreakdownSaga);
  yield takeLatest([BreakdownActions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_INTENT], saveAndUpdateHeadlineIntentSaga);
  yield takeLatest([BreakdownActions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_REQUEST], saveAndUpdateHeadlineBreakdownSaga);
  yield takeLatest([BreakdownActions.DOWNLOAD_BREAKDOWN_FOR_TA_REQUEST], downloadBreakdownForTASaga);
  yield takeLatest([BreakdownActions.GET_HEADLINE_BREAKDOWN_VERSION_LIST_REQUEST], getHeadlineBreakdownVersionListSaga);
  yield takeLatest(
    [BreakdownActions.SET_HEADLINE_BREAKDOWN_SELECTED_VERSION],
    retrieveSelectedHeadlineBreakdownVersion
  );
  yield takeLatest([BreakdownActions.DOWNLOAD_BREAKDOWN_FOR_CLIENT_REQUEST], downloadBreakdownForClientSaga);
  yield takeLatest([BreakdownActions.GENERATE_INVOICE_REQUEST], generateInvoiceSaga);
  yield takeLatest([BreakdownActions.GENERATE_MANUAL_INVOICE_REQUEST], generateManualInvoiceSaga);
  yield takeLatest(
    [
      BreakdownActions.GENERATE_INVOICE_SUCCESS,
      SET_BOOKING_STATUS_SUCCESS,
      BreakdownActions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_SUCCESS,
      POST_CANCELLATION_INVOICE_SUCCESS,
    ],
    syncPurchaseCostReview
  );
  yield takeLatest([BreakdownActions.GENERATE_INVOICE_SUCCESS, SET_BOOKING_STATUS_SUCCESS], syncFinanceDocument);
  yield takeLatest([BreakdownActions.GET_SUPPLIERS_REQUEST], getSuppliersSaga);
}
