import { TCurrencyCode } from 'interfaces';
import React from 'react';
import { Totals } from 'services/BackendApi';
import { Price } from "ui/Price";

interface IAccommodationModalPriceProps {
  totals: Totals;
  currencyCode: TCurrencyCode;
}

export const AccommodationModalPrice: React.FC<IAccommodationModalPriceProps> = ({ currencyCode, totals }) => {
  const priceClassName = 'font-pt-sans text-right m-0';

  return (
    <Price
      total={totals.total}
      totalBeforeDiscount={totals.totalBeforeDiscount}
      currencyCode={currencyCode}
      isOnRequest={totals.oneOrMoreItemsOnRequest}
      totalClassName={`${priceClassName} text-15px leading-18px text-flint`}
      totalBeforeDiscountClassName={`${priceClassName} text-13px text-gold-light line-through`}
      totalAfterDiscountClassName={`${priceClassName} text-15px leading-18px text-red-15`}
      isOnRequestClassName={`${priceClassName} text-15px leading-18px text-flint`}
    />
  )
}