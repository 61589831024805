import React, { useCallback } from 'react';
import FluidButton from 'ui/FluidButton';
import classnames from 'classnames';
import UserIcon from 'ui/Icons/person-alt.component.svg';
import { formatDateDisplayFourDigitYear, formatPrice, getCurrencyBySymbol, numberOfNights, rateStringToCents } from 'utils';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { isNil, sortBy } from 'lodash-es';
import CalendarIcon from 'ui/Icons/calendar-today.component.svg';
import { SvgIcon } from 'ui/SvgIcon';

import { BookingBuilderResponse, IHotelAccommodationsSearchRequest, ProductSetAccommodation } from 'services/BackendApi';
import { useDispatch, useSelector } from 'react-redux';
import { BAMBookingBuildRequestSelector, BAMEndDateFilterSelector, BAMLastRequestSelector, BAMPageSelector, BAMSelectedMealPlansSelector, BAMSelectedOccasionsSelector, BAMSelectedRepeatGuestSelector, BAMStartDateFilterSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { bamBookingBuildRequestAction, resetBAMPageAddonsAction, resetBAMStateAction, setAddAncillaryAccommodationModalToggleAction, setBAMPageAction } from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { ITopNavigationData } from 'services/BookingManagerApi';
import { AddAncillaryRightHandPanelTotalCost } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanelTotalCost';
import { EBAMPage } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { EditButton } from 'ui/EditButton/EditButton';
import { RHPPageAddons } from './RightHandPanel/RHPPageAddons';
import { RHPPageAccommodations } from './RightHandPanel/RHPPageAccommodations';
import { RHPOffers } from './RightHandPanel/RHPOffers';
import { enqueueNotification } from 'store/modules/ui';
import { RHPServiceCharge } from './RightHandPanel/RHPServiceCharge';

const GuestAges: React.FC<{ lastSearchRequest: IHotelAccommodationsSearchRequest }> = ({ lastSearchRequest }) => {
  return (
    <div className="guest-ages flex items-center mt-[5px]">
      <UserIcon className="w-[16px] h-[16px] stroke-black fill-black" />
      <span className="ml-[5px]">x{lastSearchRequest?.numberOfAdults}</span>
      {lastSearchRequest?.agesOfAllChildren &&
        lastSearchRequest.agesOfAllChildren.length >= 1 && (
          <>
            <UserIcon className="w-[12px] h-[12px] stroke-black fill-black" />
            <span className="ml-[5px]">x{lastSearchRequest.agesOfAllChildren.length}</span>
            <span>&nbsp;({sortBy(lastSearchRequest.agesOfAllChildren).join(', ')}&nbsp;years)</span>
          </>
        )}
    </div>
  )
}

export interface IAccommodationRightHandPanelProps {
  selectedBuild: BookingBuilderResponse;
  currencySymbol: string;
  bookingUuid: string;
  topNavigationData: ITopNavigationData;
  loadingStatus: ENetworkRequestStatus;
}

export const AccommodationRightHandPanel: React.FC<IAccommodationRightHandPanelProps> = ({ currencySymbol, selectedBuild, loadingStatus, topNavigationData, bookingUuid }) => {
  const dispatch = useDispatch();
  const lastSearchRequest = useSelector(BAMLastRequestSelector);
  const startDate = useSelector(BAMStartDateFilterSelector);
  const endDate = useSelector(BAMEndDateFilterSelector);
  const bookingBuildRequest = useSelector(BAMBookingBuildRequestSelector);
  const selectedAccommodation = selectedBuild.availableProductSets.Accommodation[0];
  const selectedMealPlan = selectedAccommodation?.availableSubProductSets['Meal Plan'].find(
    item => item.selected
  );
  const rightPanelClassname = 'right-panel font-pt-sans border-solid border-1 border-gray-40 rounded-[4px] p-20px flex-[260] flex flex-col justify-between w-[321px] space-y-20px';
  const page = useSelector(BAMPageSelector);

  const handleContinueButton = useCallback(() => {
    dispatch(setBAMPageAction(EBAMPage.PAGE_ADDONS))
  }, [dispatch]);

  const handleAddAccommodationButton = useCallback(() => {
    dispatch(enqueueNotification({
      message: 'Accommodation added successfully.',
      options: { variant: 'success' },
    }));
    dispatch(setAddAncillaryAccommodationModalToggleAction(false));
    dispatch(resetBAMStateAction())
  }, [dispatch]);

  const handleEdit = useCallback(() => {
    dispatch(resetBAMPageAddonsAction());
    dispatch(bamBookingBuildRequestAction(bookingUuid));
  }, [dispatch, bookingUuid]);

  if (!selectedMealPlan || !selectedAccommodation) {
    return (
      <div className={rightPanelClassname}>
        <ErrorBar message="Something went wrong" />
      </div>
    )
  }

  return (
    <div className={rightPanelClassname}>
      {loadingStatus === ENetworkRequestStatus.PENDING && <LoadingBar />}

      {loadingStatus === ENetworkRequestStatus.ERROR && <ErrorBar />}

      {loadingStatus === ENetworkRequestStatus.SUCCESS && !isNil(selectedAccommodation) && (
        <React.Fragment>
          <div className={classnames("your-choice rhp-top-part flex flex-col ", { 'opacity-50': bookingBuildRequest === ENetworkRequestStatus.PENDING })}>
            <div className="flex items-center justify-between">
              <span className="text-xs text-gray-100 uppercase">Your Choice</span>
              {page === EBAMPage.PAGE_ADDONS && <EditButton caption="Edit" onEdit={handleEdit} />}
            </div>
            <span className="mt-[5px] text-17px leading-22px text-black font-bold">
              {topNavigationData.hotelName} - {selectedAccommodation.products[0].name}
            </span>
            {lastSearchRequest && <GuestAges lastSearchRequest={lastSearchRequest} />}
            
            <span className="dates flex mt-[5px] justify-between items-center space-x-2 w-full">
              <div className="flex items-center gap-[5px]">
                <SvgIcon width="16px" height="16px" IconComponent={CalendarIcon} defaultFill="#413E3B" />
                <span>{formatDateDisplayFourDigitYear(startDate)} - {formatDateDisplayFourDigitYear(endDate)}</span>
              </div>
              <span className="flex justify-center items-center bg-teal-40 px-[10px] h-[27px]">{numberOfNights(startDate, endDate)}&nbsp;N</span>
            </span>

            {page === EBAMPage.PAGE_ACCOMMODATIONS && <RHPPageAccommodations
              selectedBuild={selectedBuild}
              currencySymbol={currencySymbol}
              bookingBuildRequest={bookingBuildRequest}
              bookingUuid={bookingUuid}
            />}
            {page === EBAMPage.PAGE_ADDONS && <RHPPageAddons
              selectedBuild={selectedBuild}
              currencySymbol={currencySymbol}
              bookingBuildRequest={bookingBuildRequest}
              bookingUuid={bookingUuid}
            />}
          </div>

          {bookingBuildRequest === ENetworkRequestStatus.PENDING && <LoadingBar />}
          {bookingBuildRequest !== ENetworkRequestStatus.PENDING && (
            <div className="rhp-bottom-part space-y-10px pt-10px">
              <RHPOffers selectedBuild={selectedBuild} />

              <RHPServiceCharge currencySymbol={currencySymbol} selectedBuild={selectedBuild} />
              
              <AddAncillaryRightHandPanelTotalCost
                price={selectedBuild.totals.total}
                isOnRequest={selectedBuild.totals.oneOrMoreItemsOnRequest}
                currencySymbol={currencySymbol}
              />

              {page === EBAMPage.PAGE_ACCOMMODATIONS && (
                <FluidButton
                  onClick={handleContinueButton}
                  className="block w-full"
                  type="primary"
                >
                  Continue &gt;
                </FluidButton>
              )}
              {page === EBAMPage.PAGE_ADDONS && (
                <FluidButton
                  onClick={handleAddAccommodationButton}
                  className="block w-full"
                  type="primary"
                >
                  Add Accommodation
                </FluidButton>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
