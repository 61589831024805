import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { MealPlanDropdownOptions, MealPlanOptionPrice } from './MealPlanDropdownOptions';
import classNames from 'classnames';
import { TriangleDownComponent } from 'ui/Icons';
import { IStaticRate } from './types';
import { TCurrencyCode } from 'interfaces';
import { useHideOnClickOutside } from 'hooks/useHideOnClickOutside';

export interface IMealPlanDropdownProps {
  value?: IStaticRate;
  options: IStaticRate[];
  currency: TCurrencyCode;
  onChoose: (value: IStaticRate) => void;
  label?: string;
  maxVisibleItems?: number;
  labelWhenNothingSelected?: string;
  className?: string;
  inputClassName?: string;
  showSelectedOption?: boolean;
  disabled?: boolean;
}

export const MealPlanDropdown: React.FC<IMealPlanDropdownProps> = React.memo(
  ({
    currency,
    value,
    options,
    onChoose,
    maxVisibleItems,
    className = '',
    inputClassName = '',
    disabled = false,
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const inputClassname = classnames('meal-plan-selected w-full text-[15px] leading-[19px] text-nowrap overflow-hidden text-ellipsis py-[7px] px-[10px] text-black outline-none placeholder:text-gray-40 flex justify-between gap-[5px] items-center', {
      'text-black border border-solid border-gray-40 bg-white-prime': !disabled,
      'text-gray-100 border-none bg-gray-10': disabled,
      'border-green-prime border-2': isOpen,
    });
    const { instanceClassname } = useHideOnClickOutside(setIsOpen, 'select');

    const toggleDropdown = useCallback(() => {
      if (!disabled) setIsOpen(!isOpen);
    }, [disabled, isOpen]);

    const handleChoose = useCallback(
      (newValue: IStaticRate) => {
        onChoose(newValue);
        setIsOpen(false);
      },
      [setIsOpen],
    );

    return (
      <div className={classnames(instanceClassname, 'meal-plan-dropdown flex flex-col w-full min-w-[75px]', className)}>
        <div
          className={classnames(
            'relative flex w-full cursor-pointer items-center justify-between text-[15px]',
            inputClassName,
          )}
        >
          <div className="options flex items-center justify-start relative h-full w-full">
            <div className={inputClassname} onClick={toggleDropdown}>
              <div className='max-w-[calc(100% - 10px)] overflow-hidden text-ellipsis'>{value?.mealPlan.names.join(' & ') ?? ''}&nbsp;<MealPlanOptionPrice isOnRequest={value?.mealPlan.isOnRequest ?? true} totalBeforeDiscountCents={value?.mealPlan.totalBeforeDiscountCents} currency={currency} totalCents={value?.mealPlan.totalCents} /></div>
              <span className={classNames({ 'rotate-180': isOpen })} onClick={toggleDropdown}><TriangleDownComponent /></span>
            </div>
            <div
              className={classnames(
                'w-full font-pt-sans text-black absolute top-[37px] z-30 border border-solid border-t-0 border-gray-40 bg-ivory',
                { hidden: !isOpen },
              )}
            >
              <MealPlanDropdownOptions
                value={value}
                options={options}
                currency={currency}
                maxVisibleItems={maxVisibleItems}
                onChoose={handleChoose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);
