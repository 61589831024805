import { ModalModes } from 'containers/SummaryForm/const';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as ProposalsSelectors from 'store/modules/proposals/selectors';
import * as ProposalsSelectorsV2 from 'store/modules/proposalsList/selectors';
import * as ProposalsActions from 'store/modules/proposals/actions';
import { convertArrayToKeyValueObject } from 'utils';
import { BookingStatusTypes } from 'config/enums';
import { guestInfoSelector } from 'store/modules/bookingBuilder';
import { fromPairs, isNil, omit, toPairs } from 'ramda';
import { offersQuerySelector } from 'store/modules/fastSearch';
import { selectedCompanyMembershipSelector } from 'store/modules/agents';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';
import { enqueueNotification } from 'store/modules/ui';
import { ITravelAgent } from 'services/BackendApi';

export const useAddBookingToProposal = ({
  bookingBuild,
  setRequestInProgress,
  backendApi,
  containsLiveRates,
  openModalRatesWarning,
  closeModalRatesWarning,
  setShouldShowLeaveAlert,
  taMarginAmountOverride,
  selectedTa,
  basketBuildUuid,
  onPostRemove,
  clientCountryCode,
}: {
  bookingBuild: any;
  setRequestInProgress: any;
  backendApi: any;
  containsLiveRates: any;
  openModalRatesWarning: any;
  closeModalRatesWarning: any;
  setShouldShowLeaveAlert: any;
  taMarginAmountOverride: any;
  selectedTa: Partial<ITravelAgent> | null;
  basketBuildUuid: any;
  onPostRemove: any;
  clientCountryCode: any;
}) => {
  const history = useHistory();
  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);
  const proposalsV2 = useSelector(ProposalsSelectorsV2.proposalsSelector);
  const isProposalDownloading = useSelector(ProposalsSelectorsV2.requestPendingSelector);
  const proposals = convertArrayToKeyValueObject(proposalsV2, 'uuid', 'name');
  const proposalStatus = useSelector(ProposalsSelectors.getProposalsStatus);
  const dispatch = useDispatch();

  const guestInformation = useSelector(guestInfoSelector);
  const guestInfoProposal = fromPairs(
    toPairs(omit(['isRepeatGuest'], guestInformation)).filter(tuple => !isNil(tuple[1]))
  );
  const searchQuery = useSelector(offersQuerySelector);
  const selectedCompanyMembership = useSelector(selectedCompanyMembershipSelector);
  const actingOnBehalfOfUser = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUserSelector);

  const handleProposalOnSuccess = useCallback(() => history.push('/search/beta'), [history]);
  const handleOnProposalModalClose = useCallback(() => setIsProposalModalOpen(false), []);

  const handleAddToProposalButton = useCallback(() => {
    setIsProposalModalOpen(true);
    closeModalRatesWarning();
  }, [closeModalRatesWarning]);

  const handleAddToProposalTrigger = useCallback(() => {
    if (containsLiveRates) {
      openModalRatesWarning(ModalModes.PROPOSAL);
    } else {
      handleAddToProposalButton();
    }
  }, [containsLiveRates, handleAddToProposalButton, openModalRatesWarning]);

  const handleAddToProposal = async proposalUuid => {
    setIsProposalModalOpen(false);
    setRequestInProgress(true);
    const payload: any = {
      ...guestInfoProposal,
      specialRequests: [guestInfoProposal.comments],
    };
    const finalPayload: any = {};

    finalPayload.status = BookingStatusTypes.POTENTIAL;
    finalPayload.placeHolds = false;
    finalPayload.bookingHash = bookingBuild.response.bookingHash;
    finalPayload.bookingBuild = bookingBuild.request;
    finalPayload.bookingInformation = {
      travelAgentUserUuid: selectedTa ? selectedTa.uuid : actingOnBehalfOfUser!.uuid,
      guestTitle: payload.guestTitle,
      guestFirstName: payload.guestFirstName,
      guestLastName: payload.guestLastName,
      isRepeatGuest: false,
      taMarginType: 'percentage',
      comments: payload.comments,
      specialRequests: payload.specialRequests,
      proposalUuid,
    };
    finalPayload.basketBuildUuid = basketBuildUuid;

    if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
      finalPayload.bookingInformation.taMarginAmount = taMarginAmountOverride;
      finalPayload.bookingInformation.travelAgentUserUuid = selectedTa ? selectedTa.uuid : actingOnBehalfOfUser!.uuid;
    }

    try {
      await backendApi.createBooking({
        bookingAttributes: finalPayload,
        clientCountryCode: clientCountryCode,
        selectedCompanyMembership,
        actingOnBehalfOfUser,
      });

      onPostRemove(basketBuildUuid);

      dispatch(
        enqueueNotification({
          message: 'Booking created and added to proposal successfully.',
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(
        enqueueNotification({
          message: 'Failed to create booking and/or add to proposal.',
          options: { variant: 'error' },
        })
      );
    }

    setRequestInProgress(false);
    setShouldShowLeaveAlert(false);
  };

  const handleCreateNewProposal = async newProposalName => {
    console.log('selectedTa', selectedTa);
    setIsProposalModalOpen(false);
    setRequestInProgress(true);
    const newProposalInfo: any = {
      name: newProposalName,
      userUuid: selectedTa ? selectedTa.uuid : actingOnBehalfOfUser!.uuid,
      ...guestInfoProposal,
    };

    const finalPayload: any = {};
    finalPayload.status = BookingStatusTypes.POTENTIAL;
    finalPayload.placeHolds = false;
    finalPayload.bookingHash = bookingBuild.response.bookingHash;
    finalPayload.bookingBuild = bookingBuild.request;
    finalPayload.bookingInformation = {
      ...guestInfoProposal,
      specialRequests: [guestInfoProposal.comments],
      travelAgentUserUuid: selectedTa ? selectedTa.uuid : actingOnBehalfOfUser!.uuid,
      isRepeatGuest: false,
      taMarginType: 'percentage',
    };
    finalPayload.basketBuildUuid = basketBuildUuid;

    if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
      finalPayload.bookingInformation.taMarginAmount = taMarginAmountOverride;
      finalPayload.bookingInformation.travelAgentUserUuid = selectedTa ? selectedTa.uuid : actingOnBehalfOfUser!.uuid;
    }

    // if we dont have the new proposal info user uuid for some reason, and we DO have
    // a selected TA, just use that
    if (selectedTa && isNil(newProposalInfo.userUuid)) {
      newProposalInfo.userUuid = selectedTa.uuid;
    }
    try {
      await backendApi.createBooking({
        bookingAttributes: finalPayload,
        newProposalAttributes: newProposalInfo,
        clientCountryCode: clientCountryCode,
        selectedCompanyMembership,
        actingOnBehalfOfUser,
      });

      onPostRemove(basketBuildUuid);

      dispatch(
        enqueueNotification({
          message: 'Booking and proposal created and linked successfully.',
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(
        enqueueNotification({
          message: 'Failed to create booking and/or proposal.',
          options: { variant: 'error' },
        })
      );
    }
    setRequestInProgress(false);
    setShouldShowLeaveAlert(false);
  };

  return {
    handleAddToProposalButton,
    handleCreateNewProposal,
    handleAddToProposal,
    handleAddToProposalTrigger,
    proposals,
    proposalStatus,
    isProposalDownloading,
    isProposalModalOpen,
    handleProposalOnSuccess,
    handleOnProposalModalClose,
  };
};
