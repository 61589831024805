import React from 'react';
import { makeBackendApi } from 'services/BackendApi/BackendApi';
import { ENetworkRequestStatus, IMainCompanyInfoResponse } from 'services/BackendApi/types';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useApiRequest } from 'hooks/useApiRequest';
import { Link } from 'ui/Link';
import Helmet from 'react-helmet';
import { useRouteMatch } from 'react-router';
import * as _ from 'lodash-es';

export const SharedLinkPage = () => {
  const match = useRouteMatch<{ sharedLinkUuid: string }>();
  const sharedLinkUuid = match.params.sharedLinkUuid;
  const backendApi = makeBackendApi();

  const getMainCompanyInfoRequest = useApiRequest(backendApi.fetchMainCompanyInfo);

  const getSharedLinkContent = useApiRequest(backendApi.sharedLinkGetContent, [sharedLinkUuid]);

  if (_.isNil(sharedLinkUuid)) {
    return (
      <div className="container w-1280px mx-auto pt-10">
        <ErrorBar message="No share link UUID provided" />
      </div>
    );
  }

  if (
    getMainCompanyInfoRequest.response === undefined ||
    getMainCompanyInfoRequest.requestStatus === ENetworkRequestStatus.PENDING
  ) {
    return (
      <div className="container w-1280px mx-auto">
        <LoadingBar />
      </div>
    );
  }

  if (getMainCompanyInfoRequest.requestStatus === ENetworkRequestStatus.ERROR) {
    return (
      <div className="container w-1280px mx-auto">
        <ErrorBar />
      </div>
    );
  }

  const info = getMainCompanyInfoRequest.response.data.data.info;
  const logo = getMainCompanyInfoRequest.response.data.data.logo;

  return (
    <>
      <Helmet title="Shared Link" />
      <div className="w-full flex flex-col space-y-40px">
        <div className="text-center shadow-pe1 py-4">
          <Link to={`/`}>
            <img className="mx-auto max-w-[400px] max-h-[80px]" src={logo.url} alt={info.name} />
          </Link>
        </div>

        {/* a 410 means its expired */}
        {getSharedLinkContent.status === 410 && (
          <div className="font-hurmegeometric-sans text-15px leading-28px text-black container w-1280px mx-auto text-center uppercase children:block">
            <span>This page is no longer available</span>
            <span>The link has expired.</span>
            <span>Please, contact the sender of this link for further assistance.</span>
          </div>
        )}

        {/* a 200 is all good */}
        {getSharedLinkContent.status === 200 && (
          <div className="font-hurmegeometric-sans text-15px leading-28px text-black container w-1280px mx-auto text-center uppercase children:block">
            <span>WIP - Come Back Soon</span>
          </div>
        )}
      </div>
    </>
  );
};
