import React from 'react';

import { IIconProps } from 'ui/CircleIconButton';
import { theme } from '../../../../tailwind.config';
export const CheckmarkIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['black'], background = 'transparent' }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: background, minWidth: '16px', minHeight: '16px' }}
    >
      <path
        d="M6.0001 10.7799L3.2201 7.9999L2.27344 8.9399L6.0001 12.6666L14.0001 4.66656L13.0601 3.72656L6.0001 10.7799Z"
        fill={fill}
      />
    </svg>
  )
);

export default CheckmarkIcon;
