import React, { Fragment } from 'react';
import { MainStyles } from './styles';
import Separator from './components/Separator';
import ImporterErrorReport from './components/ImporterErrorReport';
import LatestStatusInfo from './components/LatestStatusInfo';
import { IWithImporterProps } from 'hoc/WithImporter';
import FluidButton from 'ui/FluidButton';
import { IImportStatus } from 'services/BackendApi';
import { theme } from '../../../tailwind.config';
import { ConfirmationModal, EConfirmationModalType } from '../../ui/ConfirmationModal';
import { Link } from 'ui/Link';
import { IDynamicParametersResponse } from 'services/ParametersProviderApi';

export default class Importer extends React.Component<IImportProps> {
  componentDidMount() {
    this.props.importPageLoaded();
  }

  componentWillUnmount() {
    this.props.importPageUnloaded();
  }

  render() {
    const {
      className,
      entityName,
      confirmationCopy,
      importRequestIsPending,
      latestStatus,
      confirmationModalOpen,
      openImportConfirmationModal,
      confirmImportIntent,
      cancelImportIntent,
      workbookId,
    } = this.props;

    const workbookUrl = workbookId ? `https://docs.google.com/spreadsheets/d/${workbookId}/edit` : null;

    const statusLabel = (status: IImportStatus['status'], success: boolean) => {
      switch (status) {
        case 'in-progress':
          return 'In Progress';
        case 'pending':
          return 'Pending';
        case 'done':
          return success ? 'Success' : 'Fail';
      }
    };

    const statusColor = (status: IImportStatus['status'], success: boolean) => {
      switch (status) {
        case 'in-progress':
        case 'pending':
          return theme.colors['yellow-30'];
        case 'done':
          return success ? theme.colors['status-confirmed'] : theme.colors['red-95'];
      }
    };

    return (
      <div className="container w-full max-w-1280px mx-auto space-y-20px">
        <div>
          <Link to={`/hotel-admin`} type="external">
            <span className="back-link text-brown-100 hover:text-brown-140 visited:text-brown-100 text-15px font-bold leading-19px underline cursor-pointer font-pt-sans select-none">
              Back to Hotels
            </span>
          </Link>
          <section className="controls">
            <div className="flex justify-between items-center w-full">
              {this.props.pageTitle}

              <div className="flex flex-col space-y-10px text-right">
                {latestStatus && <LatestStatusInfo status={latestStatus} />}
                <FluidButton
                  className="self-end"
                  disabled={importRequestIsPending}
                  onClick={openImportConfirmationModal}
                  type="primary"
                >
                  Import
                </FluidButton>
              </div>
            </div>
          </section>

          {latestStatus && (
            <div className="bg-ivory px-20px py-15px flex flex-col space-y-10px rounded-[4px]">
              <p className="font-pt-sans text-17px leading-22px">
                <span className="font-bold text-black">Status:</span>
                <span
                  style={{
                    color: statusColor(latestStatus.status, latestStatus.data?.success || false),
                  }}
                  className="ml-2 font-bold"
                >
                  {statusLabel(latestStatus.status, latestStatus.data?.success || false)}
                </span>
              </p>

              {latestStatus.data && (
                <section className="report">
                  <ImporterErrorReport status={latestStatus.status} data={latestStatus.data} />
                </section>
              )}
            </div>
          )}

          {confirmationModalOpen && (
            <ConfirmationModal
              type={EConfirmationModalType.WARNING}
              className="confirm-import-modal"
              title={this.props.confirmationCopy || 'Import'}
              message={`Are you sure you want to Import ${entityName}?`}
              confirmButtonLabel="Yes"
              cancelButtonLabel="No"
              onConfirm={confirmImportIntent}
              onCancel={cancelImportIntent}
              isConfirmLoading={false}
            />
          )}
        </div>
        {workbookUrl && (
          <div className="editor">
            <iframe className="w-full h-[100vh] border-0" src={workbookUrl} />
          </div>
        )}
      </div>
    );
  }
}

export interface IImportProps extends IWithImporterProps {
  className?: string;
  entityName: string;
  confirmationCopy?: string;
  dynamicParameters: IDynamicParametersResponse;
  pageTitle: React.ReactNode;
}
