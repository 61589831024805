import React from 'react';
import { IHotel, ISeason } from 'services/BackendApi';
import SingleDateInput from 'pureUi/SingleDateInput';
import { addYears } from 'date-fns';
import FluidButton from 'ui/FluidButton';
import * as Inputs from '../Inputs';
import { produce } from 'immer';
import { isNil } from 'lodash-es';
import { formatDate } from 'utils';
import { v4 as uuidv4 } from 'uuid';

export interface iSeasonProps {
  hotel: IHotel;
  season: Partial<ISeason>;
  onUpdate: (field: keyof ISeason, value: any) => void;
  onCta: () => void;
  ctaLabel: string;
}

export const Season = (props: iSeasonProps) => {
  const { season } = props;

  const addNewTimePeriod = () => {
    const updatedSeasonDates = produce(season.seasonDates, draftSeasonDates => {
      draftSeasonDates!.push({
        uuid: uuidv4(),
        isNew: true,
        validFrom: '',
        validTo: '',
        seasonUuid: props.season.uuid,
      });
    });

    props.onUpdate('seasonDates', updatedSeasonDates);
  };

  return (
    <div className="font-pt-sans py-3 flex flex-col space-y-3 w-full">
      <fieldset className="border borders-solid border-gray-20 p-4">
        <legend className="text-sm text-gray-100">Season Details</legend>
        <Inputs.Input
          label="Name"
          value={season.name || ''}
          onChange={val => {
            props.onUpdate('name', val);
          }}
        />
      </fieldset>

      <fieldset className="border borders-solid border-gray-20 p-4">
        <legend className="text-sm text-gray-100">Time Periods</legend>

        <div className="flex flex-col space-y-4">
          {(season.seasonDates || [])
            .filter(sd => sd.deleted !== true)
            .map((sd, seasonDateIndex) => {
              return (
                <div key={sd.uuid || seasonDateIndex} className="flex space-x-2 items-end">
                  <label>
                    {seasonDateIndex === 0 && <span className="text-sm text-gray-100">From</span>}
                    <SingleDateInput
                      value={sd.validFrom !== '' ? new Date(sd.validFrom) : null}
                      onChange={value => {
                        props.onUpdate(
                          'seasonDates',
                          produce(season.seasonDates, draftSeasonDates => {
                            if (isNil(draftSeasonDates)) {
                              draftSeasonDates = [];
                            }
                            if (isNil(seasonDateIndex)) {
                              return;
                            }
                            const realIndex = draftSeasonDates.findIndex(_sd => _sd.uuid === sd.uuid);
                            draftSeasonDates[realIndex].validFrom = formatDate(value);
                          })
                        );
                      }}
                      showYearDropdown
                      enablePastDates
                      minDate={addYears(new Date(), -150)}
                      maxDate={addYears(new Date(), 150)}
                      className="min-w-[200px]"
                    />
                  </label>

                  <span className="mb-[10px]">-</span>

                  <label>
                    {seasonDateIndex === 0 && <span className="text-sm text-gray-100">To</span>}
                    <SingleDateInput
                      value={sd.validTo !== '' ? new Date(sd.validTo) : null}
                      onChange={value => {
                        props.onUpdate(
                          'seasonDates',
                          produce(season.seasonDates, draftSeasonDates => {
                            if (isNil(draftSeasonDates)) {
                              draftSeasonDates = [];
                            }
                            if (isNil(seasonDateIndex)) {
                              return;
                            }
                            const realIndex = draftSeasonDates.findIndex(_sd => _sd.uuid === sd.uuid);
                            draftSeasonDates[realIndex].validTo = formatDate(value);
                          })
                        );
                      }}
                      showYearDropdown
                      enablePastDates
                      minDate={addYears(new Date(), -150)}
                      maxDate={addYears(new Date(), 150)}
                      className="min-w-[200px]"
                    />
                  </label>
                  <button
                    className="self-center mt-[22px] rounded-full w-6 h-6 bg-brown-60 hover:bg-brown-80 text-white text-xs cursor-pointer"
                    onClick={() => {
                      props.onUpdate(
                        'seasonDates',
                        produce(season.seasonDates, draftSeasonDates => {
                          draftSeasonDates![seasonDateIndex].deleted = true;
                        })
                      );
                    }}
                  >
                    &#x2715;
                  </button>
                </div>
              );
            })}
        </div>

        <FluidButton type="secondary" className="w-[200px] mt-3" onClick={addNewTimePeriod}>
          Add Time Period
        </FluidButton>
      </fieldset>

      <FluidButton
        type="primary"
        className="w-[200px] self-end"
        onClick={() => {
          props.onCta();
        }}
      >
        {props.ctaLabel}
      </FluidButton>
    </div>
  );
};
