import React from 'react';
import classnames from 'classnames';
import { PencilIcon } from 'ui/Icons/components/Pencil.component';

interface IEditButtonProps {
  caption: string;
  onEdit: () => void;
  className?: string;
}

export const EditButton: React.FC<IEditButtonProps> = React.memo(({ onEdit, className, caption }) => {
  const elementClassName = classnames('booking-accommodation-edit flex justify-end items-center gap-[3px] cursor-pointer', className);

  return (
    <div className={elementClassName} onClick={onEdit}>
      <PencilIcon />
      <a className="edit-label font-pt-sans text-[12px] text-brown-prime leading-[normal] underline uppercase tracking-[0.48px]">{caption}</a>
    </div>
  );
});