import React from 'react';
import { RHPSubtitle } from './RHPSubtitle';
import { Transfer } from 'services/BackendApi';
import { ETransferDirection } from 'store/modules/bookingBuilder/subdomains/transfers/types';

const getProductName = (product: { uuid: string; name: string; direction: ETransferDirection }) => {
  const directionMapping = {
    in: '(IN)',
    out: '(OUT)',
    return: '',
  }
  const productName = [product.name, directionMapping[product.direction]].filter(Boolean).join(' ');
  return productName;
}

export interface IRHPTransfersProps {
  isMandatory: boolean;
  products: { uuid: string; name: string; direction: ETransferDirection }[];
  isVisible: boolean;
}

export const RHPTransfers: React.FC<IRHPTransfersProps> = ({ isMandatory, products, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  let title = 'Transfers';
  if (isMandatory) {
    title += ' *';
  }

  const oneWayInTransfer = products.find(item => item.direction === ETransferDirection.IN);
  const oneWayOutTransfer = products.find(item => item.direction === ETransferDirection.OUT);
  const returnTransfer = products.find(item => item.direction === ETransferDirection.RETURN);

  return (
    <div className="rhp-transfers">
        <RHPSubtitle>{title}</RHPSubtitle>
        {(oneWayInTransfer || oneWayOutTransfer) && (
          <>
            <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">ONE WAY (IN)</p>
            {oneWayInTransfer ? (
              <p className="rhp-transfer m-0 mt-[5px]">{getProductName(oneWayInTransfer)}</p>
            ) : (
              <p className="font-pt-sans text-[13px] italic leading-[15px] text-red-95 mt-[5px] mb-0">You have not selected an inbound transfer product yet.</p>
            )}
          </>
        )}
        {(oneWayInTransfer || oneWayOutTransfer) && (
          <>
            <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">ONE WAY (OUT)</p>
            {oneWayOutTransfer ? (
              <p className="rhp-transfer m-0 mt-[5px]">{getProductName(oneWayOutTransfer)}</p>
            ) : (
              <p className="font-pt-sans text-[13px] italic leading-[15px] text-red-95 mt-[5px] mb-0">You have not selected an outbound transfer product yet.</p>
            )}
          </>
        )}
        {returnTransfer && (
          <>
            <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">RETURN TRANSFER</p>
            <p className="rhp-transfer m-0 mt-[5px]">{getProductName(returnTransfer)}</p>
          </>
        )}

        {products.length === 0 && (
          <p className="font-pt-sans text-[15px] italic leading-[normal] text-gray-100 mt-[5px] mb-0">None added</p>
        )}

        {isMandatory && <p className="font-pt-sans text-[13px] italic leading-[15px] text-red-95 mt-[5px] mb-0">You have not selected Transfer product(s) yet.</p>}
      </div>
  )
}
