import React from 'react';
import { useSelector } from "react-redux";
import { BookingBuilderResponse, ENetworkRequestStatus } from "services/BackendApi";
import { BAMSelectedFinesSelector, BAMSelectedGroundServicesSelector, BAMSelectedMealPlansSelector, BAMSelectedOccasionsSelector, BAMSelectedOtherItemsSelector, BAMSelectedRepeatGuestSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { CheckmarkComponent, MealPlanComponent } from 'ui/Icons';
import { RHPTransfers } from './RHPTransfers';
import { RHPProducts } from './RHPProducts';

export interface IRHPPageAddonsProps {
  selectedBuild: BookingBuilderResponse;
  currencySymbol: string;
  bookingBuildRequest: ENetworkRequestStatus;
  bookingUuid: string;
}

export const RHPPageAddons: React.FC<IRHPPageAddonsProps> = ({ selectedBuild, bookingBuildRequest, bookingUuid, currencySymbol }) => {
  // const dispatch = useDispatch();
  const selectedMealPlan = useSelector(BAMSelectedMealPlansSelector);
  // const selectedAccommodation = selectedBuild.availableProductSets.Accommodation[0];
  const selectedOccasions = useSelector(BAMSelectedOccasionsSelector);
  const selectedOccasionsAsText = selectedOccasions.map(item => item[0].toUpperCase() + item.slice(1)).join(' & ');
  const isRepeatGuest = useSelector(BAMSelectedRepeatGuestSelector);

  const selectedTransfers = selectedBuild.availableProductSets['Transfer'].filter(
    item => item.selected
  ).map(item => ({ uuid: item.products[0].uuid, name: item.products[0].name, direction: item.meta?.direction ?? 'return' }));
  const errors = selectedBuild.errors as unknown as { type: string; meta: string; message: string; }[];
  const areTransfersMandatory = !!errors.find(
    item => item.message === 'You have not selected Transfer product(s) yet'
  );
  const selectedGroundServices = selectedBuild.availableProductSets['Ground Service'].filter(
    item => item.selected
  ).map(item => ({ uuid: item.products[0].uuid, name: item.products[0].name}));
  const selectedOtherItems = selectedBuild.availableProductSets['Supplement'].filter(
    item => item.selected
  ).map(item => ({ uuid: item.products[0].uuid, name: item.products[0].name}));
  const selectedFines = selectedBuild.availableProductSets['Fine'].filter(
    item => item.selected
  ).map(item => ({ uuid: item.products[0].uuid, name: item.products[0].name}));
  
  return (
    <div className="flex flex-col gap-[20px] mt-[10px]">
      <div className="flex flex-col gap-[5px] mt-[10px]">
        <div className="meal-plan flex items-center gap-[5px]">
          <MealPlanComponent />
          <span className="font-pt-sans text-[15px] leading-[normal]">{selectedMealPlan?.mealPlan.names.join(' & ')}</span>
        </div>
        {selectedOccasionsAsText.length > 0 && (
          <div className="occasions flex items-center gap-[5px]">
            <CheckmarkComponent />
            <span className="font-pt-sans text-[15px] leading-[normal]">Occasion(s): {selectedOccasionsAsText}</span>
          </div>
        )}
        {isRepeatGuest && (
          <div className="is-repeat-guest flex items-center gap-[5px]">
            <CheckmarkComponent />
            <span className="font-pt-sans text-[15px] leading-[normal]">Repeat Guest</span>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-[20px]">
        <RHPTransfers
          isMandatory={areTransfersMandatory}
          products={selectedTransfers}
          isVisible={selectedTransfers.length > 0 || areTransfersMandatory}
        />

        <RHPProducts
          title="Ground Services"
          products={selectedGroundServices}
          isVisible={selectedGroundServices.length > 0}
        />

        <RHPProducts
          title="Other Items"
          products={selectedOtherItems}
          isVisible={selectedOtherItems.length > 0}
        />

        <RHPProducts
          title="Fines"
          products={selectedFines}
          isVisible={selectedFines.length > 0}
        />
      </div>
    </div>
  )
}