import { useEffect, useState } from 'react';
import { ENetworkRequestStatus } from 'services/BackendApi';
import * as _ from 'lodash-es';

export const useApiRequest = <TFuncDef extends (...args: any[]) => any>(
  apiClientFunction: TFuncDef,
  functionArgs?: Parameters<TFuncDef>
) => {
  type TResponseType = Awaited<ReturnType<TFuncDef>>;

  const [response, setResponse] = useState<TResponseType>();
  const [status, setStatus] = useState<number | undefined>();
  const [requestStatus, setRequestStatus] = useState(ENetworkRequestStatus.IDLE);

  useEffect(() => {
    const fetchData = async () => {
      setRequestStatus(ENetworkRequestStatus.PENDING);

      try {
        const returnValue = ((await apiClientFunction(...(functionArgs || []))) as unknown) as TResponseType;
        setResponse(returnValue);
        setRequestStatus(ENetworkRequestStatus.SUCCESS);

        // behind an if in case a non-API client function is passed in
        if ((returnValue as any).status) {
          setStatus((returnValue as any).status);
        }
      } catch (error) {
        setRequestStatus(ENetworkRequestStatus.ERROR);

        // behind an if in case a non-API client function is passed in
        if (error.response) {
          setResponse(error.response);
          if (error.response.status) {
            setStatus(error.response.status);
          }
        }
      }
    };

    fetchData();
  }, [...(functionArgs || [])]);

  return { response, requestStatus, status };
};
