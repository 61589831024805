import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import classnames from 'classnames';
import { BookingBuilderResponse, ENetworkRequestStatus, IAvailableProduct, Totals } from "services/BackendApi";
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { useDispatch, useSelector } from "react-redux";
import { HidingTooltip } from "ui/Tooltip";
import { SvgIcon } from "ui/SvgIcon";
import { InfoTransparentIcon } from "ui/Icons/components/InfoTransparent.component";
import { theme } from "../../../../tailwind.config";
import { AccommodationModalPrice } from "../AccommodationModalPrice";
import { getCurrencyBySymbol } from "utils";
import AnimateHeight from "react-animate-height";
import Checkbox from "ui/Checkbox";
import { addBAMSelectedFineAction, addBAMSelectedGroundServiceAction, addBAMSelectedOtherItemAction, bamBookingBuildRequestAction } from "store/modules/bookingManager/subdomains/breakdown/actions";

export interface ILHPProductLineProps {
  currencySymbol: string;
  product: IAvailableProduct;
  isSelected: boolean;
  onChoose: (product: IAvailableProduct) => void;
}

export const LHPProductLine: React.FC<ILHPProductLineProps> = ({ product, onChoose, currencySymbol, isSelected }) => {
  const totals: Totals = {
    oneOrMoreItemsOnRequest: product.isOnRequestOrPartiallyOnRequest,
    total: product.total,
    totalBeforeDiscount: product.totalBeforeDiscount,
  } as unknown as Totals;

  const renderTooltip = useCallback(() => {
    return (
      <div className="bam-tooltip text-13px leading-[17px] text-black px-[8px] py-[5px] shadow-pe6 normal-case text-left w-[300px]">
        {product.breakdown[0].cancellationPolicy && (
          <>
            <p className="font-bold">Cancellation Policy:</p>
            <p className="font-normal">{product.breakdown[0].cancellationPolicy}</p>
          </>
        )}
        {product.breakdown[0].paymentTerms && (
          <>
            <p className="font-bold">Payment Terms:</p>
            <p className="font-normal">{product.breakdown[0].paymentTerms}</p>
          </>
        )}
      </div>
    );
  }, []);

  const handleChoose = useCallback(() => {
    onChoose(product);
  }, [onChoose]);

  return (
    <div className="lhp-product-content flex justify-between items-center gap-[40px] min-h-[40px]" >
      <div className="lhp-product-left" onClick={handleChoose}>
        <div className="inline-flex">
          <Checkbox checked={isSelected} />
        </div>
        <span className="product text-black font-pt-sans ml-[8px] cursor-pointer text-[15px]">
          {product.products[0].name}
        </span>
      </div>
      <div className={classnames('lhp-product-right flex items-center justify-end gap-[20px] h-full ml-[-16px] pr-[5px] pl-[16px]')}>
        <AccommodationModalPrice
          totals={totals}
          currencyCode={getCurrencyBySymbol(currencySymbol).code}
        />
        {(product.breakdown[0].paymentTerms || product.breakdown[0].cancellationPolicy) ? (
          <HidingTooltip renderTooltipContent={renderTooltip} position="left-top">
            <span className="cursor-pointer flex">
              <SvgIcon
                IconComponent={InfoTransparentIcon}
                defaultFill={theme.colors['gray-40']}
                hoverFill={theme.colors['gray-80']}
                activeFill={theme.colors['gray-40']}
                width="18px"
                height="18px"
              />
            </span>
          </HidingTooltip>
        ) : <div className="min-w-[18px] min-h-[18px]"></div>}
      </div>
    </div>
  )
}

export interface ILHPProductsProps {
  title: string;
  currencySymbol: string;
  products: IAvailableProduct[];
  selectedBuild: BookingBuilderResponse;
  bookingUuid: string;
}

export const LHPProducts: React.FC<ILHPProductsProps> = ({ title, products, bookingUuid, selectedBuild, currencySymbol }) => {
  const dispatch = useDispatch();
  const productSelectorMapping = {
    'Ground Services': BreakdownSelectors.BAMSelectedGroundServicesSelector,
    'Other Items': BreakdownSelectors.BAMSelectedOtherItemsSelector,
    'Fines': BreakdownSelectors.BAMSelectedFinesSelector,
  };
  const selector = productSelectorMapping[title];
  const selectedProducts = useSelector(selector) as string[];
  const searchAccommodationsRequest = useSelector(BreakdownSelectors.BAMSearchAccommodationsRequestSelector);
  const selectedAccommodation = selectedBuild.availableProductSets.Accommodation[0];
  const [isExpanded, setIsExpanded] = useState(false);

  useLayoutEffect(() => {
    const productActionMapping = {
      'Ground Services': addBAMSelectedGroundServiceAction,
      'Other Items': addBAMSelectedOtherItemAction,
      'Fines': addBAMSelectedFineAction,
    };
    const action = productActionMapping[title];
    if (action) {
      products.map(product => {
        if (product.selected && !selectedProducts.includes(product.products[0].uuid)) {
          dispatch(action(product));
        }
      })
    }
  }, [products]);

  const handleChoose = useCallback((product: IAvailableProduct) => {
    const productActionMapping = {
      'Ground Services': addBAMSelectedGroundServiceAction,
      'Other Items': addBAMSelectedOtherItemAction,
      'Fines': addBAMSelectedFineAction,
    };
    const action = productActionMapping[title];
    if (action) {
      dispatch(action(product));
      dispatch(bamBookingBuildRequestAction(bookingUuid))
    }
  }, [title]);

  const toggleSection = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  if (searchAccommodationsRequest === ENetworkRequestStatus.PENDING) {
    return null;
  }

  return (
    <div className="lhp-product  px-[15px] border border-solid border-gray-20 pb-[10px]">
      <div className="lhp-product-heading flex items-center gap-[8px] cursor-pointer h-[72px]" onClick={toggleSection}>
        <span
          className={classnames('w-5 transition-transform bg-none flex justify-around cursor-pointer', {
            'transform rotate-90': isExpanded,
          })}
        >
          <i className="fas fa-chevron-right" />
        </span>
        <span className={classnames('font-pt-sans flex gap-[5px]', { 'font-bold': isExpanded })}>
          {title}
        </span>
      </div>
      <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0}>
        <div className="">
          {products.map(item => (
            <LHPProductLine
              currencySymbol={currencySymbol}
              onChoose={handleChoose}
              isSelected={selectedProducts.includes(item.products[0].uuid)}
              product={item}
            />
          ))}
        </div>
      </AnimateHeight>
    </div>
  )
}
