import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import classnames from 'classnames';
import { BookingBuilderResponse, ENetworkRequestStatus, IAvailableTransfer, Totals } from "services/BackendApi";
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "ui/Radio";
import { HidingTooltip } from "ui/Tooltip";
import { SvgIcon } from "ui/SvgIcon";
import { InfoTransparentIcon } from "ui/Icons/components/InfoTransparent.component";
import { theme } from "../../../../tailwind.config";
import { AccommodationModalPrice } from "../AccommodationModalPrice";
import { getCurrencyBySymbol } from "utils";
import AnimateHeight from "react-animate-height";
import { addBAMSelectedTransferAction, bamBookingBuildRequestAction, resetBAMSelectedTransferAction } from "store/modules/bookingManager/subdomains/breakdown/actions";
import { IBAMTransfer } from "services/BookingManagerApi";

export interface ILHPProductLineProps {
  currencySymbol: string;
  product: IAvailableTransfer;
  isSelected: boolean;
  onChoose: (product: IAvailableTransfer) => void;
}

export const LHPProductLine: React.FC<ILHPProductLineProps> = ({ product, onChoose, currencySymbol, isSelected }) => {
  const totals: Totals = useMemo(() => ({
    oneOrMoreItemsOnRequest: product.isOnRequestOrPartiallyOnRequest,
    total: product.total,
    totalBeforeDiscount: product.totalBeforeDiscount,
  } as unknown as Totals), [product]);

  const directionMapping = {
    in: '(IN)',
    out: '(OUT)',
    return: '',
  }
  const productName = [product.products[0].name, directionMapping[product.meta?.direction]].filter(Boolean).join(' ');
  const renderTooltip = useCallback(() => {
    return (
      <div className="bam-tooltip text-13px leading-[17px] text-black px-[8px] py-[5px] shadow-pe6 normal-case text-left w-[300px]">
        {product.breakdown[0].cancellationPolicy && (
          <>
            <p className="font-bold">Cancellation Policy:</p>
            <p className="font-normal">{product.breakdown[0].cancellationPolicy}</p>
          </>
        )}
        {product.breakdown[0].paymentTerms && (
          <>
            <p className="font-bold">Payment Terms:</p>
            <p className="font-normal">{product.breakdown[0].paymentTerms}</p>
          </>
        )}
      </div>
    );
  }, []);

  const handleChoose = useCallback(() => {
    onChoose(product);
  }, [onChoose]);

  return (
    <div className="lhp-product-content flex justify-between items-center min-h-[40px]" >
      <div className="lhp-product-left" onClick={handleChoose}>
        <div className="inline-flex">
          <Radio checked={isSelected} />
        </div>
        <span className="product text-black font-pt-sans ml-[8px] cursor-pointer text-[15px]">
          {productName}
        </span>
      </div>
      <div className={classnames('lhp-product-right flex items-center justify-end gap-[20px] h-full ml-[-16px] pr-[5px] pl-[16px]')}>
        <AccommodationModalPrice
          totals={totals}
          currencyCode={getCurrencyBySymbol(currencySymbol).code}
        />
        {(product.breakdown[0].paymentTerms || product.breakdown[0].cancellationPolicy) ? (
          <HidingTooltip renderTooltipContent={renderTooltip} position="left-top">
            <span className="cursor-pointer flex">
              <SvgIcon
                IconComponent={InfoTransparentIcon}
                defaultFill={theme.colors['gray-40']}
                hoverFill={theme.colors['gray-80']}
                activeFill={theme.colors['gray-40']}
                width="18px"
                height="18px"
              />
            </span>
          </HidingTooltip>
        ) : <div className="min-w-[18px] min-h-[18px]"></div>}
      </div>
    </div>
  )
}

export interface ILHPTransfersProps {
  title: string;
  currencySymbol: string;
  products: IAvailableTransfer[];
  selectedBuild: BookingBuilderResponse;
  bookingUuid: string;
}

enum ETransferGrouping {
  RETURN = 'RETURN',
  ONE_WAY = 'ONE_WAY',
  NONE = 'NONE',
}

export interface ILHPTransfersGroupingProps {
  transferGrouping: ETransferGrouping;
  onChoose: (transferGrouping: ETransferGrouping) => void;
}

const LHPTransfersGrouping: React.FC<ILHPTransfersGroupingProps> = ({ transferGrouping, onChoose }) => {

  return (
    <div className="lhp-transfer-grouping flex gap-[20px] justify-start items-center mb-[25px]" >
        <div className="inline-flex" onClick={() => onChoose(ETransferGrouping.RETURN)}>
          <Radio checked={transferGrouping === ETransferGrouping.RETURN} />
          <span className="product text-black font-pt-sans ml-[8px] cursor-pointer text-[15px]">
            Return
          </span>
        </div>
        
        <div className="inline-flex" onClick={() => onChoose(ETransferGrouping.ONE_WAY)}>
          <Radio checked={transferGrouping === ETransferGrouping.ONE_WAY} />
          <span className="product text-black font-pt-sans ml-[8px] cursor-pointer text-[15px]">
            One Way
          </span>
        </div>

        <div className="inline-flex" onClick={() => onChoose(ETransferGrouping.NONE)}>
          <Radio checked={transferGrouping === ETransferGrouping.NONE} />
          <span className="product text-black font-pt-sans ml-[8px] cursor-pointer text-[15px]">
            None
          </span>
        </div>
    </div>
  )
}

export const LHPTransfers: React.FC<ILHPTransfersProps> = ({ title, products, bookingUuid, selectedBuild, currencySymbol }) => {
  const dispatch = useDispatch();
  const returnTransfers = products.filter(item => item?.meta === undefined);
  const inTransfers = products.filter(item => item?.meta?.direction === 'in');
  const outTransfers = products.filter(item => item?.meta?.direction === 'out');
  const selectedProducts = useSelector(BreakdownSelectors.BAMSelectedTransfersSelector) as IBAMTransfer[];
  const searchAccommodationsRequest = useSelector(BreakdownSelectors.BAMSearchAccommodationsRequestSelector);
  const [transferGrouping, setTransferGrouping] = useState<ETransferGrouping>(ETransferGrouping.NONE);
  const [isExpanded, setIsExpanded] = useState(true);
  
  useLayoutEffect(() => {
    products.map(product => {
      if (product.selected && !selectedProducts.find(item => item.uuid === product.products[0].uuid)) {
        dispatch(addBAMSelectedTransferAction(product));
      }
    })
  }, [products]);

  const handleTransferGroupingChoose = useCallback((transferGrouping: ETransferGrouping) => {
    setTransferGrouping(transferGrouping);
    dispatch(resetBAMSelectedTransferAction());
    dispatch(bamBookingBuildRequestAction(bookingUuid))
  }, [bookingUuid]);

  const handleChoose = useCallback((product: IAvailableTransfer) => {
    dispatch(addBAMSelectedTransferAction(product));
    dispatch(bamBookingBuildRequestAction(bookingUuid))
  }, [title]);

  const toggleSection = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  if (searchAccommodationsRequest === ENetworkRequestStatus.PENDING) {
    return null;
  }

  return (
    <div className="lhp-product  px-[15px] border border-solid border-gray-20">
      <div className="lhp-product-heading flex items-center gap-[8px] cursor-pointer h-[72px]" onClick={toggleSection}>
        <span
          className={classnames('w-5 transition-transform bg-none flex justify-around cursor-pointer', {
            'transform rotate-90': isExpanded,
          })}
        >
          <i className="fas fa-chevron-right" />
        </span>
        <span className={classnames('font-pt-sans flex gap-[5px]', { 'font-bold': isExpanded })}>
          {title}
        </span>
      </div>
      <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0}>
        <div className="">
          <LHPTransfersGrouping transferGrouping={transferGrouping} onChoose={handleTransferGroupingChoose} />
          {transferGrouping === ETransferGrouping.RETURN && returnTransfers.length > 0 && (
            <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">RETURN TRANSFER</p>
          )}
          {transferGrouping === ETransferGrouping.RETURN && returnTransfers.map(item => (
            <LHPProductLine
              currencySymbol={currencySymbol}
              onChoose={handleChoose}
              isSelected={!!selectedProducts.find(product => product.uuid === item.products[0].uuid)}
              product={item}
            />
          ))}
          {transferGrouping === ETransferGrouping.ONE_WAY && inTransfers.length > 0 && (
            <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">ONE WAY (IN)</p>
          )}
          {transferGrouping === ETransferGrouping.ONE_WAY && inTransfers.map(item => (
            <LHPProductLine
              currencySymbol={currencySymbol}
              onChoose={handleChoose}
              isSelected={!!selectedProducts.find(product => product.uuid === item.products[0].uuid && product.direction === item.meta.direction)}
              product={item}
            />
          ))}
          {transferGrouping === ETransferGrouping.ONE_WAY && outTransfers.length > 0 && (
            <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">ONE WAY (OUT)</p>
          )}
          {transferGrouping === ETransferGrouping.ONE_WAY && outTransfers.map(item => (
            <LHPProductLine
              currencySymbol={currencySymbol}
              onChoose={handleChoose}
              isSelected={!!selectedProducts.find(product => product.uuid === item.products[0].uuid && product.direction === item.meta.direction)}
              product={item}
            />
          ))}
        </div>
      </AnimateHeight>
    </div>
  )
}
