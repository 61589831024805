import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { SharedLinkPage } from './';

export interface SharedLinkRoutingProps extends RouteComponentProps {}

export const SharedLinkRouting = (props: SharedLinkRoutingProps) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} component={SharedLinkPage} exact />
      <Route path={`${props.match.path}/:sharedLinkUuid`} component={SharedLinkPage} exact />
    </Switch>
  );
};
