import { TCurrencyCode } from 'interfaces';
import {
  IBookingLedgerSortableItems,
  IDepositAccountResponse,
  IDepositAccountSortableItems,
  IManualBookingLedgerRowResponse,
  IDepositAccountRow,
  IDepositAccountRowWithBalance,
  IStatementPdf,
  IPaymentMethod,
  IPaymentMethodPerCurrency,
} from 'services/BookingManagerApi';
import {
  IBookingLedger,
  IBookingLedgerPageRow,
  IBookingLedgerRow,
  IDepositAccountRowForSave,
  ILedgerTotals,
  IManualBookingLedgerRowForSave,
} from './model';

export const SELECT_COMPANY = 'ledger/SELECT_COMPANY';

export const BOOKING_STATEMENT_TOTALS_REQUEST = 'ledger/BOOKING_STATEMENT_TOTALS_REQUEST';
export const BOOKING_STATEMENT_TOTALS_SUCCESS = 'ledger/BOOKING_STATEMENT_TOTALS_SUCCESS';
export const BOOKING_STATEMENT_TOTALS_FAILURE = 'ledger/BOOKING_STATEMENT_TOTALS_FAILURE';

export const DEPOSIT_STATEMENT_TOTALS_REQUEST = 'ledger/DEPOSIT_STATEMENT_TOTALS_REQUEST';
export const DEPOSIT_STATEMENT_TOTALS_SUCCESS = 'ledger/DEPOSIT_STATEMENT_TOTALS_SUCCESS';
export const DEPOSIT_STATEMENT_TOTALS_FAILURE = 'ledger/DEPOSIT_STATEMENT_TOTALS_FAILURE';

export const BOOKING_LEDGER_REQUEST = 'ledger/BOOKING_LEDGER_REQUEST';
export const BOOKING_LEDGER_SUCCESS = 'ledger/BOOKING_LEDGER_SUCCESS';
export const BOOKING_LEDGER_FAILURE = 'ledger/BOOKING_LEDGER_FAILURE';
export const BOOKING_LEDGER_SET_SORT = 'ledger/BOOKING_LEDGER_SET_SORT';

export const BOOKING_LEDGER_SET_PAGE_NUMBER = 'ledger/BOOKING_LEDGER_SET_PAGE_NUMBER';
export const BOOKING_LEDGER_SET_ITEMS_PER_PAGE = 'ledger/BOOKING_LEDGER_SET_ITEMS_PER_PAGE';

export const BOOKING_LEDGER_RESET = 'ledger/BOOKING_LEDGER_RESET';

export const DEPOSIT_ACCOUNT_REQUEST = 'ledger/DEPOSIT_ACCOUNT_REQUEST';
export const DEPOSIT_ACCOUNT_SUCCESS = 'ledger/DEPOSIT_ACCOUNT_SUCCESS';
export const DEPOSIT_ACCOUNT_FAILURE = 'ledger/DEPOSIT_ACCOUNT_FAILURE';
export const DEPOSIT_ACCOUNT_SET_SORT = 'ledger/DEPOSIT_ACCOUNT_SET_SORT';
export const DEPOSIT_ACCOUNT_SET_PAGE_NUMBER = 'ledger/DEPOSIT_ACCOUNT_SET_PAGE_NUMBER';
export const DEPOSIT_ACCOUNT_SET_ITEMS_PER_PAGE = 'ledger/DEPOSIT_ACCOUNT_SET_ITEMS_PER_PAGE';
export const DEPOSIT_ACCOUNT_RESET = 'ledger/DEPOSIT_ACCOUNT_RESET';

export const BOOKING_LEDGER_POST_EXPORT_REQUEST = 'ledger/BOOKING_LEDGER_POST_EXPORT_REQUEST';
export const BOOKING_LEDGER_POST_EXPORT_SUCCESS = 'ledger/BOOKING_LEDGER_POST_EXPORT_SUCCESS';
export const BOOKING_LEDGER_POST_EXPORT_FAILURE = 'ledger/BOOKING_LEDGER_POST_EXPORT_FAILURE';

export const BOOKING_LEDGER_GET_EXPORT_REQUEST = 'ledger/BOOKING_LEDGER_GET_EXPORT_REQUEST';
export const BOOKING_LEDGER_GET_EXPORT_SUCCESS = 'ledger/BOOKING_LEDGER_GET_EXPORT_SUCCESS';
export const BOOKING_LEDGER_GET_EXPORT_FAILURE = 'ledger/BOOKING_LEDGER_GET_EXPORT_FAILURE';

export const BOOKING_LEDGER_POST_AGGREGATE_EXPORT_REQUEST = 'ledger/BOOKING_LEDGER_POST_AGGREGATE_EXPORT_REQUEST';
export const BOOKING_LEDGER_POST_AGGREGATE_EXPORT_SUCCESS = 'ledger/BOOKING_LEDGER_POST_AGGREGATE_EXPORT_SUCCESS';
export const BOOKING_LEDGER_POST_AGGREGATE_EXPORT_FAILURE = 'ledger/BOOKING_LEDGER_POST_AGGREGATE_EXPORT_FAILURE';

export const BOOKING_LEDGER_GET_AGGREGATE_EXPORT_REQUEST = 'ledger/BOOKING_LEDGER_GET_AGGREGATE_EXPORT_REQUEST';
export const BOOKING_LEDGER_GET_AGGREGATE_EXPORT_SUCCESS = 'ledger/BOOKING_LEDGER_GET_AGGREGATE_EXPORT_SUCCESS';
export const BOOKING_LEDGER_GET_AGGREGATE_EXPORT_FAILURE = 'ledger/BOOKING_LEDGER_GET_AGGREGATE_EXPORT_FAILURE';

export const BOOKING_LEDGER_GENERATE_PDF_REQUEST = 'ledger/BOOKING_LEDGER_GENERATE_PDF_REQUEST';
export const BOOKING_LEDGER_GENERATE_PDF_SUCCESS = 'ledger/BOOKING_LEDGER_GENERATE_PDF_SUCCESS';
export const BOOKING_LEDGER_GENERATE_PDF_FAILURE = 'ledger/BOOKING_LEDGER_GENERATE_PDF_FAILURE';

export const BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_REQUEST = 'ledger/BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_REQUEST';
export const BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_SUCCESS = 'ledger/BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_SUCCESS';
export const BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_FAILURE = 'ledger/BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_FAILURE';

export const DEPOSIT_ACCOUNT_GENERATE_PDF_REQUEST = 'ledger/DEPOSIT_ACCOUNT_GENERATE_PDF_REQUEST';
export const DEPOSIT_ACCOUNT_GENERATE_PDF_SUCCESS = 'ledger/DEPOSIT_ACCOUNT_GENERATE_PDF_SUCCESS';
export const DEPOSIT_ACCOUNT_GENERATE_PDF_FAILURE = 'ledger/DEPOSIT_ACCOUNT_GENERATE_PDF_FAILURE';

export const DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_REQUEST = 'ledger/DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_REQUEST';
export const DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_SUCCESS = 'ledger/DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_SUCCESS';
export const DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_FAILURE = 'ledger/DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_FAILURE';

export const SUMMARY_GENERATE_PDF_REQUEST = 'ledger/SUMMARY_GENERATE_PDF_REQUEST';
export const SUMMARY_GENERATE_PDF_SUCCESS = 'ledger/SUMMARY_GENERATE_PDF_SUCCESS';
export const SUMMARY_GENERATE_PDF_FAILURE = 'ledger/SUMMARY_GENERATE_PDF_FAILURE';

export const SUMMARY_DOWNLOAD_PDF_HISTORY_REQUEST = 'ledger/SUMMARY_DOWNLOAD_PDF_HISTORY_REQUEST';
export const SUMMARY_DOWNLOAD_PDF_HISTORY_SUCCESS = 'ledger/SUMMARY_DOWNLOAD_PDF_HISTORY_SUCCESS';
export const SUMMARY_DOWNLOAD_PDF_HISTORY_FAILURE = 'ledger/SUMMARY_DOWNLOAD_PDF_HISTORY_FAILURE';

// UI stuff
export const STATEMENT_FILTER_SET_FROM_DATE = 'ledger/STATEMENT_FILTER_SET_FROM_DATE';
export const STATEMENT_FILTER_SET_TO_DATE = 'ledger/STATEMENT_FILTER_SET_TO_DATE';
export const STATEMENT_FILTER_SET_TYPE = 'ledger/STATEMENT_FILTER_SET_TYPE';
export const STATEMENT_FILTER_SET_STATUS = 'ledger/STATEMENT_FILTER_SET_STATUS';
export const STATEMENT_FILTER_ADD_BOOKING_REF = 'ledger/STATEMENT_FILTER_ADD_BOOKING_REF';
export const STATEMENT_FILTER_SET_BOOKING_REF = 'ledger/STATEMENT_FILTER_SET_BOOKING_REF';
export const STATEMENT_FILTER_DELETE_BOOKING_REF = 'ledger/STATEMENT_FILTER_DELETE_BOOKING_REF';
export const STATEMENT_FILTER_RESET = 'ledger/STATEMENT_FILTER_RESET';
export const STATEMENT_FILTER_UPDATE_RESULTS = 'ledger/STATEMENT_FILTER_UPDATE_RESULTS';

export const STATEMENT_FILTER_SET_AMOUNT_MIN = 'ledger/STATEMENT_FILTER_SET_AMOUNT_MIN';
export const STATEMENT_FILTER_SET_AMOUNT_MAX = 'ledger/STATEMENT_FILTER_SET_AMOUNT_MAX';

export const DEPOSIT_FILTER_SET_FROM_DATE = 'ledger/DEPOSIT_FILTER_SET_FROM_DATE';
export const DEPOSIT_FILTER_SET_TO_DATE = 'ledger/DEPOSIT_FILTER_SET_TO_DATE';
export const DEPOSIT_FILTER_SET_STATUS = 'ledger/DEPOSIT_FILTER_SET_STATUS';
export const DEPOSIT_FILTER_SET_BOOKING_REF = 'ledger/DEPOSIT_FILTER_SET_BOOKING_REF';
export const DEPOSIT_FILTER_RESET = 'ledger/DEPOSIT_FILTER_RESET';
export const DEPOSIT_FILTER_UPDATE_RESULTS = 'ledger/DEPOSIT_FILTER_UPDATE_RESULTS';

export const DEPOSIT_ACCOUNT_POST_EXPORT_REQUEST = 'ledger/DEPOSIT_ACCOUNT_POST_EXPORT_REQUEST';
export const DEPOSIT_ACCOUNT_POST_EXPORT_SUCCESS = 'ledger/DEPOSIT_ACCOUNT_POST_EXPORT_SUCCESS';
export const DEPOSIT_ACCOUNT_POST_EXPORT_FAILURE = 'ledger/DEPOSIT_ACCOUNT_POST_EXPORT_FAILURE';

export const DEPOSIT_ACCOUNT_GET_EXPORT_REQUEST = 'ledger/DEPOSIT_ACCOUNT_GET_EXPORT_REQUEST';
export const DEPOSIT_ACCOUNT_GET_EXPORT_SUCCESS = 'ledger/DEPOSIT_ACCOUNT_GET_EXPORT_SUCCESS';
export const DEPOSIT_ACCOUNT_GET_EXPORT_FAILURE = 'ledger/DEPOSIT_ACCOUNT_GET_EXPORT_FAILURE';

export const DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_REQUEST = 'ledger/DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_REQUEST';
export const DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_SUCCESS = 'ledger/DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_SUCCESS';
export const DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_FAILURE = 'ledger/DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_FAILURE';

export const DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_REQUEST = 'ledger/DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_REQUEST';
export const DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_SUCCESS = 'ledger/DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_SUCCESS';
export const DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_FAILURE = 'ledger/DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_FAILURE';

export const OPEN_BALANCE_CARRIED_FORWARD_MODAL = 'ledger/OPEN_BALANCE_CARRIED_FORWARD_MODAL';
export const CLOSE_BALANCE_CARRIED_FORWARD_MODAL = 'ledger/CLOSE_BALANCE_CARRIED_FORWARD_MODAL';

export const CHECK_INITIAL_BCF_REQUEST = 'ledger/CHECK_INITIAL_BCF_REQUEST';
export const CHECK_INITIAL_BCF_SUCCESS = 'ledger/CHECK_INITIAL_BCF_SUCCESS';
export const CHECK_INITIAL_BCF_FAILURE = 'ledger/CHECK_INITIAL_BCF_FAILURE';

export const SAVE_BCF_ROW_REQUEST = 'ledger/SAVE_BCF_ROW_REQUEST';
export const SAVE_BCF_ROW_SUCCESS = 'ledger/SAVE_BCF_ROW_SUCCESS';
export const SAVE_BCF_ROW_FAILURE = 'ledger/SAVE_BCF_ROW_FAILURE';

export const OPEN_DEPOSIT_ACCOUNT_MODAL = 'ledger/OPEN_DEPOSIT_ACCOUNT_MODAL';
export const CLOSE_DEPOSIT_ACCOUNT_MODAL = 'ledger/CLOSE_DEPOSIT_ACCOUNT_MODAL';
export const DEPOSIT_CHECK_INITIAL_BCF_REQUEST = 'ledger/DEPOSIT_CHECK_INITIAL_BCF_REQUEST';
export const DEPOSIT_CHECK_INITIAL_BCF_SUCCESS = 'ledger/DEPOSIT_CHECK_INITIAL_BCF_SUCCESS';
export const DEPOSIT_CHECK_INITIAL_BCF_FAILURE = 'ledger/DEPOSIT_CHECK_INITIAL_BCF_FAILURE';
export const DEPOSIT_SAVE_ROW_REQUEST = 'ledger/DEPOSIT_SAVE_ROW_REQUEST';
export const DEPOSIT_SAVE_ROW_SUCCESS = 'ledger/DEPOSIT_SAVE_ROW_SUCCESS';
export const DEPOSIT_SAVE_ROW_FAILURE = 'ledger/DEPOSIT_SAVE_ROW_FAILURE';
export const DEPOSIT_DELETE_ROW_REQUEST = 'ledger/DEPOSIT_DELETE_ROW_REQUEST';
export const DEPOSIT_DELETE_ROW_SUCCESS = 'ledger/DEPOSIT_DELETE_ROW_SUCCESS';
export const DEPOSIT_DELETE_ROW_FAILURE = 'ledger/DEPOSIT_DELETE_ROW_FAILURE';
export const SET_DEPOSIT_ACCOUNT_ROW_TO_DELETE = 'ledger/SET_DEPOSIT_ACCOUNT_ROW_TO_DELETE';
export const SET_DAR_DELETE_MODAL_OPEN = 'ledger/SET_DAR_DELETE_MODAL_OPEN';

export const GET_DEPOSIT_PAYMENT_METHODS_REQUEST = 'ledger/GET_DEPOSIT_PAYMENT_METHODS_REQUEST';
export const GET_DEPOSIT_PAYMENT_METHODS_SUCCESS = 'ledger/GET_DEPOSIT_PAYMENT_METHODS_SUCCESS';
export const GET_DEPOSIT_PAYMENT_METHODS_FAILURE = 'ledger/GET_DEPOSIT_PAYMENT_METHODS_FAILURE';

export type SelectCompanyAction = ReturnType<typeof selectCompanyAction>;
export const selectCompanyAction = (company: string) => ({
  type: SELECT_COMPANY as typeof SELECT_COMPANY,
  company,
});

export type DepositStatementTotalsRequestAction = ReturnType<typeof depositStatementTotalsRequestAction>;
export const depositStatementTotalsRequestAction = (company: string) => ({
  type: DEPOSIT_STATEMENT_TOTALS_REQUEST as typeof DEPOSIT_STATEMENT_TOTALS_REQUEST,
  company,
});

export type DepositStatementTotalsSuccessAction = ReturnType<typeof depositStatementTotalsSuccessAction>;
export const depositStatementTotalsSuccessAction = (totals: ILedgerTotals[]) => ({
  type: DEPOSIT_STATEMENT_TOTALS_SUCCESS as typeof DEPOSIT_STATEMENT_TOTALS_SUCCESS,
  totals,
});

export type DepositStatementTotalsFailureAction = ReturnType<typeof depositStatementTotalsFailureAction>;
export const depositStatementTotalsFailureAction = (error: Error) => ({
  type: DEPOSIT_STATEMENT_TOTALS_FAILURE as typeof DEPOSIT_STATEMENT_TOTALS_FAILURE,
  error,
});

export type BookingStatementTotalsRequestAction = ReturnType<typeof bookingStatementTotalsRequestAction>;
export const bookingStatementTotalsRequestAction = (company: string) => ({
  type: BOOKING_STATEMENT_TOTALS_REQUEST as typeof BOOKING_STATEMENT_TOTALS_REQUEST,
  company,
});

export type BookingStatementTotalsSuccessAction = ReturnType<typeof bookingStatementTotalsSuccessAction>;
export const bookingStatementTotalsSuccessAction = (totals: ILedgerTotals[]) => ({
  type: BOOKING_STATEMENT_TOTALS_SUCCESS as typeof BOOKING_STATEMENT_TOTALS_SUCCESS,
  totals,
});

export type BookingStatementTotalsFailureAction = ReturnType<typeof bookingStatementTotalsFailureAction>;
export const bookingStatementTotalsFailureAction = (error: Error) => ({
  type: BOOKING_STATEMENT_TOTALS_FAILURE as typeof BOOKING_STATEMENT_TOTALS_FAILURE,
  error,
});

export type BookingLedgerRequestAction = ReturnType<typeof bookingLedgerRequestAction>;
export const bookingLedgerRequestAction = (currency: TCurrencyCode) => ({
  type: BOOKING_LEDGER_REQUEST as typeof BOOKING_LEDGER_REQUEST,
  currency,
});

export type BookingLedgerSuccessAction = ReturnType<typeof bookingLedgerSuccessAction>;
export const bookingLedgerSuccessAction = (bookingLedger: IBookingLedger) => ({
  type: BOOKING_LEDGER_SUCCESS as typeof BOOKING_LEDGER_SUCCESS,
  bookingLedger,
});

export type BookingLedgerFailureAction = ReturnType<typeof bookingLedgerFailureAction>;
export const bookingLedgerFailureAction = (error: Error) => ({
  type: BOOKING_LEDGER_FAILURE as typeof BOOKING_LEDGER_FAILURE,
  error,
});

export type BookingLedgerSetSortAction = ReturnType<typeof bookingLedgerSetSortAction>;
export const bookingLedgerSetSortAction = (sortBy: IBookingLedgerSortableItems, sortOrder: 'asc' | 'desc') => ({
  type: BOOKING_LEDGER_SET_SORT as typeof BOOKING_LEDGER_SET_SORT,
  sortBy,
  sortOrder,
});

export type BookingLedgerSetPageNumberAction = ReturnType<typeof bookingLedgerSetPageNumberAction>;
export const bookingLedgerSetPageNumberAction = (pageNumber: number) => ({
  type: BOOKING_LEDGER_SET_PAGE_NUMBER as typeof BOOKING_LEDGER_SET_PAGE_NUMBER,
  pageNumber,
});

export type BookingLedgerSetItemsPerPageAction = ReturnType<typeof bookingLedgerSetItemsPerPageAction>;
export const bookingLedgerSetItemsPerPageAction = (itemsPerPage: number) => ({
  type: BOOKING_LEDGER_SET_ITEMS_PER_PAGE as typeof BOOKING_LEDGER_SET_ITEMS_PER_PAGE,
  itemsPerPage,
});

export type BookingLedgerResetAction = ReturnType<typeof bookingLedgerResetAction>;
export const bookingLedgerResetAction = () => ({
  type: BOOKING_LEDGER_RESET as typeof BOOKING_LEDGER_RESET,
});

export type DepositAccountRequestAction = ReturnType<typeof depositAccountRequestAction>;
export const depositAccountRequestAction = (currency: TCurrencyCode) => ({
  type: DEPOSIT_ACCOUNT_REQUEST as typeof DEPOSIT_ACCOUNT_REQUEST,
  currency,
});

export type DepositAccountSuccessAction = ReturnType<typeof depositAccountSuccessAction>;
export const depositAccountSuccessAction = (depositAccount: IDepositAccountResponse) => ({
  type: DEPOSIT_ACCOUNT_SUCCESS as typeof DEPOSIT_ACCOUNT_SUCCESS,
  depositAccount,
});

export type DepositAccountFailureAction = ReturnType<typeof depositAccountFailureAction>;
export const depositAccountFailureAction = (error: Error) => ({
  type: DEPOSIT_ACCOUNT_FAILURE as typeof DEPOSIT_ACCOUNT_FAILURE,
  error,
});

export type DepositAccountSetSortAction = ReturnType<typeof depositAccountSetSortAction>;
export const depositAccountSetSortAction = (sortBy: IDepositAccountSortableItems, sortOrder: 'asc' | 'desc') => ({
  type: DEPOSIT_ACCOUNT_SET_SORT as typeof DEPOSIT_ACCOUNT_SET_SORT,
  sortBy,
  sortOrder,
});

export type DepositAccountSetPageNumberAction = ReturnType<typeof depositAccountSetPageNumberAction>;
export const depositAccountSetPageNumberAction = (pageNumber: number) => ({
  type: DEPOSIT_ACCOUNT_SET_PAGE_NUMBER as typeof DEPOSIT_ACCOUNT_SET_PAGE_NUMBER,
  pageNumber,
});

export type DepositAccountSetItemsPerPageAction = ReturnType<typeof depositAccountSetItemsPerPageAction>;
export const depositAccountSetItemsPerPageAction = (itemsPerPage: number) => ({
  type: DEPOSIT_ACCOUNT_SET_ITEMS_PER_PAGE as typeof DEPOSIT_ACCOUNT_SET_ITEMS_PER_PAGE,
  itemsPerPage,
});

export type DepositAccountResetAction = ReturnType<typeof depositAccountResetAction>;
export const depositAccountResetAction = () => ({
  type: DEPOSIT_ACCOUNT_RESET as typeof DEPOSIT_ACCOUNT_RESET,
});

export type BookingLedgerPostExportRequestAction = ReturnType<typeof bookingLedgerPostExportRequestAction>;
export const bookingLedgerPostExportRequestAction = () => ({
  type: BOOKING_LEDGER_POST_EXPORT_REQUEST as typeof BOOKING_LEDGER_POST_EXPORT_REQUEST,
});

export type BookingLedgerPostExportSuccessAction = ReturnType<typeof bookingLedgerPostExportSuccessAction>;
export const bookingLedgerPostExportSuccessAction = (exportUuid: string) => ({
  type: BOOKING_LEDGER_POST_EXPORT_SUCCESS as typeof BOOKING_LEDGER_POST_EXPORT_SUCCESS,
  exportUuid,
});

export type BookingLedgerPostExportFailureAction = ReturnType<typeof bookingLedgerPostExportFailureAction>;
export const bookingLedgerPostExportFailureAction = (error: string) => ({
  type: BOOKING_LEDGER_POST_EXPORT_FAILURE as typeof BOOKING_LEDGER_POST_EXPORT_FAILURE,
  error,
});

export type BookingLedgerGetExportRequestAction = ReturnType<typeof bookingLedgerGetExportRequestAction>;
export const bookingLedgerGetExportRequestAction = () => ({
  type: BOOKING_LEDGER_GET_EXPORT_REQUEST as typeof BOOKING_LEDGER_GET_EXPORT_REQUEST,
});

export type BookingLedgerGetExportSuccessAction = ReturnType<typeof bookingLedgerGetExportSuccessAction>;
export const bookingLedgerGetExportSuccessAction = (signedUrl?: string) => ({
  type: BOOKING_LEDGER_GET_EXPORT_SUCCESS as typeof BOOKING_LEDGER_GET_EXPORT_SUCCESS,
  signedUrl,
});

export type BookingLedgerGetExportFailureAction = ReturnType<typeof bookingLedgerGetExportFailureAction>;
export const bookingLedgerGetExportFailureAction = (error: string) => ({
  type: BOOKING_LEDGER_GET_EXPORT_FAILURE as typeof BOOKING_LEDGER_GET_EXPORT_FAILURE,
  error,
});

export type BookingLedgerPostAggregateExportRequestAction = ReturnType<
  typeof bookingLedgerPostAggregateExportRequestAction
>;
export const bookingLedgerPostAggregateExportRequestAction = () => ({
  type: BOOKING_LEDGER_POST_AGGREGATE_EXPORT_REQUEST as typeof BOOKING_LEDGER_POST_AGGREGATE_EXPORT_REQUEST,
});

export type BookingLedgerPostAggregateExportSuccessAction = ReturnType<
  typeof bookingLedgerPostAggregateExportSuccessAction
>;
export const bookingLedgerPostAggregateExportSuccessAction = (exportUuid: string) => ({
  type: BOOKING_LEDGER_POST_AGGREGATE_EXPORT_SUCCESS as typeof BOOKING_LEDGER_POST_AGGREGATE_EXPORT_SUCCESS,
  exportUuid,
});

export type BookingLedgerPostAggregateExportFailureAction = ReturnType<
  typeof bookingLedgerPostAggregateExportFailureAction
>;
export const bookingLedgerPostAggregateExportFailureAction = (error: string) => ({
  type: BOOKING_LEDGER_POST_AGGREGATE_EXPORT_FAILURE as typeof BOOKING_LEDGER_POST_AGGREGATE_EXPORT_FAILURE,
  error,
});

export type BookingLedgerGetAggregateExportRequestAction = ReturnType<
  typeof bookingLedgerGetAggregateExportRequestAction
>;
export const bookingLedgerGetAggregateExportRequestAction = () => ({
  type: BOOKING_LEDGER_GET_AGGREGATE_EXPORT_REQUEST as typeof BOOKING_LEDGER_GET_AGGREGATE_EXPORT_REQUEST,
});

export type BookingLedgerGetAggregateExportSuccessAction = ReturnType<
  typeof bookingLedgerGetAggregateExportSuccessAction
>;
export const bookingLedgerGetAggregateExportSuccessAction = (signedUrl?: string) => ({
  type: BOOKING_LEDGER_GET_AGGREGATE_EXPORT_SUCCESS as typeof BOOKING_LEDGER_GET_AGGREGATE_EXPORT_SUCCESS,
  signedUrl,
});

export type BookingLedgerGetAggregateExportFailureAction = ReturnType<
  typeof bookingLedgerGetAggregateExportFailureAction
>;
export const bookingLedgerGetAggregateExportFailureAction = (error: string) => ({
  type: BOOKING_LEDGER_GET_AGGREGATE_EXPORT_FAILURE as typeof BOOKING_LEDGER_GET_AGGREGATE_EXPORT_FAILURE,
  error,
});

export type DepositAccountPostExportRequestAction = ReturnType<typeof depositAccountPostExportRequestAction>;
export const depositAccountPostExportRequestAction = () => ({
  type: DEPOSIT_ACCOUNT_POST_EXPORT_REQUEST as typeof DEPOSIT_ACCOUNT_POST_EXPORT_REQUEST,
});

export type DepositAccountPostExportSuccessAction = ReturnType<typeof depositAccountPostExportSuccessAction>;
export const depositAccountPostExportSuccessAction = (exportUuid: string) => ({
  type: DEPOSIT_ACCOUNT_POST_EXPORT_SUCCESS as typeof DEPOSIT_ACCOUNT_POST_EXPORT_SUCCESS,
  exportUuid,
});

export type DepositAccountPostExportFailureAction = ReturnType<typeof depositAccountPostExportFailureAction>;
export const depositAccountPostExportFailureAction = (error: string) => ({
  type: DEPOSIT_ACCOUNT_POST_EXPORT_FAILURE as typeof DEPOSIT_ACCOUNT_POST_EXPORT_FAILURE,
  error,
});

export type DepositAccountGetExportRequestAction = ReturnType<typeof depositAccountGetExportRequestAction>;
export const depositAccountGetExportRequestAction = () => ({
  type: DEPOSIT_ACCOUNT_GET_EXPORT_REQUEST as typeof DEPOSIT_ACCOUNT_GET_EXPORT_REQUEST,
});

export type DepositAccountGetExportSuccessAction = ReturnType<typeof depositAccountGetExportSuccessAction>;
export const depositAccountGetExportSuccessAction = (signedUrl?: string) => ({
  type: DEPOSIT_ACCOUNT_GET_EXPORT_SUCCESS as typeof DEPOSIT_ACCOUNT_GET_EXPORT_SUCCESS,
  signedUrl,
});

export type DepositAccountGetExportFailureAction = ReturnType<typeof depositAccountGetExportFailureAction>;
export const depositAccountGetExportFailureAction = (error: string) => ({
  type: DEPOSIT_ACCOUNT_GET_EXPORT_FAILURE as typeof DEPOSIT_ACCOUNT_GET_EXPORT_FAILURE,
  error,
});

export type DepositAccountPostAggregateExportRequestAction = ReturnType<
  typeof depositAccountPostAggregateExportRequestAction
>;
export const depositAccountPostAggregateExportRequestAction = () => ({
  type: DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_REQUEST as typeof DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_REQUEST,
});

export type DepositAccountPostAggregateExportSuccessAction = ReturnType<
  typeof depositAccountPostAggregateExportSuccessAction
>;
export const depositAccountPostAggregateExportSuccessAction = (exportUuid: string) => ({
  type: DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_SUCCESS as typeof DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_SUCCESS,
  exportUuid,
});

export type DepositAccountPostAggregateExportFailureAction = ReturnType<
  typeof depositAccountPostAggregateExportFailureAction
>;
export const depositAccountPostAggregateExportFailureAction = (error: string) => ({
  type: DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_FAILURE as typeof DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_FAILURE,
  error,
});

export type DepositAccountGetAggregateExportRequestAction = ReturnType<
  typeof depositAccountGetAggregateExportRequestAction
>;
export const depositAccountGetAggregateExportRequestAction = () => ({
  type: DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_REQUEST as typeof DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_REQUEST,
});

export type DepositAccountGetAggregateExportSuccessAction = ReturnType<
  typeof depositAccountGetAggregateExportSuccessAction
>;
export const depositAccountGetAggregateExportSuccessAction = (signedUrl?: string) => ({
  type: DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_SUCCESS as typeof DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_SUCCESS,
  signedUrl,
});

export type DepositAccountGetAggregateExportFailureAction = ReturnType<
  typeof depositAccountGetAggregateExportFailureAction
>;
export const depositAccountGetAggregateExportFailureAction = (error: string) => ({
  type: DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_FAILURE as typeof DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_FAILURE,
  error,
});

export type StatementFilterSetFromDateAction = ReturnType<typeof statementFilterSetFromDateAction>;
export const statementFilterSetFromDateAction = (fromDate: string) => ({
  type: STATEMENT_FILTER_SET_FROM_DATE as typeof STATEMENT_FILTER_SET_FROM_DATE,
  fromDate,
});

export type StatementFilterSetToDateAction = ReturnType<typeof statementFilterSetToDateAction>;
export const statementFilterSetToDateAction = (toDate: string) => ({
  type: STATEMENT_FILTER_SET_TO_DATE as typeof STATEMENT_FILTER_SET_TO_DATE,
  toDate,
});

export type StatementFilterSetTypesAction = ReturnType<typeof statementFilterSetTypesAction>;
export const statementFilterSetTypesAction = (rowTypes: string[]) => ({
  type: STATEMENT_FILTER_SET_TYPE as typeof STATEMENT_FILTER_SET_TYPE,
  rowTypes,
});

export type StatementFilterSetStatusAction = ReturnType<typeof statementFilterSetStatusAction>;
export const statementFilterSetStatusAction = (status: string[]) => ({
  type: STATEMENT_FILTER_SET_STATUS as typeof STATEMENT_FILTER_SET_STATUS,
  status,
});

export type StatementFilterAddBookingRefAction = ReturnType<typeof statementFilterAddBookingRefAction>;
export const statementFilterAddBookingRefAction = () => ({
  type: STATEMENT_FILTER_ADD_BOOKING_REF as typeof STATEMENT_FILTER_ADD_BOOKING_REF,
});

export type StatementFilterSetBookingRefAction = ReturnType<typeof statementFilterSetBookingRefAction>;
export const statementFilterSetBookingRefAction = (index: number, bookingRefValue: string) => ({
  type: STATEMENT_FILTER_SET_BOOKING_REF as typeof STATEMENT_FILTER_SET_BOOKING_REF,
  index,
  bookingRefValue,
});

export type StatementFilterDeleteBookingRefAction = ReturnType<typeof statementFilterDeleteBookingRefAction>;
export const statementFilterDeleteBookingRefAction = (index: number) => ({
  type: STATEMENT_FILTER_DELETE_BOOKING_REF as typeof STATEMENT_FILTER_DELETE_BOOKING_REF,
  index,
});

export type StatementFilterSetAmountMinAction = ReturnType<typeof statementFilterSetAmountMinAction>;
export const statementFilterSetAmountMinAction = (amount?: number) => ({
  type: STATEMENT_FILTER_SET_AMOUNT_MIN as typeof STATEMENT_FILTER_SET_AMOUNT_MIN,
  amount,
});

export type StatementFilterSetAmountMaxAction = ReturnType<typeof statementFilterSetAmountMaxAction>;
export const statementFilterSetAmountMaxAction = (amount?: number) => ({
  type: STATEMENT_FILTER_SET_AMOUNT_MAX as typeof STATEMENT_FILTER_SET_AMOUNT_MAX,
  amount,
});

export type StatementFilterResetAction = ReturnType<typeof statementFilterResetAction>;
export const statementFilterResetAction = () => ({
  type: STATEMENT_FILTER_RESET as typeof STATEMENT_FILTER_RESET,
});

export type StatementFilterUpdateResultsAction = ReturnType<typeof statementFilterUpdateResultsAction>;
export const statementFilterUpdateResultsAction = () => ({
  type: STATEMENT_FILTER_UPDATE_RESULTS as typeof STATEMENT_FILTER_UPDATE_RESULTS,
});


export type DepositFilterSetFromDateAction = ReturnType<typeof depositFilterSetFromDateAction>;
export const depositFilterSetFromDateAction = (fromDate: string) => ({
  type: DEPOSIT_FILTER_SET_FROM_DATE as typeof DEPOSIT_FILTER_SET_FROM_DATE,
  fromDate,
});

export type DepositFilterSetToDateAction = ReturnType<typeof depositFilterSetToDateAction>;
export const depositFilterSetToDateAction = (toDate: string) => ({
  type: DEPOSIT_FILTER_SET_TO_DATE as typeof DEPOSIT_FILTER_SET_TO_DATE,
  toDate,
});

export type DepositFilterSetStatusAction = ReturnType<typeof depositFilterSetStatusAction>;
export const depositFilterSetStatusAction = (status: string[]) => ({
  type: DEPOSIT_FILTER_SET_STATUS as typeof DEPOSIT_FILTER_SET_STATUS,
  status,
});

export type DepositFilterSetBookingRefAction = ReturnType<typeof depositFilterSetBookingRefAction>;
export const depositFilterSetBookingRefAction = (bookingRefValue: string) => ({
  type: DEPOSIT_FILTER_SET_BOOKING_REF as typeof DEPOSIT_FILTER_SET_BOOKING_REF,
  bookingRefValue,
});

export type DepositFilterResetAction = ReturnType<typeof depositFilterResetAction>;
export const depositFilterResetAction = () => ({
  type: DEPOSIT_FILTER_RESET as typeof DEPOSIT_FILTER_RESET,
});

export type DepositFilterUpdateResultsAction = ReturnType<typeof depositFilterUpdateResultsAction>;
export const depositFilterUpdateResultsAction = () => ({
  type: DEPOSIT_FILTER_UPDATE_RESULTS as typeof DEPOSIT_FILTER_UPDATE_RESULTS,
});



export type OpenBalanceCarriedForwardModalAction = ReturnType<typeof openBalanceCarriedForwardModalAction>;
export const openBalanceCarriedForwardModalAction = (row?: IManualBookingLedgerRowForSave) => ({
  type: OPEN_BALANCE_CARRIED_FORWARD_MODAL as typeof OPEN_BALANCE_CARRIED_FORWARD_MODAL,
  row,
});

export type CloseBalanceCarriedForwardModalAction = ReturnType<typeof closeBalanceCarriedForwardModalAction>;
export const closeBalanceCarriedForwardModalAction = () => ({
  type: CLOSE_BALANCE_CARRIED_FORWARD_MODAL as typeof CLOSE_BALANCE_CARRIED_FORWARD_MODAL,
});

export type CheckInitialBCFRequestAction = ReturnType<typeof checkInitialBCFRequestAction>;
export const checkInitialBCFRequestAction = (rowEdited?: IBookingLedgerRow) => ({
  type: CHECK_INITIAL_BCF_REQUEST as typeof CHECK_INITIAL_BCF_REQUEST,
  rowEdited,
});

export type CheckInitialBCFSuccessAction = ReturnType<typeof checkInitialBCFSuccessAction>;
export const checkInitialBCFSuccessAction = (initialBCFRowExists: boolean, firstRow?: IBookingLedgerPageRow) => ({
  type: CHECK_INITIAL_BCF_SUCCESS as typeof CHECK_INITIAL_BCF_SUCCESS,
  initialBCFRowExists,
  firstRow,
});

export type CheckInitialBCFFailureAction = ReturnType<typeof checkInitialBCFFailureAction>;
export const checkInitialBCFFailureAction = (error: string) => ({
  type: CHECK_INITIAL_BCF_FAILURE as typeof CHECK_INITIAL_BCF_FAILURE,
  error,
});

export type SaveBCFRowRequestAction = ReturnType<typeof saveBCFRowRequestAction>;
export const saveBCFRowRequestAction = (bcfRowWithoutUpload: IManualBookingLedgerRowForSave, files: File[]) => ({
  type: SAVE_BCF_ROW_REQUEST as typeof SAVE_BCF_ROW_REQUEST,
  bcfRowWithoutUpload,
  files,
});

export type SaveBCFRowSuccessAction = ReturnType<typeof saveBCFRowSuccessAction>;
export const saveBCFRowSuccessAction = (row: IManualBookingLedgerRowResponse) => ({
  type: SAVE_BCF_ROW_SUCCESS as typeof SAVE_BCF_ROW_SUCCESS,
  row,
});

export type SaveBCFRowFailureAction = ReturnType<typeof saveBCFRowFailureAction>;
export const saveBCFRowFailureAction = (error: string) => ({
  type: SAVE_BCF_ROW_FAILURE as typeof SAVE_BCF_ROW_FAILURE,
  error,
});

export type BookingLedgerGeneratePdfRequestAction = ReturnType<typeof bookingLedgerGeneratePdfRequestAction>;
export const bookingLedgerGeneratePdfRequestAction = () => ({
  type: BOOKING_LEDGER_GENERATE_PDF_REQUEST as typeof BOOKING_LEDGER_GENERATE_PDF_REQUEST,
});

export type BookingLedgerGeneratePdfSuccessAction = ReturnType<typeof bookingLedgerGeneratePdfSuccessAction>;
export const bookingLedgerGeneratePdfSuccessAction = (pdf: IStatementPdf) => ({
  type: BOOKING_LEDGER_GENERATE_PDF_SUCCESS as typeof BOOKING_LEDGER_GENERATE_PDF_SUCCESS,
  pdf,
});

export type BookingLedgerGeneratePdfFailureAction = ReturnType<typeof bookingLedgerGeneratePdfFailureAction>;
export const bookingLedgerGeneratePdfFailureAction = (error: string) => ({
  type: BOOKING_LEDGER_GENERATE_PDF_FAILURE as typeof BOOKING_LEDGER_GENERATE_PDF_FAILURE,
  error,
});

export type BookingLedgerDownloadPdfHistoryRequestAction = ReturnType<
  typeof bookingLedgerDownloadPdfHistoryRequestAction
>;
export const bookingLedgerDownloadPdfHistoryRequestAction = (companyUuid: string, currency: TCurrencyCode) => ({
  type: BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_REQUEST as typeof BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_REQUEST,
  companyUuid,
  currency,
});

export type BookingLedgerDownloadPdfHistorySuccessAction = ReturnType<
  typeof bookingLedgerDownloadPdfHistorySuccessAction
>;
export const bookingLedgerDownloadPdfHistorySuccessAction = (pdfs: IStatementPdf[]) => ({
  type: BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_SUCCESS as typeof BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_SUCCESS,
  pdfs,
});

export type BookingLedgerDownloadPdfHistoryFailureAction = ReturnType<
  typeof bookingLedgerDownloadPdfHistoryFailureAction
>;
export const bookingLedgerDownloadPdfHistoryFailureAction = (error: string) => ({
  type: BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_FAILURE as typeof BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_FAILURE,
  error,
});

export type OpenDepositAccountModalAction = ReturnType<typeof openDepositAccountModalAction>;
export const openDepositAccountModalAction = (row?: IDepositAccountRowForSave) => ({
  type: OPEN_DEPOSIT_ACCOUNT_MODAL as typeof OPEN_DEPOSIT_ACCOUNT_MODAL,
  row,
});

export type CloseDepositAccountModalAction = ReturnType<typeof closeDepositAccountModalAction>;
export const closeDepositAccountModalAction = () => ({
  type: CLOSE_DEPOSIT_ACCOUNT_MODAL as typeof CLOSE_DEPOSIT_ACCOUNT_MODAL,
});

export type CheckDepositInitialBCFRequestAction = ReturnType<typeof checkDepositInitialBCFRequestAction>;
export const checkDepositInitialBCFRequestAction = (rowEdited?: IDepositAccountRow) => ({
  type: DEPOSIT_CHECK_INITIAL_BCF_REQUEST as typeof DEPOSIT_CHECK_INITIAL_BCF_REQUEST,
  rowEdited,
});

export type CheckDepositInitialBCFSuccessAction = ReturnType<typeof checkDepositInitialBCFSuccessAction>;
export const checkDepositInitialBCFSuccessAction = (
  initialBCFRowExists: boolean,
  firstRow?: IDepositAccountRowWithBalance
) => ({
  type: DEPOSIT_CHECK_INITIAL_BCF_SUCCESS as typeof DEPOSIT_CHECK_INITIAL_BCF_SUCCESS,
  initialBCFRowExists,
  firstRow,
});

export type CheckDepositInitialBCFFailureAction = ReturnType<typeof checkDepositInitialBCFFailureAction>;
export const checkDepositInitialBCFFailureAction = (error: string) => ({
  type: DEPOSIT_CHECK_INITIAL_BCF_FAILURE as typeof DEPOSIT_CHECK_INITIAL_BCF_FAILURE,
  error,
});

export type SaveDepositRowRequestAction = ReturnType<typeof saveDepositRowRequestAction>;
export const saveDepositRowRequestAction = (
  depositAccountRowWithoutUpload: IDepositAccountRowForSave,
  files: File[]
) => ({
  type: DEPOSIT_SAVE_ROW_REQUEST as typeof DEPOSIT_SAVE_ROW_REQUEST,
  depositAccountRowWithoutUpload,
  files,
});

export type SaveDepositRowSuccessAction = ReturnType<typeof saveDepositRowSuccessAction>;
export const saveDepositRowSuccessAction = (row: IDepositAccountResponse) => ({
  type: DEPOSIT_SAVE_ROW_SUCCESS as typeof DEPOSIT_SAVE_ROW_SUCCESS,
  row,
});

export type SaveDepositRowFailureAction = ReturnType<typeof saveDepositRowFailureAction>;
export const saveDepositRowFailureAction = (error: string) => ({
  type: DEPOSIT_SAVE_ROW_FAILURE as typeof DEPOSIT_SAVE_ROW_FAILURE,
  error,
});

export type DepositAccountGeneratePdfRequestAction = ReturnType<typeof depositAccountGeneratePdfRequestAction>;
export const depositAccountGeneratePdfRequestAction = () => ({
  type: DEPOSIT_ACCOUNT_GENERATE_PDF_REQUEST as typeof DEPOSIT_ACCOUNT_GENERATE_PDF_REQUEST,
});

export type DepositAccountGeneratePdfSuccessAction = ReturnType<typeof depositAccountGeneratePdfSuccessAction>;
export const depositAccountGeneratePdfSuccessAction = (pdf: IStatementPdf) => ({
  type: DEPOSIT_ACCOUNT_GENERATE_PDF_SUCCESS as typeof DEPOSIT_ACCOUNT_GENERATE_PDF_SUCCESS,
  pdf,
});

export type DepositAccountGeneratePdfFailureAction = ReturnType<typeof depositAccountGeneratePdfFailureAction>;
export const depositAccountGeneratePdfFailureAction = (error: string) => ({
  type: DEPOSIT_ACCOUNT_GENERATE_PDF_FAILURE as typeof DEPOSIT_ACCOUNT_GENERATE_PDF_FAILURE,
  error,
});

export type DepositAccountDownloadPdfHistoryRequestAction = ReturnType<
  typeof depositAccountDownloadPdfHistoryRequestAction
>;
export const depositAccountDownloadPdfHistoryRequestAction = (companyUuid: string, currency: TCurrencyCode) => ({
  type: DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_REQUEST as typeof DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_REQUEST,
  companyUuid,
  currency,
});

export type DepositAccountDownloadPdfHistorySuccessAction = ReturnType<
  typeof depositAccountDownloadPdfHistorySuccessAction
>;
export const depositAccountDownloadPdfHistorySuccessAction = (pdfs: IStatementPdf[]) => ({
  type: DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_SUCCESS as typeof DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_SUCCESS,
  pdfs,
});

export type DepositAccountDownloadPdfHistoryFailureAction = ReturnType<
  typeof depositAccountDownloadPdfHistoryFailureAction
>;
export const depositAccountDownloadPdfHistoryFailureAction = (error: string) => ({
  type: DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_FAILURE as typeof DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_FAILURE,
  error,
});

export type SummaryGeneratePdfRequestAction = ReturnType<typeof summaryGeneratePdfRequestAction>;
export const summaryGeneratePdfRequestAction = () => ({
  type: SUMMARY_GENERATE_PDF_REQUEST as typeof SUMMARY_GENERATE_PDF_REQUEST,
});

export type SummaryGeneratePdfSuccessAction = ReturnType<typeof summaryGeneratePdfSuccessAction>;
export const summaryGeneratePdfSuccessAction = (pdf: IStatementPdf) => ({
  type: SUMMARY_GENERATE_PDF_SUCCESS as typeof SUMMARY_GENERATE_PDF_SUCCESS,
  pdf,
});

export type SummaryGeneratePdfFailureAction = ReturnType<typeof summaryGeneratePdfFailureAction>;
export const summaryGeneratePdfFailureAction = (error: string) => ({
  type: SUMMARY_GENERATE_PDF_FAILURE as typeof SUMMARY_GENERATE_PDF_FAILURE,
  error,
});

export type SummaryDownloadPdfHistoryRequestAction = ReturnType<typeof summaryDownloadPdfHistoryRequestAction>;
export const summaryDownloadPdfHistoryRequestAction = (companyUuid: string) => ({
  type: SUMMARY_DOWNLOAD_PDF_HISTORY_REQUEST as typeof SUMMARY_DOWNLOAD_PDF_HISTORY_REQUEST,
  companyUuid,
});

export type SummaryDownloadPdfHistorySuccessAction = ReturnType<typeof summaryDownloadPdfHistorySuccessAction>;
export const summaryDownloadPdfHistorySuccessAction = (pdfs: IStatementPdf[]) => ({
  type: SUMMARY_DOWNLOAD_PDF_HISTORY_SUCCESS as typeof SUMMARY_DOWNLOAD_PDF_HISTORY_SUCCESS,
  pdfs,
});

export type SummaryDownloadPdfHistoryFailureAction = ReturnType<typeof summaryDownloadPdfHistoryFailureAction>;
export const summaryDownloadPdfHistoryFailureAction = (error: string) => ({
  type: SUMMARY_DOWNLOAD_PDF_HISTORY_FAILURE as typeof SUMMARY_DOWNLOAD_PDF_HISTORY_FAILURE,
  error,
});

export type DeleteDepositRowRequestAction = ReturnType<typeof deleteDepositRowRequestAction>;
export const deleteDepositRowRequestAction = () => ({
  type: DEPOSIT_DELETE_ROW_REQUEST as typeof DEPOSIT_DELETE_ROW_REQUEST,
});

export type DeleteDepositRowSuccessAction = ReturnType<typeof deleteDepositRowSuccessAction>;
export const deleteDepositRowSuccessAction = () => ({
  type: DEPOSIT_DELETE_ROW_SUCCESS as typeof DEPOSIT_DELETE_ROW_SUCCESS,
});

export type DeleteDepositRowFailureAction = ReturnType<typeof deleteDepositRowFailureAction>;
export const deleteDepositRowFailureAction = (error: string) => ({
  type: DEPOSIT_DELETE_ROW_FAILURE as typeof DEPOSIT_DELETE_ROW_FAILURE,
  error,
});

export type SetDepositAccountRowToDeleteAction = ReturnType<typeof setDepositAccountRowToDeleteAction>;
export const setDepositAccountRowToDeleteAction = (depositAccountRowUuid: string | null) => ({
  type: SET_DEPOSIT_ACCOUNT_ROW_TO_DELETE as typeof SET_DEPOSIT_ACCOUNT_ROW_TO_DELETE,
  depositAccountRowUuid,
});

export type SetDARDeleteModalOpenAction = ReturnType<typeof setDARDeleteModalOpenAction>;
export const setDARDeleteModalOpenAction = (isOpen: boolean) => ({
  type: SET_DAR_DELETE_MODAL_OPEN as typeof SET_DAR_DELETE_MODAL_OPEN,
  isOpen,
});

export type GetDepositPaymentMethodsRequestAction = ReturnType<typeof getDepositPaymentMethodsRequestAction>;
export const getDepositPaymentMethodsRequestAction = () => ({
  type: GET_DEPOSIT_PAYMENT_METHODS_REQUEST as typeof GET_DEPOSIT_PAYMENT_METHODS_REQUEST,
});

export type GetDepositPaymentMethodsSuccessAction = ReturnType<typeof getDepositPaymentMethodsSuccessAction>;
export const getDepositPaymentMethodsSuccessAction = (paymentMethods: IPaymentMethod[], defaultPaymentMethodPerCurrency: IPaymentMethodPerCurrency[]) => ({
  type: GET_DEPOSIT_PAYMENT_METHODS_SUCCESS as typeof GET_DEPOSIT_PAYMENT_METHODS_SUCCESS,
  paymentMethods,
  defaultPaymentMethodPerCurrency
});

export type GetDepositPaymentMethodsFailureAction = ReturnType<typeof getDepositPaymentMethodsFailureAction>;
export const getDepositPaymentMethodsFailureAction = () => ({
  type: GET_DEPOSIT_PAYMENT_METHODS_FAILURE as typeof GET_DEPOSIT_PAYMENT_METHODS_FAILURE,
});

export type LedgerAction =
  | SelectCompanyAction
  | DepositStatementTotalsRequestAction
  | DepositStatementTotalsSuccessAction
  | DepositStatementTotalsFailureAction
  | BookingStatementTotalsRequestAction
  | BookingStatementTotalsSuccessAction
  | BookingStatementTotalsFailureAction
  | BookingLedgerRequestAction
  | BookingLedgerSuccessAction
  | BookingLedgerFailureAction
  | BookingLedgerSetSortAction
  | BookingLedgerSetPageNumberAction
  | BookingLedgerSetItemsPerPageAction
  | BookingLedgerResetAction
  | DepositFilterSetFromDateAction
  | DepositFilterSetToDateAction
  | DepositFilterSetStatusAction
  | DepositFilterSetBookingRefAction
  | DepositFilterResetAction
  | DepositFilterUpdateResultsAction
  | DepositAccountRequestAction
  | DepositAccountSuccessAction
  | DepositAccountFailureAction
  | DepositAccountSetSortAction
  | DepositAccountSetPageNumberAction
  | DepositAccountSetItemsPerPageAction
  | DepositAccountResetAction
  | BookingLedgerPostExportRequestAction
  | BookingLedgerPostExportSuccessAction
  | BookingLedgerPostExportFailureAction
  | BookingLedgerGetExportRequestAction
  | BookingLedgerGetExportSuccessAction
  | BookingLedgerGetExportFailureAction
  | BookingLedgerPostAggregateExportRequestAction
  | BookingLedgerPostAggregateExportSuccessAction
  | BookingLedgerPostAggregateExportFailureAction
  | BookingLedgerGetAggregateExportRequestAction
  | BookingLedgerGetAggregateExportSuccessAction
  | BookingLedgerGetAggregateExportFailureAction
  | DepositAccountPostExportRequestAction
  | DepositAccountPostExportSuccessAction
  | DepositAccountPostExportFailureAction
  | DepositAccountGetExportRequestAction
  | DepositAccountGetExportSuccessAction
  | DepositAccountGetExportFailureAction
  | DepositAccountPostAggregateExportRequestAction
  | DepositAccountPostAggregateExportSuccessAction
  | DepositAccountPostAggregateExportFailureAction
  | DepositAccountGetAggregateExportRequestAction
  | DepositAccountGetAggregateExportSuccessAction
  | DepositAccountGetAggregateExportFailureAction
  | StatementFilterSetFromDateAction
  | StatementFilterSetToDateAction
  | StatementFilterSetTypesAction
  | StatementFilterSetStatusAction
  | StatementFilterAddBookingRefAction
  | StatementFilterDeleteBookingRefAction
  | StatementFilterSetBookingRefAction
  | StatementFilterResetAction
  | StatementFilterUpdateResultsAction
  | OpenBalanceCarriedForwardModalAction
  | CloseBalanceCarriedForwardModalAction
  | CheckInitialBCFRequestAction
  | CheckInitialBCFSuccessAction
  | CheckInitialBCFFailureAction
  | SaveBCFRowRequestAction
  | SaveBCFRowSuccessAction
  | SaveBCFRowFailureAction
  | BookingLedgerGeneratePdfRequestAction
  | BookingLedgerGeneratePdfSuccessAction
  | BookingLedgerGeneratePdfFailureAction
  | BookingLedgerDownloadPdfHistoryRequestAction
  | BookingLedgerDownloadPdfHistorySuccessAction
  | BookingLedgerDownloadPdfHistoryFailureAction
  | OpenDepositAccountModalAction
  | CloseDepositAccountModalAction
  | CheckDepositInitialBCFRequestAction
  | CheckDepositInitialBCFSuccessAction
  | CheckDepositInitialBCFFailureAction
  | SaveDepositRowRequestAction
  | SaveDepositRowSuccessAction
  | SaveDepositRowFailureAction
  | SetDARDeleteModalOpenAction
  | SetDepositAccountRowToDeleteAction
  | DeleteDepositRowRequestAction
  | DeleteDepositRowSuccessAction
  | DeleteDepositRowFailureAction
  | DepositAccountGeneratePdfRequestAction
  | DepositAccountGeneratePdfSuccessAction
  | DepositAccountGeneratePdfFailureAction
  | DepositAccountDownloadPdfHistoryRequestAction
  | DepositAccountDownloadPdfHistorySuccessAction
  | DepositAccountDownloadPdfHistoryFailureAction
  | SummaryGeneratePdfRequestAction
  | SummaryGeneratePdfSuccessAction
  | SummaryGeneratePdfFailureAction
  | SummaryDownloadPdfHistoryRequestAction
  | SummaryDownloadPdfHistorySuccessAction
  | SummaryDownloadPdfHistoryFailureAction
  | StatementFilterSetAmountMinAction
  | StatementFilterSetAmountMaxAction
  | GetDepositPaymentMethodsRequestAction
  | GetDepositPaymentMethodsSuccessAction
  | GetDepositPaymentMethodsFailureAction;
