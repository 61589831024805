import React from 'react';
import { BookingBuilderResponse } from 'services/BackendApi';
import { formatPrice } from 'utils';

export interface IRHPServiceChargeProps {
  selectedBuild: BookingBuilderResponse;
  currencySymbol: string;
}

export const RHPServiceCharge: React.FC<IRHPServiceChargeProps> = ({ selectedBuild, currencySymbol }) => {
  const serviceCharge = selectedBuild.availableProductSets.Supplement
    .find(item => item.products[0].name === 'Service Charge' && item.selected);

  if (!serviceCharge) {
    return null;
  }

  return (
    <div>
      <div className="servicecharge-wrapper flex items-center justify-between">
        <span className="text-gray-100 uppercase text-xs leading-16px w-1/2">SERVICE CHARGE</span>
        <span className="bg-gray-10 w-1/2 flex items-center pt-3px px-10px justify-end h-35px text-[19px] leading-[25px] text-gray-100 font-bold">
          {currencySymbol}{formatPrice(serviceCharge.total)}
        </span>
      </div>
    </div>
  )
}