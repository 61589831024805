import { EBankAccount, TCurrencyCode } from 'interfaces';

export interface IPaymentMethod {
  code: string;
  name: EBankAccount; // legacy naming - it should be PaymentMethodName
  currency?: TCurrencyCode | null;
  warning?: string | null;
}

export interface IPaymentMethodPerCurrency {
  currency: TCurrencyCode;
  paymentMethodCode: string;
}

export interface IPaymentMethodsGetInContextResponse {
  paymentMethods: IPaymentMethod[];
  defaultPaymentMethodPerCurrency: IPaymentMethodPerCurrency[];
};

export enum EPaymentContextCode {
  INVOICE = 'INVOICE'
}
