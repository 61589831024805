import React from 'react';
import * as Inputs from './Inputs';
import { isBlank } from 'utils';
import { ISeasonalProductAddonRate } from 'services/BackendApi';
import * as _ from 'lodash-es';

const markupTypes = [
  { value: 'flat', label: 'Flat' },
  { value: 'percentage', label: 'Percentage' },
];

interface ISeasonalProductAddonRateProps {
  addonRate: Partial<ISeasonalProductAddonRate>;
  onUpdate: (field: keyof any, value: any) => void;
  ageBrackets: any[];
}
export const SeasonalProductAddonRate = (props: ISeasonalProductAddonRateProps) => {
  const { addonRate, onUpdate, ageBrackets } = props;

  const rate = _.get(addonRate, `rate`, '0');
  const markupAmount = _.get(addonRate, `markupAmount`, '0');
  const markupType = _.get(addonRate, `markupType`, 'flat');

  return (
    <div className="flex flex-col space-y-4 w-full">
      <div className="grid grid-cols-4 gap-3 items-center">
        <span>Adult</span>
        <Inputs.Input
          label="Rate"
          value={rate}
          debounceMs={750}
          onChange={val => {
            onUpdate('rate', String(val));
          }}
        />
        <Inputs.Input
          label="Markup Amount"
          value={markupAmount}
          debounceMs={750}
          onChange={val => {
            onUpdate('markupAmount', String(val));
          }}
        />
        <Inputs.RadioCollection
          label="Markup Type"
          collection={markupTypes.map(mt => {
            return {
              isChecked: markupType === mt.value,
              value: mt.value,
              label: mt.label,
            };
          })}
          onChange={val => {
            onUpdate('markupType', val);
          }}
        />
      </div>
      {ageBrackets.map((ageBracket, ageBracketIndex) => {
        const ageBracketRate = _.get(addonRate, `alternateRates.[${ageBracket.name}].rate`, '0');
        const ageBracketMarkupAmount = _.get(addonRate, `alternateRates.[${ageBracket.name}].markupAmount`, '0');
        const ageBracketMarkupType = _.get(addonRate, `alternateRates.[${ageBracket.name}].markupType`, 'flat');

        return (
          <div key={ageBracketIndex} className="grid grid-cols-4 gap-3 items-center">
            <span>{ageBracket.name}</span>
            <Inputs.Input
              value={ageBracketRate}
              onChange={val => {
                onUpdate(`alternateRates.${ageBracket.name}.rate`, val);
              }}
              debounceMs={750}
            />
            <Inputs.Input
              value={ageBracketMarkupAmount}
              onChange={val => {
                onUpdate(`alternateRates.${ageBracket.name}.markupAmount`, val);
              }}
              debounceMs={750}
            />
            <Inputs.RadioCollection
              collection={markupTypes.map(mt => {
                return {
                  isChecked: ageBracketMarkupType === mt.value,
                  value: mt.value,
                  label: mt.label,
                };
              })}
              onChange={val => {
                onUpdate(`alternateRates.${ageBracket.name}.markupType`, val);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
