import { equals } from 'ramda';

const getEnvironment = () => {
  const host = window.location.host;

  if (host.includes('localhost')) {
    return 'local';
  }

  const envMatch = host.match(/(.*)\.pure-escapes.com/);

  return envMatch ? envMatch[1] : 'unknown';
};

export const NODE_ENV = process.env.NODE_ENV;
export const ENVIRONMENT = process.env.ENVIRONMENT || getEnvironment();

export const APP_VERBOSE_ERRORS = equals('true', process.env.APP_VERBOSE_ERRORS);

export const PARAMETERS_REFRESH_INTERVAL_SECONDS = parseInt(process.env.PARAMETERS_REFRESH_INTERVAL_SECONDS, 10) || 60;

export const PAYMENT_ENABLED = equals('true', process.env.PAYMENT_ENABLED);

export const isDev = equals('development', NODE_ENV);

const DISPLAY_DATE_FORMAT = 'd MMM yy';
const DISPLAY_CREDIT_NOTE_DATE_FORMAT = 'dd/MM/yyyy';
const DISPLAY_TIME_FORMAT = 'HH:mm';
const DISPLAY_TIMEZONE_FORMAT = '(z)';

export const ANALYTICS_SESSION_UUID_KEY = 'sessionUuid';

export const CIRCLE_BUILD_NUM = process.env.CIRCLE_BUILD_NUM || '0.0.0';
export const CHECK_APP_VERSION_TIME_INTERVAL = parseInt(process.env.CHECK_APP_VERSION_TIME_INTERVAL, 10) || 5000;
export const JWT_KEEPALIVE_INTERVAL = parseInt(process.env.JWT_KEEPALIVE_INTERVAL, 10) || 60000;
export const LOCAL_DEPLOY_URL = 'http://localhost:8080';
export const CURR_DEPLOY_BASE_URL = process.env.CURR_DEPLOY_BASE_URL || LOCAL_DEPLOY_URL;

export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_ENV = process.env.SENTRY_ENV || process.env.ENVIRONMENT || 'developer-no-env-set';
export const SENTRY_RELEASE = `webapp-frontend-${SENTRY_ENV}-${CIRCLE_BUILD_NUM}`;

export const AUTHENTICATION_STRATEGY = process.env.AUTHENTICATION_STRATEGY;

export const UPLOAD_FILE_SIZE_LIMIT = process.env.UPLOAD_FILE_SIZE_LIMIT
  ? parseFloat(process.env.UPLOAD_FILE_SIZE_LIMIT)
  : 4194304; //4MB

export const LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS = parseInt(process.env.LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS);

export const DAYS_AFTER_LAST_CHECKOUT_DISABLE_SR_EDITING = 2;

export const SERVICE_CHARGE_UUID = 'eed43452-8e07-42b2-851c-3b07e1d5f6f7';
export const FUEL_CHARGE_UUID = 'dc6aef4d-f8aa-443f-ac41-a00a0578f01a';

export const PARAMETERS_PROVIDER_URL = process.env.PARAMETERS_PROVIDER_URL;
export const REDIRECT_COGNITO_URI_IF_LOCALHOST = process.env.REDIRECT_COGNITO_URI_IF_LOCALHOST;
export const COGNITO_ENABLE_FLOW = equals('true', process.env.COGNITO_ENABLE_FLOW);
export const TINYMCE_EDITOR_LICENSE_KEY = process.env.TINYMCE_EDITOR_LICENSE_KEY;

// routes don't strictly speaking *need* to use this; this is so we can easily refer to routes
// in _other_ places than App.jsx
export const APP_ROUTING_PATHS = {
  basket: {
    home: '/basket',
    edit: '/basket-edit',
  },
  sharedLink: '/shared',
};

export default {
  defaults: {
    dateFormat: 'yyyy-MM-dd',
    displayDateFormat: DISPLAY_DATE_FORMAT,
    displayTimeFormat: DISPLAY_TIME_FORMAT,
    displayDateTimeFormat: `${DISPLAY_DATE_FORMAT}, ${DISPLAY_TIME_FORMAT}`,
    displayDateTimeIncludeTimezoneFormat: `${DISPLAY_DATE_FORMAT}, ${DISPLAY_TIME_FORMAT} ${DISPLAY_TIMEZONE_FORMAT}`,
    displayCreditNoteDateFormat: DISPLAY_CREDIT_NOTE_DATE_FORMAT,
    priceRange: [1000, 10000],
  },
};
