import {
  IAccommodationProduct,
  IAgeName,
  IFineProduct,
  IGroundServiceProduct,
  IHotel,
  IMealPlanProduct,
  IProduct,
  ISeason,
  ISeasonalProductAddonRate,
  ISeasonalProductRate,
  ISupplementProduct,
  ITransferProduct,
  DeepKeyOf,
} from 'services/BackendApi/types';
import * as _ from 'lodash-es';
import { isBlank } from 'utils';
import { IBootstrapExtraPersonSupplementProduct } from 'store/modules/bootstrap/model';

export const checkAgeNameArray = (
  ranges: IAgeName[]
): { overlaps: IAgeName[]; gaps: { start: number; end: number }[] } => {
  const overlaps: IAgeName[] = [];
  const gaps: { start: number; end: number }[] = [];

  for (let i = 0; i < ranges.length - 1; i++) {
    const current = ranges[i];
    const next = ranges[i + 1];

    if ((current.ageTo || 0) >= (next.ageFrom || 0)) {
      overlaps.push(current, next);
    } else if ((current.ageTo || 0) + 1 < (next.ageFrom || 0)) {
      gaps.push({ start: (current.ageTo || 0) + 1, end: (next.ageFrom || 0) - 1 });
    }
  }

  return { overlaps, gaps };
};

const validateMandatoryFields = <T>(mandatoryFields: Array<DeepKeyOf<T>>, item: T) => {
  for (const field of mandatoryFields) {
    const val = _.get(item, field);
    if (isBlank(val)) {
      return {
        isValid: false,
        message: `"${String(field)}" must be set`,
      };
    }
  }

  return { isValid: true, message: '' };
};

export const validateMealPlan = (mealPlan: Partial<IMealPlanProduct>) => {
  const mandatoryCheck = validateMandatoryFields<Partial<IMealPlanProduct>>(['name', 'meta.categoryType'], mealPlan);
  if (mandatoryCheck?.isValid === false) {
    return mandatoryCheck;
  }

  const { overlaps, gaps } = checkAgeNameArray(_.orderBy(mealPlan.options?.ages, ['ageFrom'], ['asc']));

  if (mealPlan.options?.ages.some(x => !x.name)) {
    return {
      isValid: false,
      message: 'all age groups must have a name',
    };
  }

  if (overlaps.length > 0) {
    return {
      isValid: false,
      message: `age groups overlap: ${overlaps.map(x => x.name).join(', ')}`,
    };
  }

  if (gaps.length > 0) {
    return {
      isValid: false,
      message: `age groups are not contiguous: ${gaps.map(x => `${x.start}-${x.end}`).join(', ')}`,
    };
  }

  return { isValid: true, message: '' };
};

export const validateHotelDetails = (hotel: IHotel) => {
  const mandatoryFields: (keyof IHotel)[] = [
    'name',
    'inLoveWith',
    'countryCode',
    'starRating',
    'description',
    'region',
    'hotelId',
  ];
  const mandatoryCheck = validateMandatoryFields<Partial<IHotel>>(mandatoryFields, hotel);
  if (mandatoryCheck?.isValid === false) {
    return mandatoryCheck;
  }

  // must have at least 1 filter
  if (hotel.filters.length === 0) {
    return { isValid: false, message: 'Hotel must have at least 1 filter' };
  }

  // if we have an external currency exchange rate, it must be a decimal number
  if (!isBlank(hotel.externalCurrencyExchangeRate)) {
    if (!hotel.externalCurrencyExchangeRate!.match(/^\d+(\.\d{1,4})?$/)) {
      return {
        isValid: false,
        message: '"External Currency Exchange Rate" must be a decimal number, and a max of 4 decimal places',
      };
    }
  }

  // fuel charge must be a decimal number with a maximum of 2 decimal places
  if (!isBlank(hotel.fuelCharge)) {
    if (!hotel.fuelCharge!.match(/^\d+(\.\d{1,2})?$/)) {
      return {
        isValid: false,
        message: '"Fuel Charge" must be a decimal number, and a max of 2 decimal places',
      };
    }
  }

  // if its not in the maldives, it cant have green tax standard occupancy
  if (hotel.countryCode !== 'MV' && hotel.greenTaxIsStandardOccupancy === true) {
    return {
      isValid: false,
      message: `"Green Tax Is Standard Occupancy" only supported on Maldives hotels`,
    };
  }

  if (_.isNil(hotel.externalSystem)) {
    if (!isBlank(hotel.externalSupplierId) || !isBlank(hotel.externalHotelId)) {
      return {
        isValid: false,
        message: `"External System" must be set if "External Supplier ID" or "External Hotel ID" is set`,
      };
    }
  }

  // if external system is set...
  if (!isBlank(hotel.externalSystem)) {
    // ...and its illusions, we need to have an external hotel id
    if (hotel.externalSystem === 'illusions' && isBlank(hotel.externalHotelId)) {
      return {
        isValid: false,
        message: 'If "External System" is "Illusions", then "External Hotel ID" must be provided',
      };
    }

    // ...and its derbysoft, we need to have an external supplier id and a hotel id
    if (hotel.externalSystem === 'derbysoft' && (isBlank(hotel.externalHotelId) || isBlank(hotel.externalSupplierId))) {
      return {
        isValid: false,
        message:
          'If "External System" is "Derbysoft", then "External Hotel ID" AND "External Supplier ID" must be provided',
      };
    }
  }

  // if external system is NOT set
  if (isBlank(hotel.externalSystem)) {
    if (!isBlank(hotel.externalSupplierId) || !isBlank(hotel.externalHotelId)) {
      return {
        isValid: false,
        message: 'Both externalSupplierId and externalHotelId should be empty if externalSystem is empty',
      };
    }
  }

  // hotel.location needs to be lat/lon format
  // valid examples: "37.7749295, -122.4194155", "123, 123", "-123.123, 123.123"
  // basically: a decimal number, a comma, another decimal number
  if (!isBlank(hotel.location)) {
    const [lat, lot] = hotel.location!.split(',').map(x => parseFloat(x.trim()));
    if (isNaN(lat) || isNaN(lot)) {
      return {
        isValid: false,
        message: '"Location" must be in lat/lon format, e.g "50, -50"',
      };
    }
  }

  return { isValid: true, message: '' };
};

export const validateAmenities = (hotel: IHotel) => {
  const amenities = hotel.amenities || [];
  for (const amenity of amenities) {
    if (isBlank(amenity)) {
      return { isValid: false, message: 'amenities cannot be blank' };
    }
  }
  return { isValid: true, message: '' };
};

export const validateHighlights = (hotel: IHotel) => {
  const highlights = hotel.highlights || [];
  for (const highlight of highlights) {
    if (isBlank(highlight)) {
      return { isValid: false, message: 'highlights cannot be blank' };
    }
  }
  return { isValid: true, message: '' };
};

export const validateOverview = (hotel: IHotel) => {
  const overview = hotel.overview || [];
  for (const overviewItem of overview) {
    if (isBlank(overviewItem)) {
      return { isValid: false, message: 'overview cannot be blank' };
    }
  }
  return { isValid: true, message: '' };
};

export const validatePoliciesRestrictions = (hotel: IHotel) => {
  const policiesRestrictions = hotel.policiesAndRestrictions || [];
  for (const policyRestriction of policiesRestrictions) {
    if (isBlank(policyRestriction)) {
      return { isValid: false, message: 'policiesRestrictions cannot be blank' };
    }
  }
  return { isValid: true, message: '' };
};

export const validateContactDetails = (hotel: IHotel) => {
  const mandatoryCheck = validateMandatoryFields<Partial<IHotel>>(['reservationEmails'], hotel);
  if (mandatoryCheck.isValid === false) {
    return mandatoryCheck;
  }
  return { isValid: true, message: '' };
};

export const validateSeasonalProductRate = (
  seasonalProductRate: Partial<ISeasonalProductRate>,
  product: IProduct<any>,
  epsProduct?: IBootstrapExtraPersonSupplementProduct
) => {
  const mandatoryCheck = validateMandatoryFields<Partial<ISeasonalProductRate>>(
    ['seasonUuid', 'markupAmount', 'markupType'],
    seasonalProductRate
  );
  if (mandatoryCheck.isValid === false) {
    return mandatoryCheck;
  }

  if (seasonalProductRate.seasonUuid === undefined) {
    return { isValid: false, message: 'Season must be set' };
  }

  if (seasonalProductRate.isOnRequest === false && isBlank(seasonalProductRate.rate)) {
    return { isValid: false, message: 'If rate is not on request, rate amount must be set' };
  }

  // if the product has options.ages, then the seasonalProductRate needs an alternateRate for each age group
  if (seasonalProductRate.isOnRequest === false && product.options.ages && product.type !== 'Accommodation') {
    for (const age of product.options.ages) {
      const alternateRateForAge = _.get(seasonalProductRate, `alternateRates.${age.name}`, null);
      if (_.isNil(alternateRateForAge)) {
        return { isValid: false, message: `Alternate rate information for age "${age.name}" is required` };
      }
      if (isBlank(alternateRateForAge.rate)) {
        return { isValid: false, message: `Alternate rate for age "${age.name}" must be set` };
      }
    }
  }

  if (product.type === 'Accommodation') {
    // we need to check EPS stuff
    const epsRate = seasonalProductRate.seasonalProductAddonRates?.find(rate => rate.productUuid === epsProduct?.uuid);
    if (isBlank(epsRate)) {
      return { isValid: false, message: 'EPS rate must be set' };
    }
    // @ts-ignore
    if (isBlank(epsRate.rate) || isNaN(epsRate.rate)) {
      console.log('b');
      return { isValid: false, message: 'EPS "Adult" rate amount must be set' };
    }
    for (const age of product.options.ages) {
      const epsRateForAge = _.get(epsRate, `alternateRates.${age.name}`, null);
      if (_.isNil(epsRateForAge)) {
        console.log('c');
        return { isValid: false, message: `EPS Alternate rate information for age "${age.name}" is required` };
      }
      // @ts-ignore
      if (isBlank(epsRateForAge.rate) || isNaN(epsRateForAge.rate)) {
        console.log('d');
        return { isValid: false, message: `EPS Alternate rate "${age.name}" amount must be set` };
      }
    }
  }

  return { isValid: true, message: '' };
};

export const validateSeasonalProductAddonRate = (
  seasonalProductAddonRate: Partial<ISeasonalProductAddonRate>,
  productAges: IAgeName[],
  seasonalProductRate: ISeasonalProductRate,
  isCreating = false
) => {
  if (seasonalProductAddonRate.isOnRequest === false && isBlank(seasonalProductAddonRate.rate)) {
    return { isValid: false, message: 'If rate is not on request, rate amounts must be set' };
  }

  for (const age of productAges) {
    const ageBracketRate = _.get(seasonalProductAddonRate, `alternateRates.${age.name}`, null);
    if (_.isNil(ageBracketRate)) {
      return { isValid: false, message: `rate information for age "${age.name}" is required` };
    }
    // whether we have a product or not depends on if we're making new, or updating a batch of existing
    if (isBlank(ageBracketRate.rate)) {
      if (seasonalProductAddonRate.product) {
        return {
          isValid: false,
          message: `rate for age "${seasonalProductAddonRate.product!.name} > ${age.name}" must have a rate`,
        };
      } else {
        return {
          isValid: false,
          message: `rate for age "${age.name}" must have a rate`,
        };
      }
    }
    // @ts-ignore
    if (isNaN(ageBracketRate.markupAmount) || isNaN(ageBracketRate.rate)) {
      return { isValid: false, message: `rates markup amounts and rates for age "${age.name}" must be numbers` };
    }
  }

  if (isCreating) {
    const mealPlanUuid = seasonalProductAddonRate.productUuid;
    const alreadyExists = seasonalProductRate.seasonalProductAddonRates?.find(
      rate => rate.productUuid === mealPlanUuid
    );
    if (alreadyExists) {
      return { isValid: false, message: `meal plan rate for this meal plan already exists for this room rate` };
    }
  }

  return { isValid: true, message: '' };
};

export const validateSeason = (season: Partial<ISeason>) => {
  const seasonDates = (season.seasonDates || []).filter(sd => sd.deleted !== true);

  const mandatoryCheck = validateMandatoryFields<Partial<ISeason>>(['name'], season);
  if (mandatoryCheck.isValid === false) {
    return mandatoryCheck;
  }
  if (seasonDates.length === 0) {
    return { isValid: false, message: 'must have at least 1 date period' };
  }

  for (const sd of seasonDates) {
    // @ts-ignore
    if (isNaN(new Date(sd.validFrom)) || isNaN(new Date(sd.validTo))) {
      return { isValid: false, message: 'Season dates must be valid dates' };
    }

    // if the season has a validFrom date, it must be before the validTo date
    if (new Date(sd.validFrom) > new Date(sd.validTo)) {
      return { isValid: false, message: 'The from date must be before the to date' };
    }
  }

  return { isValid: true, message: '' };
};

export const validateRoom = (room: Partial<IAccommodationProduct>) => {
  const mandatoryCheck = validateMandatoryFields<Partial<IAccommodationProduct>>(
    ['name', 'meta.categoryType', 'meta.description', 'meta.amenities'],
    room
  );
  if (mandatoryCheck.isValid === false) {
    return mandatoryCheck;
  }

  if (_.isNil(room.options)) {
    return { isValid: false, message: 'must have options' };
  }
  if (room.options.occupancy.standardOccupancy <= 0) {
    return { isValid: false, message: 'must have a standard occupancy greater than 0' };
  }
  if (room.options.occupancy.maximumPeople <= 0) {
    return { isValid: false, message: 'must have a maximum occupancy greater than 0' };
  }

  const hasDefaultOccupancy = room.options.occupancy.limits.some(limit => limit.name === 'default');
  if (!hasDefaultOccupancy) {
    return { isValid: false, message: 'must have an occupancy limit named "default"' };
  }

  if (room.options.ages.length >= 1) {
    // if the room has age groups, the age groups must start from age 0

    const firstAgeGroup = room.options.ages[0];
    if (firstAgeGroup.ageFrom !== 0) {
      return { isValid: false, message: 'the first age group must start from age 0' };
    }

    const hasAgeGroupsThatArentInOccupancyLimits = room.options.ages.some(age => {
      const hasLimit = room.options?.occupancy.limits.some(limit => limit.name === age.name);
      return !hasLimit;
    });
    if (hasAgeGroupsThatArentInOccupancyLimits) {
      return { isValid: false, message: 'must have an occupancy limit for each age group' };
    }

    const { overlaps, gaps } = checkAgeNameArray(_.orderBy(room.options?.ages, ['ageFrom'], ['asc']));

    if (room.options?.ages.some(x => !x.name)) {
      return {
        isValid: false,
        message: 'all age groups must have a name',
      };
    }

    if (overlaps.length > 0) {
      return {
        isValid: false,
        message: `age groups overlap: ${overlaps.map(x => x.name).join(', ')}`,
      };
    }

    if (gaps.length > 0) {
      return {
        isValid: false,
        message: `age groups are not contiguous: ${gaps.map(x => `${x.start}-${x.end}`).join(', ')}`,
      };
    }
  }

  if ((room.meta?.amenities || []).length <= 0) {
    return { isValid: false, message: 'must have at least 1 amenity' };
  }

  return { isValid: true, message: '' };
};

export const validateTransfer = (transfer: Partial<ITransferProduct>) => {
  const mandatoryCheck = validateMandatoryFields<Partial<ITransferProduct>>(
    ['name', 'category', 'options.capacity'],
    transfer
  );
  if (mandatoryCheck?.isValid === false) {
    return mandatoryCheck;
  }

  if (_.isNil(transfer.options)) {
    return { isValid: false, message: 'must have options' };
  }

  // check that transfer.options.capacity is an integer number
  if (!isBlank(transfer.options?.capacity) && !Number.isInteger(transfer.options.capacity)) {
    return { isValid: false, message: 'capacity must be an integer number' };
  }

  if (transfer.options?.ages && transfer.options.ages.length >= 1) {
    // if the ground service has age groups, the age groups must start from age 0
    const firstAgeGroup = transfer.options?.ages[0];
    if (firstAgeGroup && firstAgeGroup.ageFrom !== 0) {
      return { isValid: false, message: 'if providing age groups, the age groups must start from age 0' };
    }
  }

  return { isValid: true, message: '' };
};

export const validateSupplement = (supplement: Partial<ISupplementProduct>) => {
  const mandatoryCheck = validateMandatoryFields<Partial<ISupplementProduct>>(['name', 'category'], supplement);
  if (mandatoryCheck?.isValid === false) {
    return mandatoryCheck;
  }

  if (supplement.options?.ages && supplement.options.ages.length >= 1) {
    // if the ground service has age groups, the age groups must start from age 0
    const firstAgeGroup = supplement.options?.ages[0];
    if (firstAgeGroup && firstAgeGroup.ageFrom !== 0) {
      return { isValid: false, message: 'if providing age groups, the age groups must start from age 0' };
    }
  }

  return { isValid: true, message: '' };
};

export const validateGroundService = (groundService: Partial<IGroundServiceProduct>) => {
  const mandatoryCheck = validateMandatoryFields<Partial<IGroundServiceProduct>>(['name', 'category'], groundService);
  if (mandatoryCheck?.isValid === false) {
    return mandatoryCheck;
  }

  if (groundService.options?.ages && groundService.options.ages.length >= 1) {
    // if the ground service has age groups, the age groups must start from age 0
    const firstAgeGroup = groundService.options?.ages[0];
    if (firstAgeGroup && firstAgeGroup.ageFrom !== 0) {
      return { isValid: false, message: 'if providing age groups, the age groups must start from age 0' };
    }
  }

  return { isValid: true, message: '' };
};

export const validateFine = (fine: Partial<IFineProduct>) => {
  const mandatoryCheck = validateMandatoryFields<Partial<IFineProduct>>(['name', 'category'], fine);
  if (mandatoryCheck?.isValid === false) {
    return mandatoryCheck;
  }
  if (fine.options?.ages && fine.options.ages.length >= 1) {
    // if the fine has age groups, the age groups must start from age 0

    const firstAgeGroup = fine.options?.ages[0];
    if (firstAgeGroup && firstAgeGroup.ageFrom !== 0) {
      return { isValid: false, message: 'if providing age groups, the age groups must start from age 0' };
    }

    const { overlaps, gaps } = checkAgeNameArray(_.orderBy(fine.options?.ages, ['ageFrom'], ['asc']));

    if (fine.options?.ages.some(x => !x.name)) {
      return {
        isValid: false,
        message: 'all age groups must have a name',
      };
    }

    if (overlaps.length > 0) {
      return {
        isValid: false,
        message: `age groups overlap: ${overlaps.map(x => x.name).join(', ')}`,
      };
    }

    if (gaps.length > 0) {
      return {
        isValid: false,
        message: `age groups are not contiguous: ${gaps.map(x => `${x.start}-${x.end}`).join(', ')}`,
      };
    }
  }
  return { isValid: true, message: '' };
};
