import { IBookingManagerFinanceSubdomain } from './types';
import { initialState } from './model';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import * as Actions from './actions';
import { produce } from 'immer';

const financeReducer = (state: IBookingManagerFinanceSubdomain = initialState, action: Actions.FinanceAction) => {
  switch (action.type) {
    case Actions.GET_FINANCE_DOCUMENT_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentLoad = ENetworkRequestStatus.PENDING;
        return draft;
      });
    case Actions.GET_FINANCE_DOCUMENT_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentLoad = ENetworkRequestStatus.SUCCESS;
        draft.financeDocument = action.financeDocument;
        return draft;
      });
    case Actions.GET_FINANCE_DOCUMENT_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentLoad = ENetworkRequestStatus.ERROR;
        return draft;
      });

    case Actions.SET_IS_ADD_MODAL_OPEN:
      return produce(state, draft => {
        draft.isAddModalOpen = action.value;
        if (action.value === false) {
          draft.errorMessages.addRowAndSaveError = null;
        }
        return draft;
      });

    case Actions.ADD_ROW_AND_SAVE_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.addRowAndSaveLoad = ENetworkRequestStatus.PENDING;
        draft.errorMessages.addRowAndSaveError = null;
        return draft;
      });

    case Actions.ADD_ROW_AND_SAVE_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.addRowAndSaveLoad = ENetworkRequestStatus.SUCCESS;
        draft.financeDocument.rows = action.rows;
        draft.isAddModalOpen = false;
        return draft;
      });

    case Actions.ADD_ROW_AND_SAVE_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.addRowAndSaveLoad = ENetworkRequestStatus.ERROR;
        draft.errorMessages.addRowAndSaveError = action.error;
        return draft;
      });

    // delete row
    case Actions.DELETE_ROW_AND_SAVE_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.deleteRowAndSaveLoad = ENetworkRequestStatus.PENDING;
        return draft;
      });

    case Actions.DELETE_ROW_AND_SAVE_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.deleteRowAndSaveLoad = ENetworkRequestStatus.SUCCESS;
        draft.financeDocument.rows = action.rows;
        draft.isDeleteModalOpen = false;
        draft.deleteRowIndex = null;
        return draft;
      });

    case Actions.DELETE_ROW_AND_SAVE_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.deleteRowAndSaveLoad = ENetworkRequestStatus.ERROR;
        draft.isDeleteModalOpen = false;
        draft.deleteRowIndex = null;
        return draft;
      });

    case Actions.SET_DELETE_ROW_INDEX:
      return produce(state, draft => {
        draft.deleteRowIndex = action.value;
        return draft;
      });

    case Actions.SET_IS_DELETE_MODAL_OPEN:
      return produce(state, draft => {
        draft.isDeleteModalOpen = action.value;
        if (action.value === false) {
          draft.deleteRowIndex = null;
        }
        return draft;
      });

    // edit
    case Actions.SET_EDIT_ROW_INDEX:
      return produce(state, draft => {
        draft.editRowIndex = action.value;
        return draft;
      });

    case Actions.EDIT_ROW_AND_SAVE_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.editRowAndSaveLoad = ENetworkRequestStatus.PENDING;
        draft.errorMessages.editRowAndSaveError = null;
        return draft;
      });

    case Actions.EDIT_ROW_AND_SAVE_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.editRowAndSaveLoad = ENetworkRequestStatus.SUCCESS;
        draft.financeDocument.rows = action.rows;
        draft.isEditModalOpen = false;
        draft.editRowIndex = null;
        return draft;
      });

    case Actions.EDIT_ROW_AND_SAVE_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.editRowAndSaveLoad = ENetworkRequestStatus.ERROR;
        draft.errorMessages.editRowAndSaveError = action.error;
        return draft;
      });

    case Actions.SET_IS_EDIT_MODAL_OPEN:
      return produce(state, draft => {
        draft.isEditModalOpen = action.value;
        if (action.value === false) {
          draft.editRowIndex = null;
          draft.errorMessages.editRowAndSaveError = null;
        }
        return draft;
      });

    case Actions.GET_FINANCE_DOCUMENT_BALANCE_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentBalanceLoad = ENetworkRequestStatus.PENDING;
        return draft;
      });
    case Actions.GET_FINANCE_DOCUMENT_BALANCE_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentBalanceLoad = ENetworkRequestStatus.SUCCESS;
        draft.financeDocumentBalance = action.financeDocumentBalance;
        return draft;
      });
    case Actions.GET_FINANCE_DOCUMENT_BALANCE_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentBalanceLoad = ENetworkRequestStatus.ERROR;
        return draft;
      });
    case Actions.SET_FINANCE_TABLE_TYPE_TO_RENDER:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentLoad = ENetworkRequestStatus.IDLE;
        draft.networkRequests.financeDocumentBalanceLoad = ENetworkRequestStatus.IDLE;
        draft.financeTableTypeToRender = action.value;
        return draft;
      });

    case Actions.GET_FINANCE_DOCUMENT_VERSION_LIST_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentVersionListLoad = ENetworkRequestStatus.PENDING;
        return draft;
      });
    case Actions.GET_FINANCE_DOCUMENT_VERSION_LIST_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentVersionListLoad = ENetworkRequestStatus.SUCCESS;
        draft.versionList = action.versionList;
        // draft.activeVersion = action.versionList[0].version;
        return draft;
      });
    case Actions.GET_FINANCE_DOCUMENT_VERSION_LIST_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.financeDocumentVersionListLoad = ENetworkRequestStatus.ERROR;
        return draft;
      });

    case Actions.SET_FINANCE_DOCUMENT_ACTIVE_VERSION:
      return produce(state, draft => {
        draft.activeVersion = action.versionNum;
        return draft;
      });
    
    case Actions.GET_PAYMENT_METHODS_REQUEST:  
      return produce(state, draftState => {
        draftState.networkRequests.paymentMethodsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_PAYMENT_METHODS_SUCCESS:  
      return produce(state, draftState => {
        draftState.paymentMethods.list = action.paymentMethods;
        draftState.paymentMethods.defaultPaymentMethodPerCurrency = action.defaultPaymentMethodPerCurrency;
        draftState.networkRequests.paymentMethodsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    
    case Actions.GET_PAYMENT_METHODS_FAILURE:  
      return produce(state, draftState => {
        draftState.networkRequests.paymentMethodsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return state;
  }
};

export default financeReducer;
