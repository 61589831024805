import React, { useState, Fragment, useCallback } from 'react';
import { head, pathOr, pipe, propOr, values } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { isNil, pick } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Form, Input, Loader, List } from '@pure-escapes/webapp-ui-components';
import { saveAs } from 'file-saver';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import LodgingSummary from 'containers/LodgingSummary';
import SummaryFormExtras from 'containers/SummaryFormExtras/SummaryFormExtras';

import { useEffectBoundary } from 'effects';
import { isOnBasketRoute, mapWithIndex, noop } from 'utils';
import * as BookingBuilderUtils from 'utils/bookingBuilder';

import { ProductTypes, BookingStatusTypes } from 'config/enums';
import { APP_ROUTING_PATHS, LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS } from 'config';

import { ErrorMessage, ErrorMessageWarn, HotelName, StyledSummary } from './SummaryForm.styles';
import { PrimaryButtonTall, PrimaryButtonTallAltColor } from 'pureUi/Buttons';
import { makeBackendApi, ENetworkRequestStatus, ICompany, BookingBuilderRequest } from 'services/BackendApi';
import { makeBookingManagerApi } from 'services/BookingManagerApi';

import { TableCardBox, TableCardRow } from 'pureUi/TableCard';
import { StandardModal } from 'pureUi/Modal';
import { LiveRatePricesModal } from '../HotelContainer/LiveRatePricesModal';
import { RatesWarningModalContent } from '../HotelContainer/RatesWarningModal';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import * as HotelSelectors from 'store/modules/hotel';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';

import { isBookingOnRequestV2, getBookingCurrency } from 'store/modules/bookings';
import { getHotelAccommodationProductsSelector } from 'store/modules/hotelAccommodationProducts';
import * as UiActions from 'store/modules/ui/actions';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { DownloadQuoteMarginModal } from './DownloadQuoteMarginModal';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { ModalModes } from './const';
import { useHistory } from 'react-router';
import { sanitizeBBv1Request } from 'store/modules/bookingBuilder/sagas/updateBookingBuilderResponse';
import * as BasketSelectors from 'store/modules/basket/selectors';
import * as BasketActions from 'store/modules/basket/actions';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';
import { Link } from 'ui/Link';

const getSingleValue = (type, data) => pipe(pathOr([''], ['request', type]), head, propOr('', 'uuid'))(data);

export const renderHotelName = ({ name }) => {
  return (
    <TableCardBox>
      <TableCardRow depth={1}>
        <HotelName>{name}</HotelName>
      </TableCardRow>
    </TableCardBox>
  );
};

const renderError = ({ message, type }, i) => {
  if (type === 'warn') {
    return <ErrorMessageWarn key={i}>{message}</ErrorMessageWarn>;
  }

  return <ErrorMessage key={i}>{message}</ErrorMessage>;
};

const renderSummaryErrors = errors => !isNilOrEmpty(errors) && <List>{mapWithIndex(renderError, errors)}</List>;
const renderBookingErrors = errors => !isNilOrEmpty(errors) && <List>{mapWithIndex(renderError, errors)}</List>;

const renderLodgingSummary = (lodging, availableProductSets, potentialBooking, textOnlyOffersPerLodging) => {
  return (
    <LodgingSummary
      hotelUuid={lodging.hotelUuid}
      key={lodging.index}
      lodging={lodging}
      availableProductSets={availableProductSets}
      potentialBooking={potentialBooking}
      textOnlyOffersPerLodging={textOnlyOffersPerLodging}
    />
  );
};

const renderLodgingSummaries = (t, booking, lodgingSummaries) => {
  if (!booking) {
    return null;
  }
  const { request: bookingRequest, response: bookingResponse } = booking;

  if (!bookingRequest || !bookingResponse) {
    return null;
  }

  if (lodgingSummaries.length <= 0) {
    return null;
  }

  return (
    <TableCardBox>
      {lodgingSummaries.map(lodging =>
        renderLodgingSummary(
          lodging,
          bookingResponse.availableProductSets,
          bookingResponse.potentialBooking,
          bookingResponse.textOnlyOffersPerLodging
        )
      )}
    </TableCardBox>
  );
};

const handleSaveBookingButton = async props => {
  const { bookingDomain, backendApi, clientCountryCode, selectedCompanyMembership, actingOnBehalfOfUserUuid } = props;

  const attr = BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({ bookingDomain });

  try {
    const booking = await backendApi.createBooking({
      bookingAttributes: attr,
      clientCountryCode,
      selectedCompanyMembership,
      actingOnBehalfOfUserUuid,
    });
    window.location.href = `/booking-manager/${booking.uuid}`;
  } catch (e) {
    throw new Error(e);
  }
};

const enquiryCreatedNotificationMessage = booking => (
  <a
    className="download-quote-enquiry text-white no-underline hover:text-white"
    href={`/booking-manager/${booking.uuid}`}
  >
    <div>This enquiry has been saved with reference: {booking.humanReadableId}</div>
    <div>Click here to manage your booking</div>
  </a>
);

const handleDownloadQuoteButton = async props => {
  const {
    bookingDomain,
    backendApi,
    bookingManagerApi,
    enqueueNotification,
    clientCountryCode,
    quoteMrginLogoOption,
    selectedCompanyMembership,
    actingOnBehalfOfUserUuid,
  } = props;

  try {
    const attr = BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({ bookingDomain });
    const booking = await backendApi.createBooking({
      bookingAttributes: attr,
      clientCountryCode,
      selectedCompanyMembership,
      actingOnBehalfOfUserUuid,
    });

    enqueueNotification({
      message: enquiryCreatedNotificationMessage(booking),
      options: { variant: 'success' },
    });

    let upload = { url: '', filename: '' };
    if (quoteMrginLogoOption === EPdfLogo.MAIN_COMPANY_LOGO || quoteMrginLogoOption === null) {
      const { data } = await bookingManagerApi.generateBreakdownPdfForTa(booking.uuid);
      upload = data;
    } else {
      const { data } = await bookingManagerApi.generateBreakdownPdfForClient(booking.uuid, quoteMrginLogoOption);
      upload = data;
    }

    saveAs(upload.url, upload.filename);
  } catch (err) {
    enqueueNotification({
      message: 'Failed to generate a quote to download',
      options: { variant: 'error' },
    });

    throw err;
  }
};

const handleRequestBookingButton = async props => {
  const {
    bookingDomain,
    backendApi,
    liveRatePricesModalData,
    clientCountryCode,
    selectedCompanyMembership,
    isInstantBook = false,
    actingOnBehalfOfUser,
  } = props;

  const attr = {
    ...BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({
      bookingDomain,
      bookingStatus: BookingStatusTypes.REQUESTED,
    }),
    isInstantBook,
  };

  // merge new prices coming from priceCheck endpoint
  const bookingAttributes = !liveRatePricesModalData
    ? attr
    : {
        ...attr,
        bookingBuild: {
          ...attr.bookingBuild,
          Accommodation: attr.bookingBuild!.Accommodation.map((acc, index) => ({
            ...acc,
            liveRate: {
              ...acc.liveRate,
              amount: liveRatePricesModalData[index].newPrice ?? liveRatePricesModalData[index].prevPrice,
            },
          })),
        },
      };

  try {
    props.bbv1ClearBookingErrors();
    const booking = await backendApi.createBooking({
      bookingAttributes,
      clientCountryCode,
      selectedCompanyMembership,
      actingOnBehalfOfUser,
    });
    window.location.href = `/booking-manager/${booking.uuid}`;
  } catch (e) {
    e.response.data.errors?.map(error => props.bbv1AddBookingError(error));
    throw e;
  }
};

interface BookingAccommodation {
  uuid: string;
  startDate: string;
  endDate: string;
  honeymoon: boolean;
  anniversary: boolean;
  wedding: boolean;
  birthday: boolean;
  repeatCustomer: boolean;
  guestAges: {
    numberOfAdults: number;
    agesOfAllChildren: number[];
  };
  availableToInstantBook: boolean;
  liveRate?: {
    externalRateId: string;
    externalMealPlanCode: string;
    externalMealPlanDescription: string;
    amount: number;
  };
}

const RenderForm = ({
  t,
  booking,
  // bookLabel,
  canBook,
  errors,
  bookingErrors,
  id,
  initialValues,
  isOnRequest,
  summaryOnly,
  backendApi,
  bookingManagerApi,
  bookingDomain,
  handleAddToProposalClick,
  onSubmit,
  domainValidation,
  isPristine,
  setIsPristineAction,
  hasClicked,
  setHasClicked,
  isQuoteMarginSelectorVisible,
  setQuoteMarginSelectorVisibility,
  enqueueNotification,
  bbv1AddBookingError,
  bbv1ClearBookingErrors,
  liveRatePricesModalData,
  currency,
  isLiveRatePricesModalOpen,
  openLiveRatePricesModal,
  closeLiveRatePricesModal,
  accommodationProducts,
  modalRatesWarningMode,
  openModalRatesWarning,
  closeModalRatesWarning,
  totalForPricedItemsCents,
  clientCountryCode,
  companyUuid,
  selectedCompanyMembership,
  lodgingSummaries,
  hotel,
  originalRequest,
  selectedTa,
  isTa,
  basketBuild,
  actingOnBehalfOfUser,
}) => {
  const [isQuoteDownloading, setIsQuoteDownloading] = useState(false);
  const [basketPostingNetwork, setBasketPostingNetwork] = useState(ENetworkRequestStatus.IDLE);

  const isValid = values(domainValidation).every(arr => !arr?.length);
  const history = useHistory();
  const dispatch = useDispatch();
  const actionGuard = action =>
    isValid
      ? action
      : () => {
          setIsPristineAction(false);
        };
  const actionsDisabled =
    [ENetworkRequestStatus.PENDING, ENetworkRequestStatus.ERROR].includes(bookingDomain.updateBookingRequestStatus) ||
    !isValid;

  const handleSaveBooking = actionGuard(async () => {
    setHasClicked(true);
    try {
      await handleSaveBookingButton({
        backendApi,
        bookingDomain,
        clientCountryCode,
        selectedCompanyMembership,
        actingOnBehalfOfUser,
      });
    } catch (e) {
      setHasClicked(false);
    }
    closeModalRatesWarning();
  });

  const handleDownloadQuote = () => {
    setQuoteMarginSelectorVisibility(true);
    closeModalRatesWarning();
  };

  const handleDownloadQuoteTrigger = actionGuard(async (quoteMrginLogoOption: EPdfLogo | null) => {
    setHasClicked(true);
    let hasError = false;
    setIsQuoteDownloading(true);
    try {
      await handleDownloadQuoteButton({
        backendApi,
        bookingManagerApi,
        bookingDomain,
        enqueueNotification,
        clientCountryCode,
        quoteMrginLogoOption,
        selectedCompanyMembership,
        actingOnBehalfOfUser,
      });
    } catch (e) {
      hasError = true;
      throw e;
    } finally {
      if (!hasError) {
        setQuoteMarginSelectorVisibility(false);
      }
      setIsQuoteDownloading(false);
      setHasClicked(false);
    }

    closeModalRatesWarning();
  });

  const handleAddToProposal = actionGuard(() => {
    handleAddToProposalClick();
    closeModalRatesWarning();
  });

  const handleLiveRatePricesModalConfirm = async () => {
    setHasClicked(true);
    try {
      await handleRequestBookingButton({
        backendApi,
        bookingDomain,
        bbv1AddBookingError,
        bbv1ClearBookingErrors,
        liveRatePricesModalData,
        clientCountryCode,
        selectedCompanyMembership,
        actingOnBehalfOfUser,
      });
    } catch (e) {
      setHasClicked(false);
      enqueueNotification({
        message: 'Error booking Live Rate. Please, try later to complete.',
        options: { variant: 'error' },
      });
    }
  };

  const performBasketAdd = async (showToast: boolean = true) => {
    if (!originalRequest) {
      return;
    }
    const request = sanitizeBBv1Request(originalRequest, true);

    // regardless of whether we're UPDATING or BOOKING, we need to set the leave warning to false
    dispatch(BasketActions.setShouldShowLeaveWarningInBookingBuilderAction(false));
    if (!request || !request.Accommodation || request.Accommodation.length <= 0) {
      return;
    }

    setBasketPostingNetwork(ENetworkRequestStatus.PENDING);

    try {
      const res = await backendApi.addToBasket(request, basketBuild, actingOnBehalfOfUser, clientCountryCode);
      setBasketPostingNetwork(ENetworkRequestStatus.SUCCESS);
      if (showToast) {
        enqueueNotification({
          message: !isNil(basketBuild)
            ? `Product updated in basket successfully`
            : `Product added to basket successfully`,
          options: { variant: 'success' },
        });
      }
      return res;
    } catch (error) {
      setBasketPostingNetwork(ENetworkRequestStatus.ERROR);
      if (showToast) {
        enqueueNotification({
          message: `Saving Error. Please try later`,
          options: { variant: 'error' },
        });
      }
      console.error(error);
    }
  };

  const ModalActions = Object.freeze({
    [ModalModes.SAVE]: handleSaveBooking,
    [ModalModes.DOWNLOAD]: handleDownloadQuote,
    [ModalModes.PROPOSAL]: handleAddToProposal,
  });

  const requestedBookingAccommodations: BookingAccommodation[] = booking?.request?.Accommodation ?? [];

  const isAnyRoomsFreeSale = requestedBookingAccommodations.some(accommodation => accommodation.availableToInstantBook);

  const areAllBasketLodgingInstantBook = (lodgingSummaries || []).every(lodging => lodging.availableToInstantBook);

  const { dynamicParameters } = useDynamicParameters();

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
      {({ values }) => (
        <Fragment>
          <Input type="hidden" value={canBook} name="valid" />
          <SummaryFormExtras id={id} booking={booking} />
          {renderSummaryErrors(errors)}
          {/* TODO I _think_ this is complete legacy, but leaving it in for now in case we need to easily revert */}
          {/* <div className="mb-10px">{renderBookingErrors(bookingErrors)}</div> */}

          {isAnyRoomsFreeSale && !areAllBasketLodgingInstantBook && (
            <span className="mb-8px block uppercase text-xs font-[600] leading-14px text-[#FF0000] font-hurmegeometric-sans">
              Some rates added in the basket are not 'Instant Book', so you can't 'Book Now'
            </span>
          )}

          <div className="summary-form-buttons">
            <div className="flex space-x-2">
              {/* add to basket button */}
              {dynamicParameters.ENABLE_BASKET && (
                <PrimaryButtonTall
                  className={'add-to-cart-button'}
                  disabled={!canBook || hasClicked}
                  onClick={async () => {
                    setHasClicked(true);
                    await performBasketAdd();
                    setHasClicked(false);

                    // if we have a basketBuild, we're editing
                    // and if we're editing, we want to go BACK to basket
                    // with some params to pre-select things
                    if (basketBuild) {
                      const params = new URLSearchParams();
                      params.append('buildUuid', basketBuild.uuid);
                      if (!isTa) {
                        params.append('travelAgentUuid', selectedTa?.uuid);
                      }
                      if (actingOnBehalfOfUser) {
                        params.append('actingOnBehalfOfUserUuid', actingOnBehalfOfUser.uuid);
                      }

                      history.push(`${APP_ROUTING_PATHS.basket.home}?${params.toString()}`);
                    }
                  }}
                >
                  {basketBuild ? 'Update Basket' : 'Add to Basket'}
                </PrimaryButtonTall>
              )}

              {/* book button */}
              {!dynamicParameters.ENABLE_BASKET && !isOnBasketRoute() && (
                <PrimaryButtonTall
                  className={'book-now-button'}
                  disabled={!canBook || hasClicked}
                  onClick={async () => {
                    setHasClicked(true);
                    if (dynamicParameters.ENABLE_BASKET) {
                      const res = await performBasketAdd(false);
                      setHasClicked(false);
                      const params = new URLSearchParams({
                        buildUuid: res.data.uuid,
                      });
                      if (!isTa) {
                        params.append('travelAgentUuid', selectedTa?.uuid);
                      }
                      history.push(`/basket?${params.toString()}`);
                    } else {
                      setHasClicked(false);
                      const searchQuery = window.location.search ?? '';
                      history.push(`/booking-confirmation/${hotel.uuid}${searchQuery}`);
                    }
                  }}
                >
                  {dynamicParameters.ENABLE_BASKET ? 'Book' : 'Next'}
                </PrimaryButtonTall>
              )}
            </div>
          </div>

          {dynamicParameters.ENABLE_BASKET && (
            <div className="flex justify-around mt-3">
              <Link to={basketBuild ? `/basket?travelAgentUuid=${selectedTa.uuid}` : '/basket'} className="uppercase underline text-xs">
                Go to basket
              </Link>
            </div>
          )}
          {isQuoteMarginSelectorVisible && (
            <DownloadQuoteMarginModal
              isQuoteDownloading={isQuoteDownloading}
              onClose={() => setQuoteMarginSelectorVisibility(false)}
              onDownload={handleDownloadQuoteTrigger}
            />
          )}
          {isLiveRatePricesModalOpen && (
            <StandardModal removePadding={true} showCloseButton={false}>
              <LiveRatePricesModal
                onConfirm={handleLiveRatePricesModalConfirm}
                onClose={closeLiveRatePricesModal}
                isConfirmDisabled={hasClicked}
                liveRates={liveRatePricesModalData}
                currency={currency}
                total={totalForPricedItemsCents}
              />
            </StandardModal>
          )}
          {modalRatesWarningMode !== undefined && (
            <StandardModal removePadding={true} showCloseButton={false}>
              <RatesWarningModalContent
                onConfirm={ModalActions[modalRatesWarningMode]}
                onClose={closeModalRatesWarning}
                isConfirmDisabled={hasClicked}
              />
            </StandardModal>
          )}
        </Fragment>
      )}
    </Form>
  );
};

// used in the legacy booking builder
export const SummaryForm = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hasClicked, setHasClicked] = useState(false);
  const [isQuoteMarginSelectorVisible, setQuoteMarginSelectorVisibility] = useState(false);

  // these hardcoded values are legacy and can be removed in the future
  const onSubmit = noop;
  const summaryOnly = false;
  const compact = false;

  const { id, handleAddToProposalClick, className } = props;
  const booking = useSelector(BookingBuilderSelectors.bookingBuilderSelector);
  const hotelName = useSelector(HotelSelectors.hotelNameSelector);
  const hotel = useSelector(HotelSelectors.hotelSelector);
  const selectedTa = useSelector(AgentsSelectors.selectedTaSelector);
  const bookingDomain = useSelector(BookingBuilderSelectors.bookingSelector);
  const canBook = useSelector(BookingBuilderSelectors.bookingCanBookSelector);
  const bookingBuilderResponse = useSelector(BookingBuilderSelectors.bookingResponseSelector);
  const errors = useSelector(BookingBuilderSelectors.bookingResponseAllErrors);

  // @ts-ignore - legacy selectors require passed ID that isn't used by the selector itself
  const isOnRequest = useSelector(isBookingOnRequestV2(id));
  const bookingErrors = useSelector(BookingBuilderSelectors.bookingResponseAllBookingErrors);
  const domainValidation = useSelector(BookingBuilderSelectors.domainValidationSelector);
  const isPristine = useSelector(BookingBuilderSelectors.isPristineSelector);
  const currency = useSelector(getBookingCurrency);
  const accommodationProducts = useSelector(getHotelAccommodationProductsSelector);
  const searchQuery = useSelector(FastSearchSelectors.offersQuerySelector);
  const originalRequest = useSelector(BookingBuilderSelectors.bookingRequestSelector);

  // membership stuff
  const selectedCompanyMembership = useSelector(AgentsSelectors.selectedCompanyMembershipSelector);
  const isTa = useSelector(AuthSelectors.isTAOrActingAsTa);
  const actingOnBehalfOfUser = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUserSelector);

  const setIsPristineAction = (val: boolean) => dispatch(BookingBuilderActions.setIsPristineAction(val));
  const bbv1AddBookingError = (error: any) => dispatch(BookingBuilderActions.bbv1AddBookingErrorAction(error));
  const bbv1ClearBookingErrors = () => dispatch(BookingBuilderActions.bbv1ClearBookingErrorsAction());
  const enqueueNotification = (notification: any) => dispatch(UiActions.enqueueNotification(notification));

  const backendApi = makeBackendApi(selectedTa?.uuid);
  const bookingManagerApi = makeBookingManagerApi();

  const initialValues = {
    [ProductTypes.TRANSFER]: getSingleValue(ProductTypes.TRANSFER, booking),
    [ProductTypes.GROUND_SERVICE]: getSingleValue(ProductTypes.GROUND_SERVICE, booking),
    ...pathOr({}, ['products', ProductTypes.FINE], booking),
    ...pathOr({}, ['products', ProductTypes.SUPPLEMENT], booking),
  };

  const [formValues, setFormValues] = useState(initialValues);

  useEffectBoundary(() => {
    setFormValues(initialValues);
  }, [booking]);

  const [isLiveRatePricesModalOpen, setIsLiveRatePricesModalOpen] = useState(false);
  const [liveRatePricesModalData, setLiveRatePricesModalData] = useState([]);
  const openLiveRatePricesModal = useCallback(data => {
    setIsLiveRatePricesModalOpen(true);
    setLiveRatePricesModalData(data);
  }, []);
  const closeLiveRatePricesModal = useCallback(() => {
    setIsLiveRatePricesModalOpen(false);
    dispatch(
      BookingBuilderActions.bbv1AddBookingErrorAction({
        type: 'warn',
        detail:
          "THE PRICES DISPLAYED IN THE BASKET MAY NOT BE FINAL. ANY PRICE DIFFERENCE WILL BE CONFIRMED WITH YOU WHEN YOU SELECT 'BOOK'.",
      })
    );
  }, [BookingBuilderActions.bbv1AddBookingErrorAction]);

  const [modalRatesWarningMode, setModalRatesWarningMode] = useState();
  const closeModalRatesWarning = useCallback(() => setModalRatesWarningMode(undefined), []);
  const openModalRatesWarning = useCallback(mode => () => setModalRatesWarningMode(mode), []);

  const lodgingSummaries = BookingBuilderUtils.generateLodgingSummariesFromBooking(t, booking);

  const basketBuild = useSelector(BasketSelectors.basketBuildSelector);

  return (
    <StyledSummary className={className} data-compact={compact}>
      <Loader isLoading={false} showPrev={true} text="Updating...">
        {renderHotelName({
          name: hotelName || booking?.response?.hotel?.name || 'Hotel',
        })}

        {renderLodgingSummaries(t, booking, lodgingSummaries)}

        <RenderForm
          {...{
            t,
            initialValues: formValues,
            companyUuid: props.companyUuid,
            backendApi,
            bookingManagerApi,
            handleAddToProposalClick,
            domainValidation,
            isPristine,
            setIsPristineAction,
            enqueueNotification,
            onSubmit,
            summaryOnly,
            id,
            isOnRequest,
            errors,
            booking,
            canBook,
            bookingDomain,
            hasClicked,
            setHasClicked,
            isQuoteMarginSelectorVisible,
            setQuoteMarginSelectorVisibility,
            bbv1AddBookingError,
            bbv1ClearBookingErrors,
            bookingErrors,
            currency,
            liveRatePricesModalData,
            isLiveRatePricesModalOpen,
            openLiveRatePricesModal,
            closeLiveRatePricesModal,
            accommodationProducts,
            modalRatesWarningMode,
            closeModalRatesWarning,
            openModalRatesWarning,
            totalForPricedItemsCents: bookingBuilderResponse?.totals?.totalForPricedItemsCents ?? 0,
            clientCountryCode: searchQuery.clientCountryCode,
            selectedCompanyMembership,
            lodgingSummaries,
            hotel,
            originalRequest,
            selectedTa,
            isTa,
            basketBuild,
            actingOnBehalfOfUser,
          }}
        />
      </Loader>
    </StyledSummary>
  );
};

export default SummaryForm;
