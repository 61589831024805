import * as Actions from './actions';
import * as CompanyActions from '../companyInfo/actions';
import { produce } from 'immer';
import { ILedgerDomain, initialState } from './model';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { IBookingLedgerRequestData, IDepositAccountRequestData } from 'services/BookingManagerApi';

export const ledgerReducer = (state: ILedgerDomain = initialState, action: Actions.LedgerAction | CompanyActions.FetchCompanyDepositAccountBalanceSuccess): ILedgerDomain => {
  switch (action.type) {
    case Actions.SELECT_COMPANY: {
      return produce(state, draftState => {
        draftState.selectedCompany = action.company;
        return draftState;
      });
    }

    case Actions.BOOKING_STATEMENT_TOTALS_REQUEST: {
      return produce(state, draftState => {
        draftState.bookingTotals = [];
        draftState.bookingTotalsLoad = ENetworkRequestStatus.PENDING;
        draftState.bookingTotalsError = undefined;

        return draftState;
      });
    }

    case Actions.BOOKING_STATEMENT_TOTALS_SUCCESS: {
      return produce(state, draftState => {
        draftState.bookingTotals = action.totals;
        draftState.bookingTotalsLoad = ENetworkRequestStatus.SUCCESS;
        draftState.bookingTotalsError = undefined;

        return draftState;
      });
    }

    case Actions.BOOKING_STATEMENT_TOTALS_FAILURE: {
      return produce(state, draftState => {
        draftState.bookingTotalsLoad = ENetworkRequestStatus.ERROR;
        draftState.bookingTotalsError = action.error;

        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_REQUEST: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.data = null;
        draftState.bookingsByCurrency.currency = action.currency;
        draftState.bookingsByCurrency.bookingLedgerLoad = ENetworkRequestStatus.PENDING;
        draftState.bookingsByCurrency.bookingLedgerError = undefined;

        return draftState;
      });
    }

    case Actions.DEPOSIT_STATEMENT_TOTALS_REQUEST: {
      return produce(state, draftState => {
        draftState.depositTotals = [];
        draftState.depositTotalsLoad = ENetworkRequestStatus.PENDING;
        draftState.depositTotalsError = undefined;

        return draftState;
      });
    }

    case Actions.DEPOSIT_STATEMENT_TOTALS_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositTotals = action.totals;
        draftState.depositTotalsLoad = ENetworkRequestStatus.SUCCESS;
        draftState.depositTotalsError = undefined;

        return draftState;
      });
    }

    case Actions.DEPOSIT_STATEMENT_TOTALS_FAILURE: {
      return produce(state, draftState => {
        draftState.depositTotalsLoad = ENetworkRequestStatus.ERROR;
        draftState.depositTotalsError = action.error;

        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_SUCCESS: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.data = action.bookingLedger;
        draftState.bookingsByCurrency.bookingLedgerLoad = ENetworkRequestStatus.SUCCESS;
        draftState.bookingsByCurrency.bookingLedgerError = undefined;
        draftState.bookingsByCurrency.request.updateFilters = ENetworkRequestStatus.SUCCESS;

        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_FAILURE: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.bookingLedgerLoad = ENetworkRequestStatus.ERROR;
        draftState.bookingsByCurrency.bookingLedgerError = undefined;
        draftState.bookingsByCurrency.request.updateFilters = ENetworkRequestStatus.ERROR;

        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_SET_SORT: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.sortBy = action.sortBy;
        draftState.bookingsByCurrency.sortOrder = action.sortOrder;

        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_SET_PAGE_NUMBER: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.currentPage = action.pageNumber;

        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_SET_ITEMS_PER_PAGE: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.itemsPerPage = action.itemsPerPage;

        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_RESET: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency = initialState.bookingsByCurrency;

        draftState.statementFilterFromDate = null;
        draftState.statementFilterToDate = null;
        draftState.statementFilterTypes = [];
        draftState.statementFilterStatus = null;
        draftState.statementFilterBookingRefs = [''];
        draftState.statementFilterAmountMin = null;
        draftState.statementFilterAmountMax = null;

        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_REQUEST: {
      return produce(state, draftState => {
        draftState.depositAccount.data = null;
        draftState.depositAccount.currency = action.currency;
        draftState.depositAccount.requests.rowsLoad = ENetworkRequestStatus.PENDING;
        draftState.depositAccount.errors.rowsLoadError = undefined;

        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositAccount.data = {
          balanceCarriedForward: action.depositAccount.balanceCarriedForward,
          positiveTotal: action.depositAccount.positiveTotal,
          negativeTotal: action.depositAccount.negativeTotal,
          netTotal: action.depositAccount.netTotal,
          rows: action.depositAccount.rows,
        };
        draftState.depositAccount.pagination.totalPages = Math.ceil(
          action.depositAccount.pagination.totalRows / draftState.depositAccount.pagination.itemsPerPage
        );
        draftState.depositAccount.requests.rowsLoad = ENetworkRequestStatus.SUCCESS;
        draftState.depositAccount.errors.rowsLoadError = undefined;

        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_FAILURE: {
      return produce(state, draftState => {
        draftState.depositAccount.requests.rowsLoad = ENetworkRequestStatus.ERROR;
        draftState.depositAccount.errors.rowsLoadError = action.error;

        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_SET_SORT: {
      return produce(state, draftState => {
        draftState.depositAccount.sorting.sortBy = action.sortBy;
        draftState.depositAccount.sorting.sortOrder = action.sortOrder;

        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_SET_PAGE_NUMBER: {
      return produce(state, draftState => {
        draftState.depositAccount.pagination.currentPage = action.pageNumber;

        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_SET_ITEMS_PER_PAGE: {
      return produce(state, draftState => {
        draftState.depositAccount.pagination.itemsPerPage = action.itemsPerPage;

        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_RESET: {
      return produce(state, draftState => {
        draftState.depositAccount = initialState.depositAccount;

        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_POST_EXPORT_REQUEST: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.csvExport.request = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_POST_EXPORT_SUCCESS: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.csvExport.request = ENetworkRequestStatus.SUCCESS;
        draftState.bookingsByCurrency.csvExport.exportUuid = action.exportUuid;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_POST_EXPORT_FAILURE: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.csvExport.request = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_GET_EXPORT_SUCCESS: {
      return produce(state, draftState => {
        if (action.signedUrl) {
          draftState.bookingsByCurrency.csvExport.exportUuid = null;
        }
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_POST_AGGREGATE_EXPORT_REQUEST: {
      return produce(state, draftState => {
        draftState.summaryCsvExport.bookingLedger.request = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_POST_AGGREGATE_EXPORT_SUCCESS: {
      return produce(state, draftState => {
        draftState.summaryCsvExport.bookingLedger.request = ENetworkRequestStatus.SUCCESS;
        draftState.summaryCsvExport.bookingLedger.exportUuid = action.exportUuid;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_POST_AGGREGATE_EXPORT_FAILURE: {
      return produce(state, draftState => {
        draftState.summaryCsvExport.bookingLedger.request = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_GET_AGGREGATE_EXPORT_SUCCESS: {
      return produce(state, draftState => {
        if (action.signedUrl) {
          draftState.summaryCsvExport.bookingLedger.exportUuid = null;
        }
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_GET_AGGREGATE_EXPORT_FAILURE: {
      return produce(state, draftState => {
        draftState.summaryCsvExport.bookingLedger.exportUuid = null;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_GET_EXPORT_FAILURE: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.csvExport.exportUuid = null;
        return draftState;
      });
    }
    case Actions.STATEMENT_FILTER_SET_FROM_DATE:
      return produce(state, draftState => {
        draftState.statementFilterFromDate = action.fromDate;
        return draftState;
      });

    case Actions.STATEMENT_FILTER_SET_TO_DATE:
      return produce(state, draftState => {
        draftState.statementFilterToDate = action.toDate;
        return draftState;
      });

    case Actions.STATEMENT_FILTER_SET_TYPE:
      return produce(state, draftState => {
        draftState.statementFilterTypes = action.rowTypes;
        return draftState;
      });

    case Actions.STATEMENT_FILTER_SET_STATUS:
      return produce(state, draftState => {
        draftState.statementFilterStatus = action.status;
        return draftState;
      });

    case Actions.STATEMENT_FILTER_ADD_BOOKING_REF:
      return produce(state, draftState => {
        draftState.statementFilterBookingRefs.push('');
        return draftState;
      });

    case Actions.STATEMENT_FILTER_SET_BOOKING_REF:
      return produce(state, draftState => {
        draftState.statementFilterBookingRefs[action.index] = action.bookingRefValue;
        return draftState;
      });

    case Actions.STATEMENT_FILTER_DELETE_BOOKING_REF:
      return produce(state, draftState => {
        draftState.statementFilterBookingRefs.splice(action.index, 1);
        return draftState;
      });

    case Actions.STATEMENT_FILTER_RESET:
      return produce(state, draftState => {
        draftState.statementFilterFromDate = initialState.statementFilterFromDate;
        draftState.statementFilterToDate = initialState.statementFilterToDate;
        draftState.statementFilterTypes = initialState.statementFilterTypes;
        draftState.statementFilterStatus = initialState.statementFilterStatus;
        draftState.statementFilterBookingRefs = initialState.statementFilterBookingRefs;
        draftState.statementFilterAmountMax = initialState.statementFilterAmountMax;
        draftState.statementFilterAmountMin = initialState.statementFilterAmountMin;
        return draftState;
      });

    // OWA-3233 reset the page number when we apply filters
    case Actions.STATEMENT_FILTER_UPDATE_RESULTS: {
      return produce(state, draftState => {
        const filter: IBookingLedgerRequestData['filter'] = {};

        if (state.statementFilterFromDate) {
          filter.fromDate = state.statementFilterFromDate;
        }
        if (state.statementFilterToDate) {
          filter.toDate = state.statementFilterToDate;
        }
        if (state.statementFilterTypes.length) {
          filter.type = state.statementFilterTypes;
        }
        if (state.statementFilterStatus) {
          filter.status = state.statementFilterStatus;
        }

        const refsSanitised = state.statementFilterBookingRefs.filter(x => x);
        if (refsSanitised.length) {
          filter.humanReadableId = [...refsSanitised];
        }

        if (state.statementFilterAmountMin !== null) {
          filter.amountMinCents = state.statementFilterAmountMin;
        }
        if (state.statementFilterAmountMax !== null) {
          filter.amountMaxCents = state.statementFilterAmountMax;
        }

        draftState.bookingsByCurrency.request.updateFilters = ENetworkRequestStatus.PENDING;
        draftState.bookingsByCurrency.filter = filter;
        draftState.bookingsByCurrency.currentPage = 0;
        return draftState;
      });
    }

    case Actions.DEPOSIT_FILTER_SET_FROM_DATE:
      return produce(state, draftState => {
        draftState.depositFilterFromDate = action.fromDate;
        return draftState;
      });

    case Actions.DEPOSIT_FILTER_SET_TO_DATE:
      return produce(state, draftState => {
        draftState.depositFilterToDate = action.toDate;
        return draftState;
      });

    case Actions.DEPOSIT_FILTER_SET_STATUS:
      return produce(state, draftState => {
        draftState.depositFilterStatus = action.status;
        return draftState;
      });

    case Actions.DEPOSIT_FILTER_SET_BOOKING_REF: {
      return produce(state, draftState => {
        draftState.depositFilterBookingRef = action.bookingRefValue;
        return draftState;
      });
    }

    case Actions.DEPOSIT_FILTER_RESET:
      return produce(state, draftState => {
        draftState.depositFilterFromDate = initialState.depositFilterFromDate;
        draftState.depositFilterToDate = initialState.depositFilterToDate;
        draftState.depositFilterStatus = initialState.depositFilterStatus;
        draftState.depositFilterBookingRef = initialState.depositFilterBookingRef;
        return draftState;
      });

    case Actions.DEPOSIT_FILTER_UPDATE_RESULTS: {
      return produce(state, draftState => {
        const filter: IDepositAccountRequestData['filter'] = {};

        if (state.depositFilterFromDate) {
          filter.fromDate = state.depositFilterFromDate;
        }
        if (state.depositFilterToDate) {
          filter.toDate = state.depositFilterToDate;
        }
        if (state.depositFilterStatus) {
          filter.status = state.depositFilterStatus;
        }

        const refSanitised = state.depositFilterBookingRef;
        if (refSanitised !== '') {
          filter.humanReadableIds = [refSanitised];
        }

        draftState.depositAccount.requests.rowsLoad = ENetworkRequestStatus.PENDING;
        draftState.depositAccount.filter = filter;
        draftState.depositAccount.errors.rowsLoadError = undefined;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_POST_EXPORT_REQUEST: {
      return produce(state, draftState => {
        draftState.depositAccount.csvExport.request = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_POST_EXPORT_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositAccount.csvExport.request = ENetworkRequestStatus.SUCCESS;
        draftState.depositAccount.csvExport.exportUuid = action.exportUuid;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_POST_EXPORT_FAILURE: {
      return produce(state, draftState => {
        draftState.depositAccount.csvExport.request = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_GET_EXPORT_SUCCESS: {
      return produce(state, draftState => {
        if (action.signedUrl) {
          draftState.depositAccount.csvExport.exportUuid = null;
        }
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_GET_EXPORT_FAILURE: {
      return produce(state, draftState => {
        draftState.depositAccount.csvExport.exportUuid = null;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_REQUEST: {
      return produce(state, draftState => {
        draftState.summaryCsvExport.depositAccount.request = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_SUCCESS: {
      return produce(state, draftState => {
        draftState.summaryCsvExport.depositAccount.request = ENetworkRequestStatus.SUCCESS;
        draftState.summaryCsvExport.depositAccount.exportUuid = action.exportUuid;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_POST_AGGREGATE_EXPORT_FAILURE: {
      return produce(state, draftState => {
        draftState.summaryCsvExport.depositAccount.request = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_SUCCESS: {
      return produce(state, draftState => {
        if (action.signedUrl) {
          draftState.summaryCsvExport.depositAccount.exportUuid = null;
        }
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_GET_AGGREGATE_EXPORT_FAILURE: {
      return produce(state, draftState => {
        draftState.summaryCsvExport.depositAccount.exportUuid = null;
        return draftState;
      });
    }

    case Actions.OPEN_BALANCE_CARRIED_FORWARD_MODAL: {
      return produce(state, draftState => {
        if (action.row !== undefined) {
          draftState.balanceCarriedForward.data = {};
          draftState.balanceCarriedForward.data.uuid = action.row.uuid;
          draftState.balanceCarriedForward.data.type = action.row.type;
          draftState.balanceCarriedForward.data.date = action.row.date;
          draftState.balanceCarriedForward.data.amountCents = action.row.amountCents;
          draftState.balanceCarriedForward.data.uploadUuid = action.row.uploadUuid;
          draftState.balanceCarriedForward.data.uploadName = action.row.uploadName;
          draftState.balanceCarriedForward.data.uploadUrl = action.row.uploadUrl;
        }
        draftState.balanceCarriedForward.isBCFModalOpen = true;
        return draftState;
      });
    }

    case Actions.CLOSE_BALANCE_CARRIED_FORWARD_MODAL: {
      return produce(state, draftState => {
        draftState.balanceCarriedForward.isBCFModalOpen = false;
        draftState.balanceCarriedForward.data = null;
        draftState.balanceCarriedForward.initialBCFRowExists = null;
        return draftState;
      });
    }

    case Actions.CHECK_INITIAL_BCF_REQUEST: {
      return produce(state, draftState => {
        draftState.balanceCarriedForward.requests.initialBCFRowLoad = ENetworkRequestStatus.PENDING;
        draftState.balanceCarriedForward.initialBCFRowExists = null;
        return draftState;
      });
    }

    case Actions.CHECK_INITIAL_BCF_SUCCESS: {
      return produce(state, draftState => {
        draftState.balanceCarriedForward.requests.initialBCFRowLoad = ENetworkRequestStatus.SUCCESS;
        draftState.balanceCarriedForward.initialBCFRowExists = action.initialBCFRowExists;
        draftState.balanceCarriedForward.firstRowDate = action.firstRow?.row.date ?? null;
        return draftState;
      });
    }

    case Actions.CHECK_INITIAL_BCF_FAILURE: {
      return produce(state, draftState => {
        draftState.balanceCarriedForward.requests.initialBCFRowLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.SAVE_BCF_ROW_REQUEST: {
      return produce(state, draftState => {
        draftState.balanceCarriedForward.requests.bcfRowCreate = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.SAVE_BCF_ROW_SUCCESS: {
      return produce(state, draftState => {
        draftState.balanceCarriedForward.requests.bcfRowCreate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    }

    case Actions.SAVE_BCF_ROW_FAILURE: {
      return produce(state, draftState => {
        draftState.balanceCarriedForward.requests.bcfRowCreate = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_GENERATE_PDF_REQUEST: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.pdf.requests.generate = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_GENERATE_PDF_SUCCESS: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.pdf.requests.generate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_GENERATE_PDF_FAILURE: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.pdf.requests.generate = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_REQUEST: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.pdf.requests.downloadHistory = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_SUCCESS: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.pdf.requests.downloadHistory = ENetworkRequestStatus.SUCCESS;
        draftState.bookingsByCurrency.pdf.downloadedPdfs = action.pdfs;
        return draftState;
      });
    }

    case Actions.BOOKING_LEDGER_DOWNLOAD_PDF_HISTORY_FAILURE: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.pdf.requests.downloadHistory = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.OPEN_DEPOSIT_ACCOUNT_MODAL: {
      return produce(state, draftState => {
        if (action.row !== undefined) {
          draftState.depositAccount.depositAccountModal.data = {};
          draftState.depositAccount.depositAccountModal.data.uuid = action.row.uuid;
          draftState.depositAccount.depositAccountModal.data.type = action.row.type;
          draftState.depositAccount.depositAccountModal.data.date = action.row.date;
          draftState.depositAccount.depositAccountModal.data.amountCents = action.row.amountCents;
          draftState.depositAccount.depositAccountModal.data.uploadUuid = action.row.uploadUuid;
          draftState.depositAccount.depositAccountModal.data.uploadName = action.row.uploadName;
          draftState.depositAccount.depositAccountModal.data.uploadUrl = action.row.uploadUrl;
          draftState.depositAccount.depositAccountModal.data.notes = action.row.notes;
          draftState.depositAccount.depositAccountModal.data.bankAccount = action.row.bankAccount;
        }
        draftState.depositAccount.depositAccountModal.isDepositAccountModalOpen = true;
        return draftState;
      });
    }

    case Actions.CLOSE_DEPOSIT_ACCOUNT_MODAL: {
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.isDepositAccountModalOpen = false;
        draftState.depositAccount.depositAccountModal.data = null;
        draftState.depositAccount.depositAccountModal.initialBCFRowExists = null;
        return draftState;
      });
    }

    case Actions.DEPOSIT_CHECK_INITIAL_BCF_REQUEST: {
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.requests.initialBCFRowLoad = ENetworkRequestStatus.PENDING;
        draftState.depositAccount.depositAccountModal.initialBCFRowExists = null;
        return draftState;
      });
    }

    case Actions.DEPOSIT_CHECK_INITIAL_BCF_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.requests.initialBCFRowLoad = ENetworkRequestStatus.SUCCESS;
        draftState.depositAccount.depositAccountModal.initialBCFRowExists = action.initialBCFRowExists;
        draftState.depositAccount.depositAccountModal.firstRowDate = action.firstRow?.row.date ?? null;
        return draftState;
      });
    }

    case Actions.DEPOSIT_CHECK_INITIAL_BCF_FAILURE: {
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.requests.initialBCFRowLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.DEPOSIT_SAVE_ROW_REQUEST: {
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.requests.bcfRowCreate = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.DEPOSIT_SAVE_ROW_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.requests.bcfRowCreate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    }

    case Actions.DEPOSIT_SAVE_ROW_FAILURE: {
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.requests.bcfRowCreate = ENetworkRequestStatus.ERROR;
      });
    }

    case Actions.SET_DAR_DELETE_MODAL_OPEN: {
      return produce(state, draftState => {
        draftState.depositAccount.isDeleteDARModalOpen = action.isOpen;
        return draftState;
      });
    }

    case Actions.DEPOSIT_DELETE_ROW_REQUEST: {
      return produce(state, draftState => {
        draftState.depositAccount.requests.rowDelete = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.DEPOSIT_DELETE_ROW_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositAccount.requests.rowDelete = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    }

    case Actions.DEPOSIT_DELETE_ROW_FAILURE: {
      return produce(state, draftState => {
        draftState.depositAccount.requests.rowDelete = ENetworkRequestStatus.ERROR;
      });
    }

    case Actions.SET_DEPOSIT_ACCOUNT_ROW_TO_DELETE: {
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountRowUuidToDelete = action.depositAccountRowUuid;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_GENERATE_PDF_REQUEST: {
      return produce(state, draftState => {
        draftState.depositAccount.pdf.requests.generate = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_GENERATE_PDF_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositAccount.pdf.requests.generate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_GENERATE_PDF_FAILURE: {
      return produce(state, draftState => {
        draftState.depositAccount.pdf.requests.generate = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_REQUEST: {
      return produce(state, draftState => {
        draftState.depositAccount.pdf.requests.downloadHistory = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositAccount.pdf.requests.downloadHistory = ENetworkRequestStatus.SUCCESS;
        draftState.depositAccount.pdf.downloadedPdfs = action.pdfs;
        return draftState;
      });
    }

    case Actions.DEPOSIT_ACCOUNT_DOWNLOAD_PDF_HISTORY_FAILURE: {
      return produce(state, draftState => {
        draftState.depositAccount.pdf.requests.downloadHistory = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.SUMMARY_GENERATE_PDF_REQUEST: {
      return produce(state, draftState => {
        draftState.summaryPdf.requests.generate = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.SUMMARY_GENERATE_PDF_SUCCESS: {
      return produce(state, draftState => {
        draftState.summaryPdf.requests.generate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    }

    case Actions.SUMMARY_GENERATE_PDF_FAILURE: {
      return produce(state, draftState => {
        draftState.summaryPdf.requests.generate = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.SUMMARY_DOWNLOAD_PDF_HISTORY_REQUEST: {
      return produce(state, draftState => {
        draftState.summaryPdf.requests.downloadHistory = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.SUMMARY_DOWNLOAD_PDF_HISTORY_SUCCESS: {
      return produce(state, draftState => {
        draftState.summaryPdf.requests.downloadHistory = ENetworkRequestStatus.SUCCESS;
        draftState.summaryPdf.downloadedPdfs = action.pdfs;
        return draftState;
      });
    }

    case Actions.SUMMARY_DOWNLOAD_PDF_HISTORY_FAILURE: {
      return produce(state, draftState => {
        draftState.summaryPdf.requests.downloadHistory = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.STATEMENT_FILTER_SET_AMOUNT_MIN: {
      return produce(state, draftState => {
        if (action.amount !== undefined) {
          draftState.statementFilterAmountMin = action.amount;
        } else {
          draftState.statementFilterAmountMin = null;
        }
        return draftState;
      });
    }

    case Actions.STATEMENT_FILTER_SET_AMOUNT_MAX: {
      return produce(state, draftState => {
        if (action.amount !== undefined) {
          draftState.statementFilterAmountMax = action.amount;
        } else {
          draftState.statementFilterAmountMax = null;
        }
        return draftState;
      });
    }

    case Actions.GET_DEPOSIT_PAYMENT_METHODS_REQUEST:  
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.requests.getPaymentMethods = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_DEPOSIT_PAYMENT_METHODS_SUCCESS:  
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.paymentMethods.list = action.paymentMethods;
        draftState.depositAccount.depositAccountModal.paymentMethods.defaultPaymentMethodPerCurrency = action.defaultPaymentMethodPerCurrency;
        draftState.depositAccount.depositAccountModal.requests.getPaymentMethods = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    
    case Actions.GET_DEPOSIT_PAYMENT_METHODS_FAILURE:  
      return produce(state, draftState => {
        draftState.depositAccount.depositAccountModal.requests.getPaymentMethods = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case CompanyActions.FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_SUCCESS: {
      return produce(state, draftState => {
        draftState.depositTotals = action.totals;
        return draftState;
      });
    }
  
    default:
      return state;
  }
};
