import React from 'react';
import { EUploadTag, IUploadFileInfo } from 'services/BackendApi';
import { SvgIcon } from 'ui/SvgIcon';
import Star from 'ui/Icons/star.component.svg';
import CloseBold from 'ui/Icons/close-bold.component.svg';
import Docs from 'ui/Icons/docs.component.svg';
import classNames from 'classnames';

const isUploadAnImage = (upload: IUploadFileInfo) => {
  return upload.tag === EUploadTag.PHOTO || upload.tag === EUploadTag.FEATURED_PHOTO;
};

const tagLabelMap = {
  [EUploadTag.PHOTO]: 'Photo',
  [EUploadTag.FEATURED_PHOTO]: 'Featured Photo',
  [EUploadTag.BROCHURE]: 'Brochure',
  [EUploadTag.FLOOR_PLAN]: 'Floor Plan',
};

export const Uploads = ({
  uploads,
  setFeaturedPhoto,
  deleteUpload,
  className,
}: {
  uploads: IUploadFileInfo[];
  setFeaturedPhoto?: (featuredPhoto: IUploadFileInfo) => void;
  deleteUpload?: (upload: IUploadFileInfo) => void;
  className?: string;
}) => {
  return (
    <div className={classNames('font-pt-sans grid grid-cols-3 gap-6 mt-4 w-full', className)}>
      {uploads?.map(upload => {
        if (isUploadAnImage(upload)) {
          // image upload
          return (
            <div key={upload.uuid} className="flex space-x-2 relative">
              <a href={upload.url} target="_blank">
                <img src={upload.url} alt={upload.displayName} className="w-32" />
              </a>
              <div className="flex flex-col space-y-1">
                <span className="font-bold w-[90%]">{upload.displayName}</span>
                <span className="">{tagLabelMap[upload.tag]}</span>
                <span className="text-xs">{upload.filename}</span>
              </div>

              {setFeaturedPhoto && upload.tag === EUploadTag.FEATURED_PHOTO && (
                <SvgIcon IconComponent={Star} className="absolute top-0 right-11 fill-teal-80" />
              )}

              {setFeaturedPhoto && upload.tag !== EUploadTag.FEATURED_PHOTO && (
                <button
                  onClick={() => {
                    if (confirm(`Set "${upload.displayName}" as Featured Photo?`)) {
                      setFeaturedPhoto(upload);
                    }
                  }}
                  title="Set image as Featured Photo"
                  className="bg-transparent p-0 m-0 absolute top-0 right-10 cursor-pointer hover:fill-teal-40"
                >
                  <SvgIcon IconComponent={Star} className="w-6 h-6 fill-gray-20 hover:fill-teal-40" />
                </button>
              )}

              {deleteUpload && (
                <button
                  onClick={() => {
                    if (confirm(`Delete "${upload.displayName}"?`)) {
                      deleteUpload(upload);
                    }
                  }}
                  title="Delete image"
                  className="bg-transparent absolute top-0 right-0 cursor-pointer"
                >
                  <SvgIcon IconComponent={CloseBold} className="w-3 h-3  fill-gray-80 hover:fill-red-90" />
                </button>
              )}
            </div>
          );
        } else {
          // not an image upload
          return (
            <div key={upload.uuid} className="flex space-x-2 relative">
              <a href={upload.url} target="_blank">
                <SvgIcon IconComponent={Docs} className="w-6 h-6 fill-gray-40 hover:fill-brown-prime" />
              </a>
              <div className="flex flex-col space-y-1 w-full">
                <a href={upload.url} target="_blank">
                  <span className="font-bold w-[90%]">{upload.displayName}</span>
                </a>
                <span className="">{tagLabelMap[upload.tag]}</span>

                <span className="text-xs">{upload.filename}</span>
              </div>

              {deleteUpload && (
                <button
                  onClick={() => {
                    if (confirm(`Delete "${upload.displayName}"?`)) {
                      deleteUpload(upload);
                    }
                  }}
                  className="bg-transparent absolute top-0 right-0 cursor-pointer"
                >
                  <SvgIcon IconComponent={CloseBold} className="w-3 h-3 fill-gray-80 hover:fill-red-90" />
                </button>
              )}
            </div>
          );
        }
      })}
    </div>
  );
};
