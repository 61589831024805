import React from 'react';
import { RHPSubtitle } from './RHPSubtitle';

export interface IRHPProductsProps {
  title: string;
  products: { uuid: string; name: string; }[];
  isVisible: boolean;
}

export const RHPProducts: React.FC<IRHPProductsProps> = ({ title, products, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="rhp-transfers">
      <RHPSubtitle>{title}</RHPSubtitle>
      {products.length === 0 ? (
        <p className="font-pt-sans text-[15px] italic leading-[normal] text-gray-100 mt-[5px] mb-0">None</p>
      ) : (
        products.map(item => (
          <p key={item.uuid} className="rhp-product m-0 mt-[5px]">{item.name}</p>
        ))
      )}
    </div>
  )
}
