import { call, takeLatest, select, put, cancelled, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import * as Actions from './actions';
import * as Selectors from './selectors';
import { enqueueNotification } from '../ui';
import { ENetworkRequestStatus, makeBackendApi, IHotelResponse } from 'services/BackendApi';
import {
  ILiveRatesListingFilter,
  IGetLiveRatesListingResponse,
  ILiveRatesSettingsInternalRoomsItem,
  ILiveRatesSettingsInternalRoomItemError,
  ILiveRatesSettingsExternalRoomsItem,
  ILiveRatesMappableStay,
} from 'services/BackendApi/types/LiveRatesInternal';
import { ILiveRatesListingItem } from 'services/BackendApi/types/LiveRatesInternal';
import { ILiveRatesDomain } from './model';
import { isNil } from 'lodash';

export function* getLiveRateMappingsRequestSaga() {
  // acts as the debounce, because we want this saga to be fired using `takeLatest`, but you
  // can't combine `takeLatest` with `debounce` directly
  yield delay(200);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const filter: ILiveRatesListingFilter = yield select(Selectors.filterSelector);
  const backendApi = makeBackendApi();

  yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.PENDING));

  try {
    const res: AxiosResponse<IGetLiveRatesListingResponse> = yield call(backendApi.getLiveRateMappings, filter, source);
    yield put(Actions.listActions.getSuccessAction(res.data.data.stays, res.data.meta.total));
    yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.SUCCESS));
  } catch (e) {
    yield put(Actions.listActions.getFailureAction(e.message));
    yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.ERROR));
    yield put(
      enqueueNotification({
        message: 'There was a problem getting live rate mappings.',
        options: { variant: 'error' },
      })
    );
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
}

export function* deleteRequestSaga() {
  const inventoryApi = makeBackendApi();

  yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.PENDING));

  const toDelete: ILiveRatesListingItem = yield select(Selectors.toDeleteSelector);

  try {
    // Request the action
    const res: AxiosResponse = yield call(inventoryApi.deleteStaysMappings, toDelete.uuid);

    // Register the outcome
    yield put(Actions.listActions.deleteSuccessAction());
    yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.SUCCESS));
    yield put(
      enqueueNotification({
        message: 'Live Rate deleted',
        options: { variant: 'success' },
      })
    );

    // Refresh the list
    yield put(Actions.listActions.getRequestAction());
  } catch (e) {
    // Register the outcome
    yield put(Actions.listActions.deleteFailureAction(e.message));
    yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.ERROR));
    yield put(
      enqueueNotification({
        message: 'There was a problem deleting Live rate.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getSettingsPageStayAndInternalRooms(action: Actions.GetStayAndInternalRoomsRequestAction) {
  const backendApi = makeBackendApi();
  try {
    const hotelResponse: AxiosResponse<IHotelResponse> = yield call(backendApi.getHotel, action.stayUuid);
    const internalRoomsResponse: AxiosResponse = yield call(
      backendApi.getAccommodationProductsForHotelForLiveRateMappings,
      action.stayUuid
    );

    yield put(
      Actions.settingsPageActions.getStayAndInternalRoomsSuccessAction(
        {
          uuid: hotelResponse.data.data.uuid,
          name: hotelResponse.data.data.name,
          externalId: hotelResponse.data.data.externalHotelId || '',
          externalSystem: hotelResponse.data.data.externalSystem || '',
          liveRatesForTAs: hotelResponse.data.data.liveRatesForTAs,
        },
        internalRoomsResponse.data.data.map(r => {
          return {
            uuid: r.uuid,
            name: r.name,
            externalId: r.externalProductId || '',
            isMapped: r.externalProductId !== null,
            isAmended: false,
          };
        })
      )
    );

    // now that we have the internal rooms, fetch the external rooms too
    // yield put(Actions.settingsPageActions.getExternalRoomsRequestAction(action.stayUuid));
  } catch (e) {
    console.log('e', e);
    yield put(Actions.settingsPageActions.getStayAndInternalRoomsFailureAction());
  }
}

export function* getSettingsPageExternalRoomsRooms(action: Actions.GetExternalRoomsRequestAction) {
  const backendApi = makeBackendApi();

  try {
    const externalRoomsResponse: AxiosResponse = yield call(backendApi.getLiveRateStayRooms, action.stayUuid);
    const externalRooms: ILiveRatesSettingsExternalRoomsItem[] = externalRoomsResponse.data.data.rooms.map(r => {
      return {
        name: r.name,
        externalId: r.code,
        isMapped: undefined,
      };
    });
    yield put(Actions.settingsPageActions.getExternalRoomsSuccessAction(externalRooms));
  } catch (e) {
    console.log('e', e);
  }
}

export function* saveSettingsPageLists() {
  const backendApi = makeBackendApi();

  // clear existing errors
  yield put(Actions.settingsPageActions.clearInternalRoomsErrors());

  // then calculate errors and set them
  const allInternalRooms: ILiveRatesSettingsInternalRoomsItem[] = yield select(Selectors.allInternalRoomsListSelector);
  const allExternalRooms: ILiveRatesSettingsInternalRoomsItem[] = yield select(Selectors.allExternalRoomsListSelector);
  const allExternalRoomIds = allExternalRooms.map(er => er.externalId);

  const errors: ILiveRatesSettingsInternalRoomItemError[] = [];

  const amendedInternalRooms = allInternalRooms.filter(ir => ir.isAmended);
  // for each internal room that has the same external id as another internal room, add an error with that internal rooms id
  allInternalRooms.forEach((internalRoom, index) => {
    const duplicate = allInternalRooms.find(
      (room, i) => room.externalId === internalRoom.externalId && i !== index && internalRoom.externalId !== ''
    );

    if (duplicate) {
      errors.push({
        uuid: internalRoom.uuid,
        errorMessage: 'Rooms can not share the same external ID.',
      });
    }

    // also add an error if the room is mapped to a non-existent external room
    if (internalRoom.externalId !== '' && !allExternalRoomIds.includes(internalRoom.externalId)) {
      errors.push({
        uuid: internalRoom.uuid,
        errorMessage: 'External ID set should be available for external rooms.',
      });
    }
  });
  if (errors.length > 0) {
    yield put(Actions.settingsPageActions.setInternalRoomsErrors(errors));
    return;
  }

  // if no errors, save the list
  try {
    const stayInformation: ILiveRatesDomain['settingsPage']['stayInformation'] = yield select(
      Selectors.stayInformationSelector
    );
    yield call(
      backendApi.saveLiveRateMappingsList,
      amendedInternalRooms.filter(i => i.isAmended)
    );
    if(!isNil(stayInformation?.uuid) && !isNil(stayInformation?.liveRatesForTAs)) {
      yield call(
        backendApi.patchHotel,
        stayInformation?.uuid || '',
        {liveRatesForTAs: stayInformation?.liveRatesForTAs }
      );
    }
    yield put(Actions.settingsPageActions.saveListsSuccess());

    // if we successfully update the list, we need to reload both lists but keep the existing filters
    
    yield put(Actions.settingsPageActions.getStayAndInternalRoomsRequestAction(stayInformation!.uuid));
    // yield put(Actions.settingsPageActions.getExternalRoomsRequestAction(stayInformation!.uuid));
    yield put(
      enqueueNotification({
        message: 'Live rate mapping set successfully.',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(Actions.settingsPageActions.saveListsFailure());
    yield put(
      enqueueNotification({
        message: 'There was a problem saving the live rate mappings.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getLiveRateMappableStaysRequestSaga() {
  const backendApi = makeBackendApi();

  try {
    const res: AxiosResponse = yield call(backendApi.getLiveRateMappableStays);
    yield put(Actions.createActions.getMappableStaysSuccessAction(res.data.data.hotels, res.data.data.mappableStays));
  } catch (e) {
    yield put(Actions.createActions.getMappableStaysFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Unable to get live rate products from supplier.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* patchLiveRateHotelMappingRequestSaga(action: Actions.PatchMappableStayRequestAction) {
  const backendApi = makeBackendApi();

  try {
    const externalStays: ILiveRatesMappableStay[] = yield select(Selectors.mappableStaysSelector);
    const selected = externalStays.find(x =>
      x.externalHotelId === action.externalStayUuid &&
      x.externalSystem === action.externalSupplier
    );
    const payload = {
      externalSystem: action.externalSupplier,
      externalHotelId: action.externalStayUuid,
      externalSupplierId: selected?.externalSupplierId,
      liveRatesForTAs: true, // default as per OWA-8140
    };
    yield call(backendApi.patchHotel, action.stayUuid, payload);
    yield put(Actions.createActions.patchMappableStaySuccessAction());
    yield put(Actions.createActions.closeNewMappingModalAction());
    yield put(
      enqueueNotification({
        message: 'Live rate mapping added successfully.',
        options: { variant: 'success' },
      })
    );
    yield put(Actions.listActions.getRequestAction());
  } catch (e) {
    yield put(Actions.createActions.patchMappableStayFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'There was a problem saving hotel mappings.',
        options: { variant: 'error' },
      })
    );
  }
}

// when we try and change the internal rooms filter, we need to check if we have unsave changes
// if we DO have unsaved changes, we need to show the modal on the frontend
export function* handleAttemptingToSetInternalRoomsFilter(action) {
  const unsavedChanges: boolean = yield select(Selectors.hasUnsavedChangesSelector);
  if (unsavedChanges) {
    yield put(Actions.settingsPageActions.setShowUnsavedChangesModal(true));
  } else {
    yield put(Actions.settingsPageActions.setInternalRoomsFilter(action.name));
  }
}

export function* watchLiveRatesSaga() {
  yield takeLatest([Actions.LIST_ACTION_TYPES.GET_REQUEST, Actions.LIST_ACTION_TYPES.SET_SORT_DATA], getLiveRateMappingsRequestSaga);

  yield takeLatest(
    [
      Actions.LIST_ACTION_TYPES.SET_FILTER_NAME,
      Actions.LIST_ACTION_TYPES.SET_FILTER_EXTERNAL_SYSTEM,
      Actions.LIST_ACTION_TYPES.SET_PAGE,
      Actions.LIST_ACTION_TYPES.SET_PER_PAGE,
    ],
    getLiveRateMappingsRequestSaga
  );

  yield takeLatest([Actions.LIST_ACTION_TYPES.DELETE_REQUEST], deleteRequestSaga);

  yield takeLatest(
    [Actions.SETTINGS_PAGE_ACTION_TYPES.GET_STAY_AND_INTERNAL_ROOMS_REQUEST],
    getSettingsPageStayAndInternalRooms
  );
  yield takeLatest([Actions.SETTINGS_PAGE_ACTION_TYPES.GET_EXTERNAL_ROOMS_REQUEST], getSettingsPageExternalRoomsRooms);
  yield takeLatest([Actions.SETTINGS_PAGE_ACTION_TYPES.SAVE_LISTS_REQUEST], saveSettingsPageLists);
  yield takeLatest(
    [Actions.SETTINGS_PAGE_ACTION_TYPES.ATTEMPT_SET_INTERNAL_ROOMS_FILTER_REQUEST],
    handleAttemptingToSetInternalRoomsFilter
  );

  yield takeLatest([Actions.LIST_ACTION_TYPES.DELETE_REQUEST], deleteRequestSaga);
  yield takeLatest([Actions.CREATE_ACTIONS.GET_MAPPABLE_STAYS_REQUEST], getLiveRateMappableStaysRequestSaga);
  yield takeLatest([Actions.CREATE_ACTIONS.PATCH_MAPPABLE_STAY_REQUEST], patchLiveRateHotelMappingRequestSaga);
}
