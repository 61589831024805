import { initialState } from './model';
import {
  EVoucherFormFields,
  IVoucherSubdomain,
  IVoucherSubdomainFormDataAvailableMealPlan,
  VOUCHER_INDEX_SEPARATOR,
} from './types';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { set } from 'lodash-es';

const voucherReducer = (state: IVoucherSubdomain = initialState, action: Actions.VoucherAction) => {
  switch (action.type) {
    case Actions.GET_VOUCHER_FORM_VIEW_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.voucherFormViewLoad = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_VOUCHER_FORM_VIEW_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.voucherFormViewLoad = ENetworkRequestStatus.SUCCESS;

        // reset the data
        draft.formData = {
          ...initialState.formData,
        };
        draft.formErrors = {};
        // then use the new data
        draft.formData.resortConfirmationNumber = action.voucherFormView.hotel.resortConfirmationNumber || '';
        draft.formData.resortRegion = action.voucherFormView.hotel.region || '';
        draft.formData.resortCountryCode = action.voucherFormView.hotel.countryCode || '';
        draft.formData.resortAddress = action.voucherFormView.hotel.address || '';
        draft.formData.resortName = action.voucherFormView.hotel.name;
        draft.formData.resortPhone = action.voucherFormView.hotel.phoneNumber || '';
        draft.formData.availableGuests = action.voucherFormView.guests;
        draft.formData.availableAccomodations = action.voucherFormView.accommodation;
        draft.formData.availableMealPlans = [];
        draft.formData.availableTransfers = [''];
        draft.formData.availableGroundServices = action.voucherFormView.groundService;
        draft.formData.policiesAndRestrictions = action.voucherFormView.policiesAndRestrictions || '';
      });

    case Actions.GET_VOUCHER_FORM_VIEW_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.voucherFormViewLoad = ENetworkRequestStatus.ERROR;
      });

    case Actions.SET_VOUCHER_FORM_DATA:
      return produce(state, draft => {        
        set(draft, `formData.${action.path}`, action.value);
        if (action.path === 'selectedGuests') {
          const cip = {arrivalCode: '', departureCode: ''};
          if (draft.formData.selectedGuests.length !== 0) {
            // Find the lead guest with CIP info
            const leadGuest = draft.formData.selectedGuests.find(guest => guest.isLeadGuest && (guest.arrivalCIPBookingNumber || guest.departureCIPBookingNumber));
            if (leadGuest) {
              cip.arrivalCode = leadGuest.arrivalCIPBookingNumber ?? '';
              cip.departureCode = leadGuest.departureCIPBookingNumber ?? '';
            } else {
              // Otherwise, find any guest with CIP info
              const guestWithCIP = draft.formData.selectedGuests.find(guest => guest.arrivalCIPBookingNumber || guest.departureCIPBookingNumber);
              cip.arrivalCode = guestWithCIP?.arrivalCIPBookingNumber ?? '';
              cip.departureCode = guestWithCIP?.departureCIPBookingNumber ?? '';
            }
          }   
          set(draft, `formData.cip`, cip);
      
          const isFlightInfoSet = draft.formData.selectedGuests.every(sg => {
            return sg.arrivalFlightNo != null
            && sg.arrivalDate != null
            && sg.arrivalTime != null
            && sg.departureFlightNo != null
            && sg.departureDate != null
            && sg.departureTime != null
          });

          const isMatching = !isFlightInfoSet
            ? false
            : draft.formData.selectedGuests.every(sg => {
                return (
                  sg.arrivalDate === draft.formData.selectedGuests[0].arrivalDate &&
                  sg.departureDate === draft.formData.selectedGuests[0].departureDate &&
                  sg.arrivalTime === draft.formData.selectedGuests[0].arrivalTime &&
                  sg.departureTime === draft.formData.selectedGuests[0].departureTime &&
                  sg.arrivalFlightNo?.localeCompare(draft.formData.selectedGuests[0].arrivalFlightNo || '', undefined, {
                    sensitivity: 'base',
                  }) === 0 &&
                  sg.departureFlightNo?.localeCompare(
                    draft.formData.selectedGuests[0].departureFlightNo || '',
                    undefined,
                    {
                      sensitivity: 'base',
                    }
                  ) === 0
                );
              });

          if (!isFlightInfoSet) {
            draft.formErrors[EVoucherFormFields.SELECTED_GUESTS] =
              'Please enter flight information for the selected guests';
          } else if (!isMatching) {
            draft.formErrors[EVoucherFormFields.SELECTED_GUESTS] =
              'These guests cannot be in the same voucher as flight information does not match';
          } else {
            delete draft.formErrors[EVoucherFormFields.SELECTED_GUESTS];
          }
        }

        // if we change the selected accommodation indexes, we
        // need to update what meal plans are available
        if (action.path === 'selectedAccomodationIndexes') {
          let mealPlans: IVoucherSubdomainFormDataAvailableMealPlan[] = [];
          // this crazy logic is needed to handle duplicates, see OWA-2759
          action.value.forEach(accomIndex => {
            draft.formData.availableAccomodations[parseInt(accomIndex)].mealPlan.forEach((mp, mpIndex) => {
              mealPlans.push({
                compoundIndex: `${accomIndex}${VOUCHER_INDEX_SEPARATOR}${mpIndex}`,
                title: mp.title,
                description: mp.description,
              });
            });
          });
          draft.formData.availableMealPlans = mealPlans;
        }

        if (action.path === 'notes') {
          const LINE_BREAK = /\n/g;
          const LINE_BREAKS_PERMITTED = 5;
          const exceededNotesMaxLineBreaks =
            (draft.formData.notes.match(LINE_BREAK)?.length ?? 0) > LINE_BREAKS_PERMITTED;

          if (exceededNotesMaxLineBreaks) {
            draft.formErrors[EVoucherFormFields.NOTES] = `Only ${LINE_BREAKS_PERMITTED} linebreaks permitted`;
          } else {
            delete draft.formErrors[EVoucherFormFields.NOTES];
          }
        }

        return draft;
      });

    case Actions.DOWNLOAD_VOUCHER_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.voucherDownloadLoad = ENetworkRequestStatus.PENDING;
      });
    case Actions.DOWNLOAD_VOUCHER_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.voucherDownloadLoad = ENetworkRequestStatus.SUCCESS;
      });
    case Actions.DOWNLOAD_VOUCHER_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.voucherDownloadLoad = ENetworkRequestStatus.ERROR;
      });
    case Actions.SET_VOUCHER_LOGO:
      return produce(state, draft => {
        draft.voucherLogo = action.voucherLogo;
      });
    case Actions.SET_VOUCHER_LANG:
      return produce(state, draft => {
        draft.lang = action.lang;
      });
    case Actions.SET_VOUCHER_FORM_MEAL_PLAN_DESCRIPTION:
      return produce(state, draft => {
        draft.formData.selectedMealPlanDetails = action.details;
      });
    default:
      return state;
  }
};

export default voucherReducer;
