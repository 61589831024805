import React, { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';

import { formatDateDisplay } from 'utils';
import { guestToString } from 'utils/guestInformation';
import {
  EVoucherFormFields,
  IFormError,
  IVoucherSubdomainFormData,
  VOUCHER_INDEX_SEPARATOR,
} from 'store/modules/bookingManager/subdomains/voucher/types';

import { Multiselect } from '../Multiselect';
import { getAge } from 'pureUi/GuestInformationForm/helpers';
import { UITextArea } from 'ui/UITextArea';
import { useDispatch, useSelector } from 'react-redux';
import { mealPlanDetailsSelector } from 'store/modules/bookingManager/subdomains/voucher/selectors';
import { setVoucherMealPlanDescriptionAction } from 'store/modules/bookingManager/subdomains/voucher/actions';

export interface IVoucherFormProps extends IVoucherSubdomainFormData {
  // eslint-disable-next-line no-undef
  onChange: (path: keyof IVoucherSubdomainFormData, value) => void;
  formErrors: IFormError;
  arrivalDate?: string;
  departureDate?: string;
}

export const numberOfNights = (arrivalDate: string | undefined, departureDate: string | undefined) => {
  if (!arrivalDate || !departureDate) {
    return '';
  }
  return differenceInDays(new Date(departureDate), new Date(arrivalDate));
};

const NOTES_MAX_LENGTH = 500;

export const VoucherForm = (props: IVoucherFormProps) => {
  const dispatch = useDispatch();
  const mealPlanDetails = useSelector(mealPlanDetailsSelector);
  

  useEffect(() => {
    const mealPlanDetailsSelected = props.selectedMealPlansCompoundIndexes.map(smp => {
      const [accomIndex, mealPlanIndex] = smp.split(VOUCHER_INDEX_SEPARATOR);
      return props.availableAccomodations[parseInt(accomIndex)].mealPlan[parseInt(mealPlanIndex)].description;
    }).filter(Boolean).join('\n');
    
    dispatch(setVoucherMealPlanDescriptionAction(mealPlanDetailsSelected));
  }, [dispatch, props.selectedMealPlansCompoundIndexes]);

  return (
    <div>
      <label className="block w-full mb-4 font-pt-sans">
        <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs">Guests</span>

        {props.formErrors[EVoucherFormFields.SELECTED_GUESTS] && (
          <span className="inline-block ml-2 mb-1 text-red-100 text-13px leading-14px tracking-2xs">
            {props.formErrors[EVoucherFormFields.SELECTED_GUESTS]}
          </span>
        )}

        <Multiselect
          className="guest-multiselect"
          itemsClassname="bg-white"
          itemCtaClassName="hover:bg-gray-10"
          options={props.availableGuests.map(g => {
            return {
              value: g.uuid || '',
              label: g.isLeadGuest ? `${guestToString(g)} (Lead Guest)` : `${guestToString(g)}`,
            };
          })}
          onUpdate={selectedValues => {
            const selectedGuests = props.availableGuests.filter(ag => selectedValues.includes(ag.uuid || ''));
            props.onChange('selectedGuests', selectedGuests);
          }}
          selectedValues={props.selectedGuests.map(g => g.uuid || '')}
        />
      </label>

      {props.selectedGuests.map(guest => {
        return (
          <div key={guest.uuid} className="flex space-between mb-4">
            <label className="w-full mr-4">
              <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
                Date of birth
              </span>
              <input
                type="text"
                className="date-of-birth-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
                value={guest.dateOfBirth ? formatDateDisplay(guest.dateOfBirth) : ''}
                disabled={true}
                readOnly={true}
              />
            </label>

            <label className="w-1/6 mr-4">
              <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Age</span>
              <input
                type="text"
                disabled
                className="age-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full text-left"
                value={getAge(guest) || ''}
                readOnly={true}
              />
            </label>

            <label className="w-1/6 mr-4">
              <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Title</span>
              <input
                type="text"
                disabled
                className="title-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full text-left"
                value={guest.title}
                readOnly={true}
              />
            </label>

            <label className="w-full mr-4">
              <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">First name</span>
              <input
                type="text"
                className="first-name-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
                value={guest.firstName || ''}
                disabled={true}
                readOnly={true}
              />
            </label>

            <label className="w-full">
              <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Last name</span>
              <input
                type="text"
                className="last-name-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
                value={guest.lastName || ''}
                disabled={true}
                readOnly={true}
              />
            </label>
          </div>
        );
      })}

      {props.selectedGuests.length >= 1 && <hr />}

      <label className="block w-full mb-4">
        <span className="block mb-2 text-black text-13px leading-14px tracking-2xs font-pt-sans">Resort name</span>
        <input
          type="text"
          className="resort-name-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
          disabled={true}
          readOnly={true}
          value={props.resortName}
        />
      </label>

      <div className="flex space-between mb-4">
        <label className="mr-4 w-full">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
            Booking from
          </span>
          <input
            type="text"
            value={props.arrivalDate ? formatDateDisplay(props.arrivalDate) : ''}
            className="travel-arrival-dates-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            disabled={true}
            readOnly={true}
          />
        </label>

        <label className="mr-4 w-full">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
            Booking to
          </span>
          <input
            type="text"
            className="travel-departure-dates-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            value={
              props.departureDate ? formatDateDisplay(props.departureDate) : ''
            }
            disabled={true}
            readOnly={true}
          />
        </label>

        <label className="w-full">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">No. of nights</span>
          <input
            type="text"
            className="no-of-nights-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            value={numberOfNights(props.arrivalDate, props?.departureDate) || ''}
            disabled={true}
            readOnly={true}
          />
        </label>
      </div>

      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Accommodation</span>

        <Multiselect
          className="accommodation-multiselect"
          itemsClassname="bg-white"
          itemCtaClassName="hover:bg-gray-10"
          options={props.availableAccomodations.map((accom, i) => {
            return {
              value: `${i}`,
              label: accom.title,
            };
          })}
          onUpdate={selectedValues => {
            props.onChange('selectedAccomodationIndexes', selectedValues);
            props.onChange('selectedMealPlansCompoundIndexes', []);
          }}
          selectedValues={props.selectedAccomodationIndexes}
        />
      </label>

      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Meal Plan</span>

        <Multiselect
          className="meal-plan-multiselect"
          itemsClassname="bg-white"
          itemCtaClassName="hover:bg-gray-10"
          options={props.availableMealPlans.map(item => {
            return {
              value: item.compoundIndex,
              label: item.title,
            };
          })}
          onUpdate={selectedValues => {
            props.onChange('selectedMealPlansCompoundIndexes', selectedValues);
          }}
          selectedValues={props.selectedMealPlansCompoundIndexes}
        />
      </label>

      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Meal Plan Details</span>
        <UITextArea
          className="mealplan-details-text-area"
          value={mealPlanDetails}
          onChange={value => {
            dispatch(setVoucherMealPlanDescriptionAction(value));
          }}
        />
      </label>

      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Transfers</span>
        <UITextArea
          className="transfers-text-area"
          value={props.selectedTransfers.join('\n')}
          onChange={value => {
            props.onChange('selectedTransfers', value.split('\n'));
          }}
        />
      </label>

      <label className="block w-full mb-4">
        <span className="ground-services-title block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
          Ground Services
        </span>

        <Multiselect
          className="ground-services-multiselect"
          itemsClassname="bg-white"
          itemCtaClassName="hover:bg-gray-10"
          options={props.availableGroundServices.map(item => ({ value: item, label: item }))}
          onUpdate={selectedValues => {
            props.onChange('selectedGroundServices', selectedValues);
          }}
          selectedValues={props.selectedGroundServices}
        />
      </label>

      <div className="flex space-between mb-4 w-2/3">
        <label className="mr-4 w-full">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
            CIP arrival
          </span>
          <input
            type="text"
            value={props.cip.arrivalCode}
            className="travel-arrival-dates-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            disabled={true}
            readOnly={true}
          />
        </label>

        <label className="mr-4 w-full">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
            CIP departure
          </span>
          <input
            type="text"
            className="travel-departure-dates-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            value={props.cip.departureCode}
            disabled={true}
            readOnly={true}
          />
        </label>
      </div>
      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Notes</span>
        <UITextArea
          className="notes-text-area"
          value={props.notes}
          rows={6}
          limit={NOTES_MAX_LENGTH}
          onChange={value => {
            props.onChange('notes', value);
          }}
        />

        <div className="flex mt-1">
          <span className="text-13px text-red-100 tracking-2xs leading-14px font-pt-sans w-1/2">
            {props.formErrors[EVoucherFormFields.NOTES]}
          </span>
        </div>
      </label>

      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Resort address</span>
        <input
          type="text"
          className="resort-address-input p-2 border border-solid border-gray-40 min-h-35px w-full"
          onChange={e => props.onChange('resortAddress', e.target.value)}
          value={props.resortAddress}
        />
      </label>

      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Resort phone no.</span>
        <input
          type="text"
          className="resort-phone-no-input p-2 border border-solid border-gray-40 min-h-35px w-full"
          value={props.resortPhone}
          onChange={e => props.onChange('resortPhone', e.target.value)}
        />
      </label>

      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
          Resort confirmation no.
        </span>
        <input
          type="text"
          className="resort-confirmation-no-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
          disabled={true}
          readOnly={true}
          value={props.resortConfirmationNumber}
        />
      </label>

      <div className="flex space-between mb-4">
        <label className="w-full mr-4">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
            International flight arrival
          </span>
          <input
            type="text"
            className="international-flight-arrival-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            disabled={true}
            readOnly={true}
            value={props.selectedGuests[0]?.arrivalDate ? formatDateDisplay(props.selectedGuests[0].arrivalDate) : ''}
          />
        </label>

        <label className="w-full mr-4">
          <div className="flex">
            <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Arrival time</span>
          </div>
          <input
            type="time"
            disabled={true}
            readOnly={true}
            className="arrival-time-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px max-h-35px w-full"
            value={props.selectedGuests[0]?.arrivalTime ? props.selectedGuests[0].arrivalTime : ''}
          />
        </label>

        <label className="w-full">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Flight No Arr</span>
          <input
            type="text"
            className="flight-no-arrival-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            disabled={true}
            readOnly={true}
            value={props.selectedGuests[0]?.arrivalFlightNo || ''}
          />
        </label>
      </div>

      <div className="flex space-between">
        <label className="w-full mr-4">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
            Departure dates
          </span>
          <input
            type="text"
            className="departure-dates-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            disabled={true}
            readOnly={true}
            value={
              props.selectedGuests[0]?.departureDate ? formatDateDisplay(props.selectedGuests[0].departureDate) : ''
            }
          />
        </label>

        <label className="w-full mr-4">
          <div className="flex">
            <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
              Departure time
            </span>
          </div>
          <input
            type="time"
            disabled={true}
            readOnly={true}
            className="departure-time-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px max-h-35px w-full"
            value={props.selectedGuests[0]?.departureTime ? props.selectedGuests[0].departureTime : ''}
          />
        </label>

        <label className="w-full">
          <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Flight No Dep</span>
          <input
            type="text"
            className="flight-no-departure-input p-2 bg-gray-20 border border-solid border-gray-40 min-h-35px w-full"
            disabled={true}
            readOnly={true}
            value={props.selectedGuests[0]?.departureFlightNo || ''}
          />
        </label>
      </div>
      
      <div className="sep h-1px bg-gray-10 my-5"></div>

      <label className="block w-full mb-4">
        <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
          Policies and Restrictions
        </span>
        <div className="policies-and-restrictions block h-150px w-full font-pt-sans text-[15px] p-[15px] leading-22px text-black border border-solid border-gray-40 bg-ivory overflow-y-auto">
          {props.policiesAndRestrictions}
        </div>
      </label>

    </div>
  );
};
