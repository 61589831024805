import { EVoucherLang, ENetworkRequestStatus } from 'services/BackendApi';
import { EPdfLogo } from '../breakdown/model';
import { IVoucherSubdomain } from './types';

export const initialState: IVoucherSubdomain = {
  formData: {
    selectedGuests: [],
    selectedTransfers: [],
    selectedAccomodationIndexes: [],
    selectedMealPlansCompoundIndexes: [],
    selectedMealPlanDetails: '',
    selectedGroundServices: [],
    availableGuests: [],
    availableTransfers: [],
    availableAccomodations: [],
    availableMealPlans: [],
    availableGroundServices: [],
    notes: '',
    resortName: '',
    resortAddress: '',
    resortPhone: '',
    resortConfirmationNumber: '',
    resortCountryCode: '',
    resortRegion: '',
    policiesAndRestrictions: '',
    cip: {arrivalCode: '', departureCode: ''},
  },
  voucherLogo: EPdfLogo.MAIN_COMPANY_LOGO,
  lang: EVoucherLang.EN,
  formErrors: {},
  networkRequests: {
    voucherFormViewLoad: ENetworkRequestStatus.IDLE,
    voucherDownloadLoad: ENetworkRequestStatus.IDLE,
  },
};
