import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { PageTitle } from 'ui/PageTitle';
import { BackLink } from 'ui/BackLink';
import { Field, Formik, FormikProps } from 'formik';
import { ITaskFormValues, taskFormValidationSchema } from './taskFormValidation';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { LoadingBar } from 'ui/NetworkStatusBar';
import FluidButton from 'ui/FluidButton';
import { TextInput } from 'ui/TextInput';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { bookingRefsSelector, editTaskSelector, getAssigneesRequestSelector, getFilterInfoRequestSelector, getTaskAssigneesSelector, getTaskCategoriesSelector, getTaskLoadingSelector, getTaskTeamsSelector, listTaskRemindersSelector, searchBookingRefsRequestSelector, selectedTaskSelector } from 'store/modules/taskManagement/selectors';
import * as TaskManagementActions from 'store/modules/taskManagement/actions';
import { taskPriorityOptions, taskStatusOptions } from './helpers';
import { TaskStatus } from './TaskStatus';
import { AsyncSearchDropdown } from 'ui/AsyncSearchDropdown';
import { ENetworkRequestStatus, EUserType, IUser } from 'services/BackendApi';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { StyledDateRangeInput } from 'containers/BookingList/StyledFilters';
import { getCurrentUser } from 'store/modules/auth';
import { useHistory, useRouteMatch } from 'react-router';
import { ETaskPriority, ETaskStatus, ITask } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { useCurrentWidth } from 'effects';
import { getMentionListRequestAction, getSelectedTaskRequestAction } from 'store/modules/taskManagement/actions';
import { theme } from '../../../tailwind.config';
import { formatDate } from 'utils';
import ClockIcon from 'ui/Icons/clock.component.svg';
import UserInvertIcon from 'ui/Icons/user-invert.component.svg';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { TaskComments } from './TaskComments/TaskComments';
import { TaskReminders } from './TaskReminders/TaskReminders';
import { TaskAttachments } from './TaskAttachments/TaskAttachments';
import { CircleIconButton } from 'ui/CircleIconButton';
import { edit } from 'ui/Icons';
import { IAsyncSearchDropdownOption } from 'ui/AsyncSearchDropdown/AsyncSearchDropdownOptions';
import Editor from 'pureUi/Editor';

const getUserDisplayName = (user?: Pick<IUser, "uuid" | "firstName" | "lastName">) => {
  return user ? user.firstName + ' ' + user.lastName : '';
};

interface IRequesterTypeProps {
  editorType: EUserType;
  email?: string;
}

export const EditorType: React.FC<IRequesterTypeProps> = React.memo(({ email, editorType }) => {
  const editorTypeMapping = {
    [EUserType.ADMIN]: 'ADMIN',
    [EUserType.TA]: 'TA',
    [EUserType.SR]: 'SR',
  };
  const text = editorTypeMapping[editorType] ?? '';
  const content = (
    <span
      className={classnames(
        'requester-type inline-flex py-2px px-5px bg-gray-10 rounded-1px text-black font-pt-sans text-xs leading-16px tracking-2xs uppercase ml-[5px]',
        { underline: true }
      )}
    >
      {text}
    </span>
  );

  if (email) {
    return (
      <HidingTextTooltip
        showShadow={false}
        tooltipContent={email}
        position="bottom-left"
        tooltipWrapperClassname="inline-block"
      >
        {content}
      </HidingTextTooltip>
    );
  } else {
    return content;
  }
});

interface ITaskEditor {
  task: ITask | null;
  isUpdated: boolean;
}

const TaskEditor: React.FC<ITaskEditor> = (props) => {
  const isUpdated = props.isUpdated;
  const dateDisplay = isUpdated ? props.task?.updatedAt : props.task?.createdAt;
  const editor = isUpdated ? props.task?.updatedBy : props.task?.reporter;
  return (
    <div className='flex'>
      <div className="flex items-center">
        <ClockIcon className="w-14px" fill={theme.colors['gray-80']} />
        <span className="ml-5px font-pt-sans text-15px leading-19px text-gray-100">
          {props.isUpdated ? 'Updated' : 'Created'} {props.task && dateDisplay && formatDate(new Date(dateDisplay), 'd MMMM yyyy')}
        </span>
      </div>
      <div className="flex items-center">
        <span className="flex relative -top-1px pl-[15px]">
          <UserInvertIcon fill={theme.colors['gray-80']} />
        </span>
        <div className="requester font-pt-sans text-15px leading-19px text-gray-100 ml-2px my-0">
          {`${editor?.firstName} ${editor?.lastName}`}
          <EditorType email={editor?.email} editorType={editor?.type as EUserType} />
        </div>
      </div>
    </div>
  )
}


export const TaskEdit: React.FC = () => {
  const match = useRouteMatch<{ taskId: string }>();
  const taskId = match.params.taskId;
  const dispatch = useDispatch();
  const { currentWidth } = useCurrentWidth();
  const urlParams = new URLSearchParams(window.location.search);
  const routeBookingRef = urlParams.get('bookingRef');
  const history = useHistory();
  const canEdit = true;
  const taskCategoryOptions = useSelector(getTaskCategoriesSelector);
  const taskTeamOptions = useSelector(getTaskTeamsSelector);
  const getFilterInfoRequest = useSelector(getFilterInfoRequestSelector);
  const currentUser = useSelector(getCurrentUser) as IUser;
  const assignees = useSelector(getTaskAssigneesSelector);
  const assigneeOptions: ISingleSelectOption[] = useMemo(() => [{ value: 'all', label: 'No assignment' }, ...(assignees
    ?.map(user => ({
      value: user.uuid,
      label: getUserDisplayName(user),
    })) ?? [])], [assignees]);
  const getAssigneesRequest = useSelector(getAssigneesRequestSelector);
  const editTaskRequest = useSelector(editTaskSelector);
  const taskLoading = useSelector(getTaskLoadingSelector);
  const task = useSelector(selectedTaskSelector);
  const isSaving = editTaskRequest === ENetworkRequestStatus.PENDING;
  const [assigneeQueryText, setAssigneeText] = useState('');
  const [bookingRefQuery, setBookingRefQuery] = useState('');
  const [isAssigneeChanged, setAssigneeChanged] = useState(false);
  const [isBookingRefEditEnabled, setIsBookingRefEditEnabled] = useState(false);
  const taskReminders = useSelector(listTaskRemindersSelector);
  const bookingRefs = useSelector(bookingRefsSelector);
  const searchBookingRefsRequest = useSelector(searchBookingRefsRequestSelector);

  const bookingRefsOptions: IAsyncSearchDropdownOption[] = useMemo(() => bookingRefs.map
    (bookingRef => ({ value: bookingRef, label: bookingRef }))
    , [bookingRefs]);

  const statusOptions: ISingleSelectOption[] = useMemo(() => taskStatusOptions.map(option => ({
    label: option.label,
    value: option?.value ?? undefined,
    renderItem: (className?: string) => (
      <TaskStatus className={classnames(className, 'min-h-[33px]')} value={option.value as ETaskStatus} />
    )
  })), [taskStatusOptions]);

  const categoryOptions: ISingleSelectOption[] = useMemo(() => taskCategoryOptions?.map(item => ({
    value: item.id,
    label: item.name,
  })) ?? [], [taskCategoryOptions]);

  const teamOptions: ISingleSelectOption[] = useMemo(
    () =>
      (taskTeamOptions && taskTeamOptions.length > 0)
        ? taskTeamOptions?.map(item => ({
            value: item.id,
            label: item.name,
          }))
        : [],
    [taskTeamOptions]
  );

  useLayoutEffect(() => {
    dispatch(TaskManagementActions.getUsersRequestAction('', 'assignee'));
    dispatch(TaskManagementActions.getFilterInfoRequestAction());
    dispatch(getSelectedTaskRequestAction(taskId));
  }, []);

  useEffect(() => {
    dispatch(TaskManagementActions.listAttachmentRequestAction(taskId));
    dispatch(getMentionListRequestAction());
  }, [taskId]);

  const handleAssigneeFilterChange = useCallback(value => {
    setAssigneeText(value);
    setAssigneeChanged(true);
    dispatch(TaskManagementActions.getUsersRequestAction(value, 'assignee'));
  }, []);

  const handleBookingRefsSearchChange = useCallback(value => {
    setBookingRefQuery(value);
    if (value) {
      dispatch(TaskManagementActions.searchBookingRefRequestAction(value));
    }
  }, []);

  const initialValues: ITaskFormValues = useMemo(() => {
    if (!task) {
      return {
        assigneeId: null,
        bookingRef: routeBookingRef ?? '',
        category: null,
        endDate: '',
        endTime: '00:00',
        description: '',
        priority: ETaskPriority.MEDIUM,
        startByDate: '',
        status: ETaskStatus.BACKLOG,
        subject: '',
        teamId: null,
      }
    }
    const selectedUser = assignees.find(user => user.uuid === task.assignee?.id);
    setAssigneeText(getUserDisplayName(selectedUser));
    const [endDate, rest] = new Date(task.deadline).toISOString().split('T');
    return {
      assigneeId: task.assignee?.id,
      bookingRef: task.booking?.humanReadableId ?? '',
      category: task.category.id,
      endDate: endDate,
      endTime: rest.split('.')[0],
      description: task.description,
      priority: task.priority,
      startByDate: task.startBy ? format(new Date(task.startBy), 'yyyy-MM-dd') : '',
      status: task.status,
      subject: task.subject,
      teamId: task.team.id,
    };
  }, [task]);

  useEffect(() => {
    if (getAssigneesRequest === ENetworkRequestStatus.SUCCESS && !isAssigneeChanged) {
      const selectedAssigneeOption = assigneeOptions.find(item => item.value === initialValues.assigneeId);
      setAssigneeText(selectedAssigneeOption?.label ?? '');
    }
  }, [initialValues, getAssigneesRequest]);

  useEffect(() => {
    if (task?.booking) {
      setBookingRefQuery(task.booking.humanReadableId);
    }
  }, [task]);

  const handleFormSubmit = useCallback((values: ITaskFormValues) => {
    dispatch(TaskManagementActions.editTaskRequestAction(values, history, taskId));
  }, []);

  const validateReminders = useCallback(() => {
    dispatch(TaskManagementActions.validateRemindersAction());
  }, [dispatch]);

  if (getFilterInfoRequest === ENetworkRequestStatus.PENDING || taskLoading === ENetworkRequestStatus.PENDING || !task) {
    return <LoadingBar />
  }

  return (
    <div className={classnames('task-edit-page', { 'px-[20px]': currentWidth <= 1262, 'px-[80px]': currentWidth > 1262 })}>
      <BackLink type="internal" href="/tasks">
        Back
      </BackLink>
      <div className='w-full'>
        <Formik
          initialValues={initialValues}
          validationSchema={taskFormValidationSchema}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {(form: FormikProps<ITaskFormValues>) => {
            return (
              <React.Fragment>
                <LeaveWithoutSavingModal
                  title="You have made changes to this page, and not saved. If you leave this page now, these changes will be lost."
                  confirmButtonLabel="Yes"
                  cancelButtonLabel="No"
                  when={form.dirty && (editTaskRequest === ENetworkRequestStatus.IDLE || editTaskRequest === ENetworkRequestStatus.ERROR)}
                />
                <form autoComplete={'off'} onSubmit={form.handleSubmit}>
                  <div className="form-container flex items-start justify-between gap-[60px]">
                    <div className="left-part flex-1 flex flex-col gap-[20px]">
                      <PageTitle
                        primaryText={`Edit Task - ${task.key}`}
                      />
                      <div className="subject-category flex items-start gap-[20px]">
                        <Field
                          as={TextInput}
                          type="text"
                          name="subject"
                          id="subject"
                          className="subject flex-1"
                          label="Subject *"
                          errorMessage={form.touched.subject && form.errors.subject ? form.errors.subject : null}
                          errorClassName="subject-error"
                        />
                        <Field name="category">
                          {({ field: { name, value }, form: { setFieldValue } }) => (
                            <SingleSelect
                              fieldId="category"
                              label="Category *"
                              className="title min-w-[190px]"
                              value={value}
                              showSelectedOption
                              options={categoryOptions ?? []}
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                              errorMessage={form.touched.category && form.errors.category ? form.errors.category : null}
                              errorClassName="category-error"
                            />
                          )}
                        </Field>
                      </div>
                      <Field
                        name="bookingRef"
                        label="Booking Ref."
                      >
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <div className='flex flex-col'>
                            <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                              Booking Ref.
                            </label>
                            {!isBookingRefEditEnabled && task.booking?.humanReadableId ? (
                              <span
                                className="assign-to-me font-pt-sans text-[13px] leading-[17px] underline text-brown-100 cursor-pointer top-0 right-[2px] w-fit flex items-center gap-[10px]"
                              >
                                <span onClick={() => {
                                  window.open(`/booking-manager/${task.booking?.id}`, "_blank");
                                }}>
                                  {value}
                                </span>
                                <CircleIconButton
                                  type="secondary"
                                  className="edit-action mx-1"
                                  iconClass="inline relative left-0 top-1px w-12px h-12px"
                                  iconSrc={edit}
                                  onClick={() => setIsBookingRefEditEnabled(true)}
                                />
                              </span>
                            ) : (
                              // Problematic - Temporarily removed
                              // <AsyncSearchDropdown
                              //   fieldId="bookingRef"
                              //   className="bookingRef min-w-[180px] w-[200px] flex-1"
                              //   value={bookingRefQuery ?? ''}
                              //   selectedValue={value}
                              //   options={bookingRefsOptions}
                              //   onChoose={(value: string) => {
                              //     const selectedBookingRef = bookingRefsOptions.find(item => item.value === value);
                              //     setBookingRefQuery(selectedBookingRef?.value ?? '');
                              //     setFieldValue(name, value);
                              //   }}
                              //   onChange={handleBookingRefsSearchChange}
                              //   maxVisibleItems={8}
                              //   loading={searchBookingRefsRequest === ENetworkRequestStatus.PENDING}
                              //   errorMessage={null}
                              //   hideDropdownOptions={!bookingRefQuery}
                              // />

                              <TextInput
                                id="bookingRef"
                                className="bookingRef w-300px h-[40px]"
                                type="text"
                                value={value}
                                onChange={event => {
                                  setFieldValue(name, (event.target as HTMLInputElement).value);
                                }}
                                errorMessage={form.touched.bookingRef && form.errors.bookingRef ? form.errors.bookingRef : null}
                              />
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="description">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <div className='flex flex-col'>
                            <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                              Description
                            </label>
                            <Editor text={value} handleEditorChange={(value) => setFieldValue(name, value)} options={[]} mentionAbility={false} />
                          </div>
                        )}
                      </Field>

                      <TaskAttachments taskId={taskId} />
                      <TaskComments taskId={taskId} />
                    </div>
                    <div className="right-part flex flex-col min-w-[442px]">
                      <div className="mb-[20px] h-[46px] flex flex-col">
                        <TaskEditor task={task} isUpdated={false} />
                        {task?.updatedBy && <TaskEditor task={task} isUpdated />}
                      </div>
                      <div className="border border-dashed border-gray-40 rounded-[4px] gap-[20px] p-[20px] flex flex-col min-w-[442px]">
                        <div className="status-priority flex items-start gap-[20px]">
                          <Field name="status">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <SingleSelect
                                fieldId="status"
                                label="Status *"
                                className="status min-w-[190px]"
                                value={value}
                                showSelectedOption
                                options={statusOptions ?? []}
                                onChange={value => {
                                  setFieldValue(name, value);
                                }}
                                errorMessage={form.touched.status && form.errors.status ? form.errors.status : null}
                                errorClassName="status-error"
                              />
                            )}
                          </Field>
                          <Field name="priority">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <SingleSelect
                                fieldId="task-priority-filter"
                                label="Priority *"
                                className="task-priority-filter min-w-[190px] max-w-[190px]"
                                value={value}
                                options={taskPriorityOptions ?? []}
                                onChange={value => {
                                  setFieldValue(name, value);
                                }}
                                showSelectedOption
                                maxVisibleItems={6}
                                errorMessage={form.touched.priority && form.errors.priority ? form.errors.priority : null}
                                errorClassName="priority-error"
                              />
                            )}
                          </Field>
                        </div>
                        <div className="team-assignee flex items-start gap-[20px]">
                          <Field name="teamId">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <SingleSelect
                                fieldId="teamId"
                                label="Team *"
                                className="teamId min-w-[190px]"
                                value={value}
                                showSelectedOption
                                maxVisibleItems={6}
                                options={teamOptions ?? []}
                                onChange={value => {
                                  setFieldValue(name, value);
                                }}
                                errorMessage={form.touched.teamId && form.errors.teamId ? form.errors.teamId : null}
                                errorClassName="team-error"
                              />
                            )}
                          </Field>
                          <Field name="assigneeId">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <div className="relative">
                                <AsyncSearchDropdown
                                  fieldId="assignee"
                                  label="Assignee"
                                  className="assignee min-w-[180px] flex-1"
                                  value={assigneeQueryText ?? ''}
                                  selectedValue={value}
                                  options={assigneeOptions}
                                  onOpen={() => {
                                    dispatch(TaskManagementActions.getUsersRequestAction('', 'assignee'));
                                  }}
                                  onChoose={(value: string) => {
                                    const selectedAssignee = assigneeOptions.find(item => item.value === value);
                                    setAssigneeText(selectedAssignee?.label ?? '');
                                    setFieldValue(name, value);
                                  }}
                                  onChange={handleAssigneeFilterChange}
                                  maxVisibleItems={8}
                                  loading={getAssigneesRequest === ENetworkRequestStatus.PENDING}
                                  errorMessage={null}
                                  errorClassName="assignee-error"
                                />
                                <span
                                  className="assign-to-me absolute font-pt-sans underline text-[13px] leading-[17px] text-brown-100 cursor-pointer top-0 right-[2px]"
                                  onClick={() => {
                                    const selectedUser = assignees.find(user => user.uuid === currentUser.uuid);
                                    setAssigneeText(getUserDisplayName(selectedUser));
                                    setFieldValue(name, selectedUser?.uuid ?? null);
                                  }}
                                >Assign to me</span>
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="date-time flex flex-wrap items-start gap-[20px]">
                          <Field name="startByDate">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <div className="task-startdate-filter flex flex-col flex-1 relative min-w-[180px] max-w-[120px] ">
                                <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[5px]">
                                  Start By
                                </label>
                                <DatePickerStateProvider
                                  defaultSelectedDates={[value]}
                                  placeholder="SELECT DATE"
                                  onDateChange={dates => {
                                    const date = format(new Date(dates[0]), 'yyyy-MM-dd');
                                    setFieldValue(name, date);
                                  }}
                                  isSingleDateSelection
                                  render={(params: IDatePickerSateParams) => {
                                    return (
                                      <StyledDateRangeInput
                                        displayString={params.displayString}
                                        currentDate={params.datePickerCurrentDate}
                                        totalNights={params.totalNights}
                                        selectedDates={params.selectedDates}
                                        onDayClick={params.handleDayClick}
                                        onDayMouseOver={params.handleDateMouseOver}
                                        showDatePicker={params.showDatePicker}
                                        onNextClick={params.incrementDate}
                                        onPrevClick={params.decrementDate}
                                        onMouseDown={params.toggleDatePicker}
                                        onClickOutside={params.hideDatePicker}
                                        calendarCount={1}
                                        customWidth={1}
                                        noPortal
                                        showTotalNights={false}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                          <Field name="endDate">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <div className="task-enddate-filter flex flex-col flex-1 min-w-[180px] max-w-[120px] ">
                                <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[5px]">
                                  Deadline Date *
                                </label>
                                <DatePickerStateProvider
                                  defaultSelectedDates={[value]}
                                  placeholder="SELECT DATE"
                                  onDateChange={dates => {
                                    const date = format(new Date(dates[0]), 'yyyy-MM-dd');
                                    setFieldValue(name, date);
                                  }}
                                  isSingleDateSelection
                                  render={(params: IDatePickerSateParams) => {
                                    return (
                                      <StyledDateRangeInput
                                        datePickerLeft
                                        displayString={params.displayString}
                                        currentDate={params.datePickerCurrentDate}
                                        totalNights={params.totalNights}
                                        selectedDates={params.selectedDates}
                                        onDayClick={params.handleDayClick}
                                        onDayMouseOver={params.handleDateMouseOver}
                                        showDatePicker={params.showDatePicker}
                                        onNextClick={params.incrementDate}
                                        onPrevClick={params.decrementDate}
                                        calendarCount={1}
                                        customWidth={1}
                                        noPortal
                                        onMouseDown={params.toggleDatePicker}
                                        onClickOutside={params.hideDatePicker}
                                        showTotalNights={false}
                                      />
                                    );
                                  }}
                                />
                                {form.touched.endDate && form.errors.endDate && (
                                  <p className="text-red-95 text-13px leading-2xs font-pt-sans mt-5px mb-0 ">
                                    {form.errors.endDate}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                          <Field
                            as={TextInput}
                            type="time"
                            name="endTime"
                            id="endTime"
                            className="endTime flex-1 max-w-[120px] min-w-[120px]"
                            label="Deadline Time *"
                            errorMessage={form.touched.endTime && form.errors.endTime ? form.errors.endTime : null}
                            errorClassName="endTime-error"
                            onChange={(event) => {
                              let timeValue = event.target.value;
                              // Check if seconds are missing and append ":00"
                              if (timeValue.length === 5) {
                                timeValue += ":00";
                              }
                              form.setFieldValue("endTime", timeValue);
                            }}
                          />
                        </div>
                        <TaskReminders taskId={taskId} reminders={taskReminders} />
                      </div>
                    </div>
                  </div>

                  {canEdit && (
                    <div className="buttons flex gap-[10px] mt-[20px]">
                      <FluidButton type="primary" submit isLoading={isSaving} onClick={validateReminders}>
                        Save
                      </FluidButton>
                    </div>
                  )}
                </form>
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
