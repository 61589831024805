import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { COGNITO_ENABLE_FLOW, REDIRECT_COGNITO_URI_IF_LOCALHOST } from 'config';

export const Statements = { title: 'Statements', href: `/statement` };
const WithGlobalStatements = () => {
  const dynamicParameters = ParameterService.getParameters();
  return {
    ...Statements,
    href: dynamicParameters.ENABLE_GLOBAL_STATEMENT_ROUTE ? `/statement/all` : `/statement`,
  };
};
const ProposalsV2 = {
  title: 'Proposals',
  href: '/proposals-v2',
};

export default () => {
  const dynamicParameters = ParameterService.getParameters();
  let redirectUri = dynamicParameters.COGNITO_CALLBACK_FOR_SIGNINUP;
  if (location.host === 'localhost:8080') {
    redirectUri = REDIRECT_COGNITO_URI_IF_LOCALHOST;
  }
  return {
    loggedOut: [
      {
        title: 'Create an account',
        href: '/sign-up',
        id: 'sign-up',
      },
      {
        title: 'Login',
        href: COGNITO_ENABLE_FLOW
          ? `${dynamicParameters.COGNITO_DOMAIN}/login?client_id=${dynamicParameters.COGNITO_CLIENT_ID}&response_type=code&scope=email+openid&redirect_uri=${redirectUri}`
          : '/login',
        inverse: true,
        bold: true,
        hard: true,
      },
    ],

    default: [
      {
        title: 'Home',
        id: 'home',
        href: '/',
      },
      ProposalsV2,
      {
        title: 'Enquiries & Bookings',
        href: `/bookings`,
      },
      ...(dynamicParameters.ENABLE_TRAVEL_AGENT_STATEMENTS_LINK ? Statements : {}),
    ],
    rl: [
      {
        title: 'Suppliers',
        href: `/suppliers`,
      },
      {
        title: 'Hotels',
        href: `/hotel-admin`,
        hard: true,
      },
      {
        title: 'Offers',
        href: `/offers`,
      },
      {
        title: 'Rate Breaks',
        href: `/rate-breaks`,
      },
    ],
    sr: [
      {
        title: 'Dashboard',
        href: '/',
      },
      {
        title: 'Travel Agents',
        href: '/travel-agents',
      },
      {
        title: 'Hotels',
        href: `/hotel-admin`,
        hard: true,
      },
      ProposalsV2,
      {
        title: 'Enquiries & Bookings',
        href: `/bookings`,
      },
      Statements,
    ],
    finance: [
      {
        title: 'Enquiries & Bookings',
        href: `/bookings`,
      },
      WithGlobalStatements(),
    ],
    admin: [
      {
        title: 'TC',
        href: '/travel-companies',
      },
      {
        title: 'Suppliers',
        href: `/suppliers`,
      },
      {
        title: 'Hotels',
        href: `/hotel-admin`,
        hard: true,
      },
      {
        title: 'Offers',
        href: `/offers`,
      },
      {
        title: 'Rate Breaks',
        href: `/rate-breaks`,
      },
      {
        title: 'Live Rates',
        href: `/live-rates`,
      },
      ProposalsV2,
      {
        title: 'Bookings',
        href: `/bookings`,
      },
      WithGlobalStatements(),
    ],
  };
};
