import { createSelector } from 'reselect';
import { IVoucherSubdomain, IVoucherSubdomainNetworkRequests } from './types';
import { voucherSubdomainSelector } from '../../selectors';

export const formDataSelector = createSelector(
  voucherSubdomainSelector,
  (subdomain: IVoucherSubdomain) => subdomain.formData
);

export const formErrorsSelector = createSelector(
  voucherSubdomainSelector,
  (subdomain: IVoucherSubdomain) => subdomain.formErrors
);

export const networkRequestsSelector = createSelector(
  voucherSubdomainSelector,
  (subdomain: IVoucherSubdomain): IVoucherSubdomain['networkRequests'] => subdomain.networkRequests
);

export const voucherFormViewLoadSelector = createSelector(
  networkRequestsSelector,
  (networkRequests: IVoucherSubdomainNetworkRequests): IVoucherSubdomainNetworkRequests['voucherFormViewLoad'] =>
    networkRequests.voucherFormViewLoad
);

export const voucherDownloadLoadSelector = createSelector(
  networkRequestsSelector,
  (networkRequests: IVoucherSubdomainNetworkRequests) => networkRequests.voucherDownloadLoad
);

export const voucherLogoSelector = createSelector(
  voucherSubdomainSelector,
  (subdomain: IVoucherSubdomain) => subdomain.voucherLogo
);

export const langSelector = createSelector(
  voucherSubdomainSelector,
  (subdomain: IVoucherSubdomain) => subdomain.lang
);

export const mealPlanDetailsSelector = createSelector(
  voucherSubdomainSelector,
  (subdomain: IVoucherSubdomain) => subdomain.formData.selectedMealPlanDetails
);
