import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, IPaymentMethodsGetInContextResponse } from 'services/BookingManagerApi';
import {
  GET_PAYMENT_METHODS_REQUEST,
  getPaymentMethodsSuccessAction,
  getPaymentMethodsFailureAction,
} from '../actions';
import { bookingUuidSelector } from '../../../selectors';
import { enqueueNotification } from 'store/modules/ui';

export function* getPaymentMethodsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IPaymentMethodsGetInContextResponse> = yield call(
      bookingManagerApi.getBookingPaymentMethods,
      bookingUuid

    );
    yield put(getPaymentMethodsSuccessAction(result.data.paymentMethods, result.data.defaultPaymentMethodPerCurrency));
  } catch (e) {
    yield put(getPaymentMethodsFailureAction());
    yield put(
      enqueueNotification({
        message: 'Failed to fetch booking payment methods',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchBookingManagerGetPaymentMethods() {
  yield takeLatest([GET_PAYMENT_METHODS_REQUEST], getPaymentMethodsSaga);
}
