import { EUploadTag, IUploadFileInfo } from 'services/BackendApi/types/UploadResponse';

export const omitEmptyStrings = (obj: any) => {
  const result = { ...obj };
  for (const key in result) {
    if (result[key] === '') {
      result[key] = undefined;
    }
  }
  return result;
};

export const replaceEmptyStringsWithNull = (obj: any) => {
  const result = { ...obj };
  for (const key in result) {
    if (result[key] === '') {
      result[key] = null;
    }
  }
  return result;
};

export const isUploadAnImage = (upload: IUploadFileInfo) => {
  return upload.tag === EUploadTag.PHOTO || upload.tag === EUploadTag.FEATURED_PHOTO;
};

export const categoryToLabelMap = {
  perAccommodationProduct: 'Per accomodation',
  perBooking: 'Per booking',
  perNight: 'Per night',
  perPerson: 'Per person',
  perPersonPerNight: 'Per person per night',
};
