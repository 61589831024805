import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi } from 'services/BookingManagerApi';
import { bookingUuidSelector } from '../../selectors';
import { enqueueNotification } from 'store/modules/ui';
import { saveAs } from 'file-saver';
import {
  EUploadTag,
  IVoucherDownloadPayload,
  Upload,
  EVoucherLang
} from 'services/BackendApi/types';
import { getUploadsRequestAction } from '../uploads/actions';
import { guestToString } from 'utils/guestInformation';
import {
  GET_VOUCHER_FORM_VIEW_REQUEST,
  getVoucherFormViewFailureAction,
  getVoucherFormViewSuccessAction,
  DOWNLOAD_VOUCHER_REQUEST,
  downloadVoucherSuccessAction,
  downloadVoucherFailureAction,
  setVoucherMealPlanDescriptionAction,
} from './actions';
import { 
  formDataSelector,
  voucherLogoSelector,
  langSelector,
  mealPlanDetailsSelector
} from './selectors';
import { IVoucherFormView } from 'services/BackendApi';
import { IVoucherSubdomainFormData, VOUCHER_INDEX_SEPARATOR } from './types';
import { EPdfLogo } from '../breakdown/model';

export function* getVoucherFormViewSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IVoucherFormView> = yield call(bookingManagerApi.getVoucherFormView, bookingUuid);

    yield put(getVoucherFormViewSuccessAction(result.data));
  } catch (e) {
    yield put(getVoucherFormViewFailureAction(e));
  }
}

export function* downloadVoucherSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    // @see OWA-1944, we might need to do this in future but not right now
    // const useTaLogo = action.logoType === EBreakdownLogo.TA_LOGO;

    const voucherFormData: IVoucherSubdomainFormData = yield select(formDataSelector);
    const voucherLogo: EPdfLogo = yield select(voucherLogoSelector);
    const lang: EVoucherLang = yield select(langSelector);

    if (voucherFormData.selectedGuests.length <= 0) {
      yield put(downloadVoucherFailureAction('No guests selected'));
      yield put(
        enqueueNotification({
          message: 'Cannot generate voucher PDF without any guests',
          options: { variant: 'error' },
        })
      );
      return;
    }

    if (
      !voucherFormData.selectedGuests[0].arrivalFlightNo ||
      voucherFormData.selectedGuests[0].arrivalFlightNo === '' ||
      !voucherFormData.selectedGuests[0].departureFlightNo ||
      voucherFormData.selectedGuests[0].departureFlightNo === ''
    ) {
      yield put(downloadVoucherFailureAction('No flight information'));
      yield put(
        enqueueNotification({
          message: 'Cannot generate voucher PDF without flight information',
          options: { variant: 'error' },
        })
      );
      return;
    }

    const selectedAccomodationTitles = voucherFormData.selectedAccomodationIndexes.map(sa => {
      return voucherFormData.availableAccomodations[sa].title;
    });

    const mealPlanDetails = yield select(mealPlanDetailsSelector);
    const selectedMealPlans = voucherFormData.selectedMealPlansCompoundIndexes.map((smp, index) => {
      const [accomIndex, mealPlanIndex] = smp.split(VOUCHER_INDEX_SEPARATOR);
      const mealplan = voucherFormData.availableAccomodations[parseInt(accomIndex)].mealPlan[parseInt(mealPlanIndex)];
      if (index === 0) {
        mealplan.description = mealPlanDetails;
      }
      return mealplan;
    });

    const payload: IVoucherDownloadPayload = {
      selectedGuests: voucherFormData.selectedGuests.map(guestToString),
      selectedTransfers: voucherFormData.selectedTransfers,
      selectedKeyDetails: selectedAccomodationTitles,
      selectedMealPlans: selectedMealPlans,
      selectedGroundServices: voucherFormData.selectedGroundServices,
      notes: voucherFormData.notes,
      resortName: voucherFormData.resortName,
      resortAddress: voucherFormData.resortAddress,
      resortPhone: voucherFormData.resortPhone,
      resortConfirmationNumber: voucherFormData.resortConfirmationNumber,
      arrivalTime: voucherFormData.selectedGuests[0].arrivalTime || '',
      departureTime: voucherFormData.selectedGuests[0].departureTime || '',
      resortCountryCode: voucherFormData.resortCountryCode,
      resortRegion: voucherFormData.resortRegion,
      arrivalDate: voucherFormData.selectedGuests[0].arrivalDate || '',
      flightArrivalNo: voucherFormData.selectedGuests[0].arrivalFlightNo || '',
      departureDate: voucherFormData.selectedGuests[0].departureDate || '',
      flightDepartureNo: voucherFormData.selectedGuests[0].departureFlightNo || '',
      policiesAndRestrictions: voucherFormData.policiesAndRestrictions || undefined,
      voucherLogo,
      lang,
      cip: voucherFormData.cip,
    };

    const result: AxiosResponse<Upload> = yield call(bookingManagerApi.downloadVoucher, bookingUuid, payload);

    // 3. Download pdf that was generated
    const upload = result.data;
    saveAs(upload.url, upload.filename);

    // 4. Reload the list of voucher pdfs
    yield put(getUploadsRequestAction(EUploadTag.VOUCHER));

    yield put(downloadVoucherSuccessAction());
    yield put(
      enqueueNotification({
        message: 'Voucher PDF was generated',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(downloadVoucherFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was a problem generating voucher PDF',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchVoucherSaga() {
  yield all([
    takeLatest([GET_VOUCHER_FORM_VIEW_REQUEST], getVoucherFormViewSaga),
    takeLatest([DOWNLOAD_VOUCHER_REQUEST], downloadVoucherSaga),
  ]);
}
