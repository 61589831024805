import React from 'react';
import { BookingBuilderResponse } from 'services/BackendApi';
import { CheckmarkComponent } from 'ui/Icons';
import { theme } from '../../../../tailwind.config';

export interface IRHPProductsProps {
  selectedBuild: BookingBuilderResponse;
}

export const RHPOffers: React.FC<IRHPProductsProps> = ({ selectedBuild }) => {
  const appliedOffers = selectedBuild.appliedOfferNames;
  return (
    <div className="flex flex-col gap-[3px] rhp-offers">
      {appliedOffers.map(offerName => (
        <div key={offerName} className="flex items-center gap-[5px]">
          <CheckmarkComponent fill={theme.colors['red-92']} />
          <p key={offerName} className="rhp-offer font-pt-sans text-[13px] text-red-92 m-0">{offerName}</p>
        </div>
      ))}
    </div>
  )
}
