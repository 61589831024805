import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { IPaymentMethod, IPaymentMethodPerCurrency } from 'services/BookingManagerApi';
import { IFinanceRow } from 'services/BookingManagerApi/types/FinanceResponse';

export interface IFinanceDocumentVersionListVersion {
  version: number;
  timestamp: string;
  user?: {
    uuid: string;
    email: string;
  };
}

export interface IBookingManagerFinanceSubdomain {
  financeDocument: IFinanceDocument;
  financeDocumentBalance: IFinanceDocumentBalance;
  isAddModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isEditModalOpen: boolean;
  financeTableTypeToRender: EFinanceTableTypes;
  versionList: IFinanceDocumentVersionListVersion[];
  activeVersion: number | null;
  deleteRowIndex: number | null;
  editRowIndex: number | null;
  paymentMethods: {
    list: IPaymentMethod[] | null;
    defaultPaymentMethodPerCurrency: IPaymentMethodPerCurrency[];
  };
  errorMessages: {
    addRowAndSaveError: string | null;
    editRowAndSaveError: string | null;
  };
  networkRequests: IBookingManagerFinanceSubdomainNetworkRequests;
}

export enum EFinanceTableTypes {
  SALES = 'Sales',
  PURCHASE = 'Purchase',
}

export enum EFinanceRowSummingType {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
}

export interface IFinanceDocument {
  rows: IFinanceRow[];
}

export interface IFinanceDocumentBalance {
  totalCreditCents: number;
  totalDebitCents: number;
  totalOutstandingCents: number;
}

export interface IBookingManagerFinanceSubdomainNetworkRequests {
  financeDocumentLoad: ENetworkRequestStatus;
  financeDocumentBalanceLoad: ENetworkRequestStatus;
  saveAndUpdatefinanceDocumentLoad: ENetworkRequestStatus;
  addRowAndSaveLoad: ENetworkRequestStatus;
  deleteRowAndSaveLoad: ENetworkRequestStatus;
  editRowAndSaveLoad: ENetworkRequestStatus;
  financeDocumentVersionListLoad: ENetworkRequestStatus;
  paymentMethodsLoad: ENetworkRequestStatus;
}
