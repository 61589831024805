import React, { ReactNode, useCallback, useState } from 'react';

interface IShowMoreTextProps {
  linkClassname: string;
  textClassname: string;
  text: string;
  linkLabelWhenOpen: string;
  linkLabelWhenCollapsed: string;
  maxVisibleCharacters: number;
  isParsed?: boolean;
}

export const ShowMoreText: React.FC<IShowMoreTextProps> = React.memo(
  ({
    maxVisibleCharacters,
    linkClassname,
    textClassname,
    text,
    linkLabelWhenOpen,
    linkLabelWhenCollapsed,
    isParsed,
  }) => {
    const parseText = (text: string, isParsed?: boolean): string | ReactNode => {
      return isParsed
        ? text
            .split('\n')
            .map((t, i, a) => (a.length - 1 === i ? <span key={`t-${i}`}>{t}</span> : <div key={`t-${i}`}>{t}</div>))
        : text;
    };

    const isTextTooLong = text.length > maxVisibleCharacters;
    const longVersion = parseText(text, isParsed);
    const shortVersion = parseText(`${text.slice(0, maxVisibleCharacters)}...`, isParsed);

    const [isLongVersion, setIsLongVersion] = useState(false);
    const linkLabel = isLongVersion ? linkLabelWhenOpen : linkLabelWhenCollapsed;

    const toggleShowMore = useCallback(() => {
      setIsLongVersion(!isLongVersion);
    }, [isLongVersion]);

    return (
      <div className={textClassname}>
        {isLongVersion ? longVersion : shortVersion}
        {isTextTooLong ? (
          <a className={linkClassname} onClick={toggleShowMore}>
            {linkLabel}
          </a>
        ) : null}
      </div>
    );
  }
);
